import { FaLocationArrow } from 'react-icons/fa';
import './styles/order.scss';
import { useEffect, useRef, useState } from 'react';
import { AiTwotonePhone, AiTwotoneShop } from 'react-icons/ai';
import { RiFilter2Fill } from 'react-icons/ri';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import dayjs from 'dayjs';
import './styles/notAssignedOrder.scss';
import {
  MdEmail,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineKeyboardBackspace,
  MdPictureAsPdf,
} from 'react-icons/md';
import { GetRequest } from '../../../component/Request';
import NotAssignedOrder from './NotAssignedOrder';
import { CiCalendarDate } from 'react-icons/ci';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DeleteOrderPopup from './DeleteOrderPopup';
import EditQuantityPopup from './EditQuantityPopup';
import { RxCross2 } from 'react-icons/rx';
import ChangeTheOrderStatus from './ChangeOrderStatus';
import { useReactToPrint } from 'react-to-print';
import OrderBill from './OrderBill';
import { OrderStatusEnum } from './orderStatusEnum/orderStatus.enum';
import DeclineOrderPopUp from './DeclineOrderPopUp';

// require('dotenv').config();

export default function Order() {
  const [
    orderIdToConvertInShippedOrDelivered,
    setOrderIdToConvertInShippedOrDelivered,
  ] = useState<{ _id: string; status: string }>({
    _id: '',
    status: '',
  });
  const [showDeclineOrderForm, setShowDeclineOrderForm] =
    useState<boolean>(false);
  const [orderToBeDeclined, setOrderToBeDeclined] = useState<any>('');
  const [buyerStoreForDecliningOrder, setBuyerStoreForDecliningOrder] =
    useState<any>({});
  const [orderForGeneratingBill, setOrderForGeneratingBill] = useState<any>('');
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status: string | any = searchParams.get('status');
  const [showFilters, setShowFilters] = useState(false);
  const [allOrder, setAllOrder] = useState<any>([]);
  const [selectedFromDate, setSelectedFromDate] = useState<any>(
    searchParams.get('from-date')
  );
  const [selectedToDate, setSelectedToDate] = useState<any>(
    searchParams.get('to-date')
  );
  const [newOrder, setNewOrder] = useState([]);
  const [showNewOrder, setShowNewOrder] = useState<boolean>(false);
  const [totalNewOrderCount, setTotalNewOrderCount] = useState(0);
  const [orderStatus, setOrderStatus] = useState<string>(status);
  const [pageNumber, setPageNumber] = useState(1);
  const allOrderListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreOrderOrNot, setHasMoreOrderOrNot] = useState(false);
  // for choosing buyer
  const buyerRef = useRef<any>(null);
  const buyerRefForCloseOnClickingOutside = useRef<any>(null);
  const [buyerList, setBuyerList] = useState([]);
  const [isBuyerOpen, setIsBuyerOpen] = useState(false);
  const [activeBuyer, setActiveBuyer] = useState<any>({
    id: searchParams.get('buyer-store'),
    name: searchParams.get('buyer-name'),
  });
  const [inputValueForBuyer, setInputValueForBuyer] = useState<any>('');
  const [pageNumberForBuyer, setPageNumberForBuyer] = useState(1);
  const [stopInfiniteScrollingForBuyer, setStopInfiniteScrollingForBuyer] =
    useState(false);

  // for route
  const routeRef = useRef<any>(null);
  const routeRefForCloseOnClickingOutside = useRef<any>(null);
  const [routeList, setRouteList] = useState([]);
  const [activeRoute, setActiveRoute] = useState<any>({
    id: searchParams.get('route'),
    name: searchParams.get('route-name'),
  });
  const [pageNumberForRoute, setPageNumberForRoute] = useState(1);
  const [stopInfiniteScrollingForRoute, setStopInfiniteScrollingForRoute] =
    useState(false);
  // for schedule
  const [activeSchedule, setActiveSchedule] = useState<any>({
    id: searchParams.get('route-schedule') && '',
    startDateAndTime:
      convertISOToDateTime(searchParams.get('schedule-start-date-and-time')) &&
      '',
  });
  const [scheduleList, setScheduleList] = useState([]);
  const scheduleRefForCloseOnClickingOutside = useRef<any>(null);
  const scheduleRef = useRef<any>(null);
  const [pageNumberForSchedule, setPageNumberForSchedule] = useState(1);
  const [
    stopInfiniteScrollingForSchedule,
    setStopInfiniteScrollingForSchedule,
  ] = useState(false);
  const [errorForSchedule, setErrorForSchedule] = useState<any>('');
  // page number for new order pop up
  const [pageNumberForNewOrder, setPageNumberForNewOrder] = useState(1);
  const newOrderListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreNewOrderOrNot, setHasMoreNewOrderOrNot] = useState(false);
  //
  const [updatedStateAccToQueryParam, setUpdatedStateAccToQueryParam] =
    useState<Boolean>(false);
  const editAndDeleteRef = useRef<any>(null);
  let navigate = useNavigate();
  const [
    productIdAndOrderIdForEditingQuantityOrDeletingItem,
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem,
  ] = useState<any>(false);
  const billRef = useRef<any>(null);

  const updateStateAccToQueries = async () => {
    if (searchParams.get('from-date')) {
      await setSelectedFromDate(() => searchParams.get('from-date'));
    }
    if (searchParams.get('to-date')) {
      await setSelectedToDate(() => searchParams.get('to-date'));
    }
    if (searchParams.get('route')) {
      await setActiveRoute((prevState: any) => {
        return {
          ...prevState,
          id: searchParams.get('route'),
        };
      });
    }
    if (searchParams.get('route-name')) {
      await setActiveRoute((prevState: any) => {
        return {
          ...prevState,
          name: searchParams.get('route-name'),
        };
      });
    }
    if (searchParams.get('route-schedule')) {
      await setActiveSchedule((prevState: any) => {
        return {
          ...prevState,
          id: searchParams.get('route-schedule'),
        };
      });
    }
    if (searchParams.get('schedule-start-date-and-time')) {
      await setActiveSchedule((prevState: any) => {
        return {
          ...prevState,
          startDateAndTime: convertISOToDateTime(
            searchParams.get('schedule-start-date-and-time')
          ),
        };
      });
    }
    if (searchParams.get('buyer-store')) {
      await setActiveBuyer((prevState: any) => {
        return {
          ...prevState,
          id: searchParams.get('buyer-store'),
        };
      });
    }
    if (searchParams.get('buyer-name')) {
      await setActiveBuyer((prevState: any) => {
        return {
          ...prevState,
          name: searchParams.get('buyer-name'),
        };
      });
    }

    // await fetchOrderAccToStatus();
    setUpdatedStateAccToQueryParam(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await updateStateAccToQueries();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (updatedStateAccToQueryParam) {
      fetchOrderAccToStatus(capitalizeWords(status));
      setOrderStatus(() => capitalizeWords(status));
      setPageNumber(1);
    }
  }, [updatedStateAccToQueryParam, status]);

  const convertToDatetimeLocalFormat = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);
    const year = dateTime.getFullYear();
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  function convertISOToDateTime(isoString: any) {
    // Create a new Date object from the ISO string
    const date = new Date(isoString);

    // Extract date and time components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12' in 12-hour clock

    // Assemble the formatted date and time
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

    return formattedDateTime;
  }

  const convertTimestampToFormattedDate = (timestamp: any) => {
    const inputDate = new Date(timestamp);

    const day = inputDate.getDate().toString().padStart(2, '0');
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const year = inputDate.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  };

  const fetchSchedule = async () => {
    if (!activeRoute.id) {
      setErrorForSchedule('Please Select Route To See The Schedules');
    } else {
      setErrorForSchedule('');
      await GetRequest(
        `route/${activeRoute.id}/schedule?page=${pageNumberForSchedule}&limit=8`
      )
        .then(async (res) => {
          if (res.status == 200) {
            let response = await res.data.data.map((schedule: any) => {
              return {
                id: schedule._id,
                startDateAndTime: convertISOToDateTime(
                  schedule.startDateAndTime
                ),
              };
            });

            setScheduleList((prevState: any) => {
              return prevState.concat(response);
            });

            if (res.data.data.length === 0 || res.data.data.length < 8) {
              return setStopInfiniteScrollingForSchedule(true);
            } else if (res.data.data.length === 5) {
              return setStopInfiniteScrollingForSchedule(false);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching route');
        });
    }
    console.log(activeSchedule, 'this is the active schedule ');
  };

  const handleActiveSchedule = async (schedule: any) => {
    setActiveSchedule(() => schedule);
    setScheduleList([]);
  };

  const handleClickOutsideForSchedule = (event: any) => {
    if (
      routeRefForCloseOnClickingOutside.current &&
      !routeRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setPageNumberForSchedule(1);
      setScheduleList([]);
    }
  };

  const onIntersectionForSchedule = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && !stopInfiniteScrollingForSchedule) {
      setPageNumberForSchedule((prevPageNumber: any) => prevPageNumber + 1);

      await GetRequest(
        `route/${activeRoute.id}/schedule?page=${
          pageNumberForSchedule + 1
        }&limit=8`
      )
        .then(async (res) => {
          if (res.status == 200) {
            let response = await res.data.data.map((schedule: any) => {
              return {
                id: schedule._id,
                startDateAndTime: schedule.startDateAndTime,
              };
            });

            setScheduleList((prevState: any) => {
              return prevState.concat(response);
            });

            if (res.data.data.length === 0 || res.data.data.length < 8) {
              return setStopInfiniteScrollingForSchedule(true);
            } else if (res.data.data.length === 5) {
              return setStopInfiniteScrollingForSchedule(false);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching route');
        });
    }
  };

  useEffect(() => {
    if (scheduleRef.current) {
      const observer = new IntersectionObserver(onIntersectionForSchedule);
      observer.observe(scheduleRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [scheduleList.length > 0, stopInfiniteScrollingForSchedule]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForSchedule);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForSchedule);
    };
  }, []);

  // route
  const fetchRoute = async () => {
    await GetRequest(`route/my-route?page=${pageNumberForRoute}&limit=8`)
      .then(async (res) => {
        if (res.status === 200) {
          let response = await res.data.data.map((route: any) => {
            return { id: route._id, name: route.name };
          });

          setRouteList((prevState: any) => {
            return prevState.concat(response);
          });

          if (res.data.data.length < 8) {
            return setStopInfiniteScrollingForRoute(true);
          } else {
            return setStopInfiniteScrollingForRoute(false);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching route');
      });
  };

  const handleClickOutsideForRoute = (event: any) => {
    if (
      routeRefForCloseOnClickingOutside.current &&
      !routeRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setPageNumberForRoute(1);
      setRouteList([]);
    }
  };

  const onIntersectionForRoute = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && !stopInfiniteScrollingForRoute) {
      setPageNumberForRoute((prevPageNumber: any) => prevPageNumber + 1);

      await GetRequest(`route/my-route?page=${pageNumberForRoute + 1}&limit=8`)
        .then(async (res) => {
          if (res.status == 200) {
            let response = await res.data.data.map((route: any) => {
              return { id: route._id, name: route.name };
            });

            setRouteList((prevState: any) => {
              return prevState.concat(response);
            });

            if (res.data.data.length === 0 || res.data.data.length < 8) {
              return setStopInfiniteScrollingForRoute(true);
            } else if (res.data.data.length === 5) {
              return setStopInfiniteScrollingForRoute(false);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching route');
        });
    }
  };

  useEffect(() => {
    if (routeRef.current) {
      const observer = new IntersectionObserver(onIntersectionForRoute);
      observer.observe(routeRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [routeList.length > 0, stopInfiniteScrollingForRoute]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForRoute);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForRoute);
    };
  }, []);

  const handleActiveRoute = async (route: any) => {
    setActiveRoute({
      name: route.name,
      id: route.id,
    });
    setPageNumber(1);
    setBuyerList([]);
    setRouteList([]);
    setErrorForSchedule('');
  };

  // buyer
  const handleInputChangeForBuyer = async (event: any) => {
    const value = event.target.value;
    setInputValueForBuyer(value);
    setActiveBuyer({});
    setBuyerList([]);
    let url = value
      ? `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&name=${value}&page=${pageNumberForBuyer}&limit=8`
      : `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&page=${pageNumberForBuyer}&limit=8`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          let response = await res.data.data.map((store: any) => {
            return { id: store._id, name: store.name };
          });
          setBuyerList((prevState: any) => {
            return response;
          });
          if (res.data.data.length === 0 || res.data.data.length < 8) {
            return setStopInfiniteScrollingForBuyer(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrollingForBuyer(false);
            return setIsBuyerOpen(true);
          }
        }
      })
      .catch((err) => {
        setIsBuyerOpen(false);
        console.log(err, 'this is err in fetching brands');
      });
  };

  const handleActiveBuyer = async (buyerInfo: any) => {
    setActiveBuyer({
      name: buyerInfo.name,
      id: buyerInfo.id,
    });
    setInputValueForBuyer(buyerInfo.name);
    setIsBuyerOpen(false);
    setBuyerList([]);
  };

  const fetchBuyer = async () => {
    let url = '';

    if (inputValueForBuyer) {
      url = `store?name=${inputValueForBuyer}&buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&page=${pageNumberForBuyer}&limit=8`;
    } else {
      url = `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&page=${pageNumberForBuyer}&limit=8`;
    }
    await GetRequest(url)
      .then(async (res) => {
        if (res.status == 200) {
          let response = await res.data.data.map((store: any) => {
            return { id: store._id, name: store.name };
          });

          if (res.data.data.length > 0) {
            setIsBuyerOpen(true);
          }

          setBuyerList((prevState: any) => {
            return prevState.concat(response);
          });

          console.log(response, buyerList, 'this is ithe response');
          if (res.data.data.length === 0 || res.data.data.length < 8) {
            return setStopInfiniteScrollingForBuyer(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrollingForBuyer(false);
            return setIsBuyerOpen(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching brand for the first');
      });
  };

  const handleClickOutsideForBuyer = (event: any) => {
    if (
      buyerRefForCloseOnClickingOutside.current &&
      !buyerRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsBuyerOpen(false);
      setPageNumberForBuyer(1);
      setBuyerList([]);
    }
  };

  const onIntersectionForBuyer = async (entries: any) => {
    const firstEntry = entries[0];

    if (
      firstEntry.isIntersecting &&
      !stopInfiniteScrollingForBuyer &&
      isBuyerOpen
    ) {
      setPageNumberForBuyer((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (buyerRef.current) {
      const observer = new IntersectionObserver(onIntersectionForBuyer);
      observer.observe(buyerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [buyerList.length > 0, stopInfiniteScrollingForBuyer]);

  useEffect(() => {
    if (isBuyerOpen) {
      fetchBuyer();
    }
  }, [pageNumberForBuyer]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForBuyer);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForBuyer);
    };
  }, []);

  const handleFromDateChange = async (date: any) => {
    setSelectedFromDate(() => date.target.value);
  };

  const handleToDateChange = async (date: any) => {
    setSelectedToDate(() => date.target.value);
  };

  const applyFilter = async () => {
    let url = `order?status=${orderStatus}&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=true&page=1&limit=8`;
    let navigationUrl = `/order?status=${orderStatus.toLocaleLowerCase()}`;

    if (selectedFromDate) {
      let date = dayjs(selectedFromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      url = url + `&from-date=${date}`;
      navigationUrl = navigationUrl + `&from-date=${selectedFromDate}`;
    }
    if (selectedToDate) {
      let date = dayjs(selectedToDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      url = url + `&to-date=${date}`;
      navigationUrl = navigationUrl + `&to-date=${selectedToDate}`;
    }

    if (activeRoute.id) {
      url = url + `&route=${activeRoute.id}`;
      navigationUrl =
        navigationUrl +
        `&route=${activeRoute.id}&route-name=${activeRoute.name}`;
    }

    if (activeBuyer.id) {
      url = url + `&buyer-store=${activeBuyer.id}`;
      navigationUrl =
        navigationUrl +
        `&buyer-store=${activeBuyer.id}&buyer-name=${activeBuyer.name}`;
    }

    if (activeSchedule.id) {
      url = url + `&route-schedule=${activeSchedule.id}`;
      navigationUrl =
        navigationUrl +
        `&route-schedule=${activeSchedule.id}&schedule-start-date-and-time=${activeSchedule.startDateAndTime}`;
    }

    navigate(navigationUrl);

    await GetRequest(url).then(async (res) => {
      if (res.data.statusCode === 200) {
        let response = res.data.data.map((obj: any) => {
          if (obj.status === OrderStatusEnum.Ordered) {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
              itemList: obj?.itemList.map((data: any) => {
                return { ...data, showEditAndDeleteOption: false };
              }),
            };
          } else {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
            };
          }
        });

        setAllOrder(response);

        if (res.data.data.length < 8) {
          setHasMoreOrderOrNot(false);
        } else {
          setHasMoreOrderOrNot(true);
        }
      }
    });
  };

  function formatDateToDDMMMYY(order: any) {
    let inputDate: any;

    if (
      order.status === OrderStatusEnum.Ordered ||
      order.status === OrderStatusEnum.Shipped
    ) {
      inputDate = order.updatedAt;
    }

    if (order.status === OrderStatusEnum.Preparing) {
      inputDate = order.invoiceDate;
    }

    if (order.status === OrderStatusEnum.Delivered) {
      inputDate = order.deliveredDate;
    }

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthAbbreviation = months[date.getUTCMonth()];
    const year = String(date.getUTCFullYear()).slice(-4);

    const formattedDate = `${day}-${monthAbbreviation}-${year}`;

    return formattedDate;
  }

  function capitalizeWords(inputString: any) {
    return inputString?.replace(/\b\w/g, (match: any) => match.toUpperCase());
  }

  useEffect(() => {
    fetchNewOrderCount();
    setOrderStatus(() => capitalizeWords(status));
  }, []);

  const fetchNewOrderCount = async () => {
    await GetRequest(
      `order/count?status=Ordered&seller-buyer-route-mapped=false`
    ).then((res) => {
      if (res.data.statusCode === 200) {
        setTotalNewOrderCount(res.data.data);
      }
    });
  };

  const fetchNotAssignedOrder = async () => {
    await GetRequest(
      `order?status=Ordered&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=false&page=${pageNumberForNewOrder}&limit=8`
    ).then((res) => {
      if (res.status === 200) {
        let response = res.data.data.map((obj: any) => {
          return {
            ...obj,
            showItem: false,
            totalAmount: obj.totalAmount.toFixed(2),
            itemList: obj?.itemList.map((data: any) => {
              return { ...data, showEditAndDeleteOption: false };
            }),
          };
        });

        setNewOrder(response);

        let navigationUrl = `/order?status=${orderStatus.toLocaleLowerCase()}`;

        if (selectedFromDate) {
          navigationUrl = navigationUrl + `&from-date=${selectedFromDate}`;
        }
        if (selectedToDate) {
          navigationUrl = navigationUrl + `&to-date=${selectedToDate}`;
        }

        if (activeRoute.id) {
          navigationUrl =
            navigationUrl +
            `&route=${activeRoute.id}&route-name=${activeRoute.name}`;
        }

        if (activeBuyer.id) {
          navigationUrl =
            navigationUrl +
            `&buyer-store=${activeBuyer.id}&buyer-name=${activeBuyer.name}`;
        }

        if (activeSchedule.id) {
          navigationUrl =
            navigationUrl +
            `&route-schedule=${activeSchedule.id}&schedule-start-date-and-time=${activeSchedule.startDateAndTime}`;
        }

        navigate(navigationUrl + '&show_new_order=true');

        setShowNewOrder(true);
      }
    });
  };

  const fetchOrderAccToStatus = async (status: any) => {
    let url = `order?status=${status}&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=true&page=${pageNumber}&limit=8`;

    if (searchParams.get('from-date') && selectedFromDate) {
      let date = dayjs(selectedFromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      url = url + `&from-date=${date}`;
    }

    if (searchParams.get('to-date') && selectedToDate) {
      let date = dayjs(selectedToDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      url = url + `&to-date=${date}`;
    }

    if (searchParams.get('route-name') && activeRoute?.id) {
      url = url + `&route=${activeRoute.id}`;
    }

    if (searchParams.get('buyer-store') && activeBuyer?.id) {
      url = url + `&buyer-store=${activeBuyer.id}`;
    }

    if (searchParams.get('route-schedule') && activeSchedule?.id) {
      url = url + `&route-schedule=${activeSchedule.id}`;
    }

    await GetRequest(url).then(async (res) => {
      if (res.data.statusCode === 200) {
        let response = res.data.data.map((obj: any) => {
          if (obj.status === OrderStatusEnum.Ordered) {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
              itemList: obj?.itemList.map((data: any) => {
                return { ...data, showEditAndDeleteOption: false };
              }),
            };
          } else {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
            };
          }
        });

        setAllOrder(response);

        if (res.data.data.length < 8) {
          setHasMoreOrderOrNot(false);
        } else {
          setHasMoreOrderOrNot(true);
        }
      }
    });
  };

  useEffect(() => {
    if (searchParams.get('show_new_order') === 'true') {
      fetchNotAssignedOrder();
    }
  }, []);

  const onIntersectionForNewOrderList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreOrderOrNot) {
      setPageNumberForNewOrder((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (newOrderListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForNewOrderList);
      observer.observe(newOrderListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [newOrder.length > 0, showNewOrder]);

  const getMoreNewOrderOnScroll = async (currentPageNumber: any) => {
    if (hasMoreOrderOrNot) {
      await GetRequest(
        `order?status=Pending&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=false&page=${currentPageNumber}&limit=8`
      ).then(async (res) => {
        if (res.data.statusCode === 200) {
          let response = res.data.data.map((obj: any) => {
            return {
              ...obj,
              showItem: false,
              totalAmount: obj.totalAmount.toFixed(2),
              itemList: obj?.itemList.map((data: any) => {
                return { ...data, showEditAndDeleteOption: false };
              }),
            };
          });
          setNewOrder(response);
          if (res.data.data.length < 8) {
            setHasMoreNewOrderOrNot(false);
          } else {
            setHasMoreNewOrderOrNot(true);
          }
        }
      });
    }
  };

  useEffect(() => {
    getMoreNewOrderOnScroll(pageNumberForNewOrder);
  }, [pageNumberForNewOrder]);

  // order acc to status with infinite scrolling

  useEffect(() => {
    getMoreOrderOnScroll();
  }, [pageNumber]);

  const onIntersectionForOrderList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreOrderOrNot) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const removeOrderAfterGettingAccepted = async (order: any) => {
    await setShowDeclineOrderForm(() => true);
    await setOrderToBeDeclined(() => order);
    await setBuyerStoreForDecliningOrder(() => order.buyerStore);
  };

  useEffect(() => {
    if (allOrderListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForOrderList);
      observer.observe(allOrderListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allOrder.length > 0, hasMoreOrderOrNot]);

  const getMoreOrderOnScroll = async () => {
    if (hasMoreOrderOrNot) {
      let url = `order?status=${capitalizeWords(
        status
      )}&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=true&page=${pageNumber}&limit=8`;

      if (searchParams.get('from-date') && selectedFromDate) {
        let date = dayjs(selectedFromDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        url = url + `&from-date=${date}`;
      }

      if (searchParams.get('to-date') && selectedToDate) {
        let date = dayjs(selectedToDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        url = url + `&to-date=${date}`;
      }

      if (searchParams.get('route-name') && activeRoute?.id) {
        url = url + `&route=${activeRoute.id}`;
      }

      if (searchParams.get('buyer-store') && activeBuyer?.id) {
        url = url + `&buyer-store=${activeBuyer.id}`;
      }

      if (searchParams.get('route-schedule') && activeSchedule?.id) {
        url = url + `&route-schedule=${activeSchedule.id}`;
      }

      await GetRequest(url).then(async (res) => {
        if (res.data.statusCode === 200) {
          let response = res.data.data.map((obj: any) => {
            if (obj.status === OrderStatusEnum.Ordered) {
              return {
                ...obj,
                showItem: false,
                totalAmount: obj.totalAmount.toFixed(2),
                itemList: obj?.itemList.map((data: any) => {
                  return { ...data, showEditAndDeleteOption: false };
                }),
              };
            } else {
              return {
                ...obj,
                showItem: false,
                totalAmount: obj.totalAmount.toFixed(2),
              };
            }
          });
          setAllOrder((prevState: any) => prevState.concat(response));
          if (res.data.data.length < 8) {
            setHasMoreOrderOrNot(false);
          } else {
            setHasMoreOrderOrNot(true);
          }
        }
      });
    }
  };

  const clearAllFilter = async () => {
    await setActiveBuyer(() => {
      return {};
    });
    await setInputValueForBuyer('');
    await setActiveRoute(() => {
      return {};
    });
    await setActiveSchedule(() => {
      return {};
    });
    await setSelectedFromDate(() => {
      return '';
    });
    await setSelectedToDate(() => {
      return '';
    });

    await navigate(`/order?status=${status?.toLocaleLowerCase()}`);
  };

  // Function to update the status parameter in the URL
  const updateStatus = (newStatus: any) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('status', newStatus);

    // Update the URL without a full page reload
    return `${location.pathname}?${urlSearchParams.toString()}`;
  };

  const handleClickOnAction = (indexOfOrder: number, indexOfItem: number) => {
    let updateOrder: any = [...allOrder];
    updateOrder[indexOfOrder].itemList[indexOfItem].showEditAndDeleteOption =
      true;
    setAllOrder((prevState: any) => {
      return updateOrder;
    });
  };

  // const handleClickOutside = async (event: any) => {
  //   if (
  //     editAndDeleteRef.current &&
  //     !editAndDeleteRef.current.contains(event.target)
  //   ) {
  //     let updateOrder = [...allOrder];
  //     updateOrder = await updateOrder.map((order: any) => {
  //       return {
  //         ...order,
  //         itemList: order.itemList.map((data: any) => {
  //           if (data.showEditAndDeleteOption === true) {
  //             return {
  //               ...data,
  //               showEditAndDeleteOption: false,
  //             };
  //           } else {
  //             return data;
  //           }
  //         }),
  //       };
  //     });
  //     console.log(updateOrder, '$$4');
  //     await setAllOrder(() => {
  //       return updateOrder;
  //     });
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const closeTheOption = async (indexOfItem: number, indexOfOrder: number) => {
    let updateOrder = [...allOrder];
    updateOrder[indexOfOrder].itemList[indexOfItem].showEditAndDeleteOption =
      false;

    setAllOrder((prevState: any) => {
      return updateOrder;
    });
    console.log(updateOrder, indexOfItem, indexOfOrder, allOrder, '$$$$$$');
  };

  const editQuantityOrDeleteItem = (
    pieceWiseQuantity: any,
    orderId: string,
    productId: string,
    editOrDelete: string
  ) => {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem(() => {
      return {
        productId,
        orderId,
        editOrDelete: editOrDelete,
        quantity: pieceWiseQuantity,
      };
    });
  };

  const generateBill = useReactToPrint({
    content: () => billRef.current,
    documentTitle:
      Object.keys(orderForGeneratingBill).length > 0
        ? orderForGeneratingBill?.buyerStore.name + ' - invoice'
        : '',
  });

  return (
    <div className="cnt_of_order">
      <div className="breadcrumb">
        <span className="hash">#</span>
        <p className="breadcrumb-text">Order Page</p>
      </div>
      <div className="filters">
        <input type="checkbox" id="toggle" />
        <label
          htmlFor="toggle"
          onClick={() => setShowFilters(true)}
          className="filterIcon"
        >
          <span className="filter-icon">
            <RiFilter2Fill />
          </span>
        </label>

        <div
          className={
            showFilters
              ? 'cnt_of_brand_distributor-mobile'
              : 'cnt_of_brand_distributor'
          }
        >
          <div className="heading-filter-n-cross-icon">
            <button
              className="go-back-button"
              onClick={() => setShowFilters(false)}
            >
              <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
            </button>

            <h2>
              Filters <RiFilter2Fill />
            </h2>
          </div>

          <p
            onClick={clearAllFilter}
            style={{
              color: 'red',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              cursor: 'pointer',
              marginTop: '1rem',
              fontWeight: '450',
            }}
          >
            Clear All Filters
          </p>

          <div style={{ margin: '0.5rem 0 0rem 0' }}>
            <label
              htmlFor="#"
              style={{
                fontSize: '15px',
                fontWeight: 600,
                color: ' #5b5959',
                marginBottom: '0.5rem',
                display: 'block',
              }}
              className="autocomplete-label"
            >
              From Date
            </label>
            <input
              type="date"
              style={{
                width: '100%',
                border: '1px solid #E4E7EB',
                borderRadius: '6px',
                padding: '9px',
                marginBottom: '1rem',
              }}
              value={selectedFromDate}
              onChange={handleFromDateChange}
            />
          </div>
          <div style={{ marginBottom: '0rem 0 0.5rem 0' }}>
            <label
              htmlFor="#"
              style={{
                marginBottom: '0.5rem',

                fontSize: '15px',
                fontWeight: 600,
                color: ' #5b5959',

                display: 'block',
              }}
              className="autocomplete-label"
            >
              To Date
            </label>
            <input
              style={{
                width: '100%',
                border: '1px solid #E4E7EB',
                borderRadius: '6px',
                padding: '9px',
                marginBottom: '1rem',
              }}
              type="date"
              value={selectedToDate}
              onChange={handleToDateChange}
            />
          </div>

          <div className="autocomplete-container">
            <label htmlFor="#" className="autocomplete-label">
              Buyer
            </label>
            <input
              style={{
                width: '100%',
                border: '1px solid #E4E7EB',
                borderRadius: '6px',
                padding: '9px',
                marginBottom: '1rem',
              }}
              type="text"
              placeholder="Choose Buyer"
              className="autocomplete-input"
              value={
                inputValueForBuyer ? inputValueForBuyer : activeBuyer?.name
              }
              onClick={fetchBuyer}
              onChange={handleInputChangeForBuyer}
            />

            {isBuyerOpen && buyerList.length > 0 ? (
              <ul
                className="suggestions-list"
                style={{ overflowY: 'scroll' }}
                ref={buyerRefForCloseOnClickingOutside}
              >
                {buyerList.length > 0 &&
                  buyerList.map((buyer: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => handleActiveBuyer(buyer)}
                      className="suggestion-item"
                    >
                      {buyer.name}
                    </li>
                  ))}

                {buyerList.length > 0 && (
                  <div ref={buyerRef} style={{ padding: '10px' }}></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>

          <div className="autocomplete-container">
            <label htmlFor="#" className="autocomplete-label">
              Route
            </label>
            <input
              style={{
                width: '100%',
                border: '1px solid #E4E7EB',
                borderRadius: '6px',
                padding: '9px',
                marginBottom: '1rem',
              }}
              readOnly={true}
              type="text"
              placeholder="Choose Route"
              className="autocomplete-input"
              value={activeRoute?.name}
              onClick={fetchRoute}
            />

            {routeList.length > 0 ? (
              <ul
                className="suggestions-list"
                style={{ overflowY: 'scroll' }}
                ref={routeRefForCloseOnClickingOutside}
              >
                {routeList.length > 0 &&
                  routeList.map((route: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => handleActiveRoute(route)}
                      className="suggestion-item"
                    >
                      {route.name}
                    </li>
                  ))}

                {routeList.length > 0 && (
                  <div ref={routeRef} style={{ padding: '10px' }}></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>

          <div className="autocomplete-container">
            <label htmlFor="#" className="autocomplete-label">
              Schedule
            </label>
            <input
              style={{
                width: '100%',
                border: '1px solid #E4E7EB',
                borderRadius: '6px',
                padding: '9px',
                marginBottom: '1rem',
              }}
              readOnly={true}
              type="datetime-local"
              placeholder="Choose Schedule"
              className="autocomplete-input"
              value={convertToDatetimeLocalFormat(
                activeSchedule?.startDateAndTime
              )}
              onClick={fetchSchedule}
            />
            <span className="error">{errorForSchedule}</span>
            {scheduleList.length > 0 ? (
              <ul
                className="suggestions-list"
                style={{ overflowY: 'scroll' }}
                ref={scheduleRefForCloseOnClickingOutside}
              >
                {scheduleList.length > 0 &&
                  scheduleList.map((schedule: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => handleActiveSchedule(schedule)}
                      className="suggestion-item"
                    >
                      {schedule.startDateAndTime}
                    </li>
                  ))}

                {scheduleList.length > 0 && (
                  <div ref={scheduleRef} style={{ padding: '10px' }}></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>

          <div className="apply-filter-btn-cnt">
            <button onClick={applyFilter} className="apply-filter-btn">
              Apply filters
            </button>
          </div>
        </div>
      </div>

      <div className="cnt_lists_of_distributors">
        {Object.keys(orderForGeneratingBill).length > 0 && (
          <div
            style={{
              display: 'none',
            }}
          >
            <OrderBill
              billRef={billRef}
              formatDateToDDMMMYY={formatDateToDDMMMYY}
              orderForGeneratingBill={orderForGeneratingBill}
            />
          </div>
        )}

        {showNewOrder ? (
          <NotAssignedOrder
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
            activeRoute={activeRoute}
            activeBuyer={activeBuyer}
            activeSchedule={activeSchedule}
            orderStatus={orderStatus}
            newOrderListRefForInfiniteScrolling={
              newOrderListRefForInfiniteScrolling
            }
            fetchNewOrderCount={fetchNewOrderCount}
            setNewOrder={setNewOrder}
            newOrder={newOrder}
            setShowNewOrder={setShowNewOrder}
            fetchNotAssignedOrder={fetchNotAssignedOrder}
            fetchScheduledOrder={fetchOrderAccToStatus}
          />
        ) : (
          ''
        )}

        {orderIdToConvertInShippedOrDelivered._id && (
          <ChangeTheOrderStatus
            setOrderIdToConvertInShippedOrDelivered={
              setOrderIdToConvertInShippedOrDelivered
            }
            orderIdToConvertInShippedOrDelivered={
              orderIdToConvertInShippedOrDelivered
            }
            fetchOrderAccToStatus={fetchOrderAccToStatus}
          />
        )}

        {productIdAndOrderIdForEditingQuantityOrDeletingItem.orderId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.productId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.editOrDelete ===
            'delete' && (
            <DeleteOrderPopup
              fetchOrderAccToStatus={fetchOrderAccToStatus}
              fetchNotAssignedOrder={fetchNotAssignedOrder}
              setProductIdAndOrderIdForEditingQuantityOrDeletingItem={
                setProductIdAndOrderIdForEditingQuantityOrDeletingItem
              }
              productIdAndOrderIdForEditingQuantityOrDeletingItem={
                productIdAndOrderIdForEditingQuantityOrDeletingItem
              }
            />
          )}

        {productIdAndOrderIdForEditingQuantityOrDeletingItem.orderId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.productId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.editOrDelete ===
            'edit' && (
            <EditQuantityPopup
              fetchOrderAccToStatus={fetchOrderAccToStatus}
              fetchNotAssignedOrder={fetchNotAssignedOrder}
              setProductIdAndOrderIdForEditingQuantityOrDeletingItem={
                setProductIdAndOrderIdForEditingQuantityOrDeletingItem
              }
              productIdAndOrderIdForEditingQuantityOrDeletingItem={
                productIdAndOrderIdForEditingQuantityOrDeletingItem
              }
            />
          )}

        {showDeclineOrderForm && (
          <DeclineOrderPopUp
            allOrder={allOrder}
            fetchNewOrderCount={fetchNewOrderCount}
            setAllOrder={setAllOrder}
            orderToBeDeclined={orderToBeDeclined}
            setOrderToBeDeclined={setOrderToBeDeclined}
            buyerStore={buyerStoreForDecliningOrder}
            setShowDeclineOrderForm={setShowDeclineOrderForm}
          />
        )}

        <div className="new-order-btn-cnt">
          <button className="order-button" onClick={fetchNotAssignedOrder}>
            View New Orders
            <span className="order-count" id="orderCount">
              {totalNewOrderCount}
            </span>
          </button>
        </div>

        <div className="cnt_buy_again">
          <ul className="order_page_header">
            <li
              className={
                orderStatus == OrderStatusEnum.Ordered ? 'active-nav' : ''
              }
            >
              <Link to={updateStatus('ordered')}>Scheduled </Link>
            </li>
            <li
              className={
                orderStatus == OrderStatusEnum.Preparing ? 'active-nav' : ''
              }
            >
              <Link to={updateStatus('preparing')}>Preparing </Link>
            </li>
            <li
              className={
                orderStatus == OrderStatusEnum.Shipped ? 'active-nav' : ''
              }
            >
              <Link to={updateStatus('shipped')}>Shipped</Link>
            </li>
            <li
              className={
                orderStatus == OrderStatusEnum.Delivered ? 'active-nav' : ''
              }
            >
              <Link to={updateStatus('delivered')}>Delivered</Link>
            </li>
          </ul>
        </div>
        <div>
          <ul className="all-order-cnt">
            {allOrder.length > 0 ? (
              allOrder.map((order: any, indexOfOrder: number) => {
                return (
                  <div className="order" key={order._id} style={{paddingRight:order?.urgentOrder?"20px":"15px"}}>
                     {order?.urgentOrder && <div className='urgent-order'>
                    <p>Urgent</p>
                  </div>}

                    <div className="flex justify-between flex-wrap">
                      <div className="cnt-of-seller-img-n-seller-info">
                        {!order.buyerStore.profileImage ? (
                          <AiTwotoneShop className="seller-img" />
                        ) : (
                          <img
                            className="seller-img"
                            src={order.buyerStore.profileImage}
                            alt="buyer-store"
                          />
                        )}
                        {isMobileScreen && (
                          <h2 className="buyer-name">
                            {order.buyerStore.name}
                          </h2>
                        )}
                        {isMobileScreen ? (
                          <div
                            className="buyer-date-n-email"
                            style={{
                              display: 'block',
                              flexBasis: 'content',
                              textAlign: 'center',
                            }}
                          >
                            <p className="buyer-order-date">
                              <span className="buyer-date">
                                <CiCalendarDate />
                              </span>
                              {formatDateToDDMMMYY(order)}
                            </p>
                            {order.buyerStore.phoneNumberList?.length > 0 ? (
                              <p className="buyer-number">
                                <span className="buyer-phone-icon">
                                  <AiTwotonePhone />
                                </span>

                                {`( ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                  0,
                                  3
                                )} ) ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                  3
                                )}`}
                              </p>
                            ) : (
                              ''
                            )}
                            {order.buyerStore.email ? (
                              <p className="buyer-email">
                                <span className="buyer-email-icon">
                                  <MdEmail />Change the order status
                                </span>
                                <span>{order.buyerStore.email}</span>
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="buyer-name-n-location">
                          {!isMobileScreen && (
                            <h2 className="buyer-name">
                              {order.buyerStore.name}
                            </h2>
                          )}
                          <p
                            className="buyer-location"
                            style={{
                              justifyContent: 'start',
                            }}
                          >
                            <span className="buyer-location-icon">
                              <FaLocationArrow />
                            </span>
                            <span>
                              {order.buyerStore.address.district +
                                ' , ' +
                                order.buyerStore.address.state +
                                ' , '}
                              {order.buyerStore.address.country +
                                ' , ' +
                                order.buyerStore.address.postalCode}
                            </span>
                          </p>
                          <Link
                            style={{
                              fontSize: '14px',
                              color: '#14539a',
                              cursor: 'pointer',
                            }}
                            to={`https://maps.google.com/maps?q=${order.buyerStore.location.coordinates[1]},${order.buyerStore.location.coordinates[0]}`}
                            // to={
                            //   GOOGLE_MAP_URL +
                            //   order.buyerStore.location.coordinates[1] +
                            //   ',' +
                            //   order.buyerStore.location.coordinates[0]
                            // }
                            target="_blank"
                          >
                            See Store Location on map
                          </Link>

                          {order.status !== OrderStatusEnum.Delivered && (
                            <p
                              className="change-order-status"
                              onClick={() => {
                                setOrderIdToConvertInShippedOrDelivered(() => {
                                  return {
                                    _id: order._id,
                                    status: order.status,
                                  };
                                });
                              }}
                            >
                              Change the order status
                            </p>
                          )}
                          {isMobileScreen && (
                            <p
                              style={{
                                color: 'rgb(20, 83, 154)',
                                cursor: 'pointer',
                                textAlign: 'right',
                                position: 'absolute',
                                top: '2px',
                                right: '2px',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                fontWeight: '500',
                                fontSize: '15px',
                              }}
                              onClick={async () => {
                                await setOrderForGeneratingBill(() => order);
                                await generateBill();
                                await setOrderForGeneratingBill(() => {
                                  return {};
                                });
                              }}
                            >
                              <MdPictureAsPdf
                                style={{
                                  marginRight: '0.5rem',
                                }}
                              />
                              PDF
                            </p>
                          )}
                        </div>
                      </div>

                      {isMobileScreen ? (
                        ''
                      ) : (
                        <div className="buyer-date-n-email">
                          <p
                            className="buyer-order-date"
                            style={{ justifyContent: 'center' }}
                          >
                            <span className="buyer-date">
                              <CiCalendarDate />
                            </span>
                            {formatDateToDDMMMYY(order)}
                          </p>
                          {order.buyerStore.phoneNumberList?.length > 0 ? (
                            <p className="buyer-number">
                              <span className="buyer-phone-icon">
                                <AiTwotonePhone />
                              </span>

                              {` ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                0,
                                3
                              )}  ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                3
                              )}`}
                            </p>
                          ) : (
                            ''
                          )}
                          {order.buyerStore.email ? (
                            <p className="buyer-email">
                              <span className="buyer-email-icon">
                                <MdEmail />
                              </span>
                              <span>{order.buyerStore.email}</span>
                            </p>
                          ) : (
                            ''
                          )}

                          <p
                            style={{
                              fontSize: '16px',
                              color: 'rgb(20, 83, 154)',
                              cursor: 'pointer',
                              textAlign: 'right',
                              marginTop: '0.5rem',
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                              fontWeight: '500',
                            }}
                            onClick={async () => {
                              await setOrderForGeneratingBill(() => order);
                              await generateBill();
                              await setOrderForGeneratingBill(() => {
                                return {};
                              });
                            }}
                          >
                            <MdPictureAsPdf
                              style={{
                                marginRight: '0.5rem',
                              }}
                            />
                            Get PDF
                          </p>

                          {status !== 'delivered' && (
                            <p
                              onClick={() =>
                                removeOrderAfterGettingAccepted(order)
                              }
                              className="remove-order"
                            >
                              Remove Order
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="see-order-items-cnt">
                      <p
                        className="see-order-items"
                        onClick={() => {
                          let updatedAllOrder = [...allOrder];
                          updatedAllOrder[indexOfOrder].showItem =
                            !order.showItem;
                          setAllOrder(updatedAllOrder);
                        }}
                      >
                        {order.showItem
                          ? 'Hide Order Items'
                          : 'See Order Items'}
                        {!order.showItem ? (
                          <MdKeyboardArrowDown />
                        ) : (
                          <MdKeyboardArrowUp />
                        )}
                      </p>
                    </div>

                    {isMobileScreen && order.showItem ? (
                      <OrderItemsForMobile order={order} />
                    ) : (
                      ''
                    )}
                    {order.showItem && !isMobileScreen ? (
                      <table className="bill-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>MRP</th>
                            <th>Price (Excl. tax)</th>
                            <th>Price (Incl. tax)</th>
                            <th>Quantity (In Pieces)</th>
                            {order.totalTaxAmountList.map((tax: any) => (
                              <th className="order_tax">{tax.type}</th>
                            ))}
                            <th>Total Price</th>
                            {order.status === OrderStatusEnum.Ordered && (
                              <th>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {order.itemList.map(
                            (data: any, indexOfItem: number) => {
                              return (
                                <tr key={indexOfItem}>
                                  <td className="order-name-n-img-table-data">
                                    <div>
                                      <LazyLoadImage
                                        alt={data.product.name}
                                        className="order-img"
                                        src={
                                          !data.product.image
                                            ? '/images/no-image-available-icon.jpeg'
                                            : data.product.image
                                        }
                                      />
                                    </div>
                                    <span className="order-item-name">
                                      {data.product.name}
                                    </span>
                                  </td>
                                  <td> {'₹ ' + data.product.mrp}</td>
                                  <td>
                                    {'₹ ' +
                                      data.pricePerUnit.netAmount.toFixed(2)}
                                  </td>
                                  <td>
                                    {'₹ ' +
                                      data.pricePerUnit.totalAmount.toFixed(2)}
                                  </td>
                                  <td>
                                    {data.quantity.pieceWise}
                                    {/* {data.product.selectedOption !== 'piece'
                                 ? ` (  ${data.product.quantity}
${data.product.selectedOption} )`
                                 : ''} */}
                                  </td>

                                  {data.totalTaxPercentagePrice.map(
                                    (tax: any) => (
                                      <td>
                                        {'₹ ' +
                                          `${tax.price.toFixed(2)} (` +
                                          tax.percentage +
                                          '%)'}
                                      </td>
                                    )
                                  )}
                                  <td>
                                    {'₹ ' + Number(data.totalPrice).toFixed(2)}
                                  </td>
                                  {order.status === OrderStatusEnum.Ordered && (
                                    <td
                                      style={{
                                        position: 'relative',
                                      }}
                                    >
                                      <div className="menu-container">
                                        {data.showEditAndDeleteOption ? (
                                          <p
                                            onClick={() => {
                                              return closeTheOption(
                                                indexOfItem,
                                                indexOfOrder
                                              );
                                            }}
                                          >
                                            <RxCross2
                                              style={{
                                                fontSize: '22px',
                                                marginBottom: '-2.5rem',
                                              }}
                                            />
                                          </p>
                                        ) : (
                                          <div
                                            onClick={() =>
                                              handleClickOnAction(
                                                indexOfOrder,
                                                indexOfItem
                                              )
                                            }
                                          >
                                            <span className="circle">.</span>
                                            <span className="circle">.</span>
                                            <span className="circle">.</span>
                                          </div>
                                        )}
                                      </div>
                                      {data?.showEditAndDeleteOption && (
                                        <ul
                                          className="menu-options"
                                          ref={editAndDeleteRef}
                                        >
                                          <li
                                            onClick={() => {
                                              let updateOrder = [...allOrder];
                                              updateOrder[
                                                indexOfOrder
                                              ].itemList[
                                                indexOfItem
                                              ].showEditAndDeleteOption = false;
                                              setAllOrder((prevState: any) => {
                                                return updateOrder;
                                              });
                                              editQuantityOrDeleteItem(
                                                data.quantity.pieceWise,
                                                order._id,
                                                data.product._id,
                                                'edit'
                                              );
                                            }}
                                          >
                                            Edit Quantity
                                          </li>
                                          <li
                                            onClick={() => {
                                              let updateOrder = [...allOrder];
                                              updateOrder[
                                                indexOfOrder
                                              ].itemList[
                                                indexOfItem
                                              ].showEditAndDeleteOption = false;
                                              setAllOrder((prevState: any) => {
                                                return updateOrder;
                                              });
                                              editQuantityOrDeleteItem(
                                                data.quantity.pieceWise,
                                                order._id,
                                                data.product._id,
                                                'delete'
                                              );
                                            }}
                                          >
                                            Remove
                                          </li>
                                        </ul>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}

                          <tr className="total-price-details">
                            <td colSpan={5} className="grand-total">
                              Grand Total
                            </td>

                            {order.totalTaxAmountList.map((tax: any) => (
                              <td>{'₹ ' + `${tax.amount.toFixed(2)}`}</td>
                            ))}
                            <td>
                              {'₹ ' + Number(order.totalAmount).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  margin: '1rem 0',
                  color: 'red',
                }}
              >
                {'No Order Found'}
              </p>
            )}
            {allOrder.length > 0 && (
              <div
                ref={allOrderListRefForInfiniteScrolling}
                style={{ padding: '20px' }}
              ></div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

function OrderItemsForMobile({ order }: any) {
  return (
    <div className="order-items-mb">
      {order.itemList.map((data: any, indexOfItem: number) => {
        return (
          <div
            className="order-item-detail"
            style={{
              background: 'rgb(232 233 240 / 51%)',
            }}
          >
            <div className="img-n-name">
              <LazyLoadImage
                alt={data.product.name}
                className="order-item-img"
                src={
                  !data.product.image
                    ? '/images/no-image-available-icon.jpeg'
                    : data.product.image
                }
              />
              <h1
                className="order-item-name"
                style={{
                  fontSize: '13px',
                  fontWeight: '450',
                }}
              >
                {data.product.name}
              </h1>
            </div>
            <div className="flex padding-6 justify-between">
              <p>MRP</p> <p className="heading-mb">{'₹ ' + data.product.mrp}</p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Exclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' + data.pricePerUnit.netAmount.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Inclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' + data.pricePerUnit.totalAmount}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Quantity</p>
              <p className="heading-mb">
                {data.quantity.pieceWise}
                {/* {data.product.selectedOption !== 'piece'
                  ? ` (  ${data.product.quantity}
                      ${data.product.selectedOption} )`
                  : ''} */}
              </p>
            </div>

            {data.totalTaxPercentagePrice.map((tax: any) => (
              <div className="flex justify-between padding-6">
                <p>{tax.type}</p>
                <p className="heading-mb">
                  {'₹ ' + `${tax.price.toFixed(2)} (` + tax.percentage + '%)'}
                </p>
              </div>
            ))}

            <div className="flex justify-between padding-6">
              <p>Total Price</p>
              <p className="heading-mb"> {'₹ ' + data.totalPrice.toFixed(2)}</p>
            </div>
          </div>
        );
      })}

      <div>
        {order.totalTaxAmountList.map((tax: any) => (
          <div
            className="flex  justify-between"
            style={{
              padding: '6px',
            }}
          >
            <p className="order_tax ">{'Total ' + tax.type}</p>
            <p className="heading-mb">{'₹ ' + `${tax.amount.toFixed(2)}`}</p>
          </div>
        ))}
        <div
          className="flex  justify-between"
          style={{
            padding: '6px',
          }}
        >
          <p>Total Amount</p>
          <p className="heading-mb">
            {'₹ ' + Number(order.totalAmount).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
}
