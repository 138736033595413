import { RiFilter2Fill } from 'react-icons/ri';
import './style/buyerFilter.scss';
import { GetRequest } from '../../../component/Request';
import { RxCross1 } from 'react-icons/rx';
import { BsChevronDown } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export default function BuyerFilter({
  setShowFilter,
  selectedRoute,
  setSelectedRoute,
  fetchRoute,
  //
  setListOfRoute,

  isRouteOpen,
  listOfRoute,
  routeRef,
  handleActiveRoute,
  routeObserver,
  setUserWantToSeeNotAssignedBuyer,
  userWantToSeeNotAssignedBuyer,
  setAllBuyer,
  errorForRoute,
  setErrorForRoute,
  setHasMoreBuyerListOrNot,
  setPageNumberForRoute,
}: any) {
  const navigate = useNavigate();
  const buyerFilterRef = useRef<any>(null);

  const applyFilter = async (event: any) => {
    event.preventDefault();

    if (
      userWantToSeeNotAssignedBuyer === false &&
      Object.keys(selectedRoute).length == 0
    ) {
      setErrorForRoute('Please select either one route or see all store');
    } else {
      let url: any;

      if (userWantToSeeNotAssignedBuyer) {
        url = `store?buyer=true&limit=8&page=1&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=false&route-summary=true`;
        await GetRequest(url)
          .then((res) => {
            if (res.data.statusCode == 200) {
              if (res.data.data.length < 8) {
                setHasMoreBuyerListOrNot(false);
              } else {
                setHasMoreBuyerListOrNot(true);
              }
              navigate(`/buyer?seller-buyer-route-mapped-only=false`);
              setAllBuyer(res.data.data);
              setShowFilter(false);
              setErrorForRoute('');
            }
          })
          .catch((err) => {
            console.log(err, 'err in fetching');
          });
      }
      if (selectedRoute.id) {
        url = `store?buyer=true&page=1&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&route=${selectedRoute.id}&route-summary=true`;
        await GetRequest(url)
          .then((res) => {
            if (res.data.statusCode == 200) {
              if (res.data.data.length < 8) {
                setHasMoreBuyerListOrNot(false);
              } else {
                setHasMoreBuyerListOrNot(true);
              }

              navigate(
                `/buyer?routeId=${selectedRoute.id}&routeName=${selectedRoute.name}&seller-buyer-route-mapped-only=true`
              );
              setAllBuyer(res.data.data);
              setShowFilter(false);
              setErrorForRoute('');
            }
          })
          .catch((err) => {
            console.log(err, 'err in fetching');
          });
      }
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      buyerFilterRef.current &&
      !buyerFilterRef.current.contains(event.target)
    ) {
      setShowFilter(false);
      setListOfRoute([]);
      setPageNumberForRoute(1);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="cnt-of-product-filter">
      <form action="#" className="filter-form" ref={buyerFilterRef}>
        <div className="heading">
          <h2>
            Filters <RiFilter2Fill />
          </h2>
          <button
            className="cross-icon"
            onClick={() => {
              setErrorForRoute('');
              setShowFilter(false);
            }}
          >
            <RxCross1 />
          </button>
        </div>
        <div>
          <div
            style={{
              margin: '0.7rem 0',
            }}
          >
            <div className="autocomplete-container">
              <label htmlFor="#" className="input-label" onClick={fetchRoute}>
                Routes
              </label>
              <p className="selected-route" onClick={fetchRoute}>
                {!selectedRoute.name ? 'Choose Route' : selectedRoute.name}
                <span>
                  <BsChevronDown />
                </span>
              </p>
              {isRouteOpen && listOfRoute.length > 0 ? (
                <ul
                  className="suggestions-list"
                  style={{ overflowY: 'scroll' }}
                  ref={routeRef}
                >
                  {listOfRoute.map((data: any, index: number) => (
                    <li
                      key={data.name}
                      onClick={() => handleActiveRoute(data)}
                      className="suggestion-item"
                    >
                      {data.name}
                      {index === listOfRoute.length - 1 && (
                        <div
                          ref={(el) =>
                            el && routeObserver && routeObserver.observe(el)
                          }
                        />
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              <div className="or-cnt">
                <div className="border"></div>
                <p className="or">Or</p>
                <div className="border"></div>
              </div>

              <div className="question-yes-or-no">
                <input
                  type="checkbox"
                  id="userWantToSeeNotAssignedBuyer"
                  checked={userWantToSeeNotAssignedBuyer ? true : false}
                  onClick={() => {
                    if (userWantToSeeNotAssignedBuyer) {
                      setUserWantToSeeNotAssignedBuyer(false);
                    } else {
                      setUserWantToSeeNotAssignedBuyer(true);
                    }
                    setErrorForRoute('');
                    setSelectedRoute({});
                  }}
                />
                <label
                  className="input-label"
                  htmlFor="userWantToSeeNotAssignedBuyer"
                >
                  See All Stores
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="error">{errorForRoute}</div>
        <div className="apply-filter-btn-cnt">
          <button onClick={applyFilter} className="apply-filter-btn">
            Apply filters
          </button>
        </div>
      </form>
    </div>
  );
}
