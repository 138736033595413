import { useState, useRef, useCallback, useEffect } from 'react';
import {
  LoadScript,
  GoogleMap,
  Polygon,
  useJsApiLoader,
  MarkerF,
  Autocomplete,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { GrUndo } from 'react-icons/gr';
import './polygon.scss';
import { TbArrowForwardUp } from 'react-icons/tb';
import SlidesOfPolygon from './polygonSlides/SlidesOfPolygon';
import { MdInfo } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  PostRequest,
  GetRequest,
  DeleteRequest,
  PatchRequest,
} from '../../../component/Request';
import {
  IoIosArrowDown,
  IoIosArrowDropupCircle,
  IoIosArrowUp,
} from 'react-icons/io';
import { AiFillDelete } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { FiEdit } from 'react-icons/fi';

const libraries1: any = ['places'];

export default function PolygonMap() {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const [latitude, setLatitude] = useState<any>('');
  const [longitude, setLongitude] = useState<any>('');
  const [searchResult, setSearchResult] = useState<any>(null);
  const [polygonPath, setPolygonPath] = useState<any>({
    coordinates: [],
    name: '',
  });
  const [newPolygonGettingCreated, setNewPolygonGettingCreated] =
    useState<Boolean>(false);
  const [allStepsInPolygon, setAllStepsInPolygon] = useState<any[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [confirmation, setConfirmation] = useState(false);
  const [showDialogueBox, setShowDialogueBox] = useState(false);
  const [slideStep, setSlideStep] = useState(1);
  const [polygonChangedOrNotByUser, setPolygonChangedOrNotByUser] =
    useState<Boolean>(false);
  const [addBorder, setAddBorder] = useState(false);
  const [makeUndoDisabled, setMakeUndoDisabled] = useState(false);
  const [makeRedoDisabled, setMakeRedoDisabled] = useState(false);
  const [makeSubmitDisabled, setMakeSubmitDisabled] = useState(false);
  const [error, setError] = useState('');
  const [disabledAddOneMoreBtn, setDisabledAddOneMoreBtn] = useState(false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [allAreasByGetAPI, setAllAreasByGetAPI] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [areaIdToBeUpdate, setAreaIdToBeUpdate] = useState('');
  const [showDeleteDialogueBox, setShowDeleteDialogueBox] = useState(false);
  const [areaIdToDelete, setAreaIdToDelete] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showAreaListsInPopUpMode, setShowAreaListsInPopUpMode] =
    useState(false);
  const [createPolygonAccToSearchedValue, setCreatePolygonAccToSearchedValue] =
    useState(false);
  // Define refs for Polygon instance and listeners
  const polygonRef: any = useRef(null);
  const listenersRef: any = useRef([]);

  const { isLoaded } = useJsApiLoader({
    id: 'script-loader',
    googleMapsApiKey: 'AIzaSyCzyg22gHL2KY6foIfFcjP5zrao2k0WQ10',
    libraries: libraries1,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!slideStep) {
      const timer = setTimeout(() => {
        setShowInfo(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [slideStep]);

  function onAutoCompleteLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  useEffect(() => {
    // Disable the undo button if the current step is 1
    setMakeUndoDisabled(currentStep === 1);
    // Disable the redo button if the current step is the last step in allStepsInPolygon
    setMakeRedoDisabled(currentStep === allStepsInPolygon.length);
  }, [currentStep, allStepsInPolygon]);

  function onPlaceChanged() {
    if (searchResult != null) {
      setShowDialogueBox(true);
      if (confirmation) {
        let locationObj = searchResult.getPlace();
        setLatitude(locationObj.geometry.location.lat());
        setLongitude(locationObj.geometry.location.lng());
        setCreatePolygonAccToSearchedValue(true);
      }
    }
  }

  const makeOnePolygon = (
    center: any,
    distance: number,
    pushSinglePolygonInAllAreasByGetApi: Boolean
  ) => {
    setIsLoading(true);
    let r_earth = 6378;
    var pi = Math.PI;
    let newPolygon: any;
    // let updatedPolygon = { ...polygonPath };

    // north position
    var newNorthLatitude: number =
      center.lat + (distance / r_earth) * (180 / pi);
    newPolygon = [{ lat: newNorthLatitude, lng: center.lng }];

    // east position
    var newEastLongitude =
      center.lng +
      ((distance / r_earth) * (180 / pi)) / Math.cos((center.lat * pi) / 180);
    newPolygon.push({ lat: center.lat, lng: newEastLongitude });

    // south position
    var newSouthLatitude = center.lat - (distance / r_earth) * (180 / pi);
    newPolygon.push({ lat: newSouthLatitude, lng: center.lng });

    // west position
    var newWestLongitude =
      center.lng -
      ((distance / r_earth) * (180 / pi)) / Math.cos((center.lat * pi) / 180);
    newPolygon.push({ lat: center.lat, lng: newWestLongitude });

    setPolygonPath({ coordinates: newPolygon, name: 'Selling area' });
    setAllStepsInPolygon([newPolygon]);

    if (pushSinglePolygonInAllAreasByGetApi) {
      let updatedAllAreasByGetAPI = [...allAreasByGetAPI];
      let areaToPushIn = {
        name: 'selling area',
        area: { type: 'polygon', coordinates: newPolygon },
      };
      updatedAllAreasByGetAPI = updatedAllAreasByGetAPI.concat(areaToPushIn);
      setAllAreasByGetAPI(updatedAllAreasByGetAPI);
      setMakeSubmitDisabled(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(() => position.coords.latitude);
        setLongitude(() => position.coords.longitude);
        getAllArea(position.coords.latitude, position.coords.longitude);
      });
    }
  }, []);

  const getAllArea = async (lat: number, lng: number) => {
    await GetRequest(`seller/store/brand/${brandId}/area`).then(
      async (res: any) => {
        setIsLoading(true);
        let newPolygonPath: any = [];
        if (res.data.statusCode === 200) {
          if (res.data.data?.areaList && res.data.data.areaList?.length !== 0) {
            let areaList = res.data.data.areaList;
            await setAllAreasByGetAPI((prevValue: any) => areaList);
            // making the first area as active and fixing the lat and lng as well
            let coordinates = areaList[0].area.coordinates.map((obj: any) => {
              return {
                lng: obj.longitude,
                lat: obj.latitude,
              };
            });

            await setPolygonPath((prevValue: any) => {
              return {
                coordinates,
                name: areaList[0].name,
                _id: areaList[0]._id,
              };
            });
            setLatitude(coordinates[0].lat);
            setLongitude(coordinates[0].lng);
            await setMakeSubmitDisabled(true);
          } else {
            setAllAreasByGetAPI([]);
            setMakeSubmitDisabled(false);
            setDisabledAddOneMoreBtn(true);
            await makeOnePolygon({ lat, lng }, 5, false);
          }
        }
        setIsLoading(false);
      }
    );
  };

  const containerStyle = {
    height: '80vh',
    borderRadius: '0.4rem',
  };

  function areCoordinatesNotEqual(nextPath: any, polygonPathCoordinate: any) {
    if (nextPath.length !== polygonPathCoordinate.length) {
      return true;
    }

    // Compare each element in the arrays
    for (let i = 0; i < nextPath.length; i++) {
      if (nextPath[i] !== polygonPathCoordinate[i]) {
        return true;
      }
    }

    // If all both are equal, return false
    return false;
  }

  // Call setPolygonPath with new edited path
  const onEdit = () => {
    if (polygonRef.current) {
      let updatedAllStepsInPolygon = [...allStepsInPolygon];
      const nextPath: any = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lng: latLng.lng(), lat: latLng.lat() };
        });
      if (!areCoordinatesNotEqual(nextPath, polygonPath.coordinates)) {
        setPolygonPath((prevPolygon: any) => {
          return {
            ...prevPolygon,
            coordinates: nextPath,
            name: prevPolygon.name,
          };
        });

        updatedAllStepsInPolygon = updatedAllStepsInPolygon.concat([nextPath]);

        setAllStepsInPolygon(updatedAllStepsInPolygon);
        setCurrentStep((prevStep: number) => prevStep + 1);
        setPolygonChangedOrNotByUser(true);
      }
    }
  };

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon: any) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  useEffect(() => {
    if (confirmation) {
      onPlaceChanged();
      setConfirmation(false);
      setShowDialogueBox(false);
    }
  }, [confirmation]);

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleClickOnUndo = () => {
    if (!makeUndoDisabled) {
      if (currentStep > 1) {
        setPolygonPath((prevPolygon: any) => {
          return {
            ...prevPolygon,
            name: polygonPath.name,
            coordinates: allStepsInPolygon[currentStep - 2],
          };
        });
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const handleClickOnRedo = () => {
    if (!makeRedoDisabled) {
      if (currentStep < allStepsInPolygon.length) {
        setPolygonPath((prevPolygon: any) => {
          return {
            ...prevPolygon,
            name: polygonPath.name,
            coordinates: allStepsInPolygon[currentStep],
          };
        });
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const makeConfirmationFalse = () => {
    setConfirmation(false);
    setShowDialogueBox(false);
  };

  useEffect(() => {
    if (createPolygonAccToSearchedValue) {
      setIsLoading(true);
      let r_earth = 6378;
      var pi = Math.PI;
      let center = { lat: latitude, lng: longitude };
      let newPolygon: any;

      // north position
      var newNorthLatitude: number = center.lat + (5 / r_earth) * (180 / pi);
      newPolygon = [{ lat: newNorthLatitude, lng: center.lng }];
      // east position
      var newEastLongitude =
        center.lng +
        ((5 / r_earth) * (180 / pi)) / Math.cos((center.lat * pi) / 180);
      newPolygon.push({ lat: center.lat, lng: newEastLongitude });
      // south position
      var newSouthLatitude = center.lat - (5 / r_earth) * (180 / pi);
      newPolygon.push({ lat: newSouthLatitude, lng: center.lng });
      // west position
      var newWestLongitude =
        center.lng -
        ((5 / r_earth) * (180 / pi)) / Math.cos((center.lat * pi) / 180);
      newPolygon.push({ lat: center.lat, lng: newWestLongitude });
      setPolygonPath({ coordinates: newPolygon, name: 'Selling area' });
      setAllStepsInPolygon([newPolygon]);
      setIsLoading(false);
    }
  }, [latitude, longitude]);

  const makeConfirmationTrue = async () => {
    setConfirmation(true);
  };

  const handleClickOnAddMorePolygon = async () => {
    if (!disabledAddOneMoreBtn) {
      if (allAreasByGetAPI.length != 0) {
        setNewPolygonGettingCreated(() => true);
        await makeOnePolygon({ lat: latitude, lng: longitude }, 5, true);
        setError('');
        setDisabledAddOneMoreBtn(true);
        setAreaIdToBeUpdate('');
        setAreaIdToDelete('');
        setShowUpdateButton(false);
      } else {
        setError(
          'Please submit to add at least one area then try to add more areas'
        );
      }
    }
  };

  const handleSubmit = async () => {
    if (polygonPath.name) {
      setError('');
      await PostRequest(`seller/store/brand/${brandId}/area`, {
        areaList: polygonPath.coordinates.map((obj: any) => {
          return { longitude: obj.lng, latitude: obj.lat };
        }),
        name: polygonPath.name,
      }).then(async (res) => {
        if (res.data.statusCode === 201) {
          setError('');
          setAllStepsInPolygon([]);
          setCurrentStep(1);
          setPolygonChangedOrNotByUser(false);
          setMakeSubmitDisabled(true);
          setDisabledAddOneMoreBtn(false);
          setNewPolygonGettingCreated(() => false);
          // show recently created polygon on map

          await getAllAreaAfterUpdateOrSubmit();
        }
      });
    } else {
      setError('Please Provide Name Of Your Selling Area.');
    }
  };

  const handleChange = async (event: any) => {
    setError('');
    let { value } = event.target;
    let newPolygonPath = { ...polygonPath };
    let allAreasByGetAPI;
    newPolygonPath.name = value;
    await setPolygonPath(newPolygonPath);
  };

  const deleteArea = async (brandId: any, areaId: any) => {
    await DeleteRequest(`seller/store/brand/${brandId}/area/${areaId}`).then(
      async (res) => {
        if (res.data.statusCode === 200) {
          setShowDeleteDialogueBox(false);
          setAreaIdToDelete('');
          setPolygonPath({ coordinates: [], name: '' });
          await getAllArea(latitude, longitude);
        }
      }
    );
  };

  const handleDeletePolygon = async (area: any) => {
    setShowDeleteDialogueBox(true);
    handleClickOnSinglePolygon(area);
    setAreaIdToDelete(area._id);
  };

  const handleClickOnSinglePolygon = async (area: any) => {
    let lastElementIndexInAllAreasbyGetApi = allAreasByGetAPI.length - 1;
    setPolygonChangedOrNotByUser(false);
    setCreatePolygonAccToSearchedValue(false);
    if (
      allAreasByGetAPI.length > 1 &&
      !allAreasByGetAPI[lastElementIndexInAllAreasbyGetApi]._id
    ) {
      let newAreas = [...allAreasByGetAPI];
      newAreas = newAreas.filter(
        (area, index) =>
          index !== lastElementIndexInAllAreasbyGetApi &&
          !newAreas[lastElementIndexInAllAreasbyGetApi]._id
      );

      setAllAreasByGetAPI(newAreas);
      setDisabledAddOneMoreBtn(false);
      setMakeSubmitDisabled(true);
    }

    let coordinates = area.area.coordinates.map((obj: any) => {
      return {
        lng: obj.longitude,
        lat: obj.latitude,
      };
    });

    await setPolygonPath((prevValue: any) => {
      return {
        coordinates,
        name: area.name,
        _id: area._id,
      };
    });

    setLatitude(coordinates[0].lat);
    setLongitude(coordinates[0].lng);
    setAllStepsInPolygon([coordinates]);
    setCurrentStep(1);
    setAreaIdToBeUpdate('');
    setShowUpdateButton(false);
    setMakeSubmitDisabled(true);
  };

  const updateArea = async () => {
    let payload: any = {};
    let areaInfoObjFromGetApi = allAreasByGetAPI.filter((area: any) => {
      return area._id === polygonPath._id;
    })[0];

    if (!polygonPath.name) {
      setError('Please Provide Name Of Your Selling Area');
    }

    if (polygonPath.name && areaInfoObjFromGetApi.name !== polygonPath.name) {
      payload.name = polygonPath.name;
    }

    let coordinatesInLatLng = areaInfoObjFromGetApi.area.coordinates.map(
      (obj: any) => {
        return {
          lng: obj.longitude,
          lat: obj.latitude,
        };
      }
    );

    if (
      JSON.stringify(coordinatesInLatLng) !==
      JSON.stringify(polygonPath.coordinates)
    ) {
      payload.areaList = polygonPath.coordinates.map((obj: any) => {
        return { longitude: obj.lng, latitude: obj.lat };
      });
    }

    if (Object.keys(payload).length > 0) {
      setError('');

      await PatchRequest(
        `seller/store/brand/${brandId}/area/${areaIdToBeUpdate}`,
        payload
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            setAreaIdToBeUpdate('');
            setShowUpdateButton(false);
            setAllStepsInPolygon([]);
            setCurrentStep(1);
            setPolygonChangedOrNotByUser(false);

            await getAllAreaAfterUpdateOrSubmit();
          }
        })
        .catch((err) => console.log(err, ' err in updating a area'));
    }
  };

  const getAllAreaAfterUpdateOrSubmit = async () => {
    await GetRequest(`seller/store/brand/${brandId}/area`).then(
      async (res: any) => {
        setIsLoading(true);
        let lastAreaIndex = res.data.data.areaList.length - 1;
        if (res.data.statusCode === 200) {
          if (res.data.data?.areaList && res.data.data.areaList?.length !== 0) {
            let areaList = res.data.data.areaList;
            await setAllAreasByGetAPI((prevValue: any) => areaList);
            // making the first area as active and fixing the lat and lng as well
            let coordinates = areaList[lastAreaIndex].area.coordinates.map(
              (obj: any) => {
                return {
                  lng: obj.longitude,
                  lat: obj.latitude,
                };
              }
            );

            await setPolygonPath((prevValue: any) => {
              return {
                coordinates,
                name: areaList[lastAreaIndex].name,
                _id: areaList[lastAreaIndex]._id,
              };
            });
            setLatitude(coordinates[lastAreaIndex]?.lat);
            setLongitude(coordinates[lastAreaIndex]?.lng);
            await setMakeSubmitDisabled(true);
          } else {
            setAllAreasByGetAPI([]);
            setMakeSubmitDisabled(false);
            setDisabledAddOneMoreBtn(true);
            await makeOnePolygon({ lat: latitude, lng: longitude }, 5, false);
          }
        }
        setIsLoading(false);
      }
    );
  };

  const handleClickOnEdit = async (area: any) => {
    setAreaIdToBeUpdate(area._id);
    let lastElementIndexInAllAreasbyGetApi = allAreasByGetAPI.length - 1;
    setPolygonChangedOrNotByUser(false);
    setCreatePolygonAccToSearchedValue(false);
    if (
      allAreasByGetAPI.length > 1 &&
      !allAreasByGetAPI[lastElementIndexInAllAreasbyGetApi]._id
    ) {
      let newAreas = [...allAreasByGetAPI];
      newAreas = newAreas.filter(
        (area, index) =>
          index !== lastElementIndexInAllAreasbyGetApi &&
          !newAreas[lastElementIndexInAllAreasbyGetApi]._id
      );

      setAllAreasByGetAPI(newAreas);
      setDisabledAddOneMoreBtn(false);
      setMakeSubmitDisabled(true);
    }

    let coordinates = area.area.coordinates.map((obj: any) => {
      return {
        lng: obj.longitude,
        lat: obj.latitude,
      };
    });

    await setPolygonPath((prevValue: any) => {
      return {
        coordinates,
        name: area.name,
        _id: area._id,
      };
    });

    setLatitude(coordinates[0].lat);
    setLongitude(coordinates[0].lng);
    setAllStepsInPolygon([coordinates]);
    setCurrentStep(1);
    setShowUpdateButton(true);
    setMakeSubmitDisabled(true);
  };

  const handleClickOnCancelToUpdate = () => {
    setAreaIdToBeUpdate('');
    setShowUpdateButton(false);
  };

  const center = { lat: latitude, lng: longitude };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="polypage-cnt">
        {allAreasByGetAPI?.length === 0 && (
          <SlidesOfPolygon slideStep={slideStep} setSlideStep={setSlideStep} />
        )}
        {showDialogueBox ? (
          <DialogueBox
            makeConfirmationFalse={makeConfirmationFalse}
            makeConfirmationTrue={makeConfirmationTrue}
          />
        ) : (
          ''
        )}
        {showDeleteDialogueBox ? (
          <DeleteDialogueBox
            setAreaIdToDelete={setAreaIdToDelete}
            areaIdToDelete={areaIdToDelete}
            brandId={brandId}
            deleteArea={deleteArea}
            setShowDeleteDialogueBox={setShowDeleteDialogueBox}
          />
        ) : (
          ''
        )}

        {isLoaded === true && isLoading === false ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            mapContainerClassName="App-map"
            center={center}
            zoom={screenWidth <= 280 ? 10 : 12}
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              keyboardShortcuts: false,
              controlSize: screenWidth <= 280 ? 20 : 25,
            }}
          >
            <Autocomplete
              onPlaceChanged={onPlaceChanged}
              onLoad={onAutoCompleteLoad}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: '99999999999999999999999999',
                  marginTop: '16px',
                }}
              >
                <input
                  className="polygon-map-search-bar"
                  type="text"
                  name="searchText"
                  style={{
                    zIndex: '1',
                    width: '450px',
                    padding: '8px',
                    borderRadius: '0.3rem',
                    border: 'none',
                    outline: 'none',
                  }}
                />
              </div>
            </Autocomplete>

            {polygonPath.coordinates.length > 0 && (
              <Polygon
                // Make the Polygon editable
                editable
                // ={
                //   areaIdToBeUpdate || newPolygonGettingCreated ? true : false
                // }
                path={polygonPath.coordinates}
                // Event used when manipulating and adding points
                onMouseUp={onEdit}
                // Event used when dragging the whole Polygon
                onDragEnd={onEdit}
                onLoad={onLoad}
                onUnmount={onUnmount}
              />
            )}
          </GoogleMap>
        ) : (
          <div
            style={{
              height: '80vh',
              borderRadius: '0.4rem',
              marginTop: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading....
          </div>
        )}

        <div
          style={{
            zIndex: '214',
            position: 'absolute',
            bottom: '158px',
            right: '28px',
            marginLeft: '8px',
            cursor: 'pointer',
            borderRadius: '8px',
            background: '#DEE6F0',
          }}
          className="learn-distributing-area-info"
        >
          {showInfo && (
            <span
              style={{
                fontSize: '0.68rem',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
              }}
              onClick={() => {
                setSlideStep(1);
              }}
              onMouseLeave={() => setShowInfo(false)}
            >
              Click me to learn how you can mark your selling areas
              <MdInfo
                style={{
                  fontSize: '1.7rem',
                  color: '#14539A',
                  marginLeft: '5px',
                }}
              />
            </span>
          )}
          {!showInfo && (
            <span
              style={{
                fontSize: '1.7rem',
                color: '#14539A',
              }}
              onMouseEnter={() => setShowInfo(true)}
              onClick={() => {
                setSlideStep(1);
              }}
            >
              <MdInfo />
            </span>
          )}
        </div>
        {polygonChangedOrNotByUser && (
          <>
            <div
              style={{
                zIndex: '1',
                position: 'absolute',
                top: '160px',
                marginLeft: '8px',
              }}
            >
              <button
                style={{
                  height: '33px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.8rem',
                  borderRadius: '0.25rem',
                  background: makeRedoDisabled
                    ? '#E5E5E5'
                    : 'rgb(222, 230, 240)',
                  outline: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: makeRedoDisabled ? '#B8B8B9' : '#14539a',
                }}
                onClick={handleClickOnRedo}
                type="button"
              >
                <span>Redo</span> <TbArrowForwardUp />
              </button>
            </div>

            <div
              style={{
                zIndex: '1',
                position: 'absolute',
                top: '160px',
                left: '110px',
                marginLeft: '8px',
              }}
            >
              <button
                type="button"
                onClick={handleClickOnUndo}
                style={{
                  height: '33px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '0.25rem',
                  padding: '0.8rem',
                  background: makeUndoDisabled
                    ? '#E5E5E5'
                    : 'rgb(222, 230, 240)',
                  outline: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  color: makeUndoDisabled ? '#B8B8B9' : '#14539a',
                }}
              >
                <span>Undo</span> <GrUndo />
              </button>
            </div>
          </>
        )}

        {/* area list ui for bigger screen size */}
        {screenWidth <= 480 ? (
          <div style={{ position: 'relative' }}>
            <div
              className="active-polygon-mobile"
              onClick={() => {
                setShowAreaListsInPopUpMode(true);
              }}
            >
              <p className="open-areas-lists">
                <IoIosArrowDropupCircle style={{ color: '#14539a' }} />
              </p>
              <p className="name">
                {polygonPath.name ? polygonPath.name : 'Check Areas'}
              </p>
            </div>
          </div>
        ) : (
          <div className="add-lists-of-polygon-container">
            {!isOpen && (
              <button
                type="button"
                className="btn-primary top-arrow"
                onClick={() => setIsOpen(true)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IoIosArrowUp />
              </button>
            )}
            {isOpen && (
              <button
                type="button"
                className="btn-primary bottom-arrow"
                onClick={() => setIsOpen(false)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IoIosArrowDown />
              </button>
            )}
            <div className={`polygons ${isOpen ? 'open' : ''}`}>
              {allAreasByGetAPI?.length === 0 ? (
                <div className="polygon">
                  <span className="serial-number">1.</span>
                  <input
                    className="area-input"
                    type="text"
                    placeholder="Enter Area Name"
                    value={polygonPath.name}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                allAreasByGetAPI?.map((area: any, index: any) => (
                  <div
                    className="polygon"
                    key={index}
                    style={{
                      background:
                        area._id === polygonPath._id ? 'rgb(236 237 238)' : '',
                    }}
                  >
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {/* if user wants to update then there will input type text */}
                      {areaIdToBeUpdate === area._id || !area._id ? (
                        <div className="serial-n-area-input">
                          <span>{index + 1 + '.  '}</span>
                          <input
                            type="text"
                            className="area-input"
                            value={polygonPath.name}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        <p
                          className="area-name"
                          onClick={() => handleClickOnSinglePolygon(area)}
                        >
                          {area.name
                            ? index + 1 + '.  ' + area.name
                            : index + 1 + '.  ' + `-.-`}
                        </p>
                      )}
                    </div>

                    {area._id ? (
                      <div className="edit-n-delete">
                        <button
                          className="edit-polygon-icon"
                          onClick={() => handleClickOnEdit(area)}
                        >
                          <FiEdit />
                        </button>
                        <button
                          className="delete-icon"
                          onClick={() => handleDeletePolygon(area)}
                        >
                          &times;
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))
              )}
              {isOpen && (
                <button
                  type="button"
                  className="btn-primary add-polygon"
                  style={{
                    backgroundColor: disabledAddOneMoreBtn
                      ? 'rgb(245, 245, 245)'
                      : '#14539a',

                    color: disabledAddOneMoreBtn
                      ? 'rgb(112, 112, 112)'
                      : 'white',
                    cursor: disabledAddOneMoreBtn ? 'not-allowed' : 'pointer',
                  }}
                  onClick={handleClickOnAddMorePolygon}
                >
                  Add one more
                </button>
              )}
            </div>
          </div>
        )}

        {/* area listing for smaller screen size */}
        {showAreaListsInPopUpMode && (
          <div className="pop-mode-polygon-lists">
            <div className="add-lists-of-polygon-container">
              <div className="polygons">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexBasis: '100%',
                  }}
                >
                  <button
                    className="close"
                    onClick={() => {
                      setShowAreaListsInPopUpMode(false);
                    }}
                  >
                    <RxCross2 />
                  </button>
                </div>

                {allAreasByGetAPI?.length === 0 ? (
                  <div className="polygon">
                    <span className="serial-number">1.</span>
                    <input
                      className="area-input"
                      type="text"
                      placeholder="Enter Area Name"
                      value={polygonPath.name}
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  allAreasByGetAPI?.map((area: any, index: any) => (
                    <div
                      className="polygon"
                      key={index}
                      style={{
                        background:
                          area._id === polygonPath._id
                            ? 'rgb(236 237 238)'
                            : '',
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {/* if user wants to update then there will input type text */}
                        {areaIdToBeUpdate === area._id || !area._id ? (
                          <div className="serial-n-area-input">
                            <span>{index + 1 + '.  '}</span>
                            <input
                              type="text"
                              className="area-input"
                              value={polygonPath.name}
                              onChange={handleChange}
                            />
                          </div>
                        ) : (
                          <p
                            className="area-name"
                            onClick={() => handleClickOnSinglePolygon(area)}
                          >
                            {area.name
                              ? index + 1 + '.  ' + area.name
                              : index + 1 + '.  ' + `-.-`}
                          </p>
                        )}
                      </div>

                      {area._id ? (
                        <div className="edit-n-delete">
                          <button
                            className="edit-polygon-icon"
                            onClick={() => handleClickOnEdit(area)}
                          >
                            <FiEdit />
                          </button>
                          <button
                            className="delete-icon"
                            onClick={() => handleDeletePolygon(area)}
                          >
                            &times;
                          </button>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ))
                )}
                {
                  <button
                    type="button"
                    className="btn-primary add-polygon"
                    style={{
                      backgroundColor: disabledAddOneMoreBtn
                        ? 'rgb(245, 245, 245)'
                        : '#14539a',

                      color: disabledAddOneMoreBtn
                        ? 'rgb(112, 112, 112)'
                        : 'white',
                      cursor: disabledAddOneMoreBtn ? 'not-allowed' : 'pointer',
                    }}
                    onClick={handleClickOnAddMorePolygon}
                  >
                    Add one more
                  </button>
                }
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            textAlign: 'right',
          }}
        >
          {showUpdateButton ? (
            <div
              className="button-container"
              style={{
                marginTop: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                className="cancel-button"
                onClick={handleClickOnCancelToUpdate}
              >
                Cancel
              </button>
              <button className="update-button" onClick={updateArea}>
                Update
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="btn-primary"
              style={{
                width: '150px',
                backgroundColor: makeSubmitDisabled ? '#F5F5F5' : '#14539a',
                color: makeSubmitDisabled ? 'rgb(112 112 112)' : 'white',
                cursor: makeSubmitDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={!makeSubmitDisabled ? handleSubmit : () => {}}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          )}
        </div>

        {/* continue to hit api and move to next page */}
        <div
          className="error"
          style={{ textAlign: 'center', marginBottom: '0' }}
        >
          {error}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            className="btn-primary continue-to-product-btn"
            style={{ width: '300px' }}
            onClick={() => {
              if (allAreasByGetAPI.length != 0) {
                setError('');
                navigate(`/seller/brand/${brandId}/product`);
              } else {
                setError(
                  'Please add at least one area with name then try to add more area'
                );
              }
            }}
          >
            Continue To Product List Page
          </button>
        </div>
      </div>
    );
  }
}

function DialogueBox(props: any) {
  let { makeConfirmationFalse, makeConfirmationTrue } = props;
  return (
    <div className="dialog-box-cnt">
      <div className="dialog-box">
        <p>Do you want to proceed?</p>
        <button onClick={makeConfirmationTrue} className="yes">
          Yes
        </button>
        <button onClick={makeConfirmationFalse} className="no">
          No
        </button>
      </div>
    </div>
  );
}

function DeleteDialogueBox(props: any) {
  let {
    areaIdToDelete,
    setAreaIdToDelete,
    brandId,
    deleteArea,
    setShowDeleteDialogueBox,
  } = props;
  return (
    <div className="dialog-box-cnt">
      <div className="dialog-box">
        <p>Do you want to delete?</p>
        <button
          onClick={() => {
            deleteArea(brandId, areaIdToDelete);
          }}
          className="yes"
        >
          Yes
        </button>
        <button
          onClick={() => {
            setShowDeleteDialogueBox(false);
            setAreaIdToDelete('');
          }}
          className="no"
        >
          No
        </button>
      </div>
    </div>
  );
}

function Loader() {
  return (
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
