import { useEffect, useRef, useState } from 'react';
import { DeleteRequest, PatchRequest } from '../../../component/Request';

export default function DeleteOrderPopup(props: any) {
  let {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem,
    productIdAndOrderIdForEditingQuantityOrDeletingItem,
    fetchNotAssignedOrder,
    fetchOrderAccToStatus,
  } = props;
  const [message, setMessage] = useState<any>('');
  const [error, setError] = useState<string>('');
  const [userWantToDelete, setUserWantToDeleteOrNot] = useState(false);
  const confirmationBoxRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      confirmationBoxRef.current &&
      !confirmationBoxRef.current.contains(event.target)
    ) {
      removeOrderIdAndProductId();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const removeOrderIdAndProductId = () => {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem(() => {
      return { orderId: '', productId: '' };
    });
    setError(() => '');
    setMessage(() => '');
  };

  const handleRemoveProduct = async () => {
    let { orderId, productId } =
      productIdAndOrderIdForEditingQuantityOrDeletingItem;

    if (message && !error) {
      await PatchRequest(`order/${orderId}/product/${productId}`, {
        quantityInPiece: 0,
        message,
      }).then(async (res: any) => {
        if (res.data.statusCode === 200) {
          removeOrderIdAndProductId();
          if (!fetchOrderAccToStatus) {
            await fetchNotAssignedOrder();
          } else {
            await fetchOrderAccToStatus();
          }
        }
      });
    }
  };

  const handleChange = async (event: any) => {
    let { value } = event.target;
    setMessage(() => value);
    if (!value) {
      setError('required');
    }
  };

  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box" ref={confirmationBoxRef}>
        <div
          style={{
            margin: '0.8rem 0',
          }}
        >
          <label
            htmlFor="reason"
            style={{
              fontWeight: '500',
              display: 'block',
            }}
          >
            Message for removing the item
          </label>
          <select
            style={{
              border: '1px solid #dddd',
              padding: '8px 10px',
              borderRadius: '5px',
              marginTop: '1rem',
              width: '100%',
            }}
            name="reason"
            value={message}
            id="reason"
            onChange={handleChange}
          >
            <option value="">Choose one</option>
            <option value="out of stock">Out of stock</option>
            <option value="others">Others</option>
          </select>
          {/* <InputText
            name={'reason'}
            value={message}
            label={'Message for removing the item'}
            handleClickOnOption={handleClickOnOption}
            placeholder={'Choose One'}
            option={['Out of stock', 'Others']}
          /> */}
        </div>

        <p className="error">{error}</p>

        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={removeOrderIdAndProductId}
            type="button"
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={handleRemoveProduct}
          >
            Confirm
          </button>
        </div>
      </div>
    </section>
  );
}
