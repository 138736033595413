import '../profile.scss';
import { BsFillCameraFill } from 'react-icons/bs';
import { CiUser } from 'react-icons/ci';
import { MdOutlineKeyboardBackspace, MdVerified } from 'react-icons/md';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PostRequest } from '../../../../component/Request';
import InputText from '../../../../common/InputText/InputText';
import InputDate from '../../../../common/InputDate/InputDate';
import { useDispatch, useSelector } from 'react-redux';
import {
  IUserInfo,
  updateProfileAsyncThunk,
  userSelector,
} from '../../../../features/auth/Slice';
import { AppDispatch } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../../common/Select/DropDown';
import InputEmailVerify from '../../../../common/email';

export default function UserProfile(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  let {
    setSelectedNavigation,
    setShowIndicator,
    setType,
    setMessage,
    message,
    type,
  } = props;
  let navigate = useNavigate();
  // const [phoneNumberOTP, setPhoneNumberOTP] = useState<string[]>(
  //   new Array(6).fill('')
  // );

  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  // const [errorOfOTP, setErrorOfOTP] = useState('');
  // const [sentOrResendOTPMsg, setSentOrResendOTPMsg] = useState('');
  // const [showVerifiy, setShowVerify] = useState(false);
  // const [emailOtpId, setEmailOtpId] = useState('');

  //!redux user info
  const user = useSelector(userSelector);
  const [userData, setUserData] = useState<IUserInfo>({} as IUserInfo);
  const [userInfo, setUserInfo] = useState({
    profileImage: '',
    name: '',
    dateOfBirth: '',
    email: { id: '', isVerified: false },
    gender: '',
    errors: {
      dateOfBirth: '',
      profileImage: '',
      name: '',
      email: '',
      gender: '',
    },
  });
  // const initialUserInfo = useSelector(userSelector);

  // const [showOtp, setShowOtp] = useState(false);

  // function validateEmail(email: string) {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     return '';
  //   }
  //   return 'Enter a valid email address';
  // }

  // const handleChangeForUserInfo = async (event: any) => {
  //   const { value, name } = event.target;
  //   // Create a copy of the userInfo and errors objects
  //   const updatedUserInfo = { ...userInfo };
  //   const updatedErrors = { ...userInfo.errors };

  //   // Update the corresponding fields based on the name
  //   switch (name) {
  //     case "name":
  //       updatedUserInfo.name = value;
  //       updatedErrors.name = !value ? "name is required" : "";
  //       break;

  //     case "dateOfBirth":
  //       updatedUserInfo.dateOfBirth = value;
  //       updatedErrors.dateOfBirth = value ? "" : "required";

  //       break;
  //     case "email":
  //       updatedUserInfo.email = { id: value, isVerified: false };
  //       updatedErrors.email = "";
  //       setShowOtp(false);
  //       setShowVerify(true);
  //       setResendPhoneOTPTimer(30);
  //       setPhoneNumberOTP(new Array(6).fill(""));
  //       setErrorOfOTP("");

  //       if (value !== "" && !validateEmail(value)) {
  //         updatedErrors.email = "email is invalid";
  //         setShowVerify(false);
  //       } else if (value == "") {
  //         updatedErrors.email = "";
  //         setShowVerify(false);
  //       } else {
  //         updatedErrors.email = "";
  //         setShowVerify(true);
  //         if (showOtp == true && validateEmail(value)) {
  //           setShowOtp(false);
  //           setShowVerify(true);
  //           setResendPhoneOTPTimer(30);
  //           setPhoneNumberOTP(new Array(6).fill(""));
  //           // update resend timeer here to 30
  //         }
  //       }
  //       break;
  //   }

  //   // Update the state with the modified objects
  //   setUserInfo({
  //     ...updatedUserInfo,
  //     errors: updatedErrors,
  //   });
  // };

  // const updateUser = async () => {
  //   let payload: any = {};
  //   let { dateOfBirth, gender, email, name, image } = userInfo.errors;
  //   let updateUserInfo = { ...userInfo };

  //   if (userInfo.email.id && !userInfo.email.isVerified) {
  //     updateUserInfo.errors.email =
  //       "Please provide a valid and verify your email";
  //     setUserInfo((prevState: any) => updateUserInfo);
  //   }

  //   if (!dateOfBirth && !gender && !email && !name && !image && !errorOfOTP) {
  //     if (userInfo.name && initialUserInfo.name !== userInfo.name) {
  //       payload.name = userInfo.name;
  //     }

  //     if (
  //       userInfo.dateOfBirth &&
  //       initialUserInfo.dateOfBirth !== userInfo.dateOfBirth
  //     ) {
  //       payload.dateOfBirth = userInfo.dateOfBirth;
  //     }

  //     if (userInfo.gender && initialUserInfo.gender !== userInfo.gender) {
  //       payload.gender = userInfo.gender;
  //     }

  //     if (userInfo.image && initialUserInfo.profileImage !== userInfo.image) {
  //       payload.profileImage = userInfo.image;
  //     }

  //     if (userInfo.image && initialUserInfo.profileImage !== userInfo.image) {
  //       payload.profileImage = userInfo.image;
  //     }

  //     if (
  //       userInfo.email.isVerified &&
  //       userInfo.email.id !== initialUserInfo.email
  //     ) {
  //       payload.email = { id: userInfo.email.id, isVerified: true };
  //     }

  //     if (Object.values(payload).length > 0) {
  //       await PatchRequest("user", payload, true, true, "Successfully Updated")
  //         .then(async (res: any) => {
  //           if (res.status === 200) {
  //             setUserInfo((prevState: any) => {
  //               return {
  //                 ...prevState,
  //                 image: res.data.data.profileImage || "",
  //                 name: res.data.data.name || "",
  //                 email: {
  //                   id: res.data.data.email || "",
  //                   isVerified: res.data.data.email ? true : false,
  //                 },
  //                 dateOfBirth: res.data.data.dateOfBirth || "",
  //                 gender: res.data.data.gender || "",
  //               };
  //             });
  //             await dispatch(fetchUserInfoAsyncThunk({}));

  // setShowIndicator(true);
  // setMessage("Successfully Updated");
  // setType("success");
  // setTimeout(() => {
  //   setShowIndicator(false);
  // }, 3000);
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err, "err while updating the user profile");
  //         });
  //     }
  //   }
  // };

  // const phoneNumberOTPHandleOnKeyDown = (
  //   { key }: React.KeyboardEvent<HTMLInputElement>,
  //   index: number
  // ) => {
  //   const newPhoneNumberOTP: string[] = [...phoneNumberOTP];

  //   if (key === 'Backspace') {
  //     if (newPhoneNumberOTP[index] === '') {
  //       setActiveOTPIndex(index - 1);
  //       newPhoneNumberOTP[index - 1] = '';
  //     } else {
  //       newPhoneNumberOTP[index] = '';
  //       setPhoneNumberOTP(newPhoneNumberOTP);
  //       setActiveOTPIndex(index);
  //     }
  //   } else if (key.match('[0-9]')) {
  //     newPhoneNumberOTP[index] = key;
  //     setActiveOTPIndex(index + 1);
  //     setPhoneNumberOTP(newPhoneNumberOTP);
  //   }
  // };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  // const sendOTP = async (e: any) => {
  //   await PostRequest('otp/send', {
  //     email: userData.email,
  //     profileType: 'User',
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setShowOtp(true);
  //         setSentOrResendOTPMsg('OTP has been sent to your email');
  //         setShowVerify(false);
  //         setEmailOtpId(res.data.data.otpId);
  //         setUserInfo((prevState: any) => {
  //           return {
  //             ...prevState,
  //             errors: {
  //               ...prevState.errors,
  //               email: '',
  //             },
  //           };
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.statusCode == 400) {
  //         setUserInfo((prevState: any) => {
  //           return {
  //             ...prevState,
  //             errors: {
  //               ...prevState.errors,
  //               email: err.fieldErrors[1],
  //             },
  //           };
  //         });
  //       }
  //     });
  // };

  // const handleSubmitOTP = async (event: any) => {
  //   await PostRequest('otp/verify', {
  //     email: userData.email,
  //     otp: phoneNumberOTP.join(''),
  //     otpId: emailOtpId,
  //     profileType: 'User',
  //   })
  //     .then((res: any) => {
  //       if (res.status == 200) {
  //         setUserInfo({
  //           ...userInfo,
  //           email: { id: userInfo.email.id, isVerified: true },
  //         });
  //         setShowOtp(false);
  //         setPhoneNumberOTP(new Array(6).fill(''));
  //         setResendPhoneOTPTimer(30);
  //         setStartPhoneOTPTimer(false);
  //       }
  //     })
  //     .catch((err: any) => {
  //       if (err.response.data.statusCode == 400) {
  //         setErrorOfOTP(err.response.data.message);
  //       }
  //     });
  // };

  // const calculateResendPhoneOTPTimer = useMemo(() => {
  //   if (resendPhoneOTPTimer <= 0) {
  //     setStartPhoneOTPTimer(false);
  //     return 0;
  //   }
  //   return resendPhoneOTPTimer;
  // }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  // const handleStartPhoneOTPTimer = () => {
  //   setStartPhoneOTPTimer(true);
  // };

  //! new code start from here
  async function handleProfileChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files?.length) {
      try {
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        const response = await PostRequest('file?type=user-image', formData);
        setUserData((prevState: any) => {
          return {
            ...prevState,
            profileImage: response.data.data.url,
          };
        });
        // await dispatch(
        //   updateProfileAsyncThunk({ profileImage: response.data.data.url })
        // );
      } catch (error) {}
    } else {
      setUserInfo((pre) => ({
        ...pre,
        errors: { ...pre.errors, image: 'Image not uploaded' },
      }));
    }
  }

  useEffect(() => {
    if (user) {
      setUserData((pre) => user);
    }
  }, [user]);

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    let { name, value } = event.target;
    let newUserInfo = { ...userInfo };
    if (name === 'dateOfBirth') {
      const selectedDate = new Date(value);
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 12);
      newUserInfo.dateOfBirth = value;
      if (selectedDate > minDate) {
        newUserInfo.errors.dateOfBirth = 'You must be at least 12 years old.';
      } else {
        newUserInfo.errors.dateOfBirth = '';
      }
      setUserInfo(newUserInfo);
    }

    if (name === 'gender') {
      if (value === 'Choose gender') {
        newUserInfo.errors.gender = 'Required';
      } else {
        newUserInfo.errors.gender = '';
      }
      setUserInfo(newUserInfo);
    }

    setUserData((pre: IUserInfo) => ({
      ...pre,
      [event.target.name]: event.target.value,
    }));
  }

  async function handleUpdateProfile() {
    let payload: any = {};
    let updateUserInfo = { ...userInfo };
    let { dateOfBirth, email, gender, name, profileImage } = userData;

    let newUserInfo = { ...userInfo };
    const selectedDate = new Date(dateOfBirth);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 12);

    if (selectedDate > minDate) {
      newUserInfo.errors.dateOfBirth = 'You must be at least 12 years old.';
    } else {
      newUserInfo.errors.dateOfBirth = '';
    }

    setUserInfo(() => newUserInfo);

    if (
      !userInfo.errors.email &&
      !userInfo.errors.dateOfBirth &&
      !userInfo.errors.profileImage &&
      !userInfo.errors.gender
    ) {
      const response = await dispatch(
        updateProfileAsyncThunk({
          dateOfBirth,
          email,
          gender,
          name,
          profileImage,
        })
      );

      if (response.meta.requestStatus === 'fulfilled') {
        setShowIndicator(true);
        setMessage('Successfully Updated');
        setType('success');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    }
  }

  return (
    <div className="user-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>

      <div className="flex py-5 pb-[2rem] justify-center relative">
        {user?.profileImage ? (
          <img
            src={userData?.profileImage}
            className="w-[255px] h-[137px]  object-contain"
            style={{
              borderRadius: '4rem',
              border: '1px solid #ddd',
            }}
            alt={user.name}
          />
        ) : (
          <div
            style={{
              borderRadius: '4rem',
              border: '1px solid #14539A',
            }}
            className="w-[255px] h-[137px]  flex justify-center items-center"
          >
            <CiUser
              style={{
                width: '100px',
                color: '#14539A',
                height: '100px',
              }}
            />
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            bottom: '11%',
            background: '#14539a',
            width: '45px',
            height: '45px',
            left: '55%',
            lineHeight: '40px',
            textAlign: 'center',
            borderRadius: '50%',
            overflow: 'hidden',
            border: '4px solid white',
            cursor: 'pointer',
          }}
          className="flex cursor-pointer right-[40%] md:right-[36%] justify-center items-center"
        >
          <input
            type="file"
            style={{
              position: 'absolute',
              transform: 'scale(2)',
              opacity: '0',
              cursor: 'pointer',
            }}
            name="image"
            onChange={handleProfileChange}
          />
          <BsFillCameraFill
            style={{
              cursor: 'pointer',
            }}
            className="w-[28px] h-[28px] text-white"
          />
        </div>
      </div>
      {userInfo.errors.profileImage && (
        <div
          className="error"
          style={{
            marginTop: '-1rem',

            textAlign: 'center',
          }}
        >
          {userInfo.errors.profileImage}
        </div>
      )}
      <div className="email-n-phone-number-cnt">
        <div className="form-control">
          <InputText
            name={'name'}
            initialValue={user?.name}
            value={userData.name}
            label={'Name'}
            handleChange={handleInputChange}
            placeholder={'John Doe'}
            inputValidationFC={(str) =>
              str.length === 0 ? 'Enter a valid username' : ''
            }
          />
          {userInfo.errors.name && (
            <div className="error">{userInfo.errors.name}</div>
          )}
        </div>

        <div className="form-control">
          <InputDate
            name={'dateOfBirth'}
            value={userData && (userData?.dateOfBirth as string)}
            label={'DOB'}
            initialValue={user?.dateOfBirth}
            handleChange={handleInputChange}
            placeholder={'Your Date Of Birth'}
            InputType="date"
          />
          {userInfo.errors.dateOfBirth && (
            <span className="error">{userInfo.errors.dateOfBirth}</span>
          )}
        </div>

        <div
          style={{
            margin: '1.2rem 0',
          }}
          className="form-control"
        >
          <DropDown
            name="gender"
            initialValue={user?.gender}
            label={'Gender'}
            value={userData?.gender ?? user?.gender}
            placeholder="Select Gender"
            handleChange={handleInputChange}
            option={[
              { value: 'Choose gender', label: 'Choose gender' },
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Other', label: 'Other' },
            ]}
          />
          {userInfo.errors.gender && (
            <span className="error">{userInfo.errors.gender}</span>
          )}
        </div>

        <div
          className="form-control"
          style={{
            position: 'relative',
            margin: '1.4rem 0',

            flexWrap: 'wrap',
          }}
        >
          {/* <InputText
            name={"email"}
            initialValue={user?.email}
            value={userData?.email}
            label={'Email'}
            handleChange={handleInputChange}
            placeholder={'Enter Your Email'}
            inputValidationFC={validateEmail}
            InputType='email'
          /> */}

          <InputEmailVerify
            value={userData?.email}
            handleChange={(email: string) => {
              setUserData((pre) => ({ ...pre, email }));
            }}
          />

          {/* {userInfo.email.isVerified && (
            <span
              style={{
                marginLeft: '0.5rem',
                display: 'block',
                flexBasis: '5%',
                position: 'absolute',
                right: '20px',
                top: '18%',
              }}
            >
              <MdVerified style={{ color: 'green' }} />
            </span>
          )}
          {userInfo.errors.email && (
            <div className="error">{userInfo.errors.email}</div>
          )}

          {userData.email ? (
            <p
              onClick={sendOTP}
              style={{
                color: '#175DAC',
                marginBottom: '0.8rem',
                textAlign: 'right',
                flexBasis: '100%',
                fontSize: '0.9rem',
                fontWeight: 'semibold',
                display: 'block',
              }}
            >
              Verify Email
            </p>
          ) : (
            ''
          )}
          {userInfo.email.isVerified && (
            <p
              onClick={() => {
                setShowVerify(true);
                return setUserInfo({
                  ...userInfo,
                  email: { ...userInfo.email, isVerified: false },
                });
              }}
              style={{
                color: '#175DAC',
                marginBottom: '0.8rem',
                textAlign: 'right',
                flexBasis: '100%',
                fontSize: '0.9rem',
                fontWeight: 'semibold',
                display: 'block',
              }}
            >
              Update
            </p>
          )} */}
          {/* 
          {showOtp == true && userInfo.errors.email == "" ? (
            <Otp
              setSentOrResendOTPMsg={setSentOrResendOTPMsg}
              sentOrResendOTPMsg={sentOrResendOTPMsg}
              startPhoneOTPTimer={startPhoneOTPTimer}
              handleStartPhoneOTPTimer={handleStartPhoneOTPTimer}
              setResendPhoneOTPTimer={setResendPhoneOTPTimer}
              resendPhoneOTPTimer={resendPhoneOTPTimer}
              calculateResendPhoneOTPTimer={calculateResendPhoneOTPTimer}
              activeOTPIndex={activeOTPIndex}
              inputRef={inputRef}
              phoneNumberOTP={phoneNumberOTP}
              handleSubmitOTP={handleSubmitOTP}
              errorOfOTP={errorOfOTP}
              sendOTP={sendOTP}
              phoneNumberOTPHandleOnKeyDown={phoneNumberOTPHandleOnKeyDown}
            />
          ) : (
            ""
          )} */}
        </div>
      </div>
      <div className="user-profile-update-cnt">
        <button
          type="button"
          onClick={handleUpdateProfile}
          className="btn-primary update-btn"
        >
          Update
        </button>
      </div>
    </div>
  );
}

function Otp(props: any) {
  let {
    sendOTP,
    handleStartPhoneOTPTimer,
    setResendPhoneOTPTimer,
    resendPhoneOTPTimer,
    calculateResendPhoneOTPTimer,
    inputRef,
    phoneNumberOTP,
    activeOTPIndex,
    phoneNumberOTPHandleOnKeyDown,
    handleSubmitOTP,
    errorOfOTP,
    startPhoneOTPTimer,
    sentOrResendOTPMsg,
    setSentOrResendOTPMsg,
  } = props;

  useEffect(() => {
    handleStartPhoneOTPTimer();
  }, []);

  return (
    <div className="cnt" style={{ width: '100%' }}>
      <p
        className="greeting"
        style={{
          color: 'green',
          margin: '0.75rem 0',
        }}
      >
        {sentOrResendOTPMsg}
      </p>
      <div className="otp">
        <div
          className="cnt_otp"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          {phoneNumberOTP.map((a: any, index: number) => {
            return (
              <input
                style={{
                  width: '35px',
                  height: '35px',
                }}
                key={index}
                value={phoneNumberOTP[index]}
                onChange={(event) => {
                  return;
                }}
                type="number"
                maxLength={1}
                onFocus={(event) => {
                  event.target.classList.add('border-solid');
                  event.target.classList.add('border-[1.5px]');
                  event.target.classList.add('border-black');
                }}
                onBlur={(event) => {
                  event.target.classList.remove('border-solid');
                  event.target.classList.remove('border-[1.5px]');
                  event.target.classList.remove('border-black');
                }}
                ref={index === activeOTPIndex ? inputRef : null}
                onKeyDown={(event) =>
                  phoneNumberOTPHandleOnKeyDown(event, index)
                }
                className="otp_field input"
              />
            );
          })}
        </div>
        <div
          className="resend_otp"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'red' }}>{errorOfOTP}</span>
          <p
            onClick={async () => {
              if (startPhoneOTPTimer != true) {
                await sendOTP();
                handleStartPhoneOTPTimer();
                setResendPhoneOTPTimer(3);
                setSentOrResendOTPMsg('OTP has been resend to your number');
              }
            }}
            style={{
              marginTop: '0.5rem',
              color: resendPhoneOTPTimer == 0 ? 'blue' : 'gray',
            }}
          >
            Resend OTP
            {resendPhoneOTPTimer == 0
              ? ''
              : ' in ' + calculateResendPhoneOTPTimer}
          </p>
        </div>

        <button
          type="submit"
          onClick={handleSubmitOTP}
          className="send_otp_btn"
        >
          Submit OTP
        </button>
      </div>
    </div>
  );
}
