import { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { PostRequest, PatchRequest } from '../../../../component/Request';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import ConfirmationBox from '../../buyer/ConfirmationBox';
import { useNavigate } from 'react-router-dom';
import {
  IUserStore,
  UpdateSellerStoreAsyncThunk,
  storeProfileSelector,
} from '../../../../features/auth/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store';
import InputNumber from '../../../../common/InputNumber/VerifyInputNumber';
import { PhoneNumberList } from '../../../../interface/axios.types';
import InputEmailVerify from '../../../../common/email';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { BsTelephoneMinus } from 'react-icons/bs';

const CssTextField1 = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreContact(props: any) {
  let {
    setShowIndicator,
    setMessage,
    setType,
    shopInfo,
    setShopInfo,
    setSelectedNavigation,
    handleChange,
    validateEmail,
  } = props;
  let navigate = useNavigate();
  let {
    shopName,
    shopType,
    shopCategory,
    phoneNumberList,
    panCard,
    tradeLicense,
    GST,
    email,
    shopImages,
    profileImage,
  } = shopInfo.errors;
  const [phoneNumberOtpId, setPhoneNumberOtpId] = useState<string>('');
  const [msgForPhoneOtp, setMsgForPhoneOtp] = useState<string>('');
  const [showPhoneOtp, setShowPhoneOtp] = useState<boolean>(false);
  const [startEmailTimer, setStartEmailTimer] = useState(false);
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendEmailTimer, setResendEmailTimer] = useState(30);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const [otp, setOtp] = useState<any>(new Array(6).fill(''));
  const [emailOTP, setEmailOTP] = useState<string[]>(new Array(6).fill(''));
  const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0);
  const [activeEmailOTPIndex, setActiveEmailOTPIndex] = useState<number>(0);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  let currentOtpIndex: number = 0;
  const inputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const store = useSelector(storeProfileSelector);
  const [phoneNumberObj, setPhoneNumberObj] = useState({
    nationalNumber: '',
    label: '',
    isVerified: false,
    countryCode: '+91',
    error: {
      nationalNumber: '',
      label: '',
      isVerified: '',
    },
  });

  const handleUpdateStoreContact = async () => {
    await PatchRequest('store', {
      phoneNumberList: shopInfo.phoneNumberList.map((numberObj: any) => {
        return {
          countryCode: numberObj.countryCode,
          label: numberObj.label,
          nationalNumber: numberObj.phoneNumber,
        };
      }),
      email: email.id,
    })
      .then((res: any) => {
        if (res.status === 200) {
          setShowIndicator(true);
          setMessage('Updated');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setShowIndicator(true);
        setMessage(err.response.data.message);
        setType('error');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      });
  };

  const phoneOtpHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOtp: string[] = [...otp];

    if (key !== 'Backspace' && key.match('[0-9]')) {
      newOtp[index] = key;
      setActiveOtpIndex(index + 1);
      setOtp(newOtp);
    } else if (key == 'Backspace') {
      newOtp[index] = '';
      setActiveOtpIndex(index - 1);
      setOtp(newOtp);
    }
  };

  const emailOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newEmailOTP: string[] = [...emailOTP];

    if (key !== 'Backspace' && key.match('[0-9]')) {
      newEmailOTP[index] = key;
      setActiveEmailOTPIndex(index + 1);
      setEmailOTP(newEmailOTP);
    } else if (key == 'Backspace') {
      newEmailOTP[index] = '';
      setActiveEmailOTPIndex(index - 1);
      setEmailOTP(newEmailOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOtpIndex]);

  useEffect(() => {
    emailInputRef.current?.focus();
    return;
  }, [activeEmailOTPIndex]);

  const calculateResendEmailTimer = useMemo(() => {
    if (resendEmailTimer <= 0) {
      setStartEmailTimer(false);
      return 0;
    }

    return resendEmailTimer;
  }, [resendEmailTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startEmailTimer) {
      intervalId = setInterval(() => {
        setResendEmailTimer((resendEmailTimer) => resendEmailTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startEmailTimer]);

  const handleStart = () => {
    setStartEmailTimer(true);
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }

    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };
  //! new code for updated one

  const dispatch = useDispatch<AppDispatch>();
  const [storeData, setStoreData] = useState<IUserStore>({} as IUserStore);
  useEffect(() => {
    if (store) setStoreData((pre) => store);
  }, [store]);
  async function handleProfileChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.files?.length) {
      try {
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        const response = await PostRequest('file?type=store-image', formData);
        await dispatch(
          UpdateSellerStoreAsyncThunk({ profileImage: response.data.data.url })
        );
      } catch (error) {}
    } else {
    }
  }

  function handlePhoneNumberChange(value: PhoneNumberList) {}

  async function handleUpdateProfile() {
    let response = await dispatch(
      UpdateSellerStoreAsyncThunk({
        email: storeData.email,
        phoneNumberList: storeData.phoneNumberList,
      })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      setShowIndicator(true);
      setMessage('Updated Successfully');
      setType('success');
      setTimeout(() => {
        setShowIndicator(false);
      }, 3000);
    }
  }

  return (
    <div className="cnt_of_stepone store-profile-form ">
      {/* {showConfirmationBox ? (
        <ConfirmationBox
          showConfirmationBox={showConfirmationBox}
          setShowConfirmationBox={setShowConfirmationBox}
          shopInfo={shopInfo}
          setShopInfo={setShopInfo}
          setEmailOTP={setEmailOTP}
          setResendEmailTimer={setResendEmailTimer}
        />
      ) : (
        ''
      )} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        <div className="flex flex-col my-10 justify-between  sm:flex-col">
          <div className="flex my-10 justify-between  sm:flex-col ">
            <div className="basis-[47.5%] sm:flex-wrap sm:justify-start  flex justify-between">
              <div className="basis-[25%] sm:basis-[100%]">
                <FormControl
                  fullWidth
                  sx={{
                    '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                      lineHeight: '1rem',
                      fontSize: '0.90rem',
                      color: '#9FA6B3',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '0.35rem',
                        borderColor: '#E5E7EB',
                      },
                    },
                  }}
                >
                  <InputLabel>Label</InputLabel>
                  <Select
                    name="label"
                    style={{
                      width: '100%',
                    }}
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '0.75rem 1.25rem',
                      },
                      '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root': {
                        borderColor: '#E5E7EB',
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="label"
                    value={phoneNumberObj.label}
                    required
                    error={phoneNumberObj?.error?.label ? true : false}
                    onChange={(event: any) => {
                      let { value } = event.target;
                      setShowPhoneOtp(false);
                      setOtp(new Array(6).fill(''));
                      setResendPhoneOTPTimer(30);
                      setPhoneNumberOtpId('');
                      return setPhoneNumberObj({
                        ...phoneNumberObj,
                        label: value,
                        error: {
                          ...phoneNumberObj.error,
                          label: '',
                        },
                      });
                    }}
                  >
                    <MenuItem value="Work">Work</MenuItem>
                    <MenuItem value="Personal">Personal</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                  <span
                    style={{
                      color: '#D3382F',
                      fontSize: '12.5px',
                      marginLeft: '0.5rem',
                    }}
                  >
                    {phoneNumberObj.error.label}
                  </span>
                </FormControl>
              </div>
              <div className="sm:basis-[30%] basis-[16%] sm:mt-[1.5rem]">
                <CssTextField1
                  id="outlined-basic"
                  name="countryCode"
                  type="text"
                  label="Code"
                  value={'🇮🇳  ' + phoneNumberObj.countryCode}
                />
              </div>
              <div className="sm:basis-[45%] sm:ml-[2rem] sm:mt-[1.5rem]">
                <CssTextField
                  id="outlined-basic"
                  name="phoneNumber"
                  type="number"
                  value={phoneNumberObj.nationalNumber}
                  onChange={(event: any) => {
                    let { value } = event.target;
                    setShowPhoneOtp(false);
                    setOtp(new Array(6).fill(''));
                    setResendPhoneOTPTimer(30);
                    if (phoneNumberOtpId) {
                      setPhoneNumberOtpId('');
                    }

                    if (value.length === 10) {
                      setPhoneNumberObj({
                        ...phoneNumberObj,
                        nationalNumber: value,
                        error: {
                          ...phoneNumberObj.error,
                          nationalNumber: '',
                        },
                      });
                    } else if (value.length > 10) {
                      setPhoneNumberObj({
                        ...phoneNumberObj,
                        nationalNumber: value,
                        error: {
                          ...phoneNumberObj.error,
                          nationalNumber: 'Please enter a valid number',
                        },
                      });
                    } else {
                      setPhoneNumberObj({
                        ...phoneNumberObj,
                        nationalNumber: value,
                        error: {
                          ...phoneNumberObj.error,
                          nationalNumber: '',
                        },
                      });
                    }
                  }}
                  label="Phone Number"
                  required
                  error={phoneNumberObj.error.nationalNumber ? true : false}
                  helperText={phoneNumberObj.error.nationalNumber}
                />
              </div>
            </div>

            <div className="basis-[47.5%] sm:self-center sm:justify-center sm:flex-wrap justify-between flex sm:mt-[2rem]">
              <div
                className={
                  showPhoneOtp
                    ? 'phone_otpField relative h-[45px] space-x-[0.67rem]'
                    : 'phone_otpField hidden relative h-[45px] space-x-[0.67rem]'
                }
              >
                <div className="flex justify-center">
                  <span
                    className=" otp_info top-[-48%] absolute text-xs"
                    style={{
                      color:
                        msgForPhoneOtp ===
                          'OTP has been sent to your phone number' ||
                        msgForPhoneOtp ===
                          'OTP has been sent again to your phone number'
                          ? 'green'
                          : 'red',
                    }}
                  >
                    {msgForPhoneOtp}
                  </span>
                </div>

                <div className="h-[45px] sm:self-center space-x-[0.67rem]">
                  {otp?.length > 0 &&
                    otp?.map((number: any, index: number) => {
                      return (
                        <input
                          key={index}
                          value={otp[index]}
                          onChange={(event) => {
                            return;
                          }}
                          type="number"
                          maxLength={1}
                          onFocus={(event) => {
                            event.target.classList.add('border-solid');
                            event.target.classList.add('border-[1.5px]');
                            event.target.classList.add('border-black');
                          }}
                          onBlur={(event) => {
                            event.target.classList.remove('border-solid');
                            event.target.classList.remove('border-[1.5px]');
                            event.target.classList.remove('border-black');
                          }}
                          ref={index === activeOtpIndex ? inputRef : null}
                          onKeyDown={(event) =>
                            phoneOtpHandleOnKeyDown(event, index)
                          }
                          className="shadow-md h-[45.6px] sm:h-[39px] sm:w-[39px] xs:w-[30px] xs:h-[30px]  bg-[#F6F6FB] inline-block   w-[49px] rounded-md text-center"
                        />
                      );
                    })}
                </div>

                <div className="flex justify-center">
                  <p
                    onClick={async () => {
                      await PostRequest('otp/send', {
                        nationalNumber: phoneNumberObj.nationalNumber,
                        countryCode: phoneNumberObj.countryCode,
                        label: phoneNumberObj.label,
                        profileType: 'Store',
                      }).then((res: any) => {
                        if (res.status == 200) {
                          setMsgForPhoneOtp(
                            'OTP has been sent again to your phone number'
                          );
                          setPhoneNumberOtpId(res.data.data.otpId);
                          setResendPhoneOTPTimer(30);
                          handleStartPhoneOTPTimer();
                        }
                      });
                    }}
                    className={
                      resendPhoneOTPTimer == 0
                        ? 'absolute underline resend-btn text-[#14539A]  cursor-pointer text-[12px] top-[121%]'
                        : 'absolute resend-btn text-gray-600  text-[12px] top-[121%]'
                    }
                  >
                    Resend OTP
                    {resendPhoneOTPTimer == 0
                      ? ''
                      : ' in ' + calculateResendPhoneOTPTimer}
                  </p>
                </div>
              </div>

              <div>
                {showPhoneOtp ? (
                  <div className=" sm:mt-[2.5rem] sm:basis-[100%] cnt_of_submit_n_resend_phone_btn flex space-x-4 ">
                    <button
                      type="button"
                      className="button-primary  submit_btn shadow-inner"
                      onClick={async () => {
                        let phoneOTP = otp.join('');
                        await PostRequest('otp/verify', {
                          countryCode: phoneNumberObj.countryCode,
                          nationalNumber: phoneNumberObj.nationalNumber,
                          otp: phoneOTP,
                          otpId: phoneNumberOtpId,
                          profileType: 'Store',
                        })
                          .then((res: any) => {
                            if (res.status == 200) {
                              setPhoneNumberOtpId('');
                              setPhoneNumberObj({
                                ...phoneNumberObj,
                                isVerified: true,
                              });

                              let phoneNumberArray = [
                                ...storeData.phoneNumberList,
                                {
                                  nationalNumber: phoneNumberObj.nationalNumber,
                                  label: phoneNumberObj.label,
                                  countryCode: phoneNumberObj.countryCode,
                                },
                              ];

                              setStoreData((prevStoreInfo: any) => {
                                return {
                                  ...prevStoreInfo,
                                  phoneNumberList: phoneNumberArray,
                                };
                              });

                              setPhoneNumberObj({
                                nationalNumber: '',
                                label: '',
                                isVerified: false,
                                countryCode: '+91',
                                error: {
                                  nationalNumber: '',
                                  label: '',
                                  isVerified: '',
                                },
                              });

                              setOtp(new Array(6).fill(''));
                              setShowPhoneOtp(false);
                            }
                          })
                          .catch((err: any) => {
                            setMsgForPhoneOtp(err.response.data.message);
                          });
                      }}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={async (event: any) => {
                      if (
                        phoneNumberObj.nationalNumber.length == 10 &&
                        phoneNumberObj.label != ''
                      ) {
                        await PostRequest('otp/send', {
                          countryCode: phoneNumberObj.countryCode,
                          nationalNumber: phoneNumberObj.nationalNumber,
                          profileType: 'Store',
                        })
                          .then((res: any) => {
                            if (res.data.statusCode === 200) {
                              setPhoneNumberOtpId(res.data.data.otpId);
                              setMsgForPhoneOtp(
                                'OTP has been sent to your phone number'
                              );
                              handleStartPhoneOTPTimer();

                              // showing otp fields
                              setShowPhoneOtp(true);
                            }
                          })
                          .catch((err: any) => {
                            setPhoneNumberOtpId('');
                            let newStoreInfo = { ...storeData };
                            let isNumberPresent =
                              storeData.phoneNumberList.some((obj: any) => {
                                return (
                                  obj.nationalNumber !== undefined &&
                                  obj.nationalNumber ===
                                    phoneNumberObj.nationalNumber
                                );
                              });
                            if (
                              err.response.data.message.includes(
                                'OTP already verified for this'
                              )
                            ) {
                              if (!isNumberPresent) {
                                newStoreInfo.phoneNumberList =
                                  newStoreInfo?.phoneNumberList?.concat({
                                    label: 'Work',
                                    countryCode: phoneNumberObj.countryCode,
                                    nationalNumber:
                                      phoneNumberObj.nationalNumber,
                                    isVerfied: true,
                                  });

                                setStoreData(() => newStoreInfo);
                                setPhoneNumberObj(() => {
                                  return {
                                    nationalNumber: '',
                                    label: '',
                                    isVerified: false,
                                    countryCode: '+91',
                                    error: {
                                      nationalNumber: '',
                                      label: '',
                                      isVerified: '',
                                    },
                                  };
                                });
                              }
                            }
                          });
                      } else if (
                        phoneNumberObj.nationalNumber.length != 10 &&
                        phoneNumberObj.label == ''
                      ) {
                        setPhoneNumberObj({
                          ...phoneNumberObj,
                          error: {
                            ...phoneNumberObj.error,
                            nationalNumber: 'Please enter 10 digit number',
                            label: 'Choose one',
                          },
                        });
                      } else if (phoneNumberObj.label == '') {
                        setPhoneNumberObj({
                          ...phoneNumberObj,
                          error: {
                            ...phoneNumberObj.error,
                            label: 'Choose here',
                          },
                        });
                      } else if (phoneNumberObj.nationalNumber.length != 10) {
                        setPhoneNumberObj({
                          ...phoneNumberObj,
                          error: {
                            ...phoneNumberObj.error,
                            nationalNumber: 'Please enter a 10 digit number',
                          },
                        });
                      }
                    }}
                    disabled={
                      phoneNumberObj.error.nationalNumber ||
                      phoneNumberObj.error.label ||
                      phoneNumberObj.error.isVerified
                        ? true
                        : false
                    }
                    className={
                      phoneNumberObj.error.nationalNumber ||
                      phoneNumberObj.error.label ||
                      phoneNumberObj.error.isVerified
                        ? 'button-primary-disabled  verify_number_btn shadow-inner'
                        : 'button-primary verify_number_btn shadow-inner'
                    }
                  >
                    Verify
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* ##################fix number ########################## */}

          {/* add it  */}
          {/* <InputNumber
        handleChange={(value) => {
          if (
            storeData.phoneNumberList.findIndex(
              (item) => item.nationalNumber === value.nationalNumber
            ) !== 1
          )
            setStoreData((pre) => ({
              ...pre,
              phoneNumberList: [...pre?.phoneNumberList, value],
            }));
        }}
      /> */}

          {/* // only verified numbers  */}
          {store?.phoneNumberList?.length > 0 &&
            store?.phoneNumberList?.map((phoneNumberObj: any) => {
              return (
                <div
                  key={phoneNumberObj.nationalNumber}
                  className="flex mb-10 sm:flex-col justify-between"
                >
                  <div className="basis-[47.5%] sm:flex-wrap sm:justify-start  flex justify-between">
                    <div className="basis-[25%] sm:basis-[100%]">
                      <FormControl
                        fullWidth
                        sx={{
                          '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root':
                            {
                              lineHeight: '1rem',
                              fontSize: '0.90rem',
                              color: '#9FA6B3',
                            },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderRadius: '0.35rem',
                              borderColor: '#E5E7EB',
                            },
                          },
                        }}
                      >
                        <InputLabel>Label</InputLabel>
                        <Select
                          name="label"
                          style={{
                            width: '100%',
                          }}
                          sx={{
                            '& .MuiSelect-select': {
                              padding: '0.75rem 1.25rem',
                            },
                            '& .css-u0uils-MuiFormLabel-root-MuiInputLabel-root':
                              {
                                borderColor: '#E5E7EB',
                              },
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="label"
                          value={phoneNumberObj.label}
                          aria-readonly={true}
                        >
                          <MenuItem value={phoneNumberObj.label}>
                            {phoneNumberObj.label}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="sm:basis-[30%]  basis-[16%] sm:mt-[1.5rem]">
                      <CssTextField1
                        id="outlined-basic"
                        name="countryCode"
                        type="text"
                        label="Code"
                        value={'🇮🇳' + '+91'}
                        aria-readonly={true}
                      />
                    </div>
                    <div className="sm:basis-[45%] sm:ml-[2rem] sm:mt-[1.5rem]">
                      {phoneNumberObj?.phoneNumber?<CssTextField
                        id="outlined-basic"
                        name="phoneNumber"
                        type="number"
                        value={phoneNumberObj?.phoneNumber.split(" ")[1]}
                        label="Phone Number"
                        aria-readonly={true}
                      />:<CssTextField
                      id="outlined-basic"
                      name="phoneNumber"
                      type="number"
                      value={phoneNumberObj?.nationalNumber}
                      label="Phone Number"
                      aria-readonly={true}
                    />}
                    </div>
                  </div>

                  <div className="basis-[48.5%] sm:mt-[1.5rem] sm:basis-[100%] sm:self-center sm:justify-center ">
                    <button
                      type="button"
                      onClick={() => {
                        let previousPhoneNumberList = [
                          ...storeData.phoneNumberList,
                        ];
                        let updatedPhoneNumberList =
                          previousPhoneNumberList.filter(
                            (previousPhoneNumber: any) => {
                              if (
                                previousPhoneNumber.nationalNumber !=
                                phoneNumberObj.nationalNumber
                              ) {
                                return previousPhoneNumber;
                              }
                            }
                          );
                        setStoreData((prevShopInfo: any) => {
                          return {
                            ...prevShopInfo,
                            phoneNumberList: updatedPhoneNumberList,
                          };
                        });
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      className="button-primary"
                    >
                      {'Delete   '}
                      <span className="ml-[1rem]"></span> <BsTelephoneMinus />
                    </button>
                  </div>
                </div>
              );
            })}

          <InputEmailVerify
            value={store?.email}
            handleChange={(email: string) => {
              setStoreData((pre) => ({ ...pre, email }));
            }}
          />
        </div>
        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateProfile}
            className={'button-primary md:w-[5rem] md:text-[12px]'}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
