import { useEffect, useRef, useState } from 'react';
import { PatchRequest, PostRequest } from '../../../component/Request';
import { Link, useNavigate } from 'react-router-dom';
import { FaStore } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { fetchStoreSelfAsyncThunk } from '../../../features/auth/Slice';

export default function BuyerStoreListPopup({
  buyerStoreList,
  setBuyerStoreList,
}: any) {
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [storeId, setStoreId] = useState<string>('');

  return !showConfirmation ? (
    <section className="confirmation-cnt lists-of-stores">
      <div className="confirmation-box">
        <p>Add your already registered store from ModulusBuy in one click</p>
        <ul
          style={{
            padding: '0 0.8rem',
          }}
        >
          {buyerStoreList.length > 0
            ? buyerStoreList.map((store: any) => {
                return (
                  <li
                    key={store._id}
                    className="store"
                    onClick={async () => {
                      await setStoreId(store._id);
                      await setShowConfirmation(true);
                    }}
                  >
                    <Link to="#">
                      <FaStore style={{ display: 'inline-block' }} />
                      <span
                        style={{
                          marginLeft: '0.5rem',
                        }}
                      >
                        {store.name}
                      </span>
                    </Link>
                  </li>
                );
              })
            : ''}
        </ul>
        <hr />
        <h3
          style={{
            color: '#185ef6',
            cursor: 'pointer',
            marginTop: '0.5rem',
            textAlign: 'center',
          }}
          onClick={() => {
            setBuyerStoreList(false);
          }}
        >
          Create a new store
        </h3>
      </div>
    </section>
  ) : (
    <ConfirmationBox
      showConfirmation={showConfirmation}
      storeId={storeId}
      setStoreId={setStoreId}
      setShowConfirmation={setShowConfirmation}
    />
  );
}

function ConfirmationBox({ storeId, setStoreId, setShowConfirmation }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const createStore = async () => {
    await PatchRequest(`store/${storeId}`, {
      seller: true,
    }).then(async (res) => {
      if (res.data.statusCode === 201) {
        setStoreId(() => '');
        await dispatch(fetchStoreSelfAsyncThunk());
        await navigate('/seller/brand');
      }
    });
  };

  return (
    <section className="confirmation-cnt lists-of-stores">
      <div className="confirmation-box">
        <p>Do you want to proceed to create a new store?</p>

        <div
          className="accept-decline-cnt"
          style={{
            justifyContent: 'center',
          }}
        >
          <button className="accept-btn" onClick={createStore}>
            Yes
          </button>
          <button
            style={{
              marginLeft: '0.2rem',
            }}
            className="decline-btn"
            onClick={() => {
              setStoreId('');
              setShowConfirmation(false);
            }}
          >
            No
          </button>
        </div>

        {/* <ul
          style={{
            padding: '0 0.8rem',
          }}
        >
          {buyerStoreList.length > 0
            ? buyerStoreList.map((store: any) => {
                return (
                  <li
                    key={store._id}
                    className="store"
                    onClick={() => createStore(store._id)}
                  >
                    <Link to="#">
                      <FaStore style={{ display: 'inline-block' }} />
                      <span
                        style={{
                          marginLeft: '0.5rem',
                        }}
                      >
                        {store.name}
                      </span>
                    </Link>
                  </li>
                );
              })
            : ''}
        </ul>
        <hr />
        <h3
          style={{
            color: '#185ef6',
            cursor: 'pointer',
            marginTop: '0.5rem',
          }}
          onClick={() => {
            setBuyerStoreList(false);
          }}
        >
          Create a new store
        </h3> */}
      </div>
    </section>
  );
}
