import { FiClock } from 'react-icons/fi';
import './styles/routeDropdown.scss';
import { RxCross1 } from 'react-icons/rx';
import { useEffect, useRef, useState } from 'react';
import { AiTwotoneShop } from 'react-icons/ai';
import { FaRoute } from 'react-icons/fa';
import { PatchRequest, PostRequest } from '../../../component/Request';
import { Link } from 'react-router-dom';
import { OrderStatusEnum } from './orderStatusEnum/orderStatus.enum';

export default function RouteDropdown({
  sellerRoute,
  setSellerRoute,
  buyerStore,
  setShowRoute,
  fetchNotAssignedOrder,
  fetchScheduledOrder,
  fetchNewOrderCount,
  order,
  setOrderIdToConvertInPreparingFullOrderObject
}: any) {
  const routeContainerRef = useRef<any>(null);
  const [selectedRoute, setSelectedRoute] = useState<any>({});
  const [errorForRoute, setErrorForRoute] = useState('');

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        routeContainerRef.current &&
        !routeContainerRef.current.contains(event.target)
      ) {
        setSellerRoute([]);
        setShowRoute(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSellerRoute]);

  function convertToDatetimeLocalFormat(inputFormat: any) {
    try {
      const inputDate = new Date(inputFormat);

      if (isNaN(inputDate.getTime())) {
        throw new Error('Invalid input format');
      }

      const day = inputDate.getDate();
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = monthNames[inputDate.getMonth()];
      const year = inputDate.getFullYear().toString().slice(-2);
      const hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const amOrPm = hours >= 12 ? 'pm' : 'am';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes
        .toString()
        .padStart(2, '0')}${amOrPm}`;
      return formattedDate;
    } catch (error) {
      return 'Invalid input format';
    }
  }

  const handleClickOnActiveRoute = (route: any) => {
    if (route.name == selectedRoute.name) {
      setSelectedRoute({});
      setErrorForRoute('Please select one route');
    } else {
      setSelectedRoute({ name: route.name, id: route._id });
      setErrorForRoute('');
    }
  };

  const handleClickOnSubmit = async () => {
    if(order.urgentOrder){
      await PatchRequest(`order/${order._id}`,{
        status:OrderStatusEnum.Preparing,
        urgentOrder:false,
        routeScheduleId:selectedRoute?.id
      })
      setOrderIdToConvertInPreparingFullOrderObject(null)
    }else
    if (selectedRoute.name) {
      await PostRequest(`route/${selectedRoute.id}/buyer`, {
        buyerType: 'Store',
        buyerStoreId: buyerStore._id,
      })
        .then(async (res) => {
          if (res.data.statusCode === 201) {
            await fetchNewOrderCount();
            await fetchNotAssignedOrder();
            await fetchScheduledOrder('Ordered');
            setShowRoute(false);
            setSellerRoute(false);
          }
        })
        .catch((err) => {
          console.log(err, ' err in accepting the order');
        });
    } else {
      setErrorForRoute('Please select one route');
    }
  };

  return (
    <section className="new-order-cnt">
      <div
        className="new-order-box"
        style={{ maxWidth: '500px' }}
        ref={routeContainerRef}
      >
        <div
          className="cross-icon-cnt"
          style={{ justifyContent: 'space-between' }}
        >
          <h1 className="heading-of-seller-route">
            Please select one route which are given below :-
          </h1>
          <button className="cross-icon">
            <RxCross1
              onClick={() => {
                setSellerRoute([]);
                setShowRoute(false);
                setOrderIdToConvertInPreparingFullOrderObject(null)
              }}
            />
          </button>
        </div>

        <div className="route-dropdown">
          <div className="buyer-info">
            {!buyerStore.profileImage ? (
              <AiTwotoneShop className="buyer-img" />
            ) : (
              <img
                className="buyer-img"
                src={buyerStore.profileImage}
                alt="buyer-store"
              />
            )}
            <h2 className="buyer-name">{buyerStore.name}</h2>
          </div>
          <div className="cnt-of-route-card">
            {sellerRoute.length > 0 ? (
              sellerRoute.map((route: any) => {
                return (
                  <div
                    className={
                      route._id == selectedRoute.id
                        ? `card active-card`
                        : `card`
                    }
                    onClick={() => handleClickOnActiveRoute(route)}
                  >
                    <div className="route-info">
                      <div className="name">{route.name}</div>
                      <div className="schedule">
                        <FiClock className="icon" />

                        {`Upcoming Route is ` +
                          convertToDatetimeLocalFormat(
                            route.upcomingRouteSchedule.startDateAndTime
                          )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="error ">No Routes Found, Please Add Your Routes.</p>
            )}
          </div>

          <Link className="add-new-route-btn" to="/route">
            <span>Add a new route</span> <FaRoute />
          </Link>
        </div>

        <span className="error">{errorForRoute && errorForRoute}</span>

        <div className="seller-route-button-container">
          {/* <button
            type="button"
            onClick={() => {
              setSellerRoute([]);
              setShowRoute(false);
            }}
            className="cancel-button"
          >
            Cancel
          </button> */}
          <button
            type="submit"
            onClick={handleClickOnSubmit}
            className="submit-button"
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
}
