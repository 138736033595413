import './profile.scss';
import {
  FaStore,
  FaUserCircle,
  FaMapMarkerAlt,
  FaEnvelope,
  FaRegBuilding,
} from 'react-icons/fa';

import { useState } from 'react';
import { useEffect } from 'react';
import UserProfile from './component/UserProfile';
import StoreProfile from './component/StoreProfile';
import StoreContact from './component/StoreContact';
import StoreLocation from './component/StoreLocation';
import StoreLegal from './component/StoreLegal';
import { GetRequest, PostRequest } from '../../../component/Request';
import Indicator from '../../../common/Indicator/Indicator';
import StorePublicProfileSetting from './component/StorePublicProfileSetting';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../features/auth/Slice';
import { panCardValidation, validateEmail, validateGST } from '../../../utils';

export default function Profile(props: any) {
  const [shopLocation, setShopLocation] = useState({
    line1: '',
    landmark: '',
    district: '',
    state: '',
    country: '',
    postalCode: '',
    tehsil: '',
    errors: {
      lie1: '',

      district: '',
      state: '',
      country: '',
      postalCode: '',
      tehsil: '',
    },
  });
  const [shopInfo, setShopInfo] = useState<any>({
    seller: true,
    name: '',
    typeList: [],
    categoryList: [],
    phoneNumberList: [],
    pancard: { number: '', document: '' },
    tradeLicense: { number: '', document: '' },
    profileImage: '',
    imageList: [],
    gst: { number: '' },
    email: { id: '', isVerified: false },
    aboutUs: {
      text: '',
    },
    coverImage: {
      image: '',
    },
    banner: {
      imageList: [],
    },
    errors: {
      name: '',
      typeList: '',
      categoryList: '',
      phoneNumberList: '',
      tradeLicense: { number: '', document: '' },
      pancard: { number: '', document: '' },
      gst: { number: '', document: '' },
      email: '',
      profileImage: '',
      imageList: '',
      coverImage: '',
      banner: '',
    },
  });
  const [selectedNavigation, setSelectedNavigation] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [showIndicator, setShowIndicator] = useState(false);
  const isMobileScreen = useMediaQuery({ maxWidth: '480px' });
  const location = useLocation();
  const { hash } = location;
  const navigate = useNavigate();
  const [storeProfileInfo, setStoreProfileInfo] = useState<any>({
    aboutUs: {
      text: '',
    },
    coverImage: {
      image: '',
    },
    banner: {
      imageList: [],
    },
    errors: {
      coverImage: '',
      banner: '',
    },
  });
  const [updateStoreProfileInfo, setUpdateStoreProfileInfo] = useState(false);
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    if (hash == '#user-profile') {
      setSelectedNavigation('#user-profile');
    } else if (hash == '#store-profile') {
      setSelectedNavigation('#store-profile');
    } else if (hash == '#store-public-profile') {
      setSelectedNavigation('#store-public-profile');
    } else if (hash == '#store-contact') {
      setSelectedNavigation('#store-contact');
    } else if (hash == '#store-location') {
      setSelectedNavigation('#store-location');
    } else if (hash == '#store-legal') {
      setSelectedNavigation('#store-legal');
    } else if (hash == '#store-user-setting') {
      setSelectedNavigation('#store-user-setting');
    }
  }, [hash]);

  const getStoreInfo = async () => {
    await GetRequest('store/self')
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          let activeStore = res.data.data;
          setShopInfo({
            ...activeStore,
            errors: {
              name: '',
              typeList: '',
              categoryList: '',
              phoneNumberList: '',
              tradeLicense: { number: '', document: '' },
              panCard: { number: '', document: '' },
              GST: { number: '', document: '' },
              email: '',
              profileImage: '',
              imageList: '',
              coverImage: '',
              banner: '',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err, 'err in store self api');
      });
  };

  useEffect(() => {
    if (isMobileScreen) {
      if (hash) {
        navigate(`/profile${hash}`);
        setSelectedNavigation(hash);
      } else {
        navigate('/profile');
        setSelectedNavigation('');
      }
    }
    //  else {
    //   navigate(`/profile#user-profile`);
    //   setSelectedNavigation('#user-profile');
    // }
  }, [isMobileScreen]);


  const fetchStoreProfileDetail = async () => {
    await GetRequest(`store/self?profile-detail=true`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          let { aboutUs, banner, coverImage } = res.data.data.profileDetail;

          await setStoreProfileInfo((prevState: any) => {
            return {
              ...prevState,
              ...res.data.data.profileDetail,
            };
          });

          if (
            aboutUs?.text ||
            banner?.imageList?.length > 0 ||
            coverImage?.image
          ) {
            await setUpdateStoreProfileInfo(true);
          } else {
            setUpdateStoreProfileInfo(false);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'err in getting store profile detail');
      });
  };

  const handleChange = async (event: any) => {
    let { value, name } = event.target;
    let { errors } = shopInfo;
    let updatedShopInfo = { ...shopInfo };
    switch (name) {
      case 'name':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: value,
            errors: {
              ...prevState.errors,
              name: value == '' ? 'Name is required' : '',
            },
          };
        });
        break;
      case 'banner':
        let banner = event.target.files[0];
        const formDataWithBanner = new FormData();
        formDataWithBanner.append('file', banner);
        await PostRequest('file?type=store-image', formDataWithBanner)
          .then((fileResponse: any) => {
            setStoreProfileInfo({
              ...storeProfileInfo,
              banner: fileResponse.data.data.url.includes('pdf')
                ? storeProfileInfo.banner
                : {
                    imageList: storeProfileInfo.banner.imageList.concat({
                      image: fileResponse.data.data.url,
                    }),
                  },
              errors: {
                ...storeProfileInfo.errors,
                banner: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setStoreProfileInfo({
                ...storeProfileInfo,
                errors: {
                  ...storeProfileInfo.errors,
                  banner: error.response.data.message,
                },
              });
            }
          });

        break;
      case 'profileImage':
        let img = event.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
        await PostRequest('file?type=store-image', formData)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              profileImage: fileResponse.data.data.url.includes('pdf')
                ? ''
                : fileResponse.data.data.url,
              errors: {
                ...shopInfo.errors,
                profileImage: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setShopInfo({
                ...shopInfo,
                errors: {
                  ...shopInfo.errors,
                  profileImage: error.response.data.message,
                },
              });
            }
          });
        // setTimeout(() => {
        //   setShopInfo((prevState) => {
        //     return {
        //       ...prevState,
        //       errors: {
        //         ...prevState.errors,
        //         profileImage: '',
        //       },
        //     };
        //   });
        // }, 2000);
        break;

      case 'categoryList':
        errors.categoryList = !value ? 'shop category is required' : '';
        break;
      case 'email':
        if (value == '') {
          errors.email = 'email is required';
        } else if (!validateEmail(value)) {
          errors.email = 'email is invalid';
        } else {
          errors.email = '';
        }
        break;
      case 'panCard':
        value = value.toUpperCase();
        if (value === '') {
          errors.panCard.number = 'PAN Card is required';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: '' },
              errors: { ...errors },
            };
          });
        } else if (!panCardValidation(value)) {
          errors.panCard.number = 'PAN Card is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.panCard.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        }
        break;
      case 'GST':
        if (!validateGST(value)) {
          errors.GST.number = 'GST is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.GST, number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.GST.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.GST, number: value },
              errors: { ...errors },
            };
          });
        }
        break;

      case 'tradeLicense':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: { ...prevState.tradeLicense, number: value },
            errors: { ...errors },
          };
        });
        break;
      case 'coverImage':
        let coverImg = event.target.files[0];
        const newFormData = new FormData();
        newFormData.append('file', coverImg);
        await PostRequest('file?type=store-image', newFormData)
          .then((fileResponse: any) => {
            setStoreProfileInfo({
              ...storeProfileInfo,
              coverImage: {
                image: fileResponse.data.data.url.includes('pdf')
                  ? ''
                  : fileResponse.data.data.url,
              },

              errors: {
                ...storeProfileInfo.errors,
                coverImage: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setStoreProfileInfo({
                ...storeProfileInfo,
                errors: {
                  ...storeProfileInfo.errors,
                  coverImage: error.response.data.message,
                },
              });
            }
          });
        break;
      case 'aboutUs':
        let updatedStoreProfileInfo = { ...storeProfileInfo };
        updatedStoreProfileInfo.aboutUs.text = value;
        setStoreProfileInfo(updatedStoreProfileInfo);
        break;
      case 'imageList':
        let img1 = event.target.files[0];
        const formData1 = new FormData();
        formData1.append('file', img1);
        await PostRequest('file?type=store-image', formData1)
          .then((fileResponse: any) => {
            setShopInfo({
              ...shopInfo,
              imageList: fileResponse.data.data.url.includes('pdf')
                ? shopInfo.imageList
                : shopInfo.imageList.concat(fileResponse.data.data.url),
              errors: {
                ...shopInfo.errors,
                imageList: fileResponse.data.data.url.includes('pdf')
                  ? 'pdf file type not supported'
                  : '',
              },
            });
          })
          .catch((error) => {
            if (
              error.response.data.message ===
              'File larger than 1 MB not accepted'
            ) {
              setShopInfo({
                ...shopInfo,
                errors: {
                  ...shopInfo.errors,
                  imageList: error.response.data.message,
                },
              });
            }
          });

        break;
    }
    // if (name == 'email') {
    //   setShopInfo((prevState: any) => {
    //     return {
    //       ...prevState,
    //       [name]: { id: value, isVerified: false },
    //       errors,
    //     };
    //   });
    // }
  };

  return (
    <div className="account-settings-container">
      {showIndicator ? (
        <Indicator
          message={message}
          type={type}
          errorId={String(Math.random())}
        />
      ) : (
        ''
      )}
      <NavBar
        screenWidth={screenWidth}
        selectedNavigation={selectedNavigation}
        setSelectedNavigation={setSelectedNavigation}
        fetchStoreProfileDetail={fetchStoreProfileDetail}
      />

      <>
        {selectedNavigation == '#user-profile' ? (
          <UserProfile
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == '#store-profile' ? (
          <StoreProfile
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            handleChange={handleChange}
            shopInfo={shopInfo}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == '#store-public-profile' ? (
          <StorePublicProfileSetting
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            setSelectedNavigation={setSelectedNavigation}
            updateStoreProfileInfo={updateStoreProfileInfo}
            setUpdateStoreProfileInfo={setUpdateStoreProfileInfo}
            fetchStoreProfileDetail={fetchStoreProfileDetail}
            storeProfileInfo={storeProfileInfo}
            setStoreProfileInfo={setStoreProfileInfo}
            handleChange={handleChange}
            storeId={shopInfo._id}
          />
        ) : (
          ''
        )}

        {selectedNavigation == '#store-contact' ? (
          <StoreContact
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            handleChange={handleChange}
            shopInfo={shopInfo}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == '#store-location' ? (
          <StoreLocation
            setShowIndicator={setShowIndicator}
            setType={setType}
            setMessage={setMessage}
            handleChange={handleChange}
            setShopLocation={setShopLocation}
            shopLocation={shopLocation}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}

        {selectedNavigation == '#store-legal' ? (
          <StoreLegal
            handleChange={handleChange}
            shopInfo={shopInfo}
            setShopInfo={setShopInfo}
            setSelectedNavigation={setSelectedNavigation}
          />
        ) : (
          ''
        )}
      </>
    </div>
  );
}

function NavBar(props: any) {
  let {
    screenWidth,
    selectedNavigation,
    setSelectedNavigation,
    fetchStoreProfileDetail,
  } = props;
  const navigate = useNavigate();
  const currentUser = useSelector(currentUserSelector);
  if (screenWidth >= 480) {
    return (
      <div
        style={{
          width: screenWidth > 480 ? '26%' : '100%',
          borderRight: screenWidth > 480 ? '0.5px solid #ddd' : 'none',
          marginLeft: screenWidth < 480 ? '1rem' : '0',
          marginRight: screenWidth < 480 ? '1rem' : '0',
        }}
        className="navigation"
      >
        <div>
          <div>
            User
            <hr />
          </div>
          <div
            className={`menu ${
              selectedNavigation == '#user-profile' ? 'active-nav ' : ''
            }`}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '100%',
              fontSize: 'smaller',
            }}
            onClick={() => {
              navigate('#user-profile');
            }}
          >
            <div
              className={`icon `}
              style={{ width: '28px', height: '28px', fontSize: '1rem' }}
            >
              <FaUserCircle />
            </div>
            <span style={{ fontSize: '16px' }}>User Profile</span>
          </div>
        </div>
        <div
          onClick={() => {
            navigate('#store-profile');
          }}
        >
          <div>
            Store
            <hr />
          </div>
        </div>

        <div className="submenu">
          <div
            className={`menu ${
              selectedNavigation == '#store-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Information</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-public-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-public-profile');
              fetchStoreProfileDetail();
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Public Profile Settings</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-contact' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-contact');
            }}
          >
            <div className="icon">
              <FaEnvelope />
            </div>
            <span>Contact Details</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-location' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-location');
            }}
          >
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <span>Address Details</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-legal' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-legal');
            }}
          >
            <div className="icon">
              <FaRegBuilding />
            </div>
            <span>Documents</span>
          </div>
        </div>
      </div>
    );
  } else if (screenWidth < 480 && selectedNavigation) {
    return <div style={{ display: 'none' }}></div>;
  } else {
    return (
      <div
        style={{
          width: screenWidth > 480 ? '26%' : '100%',
          borderRight: screenWidth > 480 ? '0.5px solid #ddd' : 'none',
          marginLeft: screenWidth < 480 ? '1rem' : '0',
          marginRight: screenWidth < 480 ? '1rem' : '0',
        }}
        className="navigation"
      >
        <div>
          <div>
            User
            <hr />
          </div>
          <div
            className={`menu ${
              selectedNavigation == '#user-profile' ? 'active-nav ' : ''
            }`}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexBasis: '100%',
              fontSize: 'smaller',
            }}
            onClick={() => {
              navigate('#user-profile');
            }}
          >
            <div
              className={`icon `}
              style={{ width: '28px', height: '28px', fontSize: '1rem' }}
            >
              <FaUserCircle />
            </div>
            <span style={{ fontSize: '16px' }}>User Profile</span>
          </div>
        </div>
        <div>
          <div>
            Store
            <hr />
          </div>
        </div>

        <div className="submenu">
          <div
            className={`menu ${
              selectedNavigation == '#store-profile' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Store Information</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-public-profile' ? 'active-nav' : ''
            }`}
            onClick={async () => {
              navigate('#store-public-profile');
            }}
          >
            <div className="icon">
              <FaStore />
            </div>
            <span>Public Profile Settings</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-contact' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-contact');
            }}
          >
            <div className="icon">
              <FaEnvelope />
            </div>
            <span>Contact Details</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-location' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-location');
            }}
          >
            <div className="icon">
              <FaMapMarkerAlt />
            </div>
            <span>Address Details</span>
          </div>

          <div
            className={`menu ${
              selectedNavigation == '#store-legal' ? 'active-nav' : ''
            }`}
            onClick={() => {
              navigate('#store-legal');
            }}
          >
            <div className="icon">
              <FaRegBuilding />
            </div>
            <span>Documents</span>
          </div>
        </div>
      </div>
    );
  }
}
