import './style/confirmation.scss';

export default function ConfirmationBox(props: any) {
  let { setShowConfirmationBox, removeAssignedRoute, setDataForDelete } = props;
  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box">
        <p>Do you want to remove the assigned route?</p>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={() => {
              setDataForDelete('');
              setShowConfirmationBox(false);
            }}
            type="button"
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={async () => {
              await removeAssignedRoute();
              setShowConfirmationBox(false);
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </section>
  );
}
