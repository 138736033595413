import React, { createRef, useEffect, useRef, useState } from 'react';
import './index.scss';
import DropDown from '../Select/DropDown';
import { PhoneNumberList } from '../../interface/axios.types';
import { BsCCircle, BsTelephoneMinus, BsThermometerSun } from 'react-icons/bs';
import { PostRequest } from '../../component/Request';
import InputText from '../InputText/InputText';
import { FaEdit } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import Timer from './Timer';
import TimerComponent from './Timer';
interface Props {
  value?: string;
  handleChange?: (value: string) => void;
}
export default function InputEmailVerify({ value, handleChange }: Props) {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emailOTP, setEmailOTP] = useState<string[]>(new Array(6).fill(''));
  const [activeEmailOTPIndex, setActiveEmailOTPIndex] = useState<number>(0);
  const [startEmailTimer, setStartEmailTimer] = useState(false);
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendEmailTimer, setResendEmailTimer] = useState(30);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [otpId, setOtpId] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [email, setEmail] = useState<string>(value ?? '');
  const numberOfInputs = 6;
  // create a array of refs
  const [inputRefsArray] = useState(() =>
    Array.from({ length: numberOfInputs }, () => createRef<HTMLInputElement>())
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [letters, setLetters] = useState(() =>
    Array.from({ length: numberOfInputs }, () => '')
  );

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newEmailOTP: string[] = [...emailOTP];
    if (event.key !== 'Backspace' && event.key.match('[0-9]')) {
      setCurrentIndex((prevIndex) => {
        const nextIndex =
          prevIndex < numberOfInputs - 1 ? prevIndex + 1 : prevIndex;
        const nextInput = inputRefsArray?.[nextIndex]?.current;
        if (!!nextInput) {
          nextInput.focus();
          nextInput.select();
        }
        return nextIndex;
      });
      newEmailOTP[index] = event.key;
      setActiveEmailOTPIndex(index + 1);
      setEmailOTP(newEmailOTP);
    } else if (event.key == 'Backspace') {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : 0;
        const nextInput = inputRefsArray?.[nextIndex]?.current;
        if (!!nextInput) {
          nextInput.focus();
          nextInput.select();
        }
        return nextIndex;
      });
      newEmailOTP[index] = '';
      setActiveEmailOTPIndex(index - 1);
      setEmailOTP(newEmailOTP);
    }
  };

  useEffect(() => {
    if (value) setEmail((pre) => value);
  }, [value]);

  async function handleVerify() {
    setError('');
    try {
      const response = await PostRequest('otp/send', {
        email,
        profileType: 'Store',
      });

      setOtpId((pre) => response.data.data.otpId);
      setShowModal(true);
    } catch (error: any) {
      if (error.response.data.statusCode === 409) {
        handleChange && handleChange(email);
        setUpdate(false);
      }
    }
  }
  async function handleSubmitOTP() {
    setError('');
    try {
      const response = await PostRequest('otp/verify', {
        email,
        otpId,
        otp: emailOTP.join(''),
        profileType: 'Store',
      });
      handleChange && handleChange(email);
      setShowModal(false);
      setUpdate(false);
      setEmailOTP(['', '', '', '', '', '']);
    } catch (error) {}
  }
  async function handleResendOTP() {
    setError('');
    setEmailOTP(['', '', '', '', '', '']);
    try {
      const response = await PostRequest('otp/send', {
        email,
        profileType: 'Store',
      });

      setOtpId((pre) => response.data.data.otpId);
      setResendEmailTimer(30);
    } catch (error: any) {
      if (error.response.data.statusCode === 409) {
        handleChange && handleChange(email);
        setUpdate(false);
      }
    }
  }
  return (
    <>
      <div style={{ paddingBottom: 20 }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            className="input-number-cnt"
            style={{
              flexBasis: '50%',
              marginRight: '1rem',
            }}
          >
            <label
              className={
                labelOnTop || value ? 'label label-should-move-upward' : 'label'
              }
              htmlFor={'PhoneNumber' + value}
              onFocus={() => setLabelOnTop(true)}
            >
              Email *
            </label>
            <input
              disabled={!!value && !update}
              id={'Email' + value}
              min="0"
              onFocus={() => setLabelOnTop(true)}
              type="email"
              name={'email'}
              defaultValue={value}
              value={email}
              onChange={(event) => setEmail(event?.target.value)}
              // placeholder={'abc@abc.com'}
              className="input-number"
            />
          </div>
          {value && !update ? (
            <button
              type="button"
              onClick={() => setUpdate(true)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="button-primary"
            >
              {'Update  '}
              <span className="ml-[1rem]"></span>
              <FaEdit />
            </button>
          ) : (
            <button
              onClick={handleVerify}
              type="button"
              className={
                false
                  ? 'button-primary-disabled  verify_number_btn shadow-inner'
                  : 'button-primary verify_number_btn  shadow-inner'
              }
            >
              Verify
            </button>
          )}
        </div>
        {error && <p style={{ color: '#e00' }}>{error}</p>}
      </div>
      {showModal && (
        <section className="confirmation-cnt lists-of-stores">
          <div className="confirmation-box">
            <p
              style={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              Verify Your Email{' '}
              <IoCloseSharp
                size={20}
                onClick={() => setShowModal((pre) => !pre)}
                color="#e00"
              />
            </p>

            <div
              // className={
              // value
              //   ? 'basis-[47.5%] w-[100%] flex-wrap sm:self-center otp_section self-start relative sm:justify-center  justify-between flex sm:mt-3 '
              // : 'basis-[47.5%] w-[100%] flex-wrap sm:self-center otp_section self-start relative sm:justify-center justify-between flex sm:mt-3'
              // }
              style={{ marginTop: 30 }}
            >
              <div className="sm:self-center space-x-[0.67rem]">
                <p
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    paddingBottom: 10,
                    color: '#3777c0',
                  }}
                >
                  OTP has been sent to your email
                </p>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  {emailOTP.map((_, index) => {
                    return (
                      <input
                        key={index}
                        value={emailOTP[index]}
                        onChange={(event) => {}}
                        type="text"
                        style={{ width: '40px', height: '40px' }}
                        maxLength={1}
                        onFocus={(event) => {
                          event.target.classList.add('border-solid');
                          event.target.classList.add('border-[1.5px]');
                          event.target.classList.add('border-black');
                        }}
                        onBlur={(event) => {
                          event.target.classList.remove('border-solid');
                          event.target.classList.remove('border-[1.5px]');
                          event.target.classList.remove('border-black');
                        }}
                        // onKeyUp={handleKeyPress}
                        ref={inputRefsArray[index]}
                        onKeyDown={(event) => {
                          handleKeyPress(event, index);
                          // emailOTPHandleOnKeyDown(event, index);
                        }}
                        className="shadow-md h-[27px] sm:h-[39px] sm:w-[39px] xs:w-[30px] xs:h-[30px]  bg-[#F6F6FB] inline-block   w-[27px] rounded-md text-center"
                      />
                    );
                  })}
                </div>

                <p style={{ fontSize: 15, paddingTop: 10, alignSelf: 'right' }}>
                  <TimerComponent delayTime={30} sendOTP={handleResendOTP} />
                </p>
                <button
                  onClick={handleSubmitOTP}
                  type="button"
                  className="button-primary submit_btn shadow-inner"
                  style={{ width: '100%' }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
