import './style/brand.scss';
import TextField from '@mui/material/TextField';
import { GetRequest, PostRequest } from '../../../component/Request';
import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { FiUpload, FiX } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';

export default function Brand({
  setShowBrandForm,
  showBrandForm,
  getRegisteredBrand,
}: any) {
  const [brands, setBrands] = useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [inputValueForBrand, setInputValueForBrand] = useState<any>('');
  const brandRef = useRef(null);
  const [activeBrandId, setActiveBrandId] = useState<any>('');
  const [brandProofImg, setBrandProofImg] = useState('');
  const [errorInUploadingProof, setErrorInUploadingProof] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showNoOptionsFound, setShowNoOptionsFound] = useState(false);
  const [brandNameErr, setBrandNameErr] = useState('');
  // $$$$$$$$$$$$
  const [activeBrand, setActiveBrand] = useState<any>({});
  const brandRefForCloseOnClickingOutside = useRef<any>(null);
  const [isBrandOpen, setIsBrandOpen] = useState(false);
  const [stopInfiniteScrolling, setStopInfiniteScrolling] =
    useState<any>(false);
  const [pageNumberForBrand, setPageNumberForBrand] = useState(1);

  const handleClickToUploadBrandProof = async (event: any) => {
    let img = event.target.files[0];
    const formData = new FormData();
    formData.append('file', img);
    await PostRequest('file?type=seller-brand-proof', formData)
      .then((fileResponse: any) => {
        if (fileResponse.data.statusCode == 201) {
          setBrandProofImg(fileResponse.data.data.url);
          setErrorInUploadingProof('');
        }
      })
      .catch((error) => {
        // if (
        //   error.response.data.message == 'File larger than 1 MB not accepted'
        // ) {
        setErrorInUploadingProof(error.response.data.message);
        // } else {
        //   setErrorInUploadingProof('Something went wrong');
        // }
      });
  };

  const closeTheBrandForm = () => {
    setShowBrandForm(false);
  };

  const createBrand = async () => {
    let payload: any = {};
    if (activeBrand?._id !== '') {
      payload.brandId = activeBrand._id;
    }
    if (brandProofImg) {
      payload.proof = brandProofImg;
    }
    if (activeBrand?._id !== '') {
      await PostRequest('seller/store/brand', payload)
        .then(async (res) => {
          if (res.status === 201) {
            if (showBrandForm) {
              await getRegisteredBrand();
            } else {
              navigate(`/seller/brand/${activeBrand._id}/area`);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is the error after making a brand active');
        });
    }
    if (activeBrand._id === '') {
      setBrandNameErr('Please Search Your Selling Brand And Select From List');
    }
  };

  // $$$$$$$$$$$$$$$$$$

  const handleClickOutsideForBrand = (event: any) => {
    if (
      brandRefForCloseOnClickingOutside.current &&
      !brandRefForCloseOnClickingOutside.current.contains(event.target)
    ) {
      setIsBrandOpen(false);
      setPageNumberForBrand(1);
    }
  };

  const fetchBrand = async () => {
    let url = '';

    if (inputValueForBrand) {
      url = `brand?page=${pageNumberForBrand}&name=${inputValueForBrand}&limit=8`;
    } else {
      url = `brand?page=${pageNumberForBrand}&limit=8`;
    }
    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          let response = await res.data.data.map((brand: any) => {
            return { _id: brand._id, name: brand.name };
          });
          setBrands((prevState: any) => {
            return prevState.concat(response);
          });
          if (res.data.data.length === 0 || res.data.data.length < 8) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length === 5) {
            setStopInfiniteScrolling(false);
            return setIsBrandOpen(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching brand for the first');
      });
  };

  const onIntersectionForBrand = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && !stopInfiniteScrolling && isBrandOpen) {
      setPageNumberForBrand((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (brandRef.current) {
      const observer = new IntersectionObserver(onIntersectionForBrand);
      observer.observe(brandRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [brands.length > 0, stopInfiniteScrolling]);

  useEffect(() => {
    if (isBrandOpen) {
      fetchBrand();
    }
  }, [pageNumberForBrand]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForBrand);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForBrand);
    };
  }, []);

  const handleInputChangeForBrand = async (event: any) => {
    const value = event.target.value;
    setInputValueForBrand(value);
    setActiveBrand({});
    setBrands([]);
    setBrandNameErr('');
    let url = value
      ? `brand?name=${value}&page=${pageNumberForBrand}&limit=8`
      : `brand?page=${pageNumberForBrand}&limit=8`;
    await GetRequest(url)
      .then(async (res) => {
        if (res.data.statusCode == 200) {
          let response = await res.data.data.map((brand: any) => {
            return { _id: brand._id, name: brand.name };
          });
          await setBrands(response);

          if (res.data.data.length === 0) {
            return await setStopInfiniteScrolling(true);
          } else if (res.data.data.length < 8) {
            await setStopInfiniteScrolling(true);
            await setIsBrandOpen(true);
          } else {
            setStopInfiniteScrolling(false);
            return setIsBrandOpen(true);
          }
        }
      })
      .catch((err) => {
        setIsBrandOpen(false);
      });
  };

  const handleActiveBrand = async (brandInfo: any) => {
    setActiveBrand({
      _id: brandInfo._id,
      name: brandInfo.name,
    });
    setInputValueForBrand(brandInfo.name);
    setPageNumberForBrand(1);
    setIsBrandOpen(false);
  };

  // ^^^^^^^^^^^^^^^^^^

  return (
    <section className="parent-cnt">
      <div className="cnt-brand-form">
        <div className="h1-n-cross-cnt">
          <h1>Add Your Selling Brand</h1>

          <Link
            style={{
              flexBasis: '100%',
              color: '#2962ff',
              marginTop: '0.5rem',
              fontSize: '0.9rem',
            }}
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLScr5Va--yNqrH_WaO2tv0As8Ibv9BMjZieVlGx-IXseyW9Mbg/viewform"
          >
            Don't able to find the brand you were looking for? Help us add
          </Link>
          {showBrandForm && (
            <button
              style={{
                position: 'absolute',
                right: '0px',
                top: '0px',
              }}
              onClick={closeTheBrandForm}
              className="cross-icon"
            >
              <RxCross2 />
            </button>
          )}
        </div>

        <div className="autocomplete">
          <input
            type="text"
            className="autocomplete-input"
            placeholder="Search Your Brand"
            value={inputValueForBrand}
            onClick={handleInputChangeForBrand}
            onChange={handleInputChangeForBrand}
            onBlur={() => setShowNoOptionsFound(false)}
          />

          {isBrandOpen && brands.length > 0 ? (
            <ul
              className="autocomplete-list"
              style={{ overflowY: 'scroll' }}
              ref={brandRefForCloseOnClickingOutside}
            >
              {brands.length > 0 &&
                brands.map((brand: any, index: number) => (
                  <li
                    key={index}
                    onClick={() => handleActiveBrand(brand)}
                    className="suggestion-item"
                  >
                    {brand.name}
                  </li>
                ))}

              {brands.length > 0 && (
                <div ref={brandRef} style={{ padding: '10px' }}></div>
              )}
            </ul>
          ) : (
            ''
          )}

          {showNoOptionsFound && (
            <div
              className="no-options-found"
              style={{
                padding: '10px',
              }}
            >
              Brand Not found.
            </div>
          )}
          {brandNameErr && (
            <div className="error" style={{ marginTop: '10px' }}>
              {brandNameErr}
            </div>
          )}
        </div>

        <div className="uploaded-cnt">
          {brandProofImg ? (
            <div className="image-preview">
              <span className="heading-proof">Proof of your above brand -</span>
              {brandProofImg.includes('pdf') ? (
                <iframe id="uploaded-image" src={brandProofImg} />
              ) : (
                <img
                  id="uploaded-image"
                  src={brandProofImg}
                  alt="Uploaded Image"
                />
              )}

              <div
                className="remove-icon"
                color="red"
                onClick={() => {
                  setBrandProofImg('');
                  setErrorInUploadingProof('');
                }}
              >
                <FiX />
              </div>
            </div>
          ) : (
            <div className="upload-container">
              <label htmlFor="file-input">
                <span className="file-icon">
                  <FiUpload className="uploadIcon" />
                </span>
                Upload Brand Proof
              </label>
              <input
                accept=".pdf,.svg,.jpg,.jpeg,.png"
                onChange={handleClickToUploadBrandProof}
                type="file"
                style={{ display: 'none' }}
                id="file-input"
                name="file-input"
              />
              <div
                style={{
                  fontSize: '0.7rem',
                  marginTop: '0.5rem',
                }}
              >
                Supported types: pdf, svg, jpeg, and png
              </div>
            </div>
          )}
        </div>

        {errorInUploadingProof && (
          <div className="error">{errorInUploadingProof}</div>
        )}

        <div className="text-center mt-[20px]">
          <button className="create-brand-btn" onClick={createBrand}>
            Submit
          </button>
        </div>
      </div>
    </section>
  );
}
