import { FaRoute } from 'react-icons/fa';
import './style/editSchedule.scss';
import { RxCross1 } from 'react-icons/rx';
import { BsChevronDown } from 'react-icons/bs';
import { useEffect, useRef, useState } from 'react';
import { GetRequest, PatchRequest } from '../../../component/Request';

const EditScheduleForm = (props: any) => {
  let {
    activeSchedule,
    setAllSchedule,
    editScheduleInfo,
    setEditScheduleInfo,
    allSchedule,
    index,
    setHasMoreScheduleListOrNot,
    getRouteDetail,
  } = props;
  const selectBoxRef = useRef<any>(null);
  const [showOption, setShowOption] = useState<any>(false);
  const [option, setOption] = useState<any>([]);
  function convertToCustomDateAndTimeFormat(dateAndTime: any) {
    const dateObject = new Date(dateAndTime);

    const year = dateObject.getUTCFullYear();
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getUTCDate().toString().padStart(2, '0');

    const hours = dateObject.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = dateObject
      .getUTCMilliseconds()
      .toString()
      .padStart(3, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;

    return formattedDateTime;
  }

  useEffect(() => {
    if (activeSchedule.status === 'Scheduled') {
      setOption([{ value: 'Preparing', label: 'Preparing' }]);
    } else if (activeSchedule.status === 'Preparing') {
      setOption([{ value: 'Shipped', label: 'Shipped' }]);
    } else {
      setOption([{ value: 'Delivered', label: 'Delivered' }]);
    }
  }, []);

  function convertToDatetimeLocalFormat(inputFormat: any) {
    try {
      const inputDate = new Date(inputFormat);

      if (isNaN(inputDate.getTime())) {
        throw new Error('Invalid input format');
      }

      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const day = inputDate.getDate().toString().padStart(2, '0');
      const hours = inputDate.getHours().toString().padStart(2, '0');
      const minutes = inputDate.getMinutes().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } catch (error) {
      return 'Invalid input format';
    }
  }

  const handleClickOutside = (event: any) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value, name } = event.target;
    let newEditScheduleInfo: any = { ...editScheduleInfo };
    let newEditScheduleInfoError = { ...editScheduleInfo.error };

    switch (name) {
      case 'startDateAndTime':
        const selectedDate = convertToCustomDateAndTimeFormat(value);
        const currentDate = new Date();
        newEditScheduleInfo.createRouteSchedule.startDateAndTime = '';
        if (
          selectedDate >=
          convertToCustomDateAndTimeFormat(currentDate.toISOString())
        ) {
          newEditScheduleInfo.startDateAndTime = selectedDate;
          newEditScheduleInfoError.startDateAndTime = '';
        } else {
          newEditScheduleInfo.startDateAndTime = '';
          newEditScheduleInfoError.startDateAndTime =
            'Please select a start date and time equal to or greater than the current date and time.';
        }

        break;
      case 'endDateAndTime':
        const selectedEndDate = convertToCustomDateAndTimeFormat(value);
        const selectedStartDate = convertToCustomDateAndTimeFormat(
          editScheduleInfo.startDateAndTime
        );

        if (selectedEndDate > selectedStartDate) {
          // End date and time is greater than start date and time
          newEditScheduleInfo.endDateAndTime = selectedEndDate;
          newEditScheduleInfoError.endDateAndTime = '';
        } else if (selectedEndDate === selectedStartDate) {
          // End date and time is equal to start date and time, check the time
          if (value > editScheduleInfo.startDateAndTime) {
            newEditScheduleInfo.endDateAndTime = selectedEndDate;
            newEditScheduleInfoError.endDateAndTime = '';
          } else {
            newEditScheduleInfo.endDateAndTime = ''; // or keep the previous value
            newEditScheduleInfoError.endDateAndTime =
              'End time should be greater than start time when the dates are the same.';
          }
        } else {
          newEditScheduleInfo.endDateAndTime = ''; // or keep the previous value
          newEditScheduleInfoError.endDateAndTime =
            'End date and time must be greater than the start date and time.';
        }
        break;
      case 'nextRouteSchedule':
        const selectedNextRouteStartDate =
          convertToCustomDateAndTimeFormat(value);
        const endDateTime = editScheduleInfo.endDateAndTime;
        const startDateTime = editScheduleInfo.startDateAndTime;

        if (endDateTime) {
          // Check if endDateTime is present
          if (selectedNextRouteStartDate > endDateTime) {
            // Next route start date and time is greater than the end date and time
            newEditScheduleInfo.createRouteSchedule.startDateAndTime =
              selectedNextRouteStartDate;
            newEditScheduleInfoError.createRouteSchedule.startDateAndTime = '';
          } else if (selectedNextRouteStartDate === endDateTime) {
            // ... (handle case where next route starts exactly at the end date)
          } else {
            newEditScheduleInfo.createRouteSchedule.startDateAndTime = ''; // or keep the previous value
            newEditScheduleInfoError.createRouteSchedule.startDateAndTime =
              'Next route start date and time must be greater than the end date and time.';
          }
        } else if (
          startDateTime &&
          selectedNextRouteStartDate >= startDateTime
        ) {
          // Check against startDateAndTime if endDateAndTime is not present
          newEditScheduleInfo.createRouteSchedule.startDateAndTime =
            selectedNextRouteStartDate;
          newEditScheduleInfoError.createRouteSchedule.startDateAndTime = '';
        } else {
          newEditScheduleInfo.createRouteSchedule.startDateAndTime = ''; // or keep the previous value
          newEditScheduleInfoError.createRouteSchedule.startDateAndTime =
            'Next route start date and time must be greater than the start date and time.';
        }
        break;
    }

    setEditScheduleInfo({
      ...newEditScheduleInfo,
      error: newEditScheduleInfoError,
    });
  };

  const handleClickOnStatus = (type: any) => {
    let newEditScheduleInfo: any = { ...editScheduleInfo };
    let newEditScheduleInfoError = { ...editScheduleInfo.error };

    newEditScheduleInfo.status = type;
    newEditScheduleInfoError.status = '';
    setShowOption(false);

    if (type === 'scheduled') {
      newEditScheduleInfo.createRouteSchedule.startDateAndTime = '';
      newEditScheduleInfoError.createRouteSchedule.startDateAndTime = '';
    }

    setEditScheduleInfo({
      ...newEditScheduleInfo,
      error: newEditScheduleInfoError,
    });
  };

  const updateScheduleInfo = async (event: any) => {
    event.preventDefault();
    let {
      endDateAndTime,
      startDateAndTime,
      createRouteSchedule,
      status,
      error,
    } = editScheduleInfo;
    let payload: any = {};

    let newEditScheduleInfo = { ...editScheduleInfo };

    if (status !== activeSchedule.status) {
      payload.status = status;
    }

    if (
      startDateAndTime &&
      activeSchedule.startDateAndTime !== startDateAndTime
    ) {
      payload.startDateAndTime = startDateAndTime;
    }

    if (!startDateAndTime) {
      newEditScheduleInfo.error.startDateAndTime =
        'Please Provide Start Date And Time';
    }

    if (status === 'Preparing')
      if (!createRouteSchedule.startDateAndTime) {
        newEditScheduleInfo.error.createRouteSchedule.startDateAndTime =
          'Please Provide Upcoming Schedule Start Date And Time';
      }

    if (createRouteSchedule.startDateAndTime) {
      payload = {
        ...payload,
        createRouteSchedule: {
          startDateAndTime: createRouteSchedule.startDateAndTime,
        },
      };
    }

    if (endDateAndTime && activeSchedule.endDateAndTime !== endDateAndTime) {
      payload.endDateAndTime = endDateAndTime;
    }

    if (status === 'Delivered') {
      if (!endDateAndTime) {
        newEditScheduleInfo.error.endDateAndTime =
          'Please Provide End Date And Time';
      }

      if (endDateAndTime && activeSchedule.endDateAndTime !== endDateAndTime) {
        payload.endDateAndTime = endDateAndTime;
      }
    }

    setEditScheduleInfo(newEditScheduleInfo);

    if (
      !error.startDateAndTime &&
      !error.endDateAndTime &&
      !error.createRouteSchedule.startDateAndTime &&
      Object.values(payload).length > 0
    )
      await PatchRequest(
        `route/${activeSchedule.routeId}/schedule/${activeSchedule._id}`,
        payload
      )
        .then(async (res: any) => {
          if (res.data.statusCode == 200) {
            await GetRequest(
              `route/${activeSchedule.routeId}/schedule?limit=8&page=1`
            )
              .then(async (res) => {
                if (res.data.statusCode === 200) {
                  getRouteDetail();
                  let allNewSchedule = res.data.data.map((data: any) => {
                    return {
                      ...data,
                      showDropDown: false,
                      showEditStatusForm: false,
                    };
                  });

                  setAllSchedule(allNewSchedule);
                  if (res.data.data.length < 8) {
                    await setHasMoreScheduleListOrNot(false);
                  } else {
                    await setHasMoreScheduleListOrNot(true);
                  }
                }
              })
              .catch((err) => {
                console.log(err, 'err in getting schedule');
              });
          }
        })
        .catch((err) => {
          console.log(err, 'Error in updating the schedule');
        });
  };

  const getCurrentDatetimeLocalFormat = () => {
    const currentDate = new Date();
    return convertToDatetimeLocalFormat(currentDate.toISOString());
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="cnt-of-heading-n-cross">
          <h2>
            <span className="route-icon">
              <FaRoute />
            </span>
            Route Schedule Management Form
          </h2>
          <p
            className="cross-icon"
            onClick={() => {
              let allScheduleCopy: any = [...allSchedule];
              allScheduleCopy[index].showEditStatusForm = false;
              setAllSchedule(allScheduleCopy);
            }}
          >
            <RxCross1 />
          </p>
        </div>

        <form className="addroute-form">
          <label htmlFor="type">
            Let your buyer know what's the status of his order:
          </label>

          <div className="select-box" ref={selectBoxRef}>
            <div
              className="select-box__current"
              onClick={() => {
                // console.log(showOption, 'this is the showaodsfjdefbi', option);
                setShowOption(!showOption);
              }}
            >
              <div className="select-box__value">
                <input
                  className="select-box__input"
                  type="radio"
                  id="0"
                  value="1"
                  name="Ben"
                  style={{ display: 'none' }}
                  checked
                />
                <p className="select-box__input-text">
                  {editScheduleInfo.status}
                </p>
              </div>
              <BsChevronDown className="select-box__icon" />
            </div>
            {showOption ? (
              <ul className="select-box__list">
                {option.length > 0 &&
                  option.map((option: any) => {
                    return (
                      <li
                        key={option.label}
                        className="select-box__option"
                        onClick={() => handleClickOnStatus(option.value)}
                        value={option.label}
                      >
                        <label
                          htmlFor="0"
                          style={{ margin: '0px' }}
                          aria-hidden
                        >
                          {option.label}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              ''
            )}
          </div>
          <span className="error">{editScheduleInfo.error.status}</span>
          <label htmlFor="startDateAndTime">Start Date And Time:</label>
          <input
            type="datetime-local"
            min={getCurrentDatetimeLocalFormat()}
            className="input-datetime-local"
            value={convertToDatetimeLocalFormat(
              editScheduleInfo.startDateAndTime
            )}
            onChange={handleChange}
            id="startDateAndTime"
            name="startDateAndTime"
          />
          <span className="error">
            {editScheduleInfo.error.startDateAndTime}
          </span>

          <label htmlFor="endDateAndTime">End Date And Time:</label>
          <input
            type="datetime-local"
            className="input-datetime-local"
            value={
              editScheduleInfo?.endDateAndTime &&
              convertToDatetimeLocalFormat(editScheduleInfo.endDateAndTime)
            }
            onChange={handleChange}
            id="endDateAndTime"
            min={getCurrentDatetimeLocalFormat()}
            name="endDateAndTime"
          />
          <span className="error">{editScheduleInfo.error.endDateAndTime}</span>

          {activeSchedule.status === 'Scheduled' ? (
            <>
              <label htmlFor="nextRouteSchedule">
                Next Route Schedule Start Date And Time:
              </label>
              <input
                value={convertToDatetimeLocalFormat(
                  editScheduleInfo.createRouteSchedule.startDateAndTime
                )}
                min={convertToDatetimeLocalFormat(
                  editScheduleInfo.startDateAndTime
                )}
                type="datetime-local"
                className="input-datetime-local"
                onChange={handleChange}
                name="nextRouteSchedule"
                id="nextRouteSchedule"
              />
              <span className="error">
                {editScheduleInfo.error.createRouteSchedule.startDateAndTime}
              </span>
            </>
          ) : (
            ''
          )}

          <div className="button-group">
            <button type="submit" onClick={updateScheduleInfo}>
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditScheduleForm;
