import { AiFillInfoCircle } from 'react-icons/ai';
import '../style/productList.scss';
import InputNumber from '../../../../common/InputNumber/InputNumber';

export default function EditPricePerUnit(props: any) {
  let {
    handleAddMoreRange,
    productStateTobeUpdated,
    handleChange,
    updateProductState,
    createLastQuantityWisePricePerObj,
    activeOption,
  } = props;

  const hasError = (obj: any): boolean => {
    for (const value of Object.values(obj)) {
      if (typeof value === 'object') {
        if (hasError(value)) {
          return true;
        }
      } else {
        if (value === true) {
          return true;
        }
      }
    }

    return false;
  };

  const handleDeleteRange = (index: number) => {
    let newProductState = { ...productStateTobeUpdated };

    // Remove the object at the specified index
    const deletedObject = newProductState.pricePerUnit[
      activeOption
    ].quantityWisePriceList.splice(index, 1)[0];

    // Update the 'lessThanOrEqual' property of the subsequent objects
    newProductState.pricePerUnit[activeOption].quantityWisePriceList.forEach(
      (obj: any) => {
        if (obj.moreThanOrEqual > deletedObject.moreThanOrEqual) {
          obj.moreThanOrEqual = deletedObject.moreThanOrEqual;
          obj.price = deletedObject.price;
        }
      }
    );

    // Remove the corresponding error object (if applicable)
    newProductState.error.pricePerUnit[
      activeOption
    ].quantityWisePriceList.splice(index, 1);

    updateProductState(newProductState);
  };

  const capitalizeFirstLetter = (inputString: string) => {
    if (inputString.length === 0) {
      return inputString;
    } else {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }
  };

  return (
    <div
      className="sell-modal-form"
      style={{
        border: 'none',
        marginTop: '0.8rem',
      }}
    >
      <div className="checkbox-group">
        <label className="form-control-text">
          <input
            type="radio"
            checked={
              productStateTobeUpdated.pricePerUnit[activeOption].unique === true
            }
            name={`${activeOption}IsUnique`}
            value={'true'}
            onChange={handleChange}
          />
          Fixed Price for one piece
        </label>

        {productStateTobeUpdated.pricePerUnit[activeOption].unique === true ? (
          <div className="input-n-error">
            <InputNumber
              htmlFor={`${activeOption}PerUnitPrice`}
              name={`${activeOption}PerUnitPrice`}
              value={productStateTobeUpdated.pricePerUnit[activeOption].price}
              label={`Price for one piece`}
              handleChange={handleChange}
              placeholder={'Enter price'}
            />

            <div className="error">
              {productStateTobeUpdated.error.pricePerUnit[activeOption].price}
            </div>
          </div>
        ) : (
          ''
        )}

        <label className="form-control-text">
          <input
            checked={
              productStateTobeUpdated.pricePerUnit[activeOption].unique ===
              false
            }
            value={'false'}
            type="radio"
            name={`${activeOption}IsUnique`}
            onChange={handleChange}
          />
          Customize prices based on quantity: set one price for 1-6{' '}
          {activeOption} and another for 7-12 ${activeOption}s.
        </label>
        <div className="error">
          {productStateTobeUpdated.error.pricePerUnit[activeOption].unique}
        </div>
      </div>
      {productStateTobeUpdated.pricePerUnit[activeOption].unique === false ? (
        <header style={{ marginTop: '0.5rem', paddingLeft: '1rem' }}>
          <h2 style={{ fontSize: '15px', color: '#14539a' }}>
            Add Range Of Quantity
          </h2>
        </header>
      ) : (
        ''
      )}

      {productStateTobeUpdated.pricePerUnit[activeOption].unique === false
        ? productStateTobeUpdated.pricePerUnit[
            activeOption
          ].quantityWisePriceList.map((a: any, index: number) => {
            if (index === 0 || a.lessThanOrEqual !== Number.MAX_VALUE) {
              return (
                <div className="input-container range-cnt">
                  {index > 0 ? (
                    <span
                      className="delete"
                      onClick={() => handleDeleteRange(index)}
                    >
                      &times;
                    </span>
                  ) : (
                    ''
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      marginTop: index > 0 ? '0' : '1.5rem',
                    }}
                  >
                    <div className="from">
                      <InputNumber
                        htmlFor={`${activeOption}MoreThanOrEqual` + index}
                        name={`${activeOption}MoreThanOrEqual`}
                        value={
                          productStateTobeUpdated.pricePerUnit[activeOption]
                            .quantityWisePriceList[index].moreThanOrEqual
                        }
                        label={'From'}
                        placeholder={'Min quantity'}
                      />

                      <div className="error">
                        {
                          productStateTobeUpdated.error.pricePerUnit[
                            activeOption
                          ].quantityWisePriceList[index].moreThanOrEqual
                        }
                      </div>
                    </div>
                    <div className="to">
                      <InputNumber
                        onBlur={() =>
                          createLastQuantityWisePricePerObj(
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].moreThanOrEqual,
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].lessThanOrEqual,
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].price,
                            index,
                            activeOption
                          )
                        }
                        htmlFor={`${activeOption}LessThanOrEqual` + index}
                        name={`${activeOption}LessThanOrEqual`}
                        value={
                          productStateTobeUpdated.pricePerUnit[activeOption]
                            .quantityWisePriceList[index].lessThanOrEqual
                        }
                        label={'To'}
                        handleChange={(event: any) =>
                          handleChange(event, index)
                        }
                        placeholder={'Max quantity'}
                      />

                      <div className="error">
                        {
                          productStateTobeUpdated.error.pricePerUnit[
                            activeOption
                          ].quantityWisePriceList[index].lessThanOrEqual
                        }
                      </div>
                    </div>

                    <div className="price">
                      <InputNumber
                        htmlFor={
                          'quantityWisePriceListPer' +
                          capitalizeFirstLetter(activeOption) +
                          index
                        }
                        onBlur={async () =>
                          await createLastQuantityWisePricePerObj(
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].moreThanOrEqual,
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].lessThanOrEqual,
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList[index].price,
                            index,
                            activeOption
                          )
                        }
                        name={
                          'quantityWisePriceListPer' +
                          capitalizeFirstLetter(activeOption)
                        }
                        value={
                          productStateTobeUpdated.pricePerUnit[activeOption]
                            .quantityWisePriceList[index].price
                        }
                        label={`Price Range`}
                        handleChange={(event: any) =>
                          handleChange(event, index)
                        }
                        placeholder={'Enter Price'}
                      />

                      <div className="error">
                        {
                          productStateTobeUpdated.error.pricePerUnit[
                            activeOption
                          ].quantityWisePriceList[index].price
                        }
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="last-range-cnt">
                  {productStateTobeUpdated.pricePerUnit[activeOption]
                    .quantityWisePriceList[
                    productStateTobeUpdated.pricePerUnit[activeOption]
                      .quantityWisePriceList.length - 1
                  ].lessThanOrEqual === Number.MAX_VALUE ? (
                    <div className="last-range-input">
                      <InputNumber
                        htmlFor={
                          `quantityWisePriceListPer${capitalizeFirstLetter(
                            activeOption
                          )}` + index
                        }
                        name={`quantityWisePriceListPer${capitalizeFirstLetter(
                          activeOption
                        )}`}
                        value={
                          productStateTobeUpdated.pricePerUnit[activeOption]
                            .quantityWisePriceList.length == 1
                            ? ''
                            : productStateTobeUpdated.pricePerUnit[activeOption]
                                .quantityWisePriceList[
                                productStateTobeUpdated.pricePerUnit[
                                  activeOption
                                ].quantityWisePriceList.length - 1
                              ].price
                        }
                        label={
                          'Price after ' +
                          productStateTobeUpdated.pricePerUnit[activeOption]
                            .quantityWisePriceList[
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList.length - 1
                          ].moreThanOrEqual +
                          activeOption
                        }
                        handleChange={(event: any) =>
                          handleChange(
                            event,
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList.length - 1
                          )
                        }
                        placeholder={'Enter Price'}
                      />

                      <div className="error">
                        {
                          productStateTobeUpdated.error.pricePerUnit[
                            activeOption
                          ].quantityWisePriceList[
                            productStateTobeUpdated.pricePerUnit[activeOption]
                              .quantityWisePriceList.length - 1
                          ].price
                        }
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  {productStateTobeUpdated.pricePerUnit[activeOption]
                    .quantityWisePriceList[
                    productStateTobeUpdated.pricePerUnit[activeOption]
                      .quantityWisePriceList.length - 1
                  ].lessThanOrEqual === Number.MAX_VALUE ? (
                    <button
                      type="button"
                      className={`btn-primary add-range-btn`}
                      onClick={() => handleAddMoreRange(activeOption)}
                    >
                      Add range
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              );
            }
          })
        : ''}
    </div>
  );
}
