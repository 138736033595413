import { RxCross1 } from 'react-icons/rx';
import './styles/storeOnMap.scss';
import { useEffect, useRef } from 'react';

export default function StoreOnMap({
  latitude,
  longitude,
  setShowMap,
  setBuyerStoreCoordinates,
}: any) {
  const mapContainerRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        mapContainerRef.current &&
        !mapContainerRef.current.contains(event.target)
      ) {
        setBuyerStoreCoordinates({});
        setShowMap(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowMap]);

  return (
    <div className="buyer-store-map-cnt">
      <div className="buyer-store-map" ref={mapContainerRef}>
        <div className="cross-icon-cnt">
          <button className="cross-icon">
            <RxCross1
              onClick={() => {
                setBuyerStoreCoordinates({});
                setShowMap(false);
              }}
            />
          </button>
        </div>
        <iframe
          src={`https://maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`}
          frameBorder="0"
          style={{
            border: 0,
            width: '90%',
            height: 'auto',
            borderRadius: '10px',
          }}
        ></iframe>
      </div>
    </div>
  );
}
