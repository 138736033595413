import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { PostRequest } from '../../component/Request';
import InputText from '../../common/InputText/InputText';
import './register.scss';
import InputDate from '../../common/InputDate/InputDate';
import Select from '../../common/Select/Select';
import InputNumber from '../../common/InputNumber/InputNumber';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { loginAsyncThunk } from '../../features/auth/Slice';
import { Link } from 'react-router-dom';

export default function Registration(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  const [phoneNumberOTP, setPhoneNumberOTP] = useState<string[]>(
    new Array(6).fill('')
  );
  const [otpId, setOtpId] = useState('');
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [errorOfOTP, setErrorOfOTP] = useState('');
  const [sentOrResendOTPMsg, setSentOrResendOTPMsg] = useState(
    'OTP has been sent to your Number'
  );
  const [showOTP, setShowOTP] = useState<Boolean>(false);
  const [userHasAcceptedPolicy, setuserHasAcceptedPolicy] =
    useState<boolean>(false);
  const [shopKeeperInfo, setShopKeeperInfo] = useState<any>({
    name: '',
    phoneNumber: { countryCode: '+91', number: '', isVerified: false },
    dateOfBirth: '',
    gender: '',
    errors: {
      phoneNumber: '',
      name: '',
      dateOfBirth: '',
      gender: '',
      privacyPolicy: '',
    },
  });
  const navigate = useNavigate();

  const phoneNumberOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newPhoneNumberOTP: string[] = [...phoneNumberOTP];

    if (key === 'Backspace') {
      if (newPhoneNumberOTP[index] === '') {
        setActiveOTPIndex(index - 1);
        newPhoneNumberOTP[index - 1] = '';
      } else {
        newPhoneNumberOTP[index] = '';
        setPhoneNumberOTP(newPhoneNumberOTP);
        setActiveOTPIndex(index);
      }
    } else if (key.match('[0-9]')) {
      newPhoneNumberOTP[index] = key;
      setActiveOTPIndex(index + 1);
      setPhoneNumberOTP(newPhoneNumberOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const resendOTP = async (event: any) => {
    event.target.style.display = 'none';
    await PostRequest('otp/send', {
      nationalNumber: shopKeeperInfo.phoneNumber.number,
      countryCode: shopKeeperInfo.phoneNumber.countryCode,
    })
      .then((res) => {
        if (res.data.statusCode == 200) {
          setPhoneNumberOTP(new Array(6).fill(''));
          setResendPhoneOTPTimer(30);
          setShowOTP(true);
          setOtpId(res.data.data.otpId);
        }
      })
      .catch((err) => {
        setShopKeeperInfo({
          ...shopKeeperInfo,
          error: {
            ...shopKeeperInfo.error,
            phoneNumber: err,
          },
        });
      });
  };

  const handleSubmitOTP = async (event: any) => {
    event.preventDefault();
    dispatch(
      loginAsyncThunk({
        countryCode: shopKeeperInfo.phoneNumber.countryCode,
        nationalNumber: shopKeeperInfo.phoneNumber.number,
        otp: phoneNumberOTP.join(''),
        otpId,
      })
    );

    navigate('/store/create');
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }
    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };

  const handleChange = async (event: any) => {
    let { name, value } = event.target;
    let updatedShopKeeperInfo = { ...shopKeeperInfo };
    switch (name) {
      case 'name':
        if (value) {
          setShopKeeperInfo({
            ...shopKeeperInfo,
            [name]: value,
            errors: {
              ...shopKeeperInfo.errors,
              name: '',
            },
          });
        } else {
          setShopKeeperInfo({
            ...shopKeeperInfo,
            [name]: value,
            errors: {
              ...shopKeeperInfo.errors,
              name: 'Required',
            },
          });
        }
        break;

      case 'phoneNumber':
        setShopKeeperInfo({
          ...shopKeeperInfo,
          phoneNumber: {
            ...shopKeeperInfo.phoneNumber,
            number: value,
            isVerified: false,
          },
          errors: {
            ...shopKeeperInfo.errors,
            phoneNumber: '',
          },
        });
        if (showOTP) {
          setPhoneNumberOTP(new Array(6).fill(''));
          setResendPhoneOTPTimer(30);
          setShowOTP(false);
          setErrorOfOTP('');
        }
        break;
      case 'dateOfBirth':
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 12);
        updatedShopKeeperInfo.dateOfBirth = value;
        if (selectedDate > minDate) {
          updatedShopKeeperInfo.errors.dateOfBirth =
            'You must be at least 12 years old.';
        } else {
          updatedShopKeeperInfo.errors.dateOfBirth = '';
        }
        setShopKeeperInfo(updatedShopKeeperInfo);

        break;

      default:
        break;
    }
  };

  const handleSubmitShopKeeperInfo = async (event: any) => {
    event.preventDefault();

    let obj = {};
    let { name, phoneNumber, dateOfBirth, gender } = shopKeeperInfo;
    let error = shopKeeperInfo.errors;
    let updatedShopKeeperInfo = { ...shopKeeperInfo };

    if (!name) {
      updatedShopKeeperInfo.errors.name = 'Required';
    }

    if (!dateOfBirth) {
      updatedShopKeeperInfo.errors.dateOfBirth = 'Required';
    }

    if (!gender) {
      updatedShopKeeperInfo.errors.gender = 'Required';
    }

    if (!phoneNumber.number) {
      updatedShopKeeperInfo.errors.phoneNumber = 'Required';
    }

    if (!userHasAcceptedPolicy) {
      updatedShopKeeperInfo.errors.privacy = 'Please Accept The Privacy Policy';
    }

    setShopKeeperInfo(updatedShopKeeperInfo);

    if (phoneNumber.number) {
      if (shopKeeperInfo.phoneNumber.number.length !== 10) {
        updatedShopKeeperInfo.errors.phoneNumber = 'Phone Number must be valid';
      } else {
        updatedShopKeeperInfo.errors.phoneNumber = '';
        obj = {
          ...obj,
          nationalNumber: phoneNumber.number,
          countryCode: phoneNumber.countryCode,
        };
      }
    }

    if (name) {
      obj = { ...obj, name };
    }

    if (dateOfBirth) {
      obj = { ...obj, dateOfBirth };
    }

    if (gender) {
      obj = { ...obj, gender };
    }

    if (
      !error.name &&
      !error.phoneNumber &&
      !error.gender &&
      !error.dateOfBirth &&
      userHasAcceptedPolicy
    ) {
      await PostRequest('auth/register', obj)
        .then(async (res) => {
          if (res.data.statusCode === 201) {
            setShowOTP(true);
            setOtpId(res.data.data.otpId);
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            if (err.response.data.message.includes('already in database')) {
              let updatedShopKeeperInfo = { ...shopKeeperInfo };
              updatedShopKeeperInfo.errors.phoneNumber =
                'Number has already been used';
              setShopKeeperInfo(updatedShopKeeperInfo);
            }
          }
        });
    }
  };

  const handleClickOnOption = async (data: any) => {
    let updatedShopKeeperInfo = { ...shopKeeperInfo };
    updatedShopKeeperInfo.gender = data;
    updatedShopKeeperInfo.errors.gender = '';
    setShopKeeperInfo(updatedShopKeeperInfo);
  };

  if (showOTP) {
    return (
      <section
        className="w-[400px] mx-auto my-0"
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          <h2 className="shopkeeper_details">Register Yourself As A Seller</h2>
        </div>
        <OTP
          setPhoneNumberOTP={setPhoneNumberOTP}
          setStartPhoneOTPTimer={setStartPhoneOTPTimer}
          setErrorOfOTP={setErrorOfOTP}
          setShowOTP={setShowOTP}
          setSentOrResendOTPMsg={setSentOrResendOTPMsg}
          sentOrResendOTPMsg={sentOrResendOTPMsg}
          startPhoneOTPTimer={startPhoneOTPTimer}
          handleStartPhoneOTPTimer={handleStartPhoneOTPTimer}
          setResendPhoneOTPTimer={setResendPhoneOTPTimer}
          resendPhoneOTPTimer={resendPhoneOTPTimer}
          calculateResendPhoneOTPTimer={calculateResendPhoneOTPTimer}
          activeOTPIndex={activeOTPIndex}
          inputRef={inputRef}
          phoneNumberOTP={phoneNumberOTP}
          handleSubmitOTP={handleSubmitOTP}
          errorOfOTP={errorOfOTP}
          phoneNumberOTPHandleOnKeyDown={phoneNumberOTPHandleOnKeyDown}
        />
      </section>
    );
  } else {
    return (
      <div className="registeration-page">
        <section className="registeration-form">
          <div>
            <h2 className="welcome-text">Register Yourself As A Seller</h2>
          </div>
          <form>
            <div>
              <InputText
                name={'name'}
                value={shopKeeperInfo.name}
                label={'Name'}
                handleChange={handleChange}
                placeholder={'Your Name'}
              />
              {shopKeeperInfo.errors.name && (
                <span className="error">{shopKeeperInfo.errors.name}</span>
              )}
            </div>
            <div
              style={{
                margin: '1.2rem 0',
              }}
            >
              <InputDate
                name={'dateOfBirth'}
                value={shopKeeperInfo.dateOfBirth}
                label={'Date of birth'}
                handleChange={handleChange}
                placeholder={'Your Date Of Birth'}
              />
              {shopKeeperInfo.errors.dateOfBirth && (
                <span className="error">
                  {shopKeeperInfo.errors.dateOfBirth}
                </span>
              )}
            </div>

            <div
              style={{
                margin: '1.2rem 0',
              }}
            >
              <Select
                label={'Gender'}
                value={shopKeeperInfo.gender}
                handleClickOnOption={handleClickOnOption}
                option={['Male', 'Female', 'Other']}
              />
              {shopKeeperInfo.errors.gender && (
                <span className="error">{shopKeeperInfo.errors.gender}</span>
              )}
            </div>

            <div className="mt-5 mb-5 relative flex justify-between">
              <div
                className="input-text-cnt"
                style={{
                  display: 'inline-block',
                  flexBasis: '18%',
                }}
              >
                <input
                  type="text"
                  value="🇮🇳 +91"
                  className="input-text"
                  readOnly={true}
                  style={{
                    padding: '0px',
                    textAlign: 'center',
                  }}
                />
              </div>

              <div
                style={{
                  flexBasis: '80%',
                }}
              >
                <InputNumber
                  value={shopKeeperInfo.phoneNumber.number}
                  label={'Phone Number'}
                  name={'phoneNumber'}
                  handleChange={handleChange}
                  placeholder={'Enter Your Number'}
                />
              </div>
            </div>
            {shopKeeperInfo.errors.phoneNumber && (
              <span className="error">{shopKeeperInfo.errors.phoneNumber}</span>
            )}
            <div className="flex justify-around mb-4">
              <input
                type="checkbox"
                onChange={(event) => {
                  let newShopInfo = { ...shopKeeperInfo };
                  newShopInfo.errors.privacy = '';
                  setShopKeeperInfo(newShopInfo);
                  setuserHasAcceptedPolicy(event?.target.checked);
                }}
                checked={userHasAcceptedPolicy}
                id="privacy"
                style={{ display: 'inline' }}
              />
              <label htmlFor="privacy" className="privacy-text">
                I have read privacy policy and accept all terms and condition
              </label>
            </div>

            {shopKeeperInfo.errors.privacy && (
              <span className="error">{shopKeeperInfo.errors.privacy}</span>
            )}

            <p
              className="greeting"
              style={{
                marginBottom: '1rem',
                color: '#14539a',
                textAlign: 'center',
              }}
            >
              <Link to="/login"> Already registered user? log in</Link>
            </p>

            <div className="text-center">
              <input
                type="submit"
                onClick={handleSubmitShopKeeperInfo}
                className="send_otp_btn"
                value="Register"
              />
            </div>
          </form>
        </section>
      </div>
    );
  }
}

function OTP(props: any) {
  let {
    handleStartPhoneOTPTimer,
    setResendPhoneOTPTimer,
    resendPhoneOTPTimer,
    calculateResendPhoneOTPTimer,
    currentStep,
    inputRef,
    phoneNumberOTP,
    activeOTPIndex,
    phoneNumberOTPHandleOnKeyDown,
    handleSubmitOTP,
    errorOfOTP,
    startPhoneOTPTimer,
    sentOrResendOTPMsg,
    setSentOrResendOTPMsg,
    setShowOTP,
    setPhoneNumberOTP,
    setStartPhoneOTPTimer,
    setErrorOfOTP,
  } = props;

  useEffect(() => {
    handleStartPhoneOTPTimer();
  }, []);

  const handleClickOnGoback = () => {
    setPhoneNumberOTP(new Array(6).fill(''));
    setStartPhoneOTPTimer(false);
    setResendPhoneOTPTimer(30);
    setErrorOfOTP('');
    setShowOTP(false);
  };

  return (
    <div
      className="cnt"
      style={{
        padding: '0',
      }}
    >
      <p
        className="greeting"
        style={{
          color: 'green',
          marginTop: '0.75rem',
        }}
      >
        {sentOrResendOTPMsg}
      </p>
      <div className="otp">
        <div className="cnt_otp">
          {phoneNumberOTP.map((a: any, index: number) => {
            return (
              <input
                key={index}
                value={phoneNumberOTP[index]}
                onChange={(event) => {
                  return;
                }}
                style={{
                  width: '40px',
                  height: '40px',
                }}
                type="number"
                maxLength={1}
                ref={index === activeOTPIndex ? inputRef : null}
                onKeyDown={(event) =>
                  phoneNumberOTPHandleOnKeyDown(event, index)
                }
                className="otp_field"
              />
            );
          })}
        </div>
        <div
          className="resend_otp"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'red' }}>{errorOfOTP}</span>
          <p
            onClick={() => {
              if (startPhoneOTPTimer != true) {
                handleStartPhoneOTPTimer();
                setResendPhoneOTPTimer(30);
                setSentOrResendOTPMsg('OTP has been resend to your Email');
              }
            }}
            style={{
              color: resendPhoneOTPTimer == 0 ? 'blue' : 'gray',
            }}
          >
            Resend OTP
            {resendPhoneOTPTimer == 0
              ? ''
              : ' in ' + calculateResendPhoneOTPTimer}
          </p>
        </div>

        <button
          type="submit"
          style={{
            marginBottom: '0.7rem',
          }}
          onClick={handleSubmitOTP}
          className="send_otp_btn"
        >
          Submit OTP
        </button>
      </div>
      <p
        style={{
          color: '#14539a',
          cursor: 'pointer',
        }}
        onClick={handleClickOnGoback}
      >
        Go back
      </p>
    </div>
  );
}
