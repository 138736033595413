import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  Autocomplete as GoogleMapAutoComplete,
} from '@react-google-maps/api';
import { useEffect } from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import InputText from '../../../../common/InputText/InputText';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store';
import {
  UpdateSellerStoreAsyncThunk,
  storeProfileAddressSelector,
  storeProfileLocationSelector,
} from '../../../../features/auth/Slice';

export default function StoreLocation(props: any) {
  let {
    setShopLocation,
    shopLocation,
    shopName,
    // coordinate,
    // setCoordinate,
    setSelectedNavigation,
    setShowIndicator,
    setType,
    setMessage,
  } = props;

  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState<any>(null);

  const containerStyle = {
    height: '75vh',
    marginBottom: '1.3rem',
    marginTop: '1.8rem',
    borderRadius: '0.4rem',
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API as string,
    libraries: ["places"],
  });

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      let locationObj = searchResult.getPlace();
      setCoordinate({
        latitude: locationObj.geometry.location.lat(),
        longitude: locationObj.geometry.location.lng(),
      });
    }
  }

  //! new code for updated one

  const dispatch = useDispatch<AppDispatch>();
  const [addressData, setAddressData] = useState<any>({});
  const address = useSelector(storeProfileAddressSelector);
  const location = useSelector(storeProfileLocationSelector);
  const center = {
    latitude: location?.coordinates[1],
    longitude: location?.coordinates[0],
  };
  const [coordinate,setCoordinate]= useState<any>(center)
  useEffect(() => {
    setAddressData((pre: any) => address);
  }, [address]);

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    let { value, name } = event.target;
    setAddressData((pre: any) => ({
      ...pre,
      [event.target.name]: event.target.value,
    }));

    if (name !== 'landmark') {
      setShopLocation((pre: any) => ({
        ...pre,
        errors: {
          ...pre.errors,
          [name]: value.length === 0 && `${name} is required`,
        },
      }));
    }
  }

  async function handleUpdateAddress() {
    let { country, district, line1, postalCode, state, tehsil } =
      shopLocation.errors;
    let newErrors = { ...shopLocation.errors };
    if (!addressData.country) {
      newErrors.country = 'Country is required';
    }

    if (!addressData.district) {
      newErrors.district = 'District is required';
    }

    if (!addressData.line1) {
      newErrors.line1 = 'Line1 is required';
    }

    if (!addressData.tehsil) {
      newErrors.tehsil = 'Tehsil is required';
    }

    if (!addressData.postalCode) {
      newErrors.postalCode = 'Postal Code is required';
    }

    setShopLocation((prevState: any) => {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          ...newErrors,
        },
      };
    });

    if (!country && !district && !line1 && !postalCode && !state && !tehsil) {
      let { country, district, landmark, line1, postalCode, state, tehsil } =
        addressData;
      let response = await dispatch(
        UpdateSellerStoreAsyncThunk({ address: { ...addressData },...((coordinate.lat!==location?.coordinates[1]||
          coordinate.lng!==location?.coordinates[0])&&{
            coordinates:coordinate
          }) })
      );
      if (response.meta.requestStatus === "fulfilled") {
        setShowIndicator(true);
        setMessage('Successfully Updated');
        setType('success');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    }
  }

  return (
    <div className="store-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: "inline" }} />
        </button>
      </div>
      <div id="stepTwo" className="w-full">
        <form
          action="#"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="space-y-7 mb-7"
        >
          <div className="mx-9 md:mx-2 relative">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{lat:coordinate.latitude,lng:coordinate.longitude}}
                zoom={18}
                onClick={(event: google.maps.MapMouseEvent) => {
                  setCoordinate(() => ({
                    latitude: event?.latLng?.lat(),
                    longitude: event?.latLng?.lng(),
                  }));
                }}
                options={{
                  fullscreenControl: false,
                }}
              >
                <MarkerF
                  onDragEnd={(event: google.maps.MapMouseEvent) => {
                    setCoordinate(() => ({
                      latitude: event?.latLng?.lat(),
                      longitude: event?.latLng?.lng(),
                    }));
                  }}
                  draggable={true}
                  position={{lat:coordinate.latitude,lng:coordinate.longitude}}
                />

                <GoogleMapAutoComplete
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                >
                  <div className="flex justify-center">
                    <input
                      type="text"
                      placeholder="search your shop"
                      className="md:w-[90%] w-[50%]"
                      style={{
                        border: "1px solid #E0E0E0",
                        marginTop: "4rem",
                        boxSizing: `border-box`,
                        height: `42px`,
                        padding: `0 12px`,
                        borderRadius: `5px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                      }}
                      name="searchText"
                    />
                  </div>
                </GoogleMapAutoComplete>
                <button className="w-[22px] -ml-7 inline">
                  <svg
                    className="z-50"
                    viewBox="0 0 24 24"
                    preserveAspectRatio="xMidYMid meet"
                    focusable="false"
                  >
                    <g>
                      <path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path>
                    </g>
                  </svg>
                </button>
              </GoogleMap>
            ) : (
              <h1>{isLoaded}</h1>
            )}
          </div>

          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <InputText
                name="line1"
                value={addressData?.line1}
                handleChange={handleInputChange}
                label="Line 1"
                placeholder="Enter your shop address"
              />
              {shopLocation.errors.line1 && (
                <span className="error">{shopLocation.errors.line1}</span>
              )}
            </div>
            <div className="basis-[45%] md:w-full">
              <InputText
                name="landmark"
                value={addressData?.landmark}
                handleChange={handleInputChange}
                label="Landmark"
                placeholder="near shiv mandir"
              />
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <InputText
                name="tehsil"
                value={addressData?.tehsil}
                handleChange={handleInputChange}
                label="Tehsil"
                placeholder="enter tehsil like rohini"
              />
              {shopLocation.errors.tehsil && (
                <span className="error">{shopLocation.errors.tehsil}</span>
              )}
            </div>
            <div className="basis-[45%] md:w-full">
              <InputText
                name="district"
                value={addressData?.district}
                handleChange={handleInputChange}
                label="District"
                placeholder="eg: north west Delhi"
              />
              {shopLocation.errors.district && (
                <span className="error">{shopLocation.errors.district}</span>
              )}
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <InputText
                name="state"
                label="State"
                value={addressData?.state}
                handleChange={handleInputChange}
                placeholder="eg: Delhi"
              />
              {shopLocation.errors.state && (
                <span className="error">{shopLocation.errors.state}</span>
              )}
            </div>
            <div className="basis-[45%] md:w-full">
              <InputText
                value={addressData?.country}
                handleChange={handleInputChange}
                name="country"
                label="Country"
                placeholder="eg: India"
              />
              {shopLocation.errors.country && (
                <span className="error">{shopLocation.errors.country}</span>
              )}
            </div>
          </div>
          <div className="flex my-9 justify-between  md:flex-col md:space-y-7 mx-9 md:mx-2">
            <div className="basis-[45%] md:w-full">
              <InputText
                name="zipCode"
                label="Zip Code"
                value={addressData?.postalCode}
                handleChange={handleInputChange}
                placeholder="eg: 110088"
              />
              {shopLocation.errors.postalCode && (
                <span className="error">{shopLocation.errors.postalCode}</span>
              )}
            </div>
          </div>
        </form>
        <hr />
        <div className="flex mt-7 justify-end mx-2">
          <button
            onClick={handleUpdateAddress}
            type="button"
            // disabled={
            //   !shopLocation.shopAddress ||
            //   !shopLocation.landmark ||
            //   !shopLocation.district ||
            //   !shopLocation.state ||
            //   !shopLocation.country ||
            //   !shopLocation.zipCode ||
            //   !shopLocation.tehsil
            //     ? true
            //     : false
            // }
            className={
              // !shopLocation.shopAddress ||
              // !shopLocation.landmark ||
              // !shopLocation.district ||
              // !shopLocation.state ||
              // !shopLocation.country ||
              // !shopLocation.zipCode ||
              // !shopLocation.tehsil
              //   ? 'button-primary-disabled  md:w-[5rem] md:text-[12px]'
              // :
              'button-primary text-center mb-10 md:w-[5rem] md:text-[12px]'
            }
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
