import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { IOrderedOrder } from "../../../interface/axios.types";
import InputText from "../../../common/InputText/InputText";
type Props = {
  setOrderIdToConvertInPreparing: Dispatch<SetStateAction<string>>;
  handleUpdateTheOrderToPreparing: () => void;
  order: IOrderedOrder;
  isUrgent: { urgent: boolean; message: string; error: string };
  setIsUrgent: Dispatch<
    SetStateAction<{ urgent: boolean; message: string; error: string }>
  >;
  setOrderIdToConvertInPreparingFullOrderObject:Dispatch<SetStateAction<IOrderedOrder|null>>
};
export default function AssignAndStartPreparingConfiramtionBox({
  setOrderIdToConvertInPreparing,
  handleUpdateTheOrderToPreparing,
  isUrgent,
  setIsUrgent,
  order,
  setOrderIdToConvertInPreparingFullOrderObject,
}: Props) {
  const confirmationBoxRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      confirmationBoxRef.current &&
      !confirmationBoxRef.current.contains(event.target)
    ) {
      removeOrderIdForPreparing();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removeOrderIdForPreparing = () => {
    setOrderIdToConvertInPreparing(() => "");
    setOrderIdToConvertInPreparingFullOrderObject(null)
  };
  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box" ref={confirmationBoxRef}>
        {order.urgentOrder ? (
          <p>Are you sure that you want to accept this urgent order?</p>
        ) : (
          <p>
            Are you sure that you want to accept and directly start preparing
            for this order?
          </p>
        )}
        <div
          style={{
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          {order?.urgentOrder && (
            <div style={{ margin: 20, placeItems: "self-start" }}>
              <InputText
                placeholder={"Enter Estimated delivery time"}
                label={"message"}
                value={isUrgent.message}
                labelOnTop={true}
                handleChange={function (
                  event: ChangeEvent<HTMLInputElement>
                ): void {
                  if (event.target.value.length > 5) {
                    setIsUrgent((pre) => ({ ...pre, error: "" }));
                  }
                  setIsUrgent((pre) => ({
                    ...pre,
                    message: event.target.value,
                  }));
                }}
                name={"message"}
              />
              {!!isUrgent.error && (
                <p
                  style={{
                    fontSize: 12,
                    alignSelf: "self-start",
                    fontWeight: "100",
                    color: "#e00",
                    textAlign: "start",
                    paddingRight: 10,
                  }}
                >
                  {isUrgent.error}
                </p>
              )}
            </div>
          )}
          <button
            onClick={removeOrderIdForPreparing}
            type="button"
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: "4px",
              width: "6rem",
              height: "auto",
              marginLeft: "2rem",
            }}
            className="button-primary"
            onClick={() => {
              if (!isUrgent.message.length) {
                setIsUrgent((pre) => ({
                  ...pre,
                  error: "message is required",
                }));
                return;
              }
              if (isUrgent.message.length < 3) {
                setIsUrgent((pre) => ({
                  ...pre,
                  error: "message length  should be more than 3 characters.",
                }));
                return;
              }
              return handleUpdateTheOrderToPreparing();
            }}
          >
            Proceed
          </button>
        </div>
      </div>
    </section>
  );
}
