import React, { useEffect, useRef, useState } from 'react';
import { FaRoute } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';
import './styles/route.scss';

import { RiArrowDropDownLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import {
  GetRequest,
  PatchRequest,
  PostRequest,
} from '../../../component/Request';
import Loader from '../../../layout/Loader/Loader';

const options = [
  { value: 'Daily', label: 'Daily' },
  { value: 'WeeklySixDay', label: 'Weekly Six Day' },
  {
    value: 'WeeklyFiveDay',
    label: 'Weekly Five Day',
  },
  { value: 'WeeklyFourDay', label: 'Weekly Four Day' },
  { value: 'WeeklyThreeDay', label: 'Weekly Three Day' },
  { value: 'WeeklyTwoDay', label: 'Weekly Two Day' },
  { value: 'WeeklyOneDay', label: 'Weekly One Day' },
];

const RouteForm = ({
  headingOfForm,
  setShowAddForm,
  setRoute,
  weekDay,
  setWeekDay,
  isRouteFinishingOnSameDay,
  setIsRouteFinishingOnSameDay,
  showType,
  setShowType,
  routeInfo,
  setRouteInfo,
}: any) => {
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const selectBoxRef = useRef<any>(null);
  const routeFormRef = useRef<any>(null);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { id, value, name } = event.target;
    let newRouteInfo: any = { ...routeInfo };
    let newRouteInfoError = { ...routeInfo.error };

    switch (name) {
      case 'name':
        newRouteInfo.name = value;
        newRouteInfoError.name = !value ? 'Please provide route name' : '';
        break;
      case 'description':
        newRouteInfo.description = value;
        newRouteInfoError.description = value ? '' : 'Description is required';
        break;
      case 'scheduleCommonDayList':
        let { type } = routeInfo;

        if (type == 'WeeklyOneDay') {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.includes(value)
              ? newRouteInfo.scheduleCommonDayList.filter(
                  (v: any) => v != value
                )
              : newRouteInfo.scheduleCommonDayList.concat(value);
          if (newRouteInfo.scheduleCommonDayList.length != 1) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 1 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else if (type == 'WeeklyTwoDay') {
          if (newRouteInfo.scheduleCommonDayList.length != 2) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 2 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else if (type == 'WeeklyThreeDay') {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.includes(value)
              ? newRouteInfo.scheduleCommonDayList.filter(
                  (v: any) => v != value
                )
              : newRouteInfo.scheduleCommonDayList.concat(value);
          if (newRouteInfo.scheduleCommonDayList.length != 3) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 3 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else if (type == 'WeeklyFourDay') {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.includes(value)
              ? newRouteInfo.scheduleCommonDayList.filter(
                  (v: any) => v != value
                )
              : newRouteInfo.scheduleCommonDayList.concat(value);
          if (newRouteInfo.scheduleCommonDayList.length != 4) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 4 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else if (type == 'WeeklyFiveDay') {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.includes(value)
              ? newRouteInfo.scheduleCommonDayList.filter(
                  (v: any) => v != value
                )
              : newRouteInfo.scheduleCommonDayList.concat(value);

          if (newRouteInfo.scheduleCommonDayList.length != 5) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 5 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else if (type == 'WeeklySixDay') {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.includes(value)
              ? newRouteInfo.scheduleCommonDayList.filter(
                  (v: any) => v != value
                )
              : newRouteInfo.scheduleCommonDayList.concat(value);

          if (newRouteInfo.scheduleCommonDayList.length != 6) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select only 6 day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        } else {
          newRouteInfo.scheduleCommonDayList =
            newRouteInfo.scheduleCommonDayList.concat(value);

          if (newRouteInfo.scheduleCommonDayList.length < 7) {
            newRouteInfoError.scheduleCommonDayList =
              'Please select all the day';
          } else {
            newRouteInfoError.scheduleCommonDayList = '';
          }
        }
        break;
      case 'commonStartTime':
        newRouteInfo.commonStartTime = formattedTime(value);
        newRouteInfoError.commonStartTime = value
          ? ''
          : 'start time is required';
        break;
      case 'commonEndTime':
        newRouteInfo.commonEndTime.time = formattedTime(value);
        newRouteInfoError.commonEndTime.time = value
          ? ''
          : 'common end time is required';
        break;
      case 'startDateAndTime':
        newRouteInfo.createRouteSchedule.startDateAndTime =
          convertToCustomDateAndTimeFormat(value);
        newRouteInfoError.createRouteSchedule.startDateAndTime = value
          ? ''
          : 'start date and time is required';
        break;
      case 'dayInBetweenStartAndEndTime':
        newRouteInfo.commonEndTime.dayInBetweenStartAndEndTime = value;
        newRouteInfoError.commonEndTime.dayInBetweenStartAndEndTime = value
          ? handleErrorForDayInBetweenStartAndEndTime()
          : 'days are required';

        break;
      case 'isRouteFinishingOnSameDay':
        if (value === 'true') {
          setIsRouteFinishingOnSameDay(true);
          newRouteInfo.commonEndTime.dayInBetweenStartAndEndTime = 0;
        } else {
          setIsRouteFinishingOnSameDay(false);
          newRouteInfo.commonEndTime.dayInBetweenStartAndEndTime = 1;
        }
    }

    setRouteInfo({ ...newRouteInfo, error: newRouteInfoError });
  };

  const updateErrorForscheduleCommonDayList = () => {
    let newRouteInfo: any = { ...routeInfo };

    let { type } = routeInfo;
    if (type == 'WeeklyOneDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 1) {
        return 'Please select only 1 day';
      } else {
        return '';
      }
    } else if (type == 'WeeklyTwoDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 2) {
        return 'Please select only 2 day';
      } else {
        return '';
      }
    } else if (type == 'WeeklyThreeDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 3) {
        return 'Please select only 3 day';
      } else {
        return '';
      }
    } else if (type == 'WeeklyFourDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 4) {
        return 'Please select only 4 day';
      } else {
        return '';
      }
    } else if (type == 'WeeklyFiveDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 5) {
        return 'Please select only 5 day';
      } else {
        return '';
      }
    } else if (type == 'WeeklySixDay') {
      if (newRouteInfo.scheduleCommonDayList.length != 6) {
        return 'Please select only 6 day';
      } else {
        return '';
      }
    } else {
      if (newRouteInfo.scheduleCommonDayList.length < 7) {
        return 'Please select all the day';
      } else {
        return '';
      }
    }
  };

  const handleErrorForDayInBetweenStartAndEndTime = () => {
    if (isRouteFinishingOnSameDay) {
      return '';
    } else {
      if (routeInfo.commonEndTime.dayInBetweenStartAndEndTime === '') {
        return 'days are required';
      } else {
        return '';
      }
    }
  };

  const hasTruthyValues = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === 'object' && !Array.isArray(value)) {
          // If the value is an object, recursively check its properties
          if (hasTruthyValues(value)) {
            return true; // If a truthy value is found, return true
          }
        } else if (value) {
          return true; // If any truthy value is found, return true
        }
      }
    }

    return false; // No truthy values found in the object
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const {
      name,
      coverageArea,
      commonEndTime,
      commonStartTime,
      scheduleCommonDayList,
      createRouteSchedule,
      type,
      description,
    } = routeInfo;

    // Validate the fields
    const newErrorState = {
      ...routeInfo.error,
      name: name ? '' : 'Name is required',
      description: description ? '' : 'Description is required',
      commonStartTime: commonStartTime ? '' : 'Start time is required',
      commonEndTime: {
        time: commonEndTime.time ? '' : 'Common end time is required',
        dayInBetweenStartAndEndTime:
          await handleErrorForDayInBetweenStartAndEndTime(),
      },
      coverageArea: {
        placeNameList:
          coverageArea.placeNameList.length > 0
            ? ''
            : 'At least one place name is required',
      },
      createRouteSchedule: {
        startDateAndTime: createRouteSchedule.startDateAndTime
          ? ''
          : 'start date and time is required',
      },
      scheduleCommonDayList: await updateErrorForscheduleCommonDayList(),
    };

    // Check if any errors are present
    const hasError = hasTruthyValues(newErrorState);

    if (headingOfForm.includes('Update')) {
      if (hasError) {
        await setRouteInfo({ ...routeInfo, error: newErrorState });
      }
      {
        setIsLoading(() => true);
        await updateRoute();
      }
    } else {
      if (hasError) {
        await setRouteInfo({ ...routeInfo, error: newErrorState });
      } else {
        setIsLoading(() => true);
        await PostRequest('route', {
          name,
          coverageArea,
          commonEndTime,
          commonStartTime,
          scheduleCommonDayList,
          createRouteSchedule,
          type,
          description,
        })
          .then(async (res) => {
            if (res.data.statusCode === 201) {
              setRouteInfo({
                name: '',
                description: '',
                type: 'Daily',
                scheduleCommonDayList: [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ],
                coverageArea: {
                  placeNameList: [],
                },
                commonStartTime: '',
                commonEndTime: {
                  time: '',
                  dayInBetweenStartAndEndTime: '',
                },
                createRouteSchedule: {
                  startDateAndTime: '',
                },
                error: {
                  coverageArea: {
                    placeNameList: [],
                  },
                  name: '',
                  description: '',
                  scheduleCommonDayList: '',
                  commonStartTime: '',
                  commonEndTime: {
                    time: '',
                    dayInBetweenStartAndEndTime: '',
                  },
                  createRouteSchedule: {
                    startDateAndTime: '',
                  },
                },
              });
              await GetRequest(`route/my-route`)
                .then((res) => {
                  if (res.data.statusCode == 200) {
                    setRoute(res.data.data);
                  }
                })
                .catch((err) => {
                  console.log(
                    err,
                    'This is the err i am getting in fetching all my routes'
                  );
                });
            }
            setIsLoading(() => false);
            setShowAddForm(false);
          })
          .catch((err) => {
            console.log(err, 'err in getting the ');
          });
      }
    }
  };

  const getCurrentDatetimeLocalFormat = () => {
    const currentDate = new Date();
    return convertToDatetimeLocalFormat(currentDate.toISOString());
  };

  const updateRoute = () => {
    let {
      name,
      coverageArea,
      commonEndTime,
      commonStartTime,
      scheduleCommonDayList,
      type,
      description,
    } = routeInfo;

    PatchRequest(`route/${routeInfo._id}`, {
      name,
      coverageArea,
      commonEndTime,
      commonStartTime,
      scheduleCommonDayList,

      type,
      description,
    })
      .then(async (res: any) => {
        if (res.data.statusCode == 200) {
          await GetRequest(`route/my-route`)
            .then(async (res) => {
              if (res.data.statusCode == 200) {
                setRoute(res.data.data);
                await setRouteInfo({
                  name: '',
                  description: '',
                  type: 'Daily',
                  scheduleCommonDayList: [
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ],
                  coverageArea: {
                    placeNameList: [],
                  },
                  commonStartTime: '',
                  commonEndTime: {
                    time: '',
                    dayInBetweenStartAndEndTime: '',
                  },
                  createRouteSchedule: {
                    startDateAndTime: '',
                  },
                  error: {
                    coverageArea: {
                      placeNameList: [],
                    },
                    name: '',
                    description: '',
                    scheduleCommonDayList: '',
                    commonStartTime: '',
                    commonEndTime: {
                      time: '',
                      dayInBetweenStartAndEndTime: '',
                    },
                    createRouteSchedule: {
                      startDateAndTime: '',
                    },
                  },
                });
              }
            })
            .catch((err) => {
              console.log(
                err,
                'This is the err i am getting in fetching all my routes'
              );
            });
        }

        setShowAddForm(false);
      })
      .catch((err) => {
        console.log(err, '');
      });
    setIsLoading(() => false);
  };

  function convertToCustomDateAndTimeFormat(dateAndTime: any) {
    const dateObject = new Date(dateAndTime);

    const year = dateObject.getUTCFullYear();
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getUTCDate().toString().padStart(2, '0');

    const hours = dateObject.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = dateObject
      .getUTCMilliseconds()
      .toString()
      .padStart(3, '0');

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;

    return formattedDateTime;
  }

  const handlePlaceNameListChange = (e: any) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const inputValue = e.target.value.trim();
      if (inputValue) {
        setRouteInfo((prevState: any) => {
          const updatedPlaceNameList = [
            ...prevState.coverageArea.placeNameList,
            inputValue,
          ];
          return {
            ...prevState,
            coverageArea: {
              ...prevState.coverageArea,
              placeNameList: updatedPlaceNameList,
            },
            error: {
              ...prevState.error,
              coverageArea: {
                placeNameList: '',
              },
            },
          };
        });
        e.target.value = ''; // Clear the input field
      }
    }
  };

  const handleRemovePlaceNameList = (areaToRemove: any) => {
    setRouteInfo((prevState: any) => {
      const updatedPlaceNameList = prevState.coverageArea.placeNameList.filter(
        (place: string) => place !== areaToRemove
      );

      const newRouteInfo = {
        ...prevState,
        coverageArea: {
          ...prevState.coverageArea,
          placeNameList: updatedPlaceNameList,
        },
        error: {
          ...prevState.error,
          coverageArea: {
            placeNameList:
              updatedPlaceNameList.length === 0
                ? 'Please add at least one'
                : '',
          },
        },
      };

      return newRouteInfo;
    });
  };

  const formattedTime = (value: any) => {
    // Assuming 'value' is a time string in HH:mm format
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (!timeRegex.test(value)) {
      console.error('Invalid time format. Expected HH:mm');
      return ''; // or throw an error, depending on your use case
    }

    const [hours, minutes] = value.split(':');
    const isPM = parseInt(hours, 10) >= 12;

    let parsedTime = new Date();
    parsedTime.setUTCHours((parseInt(hours, 10) % 12) + (isPM ? 12 : 0));
    parsedTime.setUTCMinutes(parseInt(minutes, 10));
    parsedTime.setUTCSeconds(0);
    parsedTime.setUTCMilliseconds(0);

    let formattedHours = parsedTime.getUTCHours().toString().padStart(2, '0');
    let formattedMinutes = parsedTime
      .getUTCMinutes()
      .toString()
      .padStart(2, '0');
    let formattedSeconds = parsedTime
      .getUTCSeconds()
      .toString()
      .padStart(2, '0');
    let formattedMilliseconds = parsedTime
      .getUTCMilliseconds()
      .toString()
      .padStart(3, '0');

    let formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}+00:00`;

    return formattedTime;
  };

  function convertToDatetimeLocalFormat(inputFormat: any) {
    try {
      const inputDate = new Date(inputFormat);

      if (isNaN(inputDate.getTime())) {
        throw new Error('Invalid input format');
      }

      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
      const day = inputDate.getDate().toString().padStart(2, '0');
      const hours = inputDate.getHours().toString().padStart(2, '0');
      const minutes = inputDate.getMinutes().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      return formattedDate;
    } catch (error) {
      return 'Invalid input format';
    }
  }

  function convertTimeFormatIntoLocal(inputTime: string): string {
    console.log(inputTime, 'this is input time !!!!!!!');
    try {
      const [timePart, _] = inputTime.split('+');
      const [hours, minutes] = timePart.split(':').map(Number);

      const convertedTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      return convertedTime;
    } catch (error) {
      return 'Invalid input time format';
    }
  }

  const handleClickOnType = (type: any) => {
    let newRouteInfo: any = { ...routeInfo };
    let newRouteInfoError = { ...routeInfo.error };
    newRouteInfo.type = type;
    newRouteInfoError.scheduleCommonDayList = '';
    setShowType(false);
    if (
      newRouteInfo.type == 'WeeklyOneDay' ||
      newRouteInfo.type == 'WeeklyTwoDay' ||
      newRouteInfo.type == 'WeeklyThreeDay' ||
      newRouteInfo.type == 'WeeklyFourDay' ||
      newRouteInfo.type == 'WeeklyFiveDay' ||
      newRouteInfo.type == 'WeeklySixDay'
    ) {
      setWeekDay([
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]);
      newRouteInfo.scheduleCommonDayList = [];
    } else {
      setWeekDay([
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]);
      newRouteInfo.scheduleCommonDayList = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
    }
    setRouteInfo({ ...newRouteInfo, error: newRouteInfoError });
  };

  const handleClickOutside = (event: any) => {
    if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
      setShowType(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function addSpacesToCamelCase(inputString: any) {
    const spacedString = inputString.replace(/([A-Z])/g, ' $1');

    return spacedString.charAt(0).toUpperCase() + spacedString.slice(1).trim();
  }

  return (
    <div className="popup-container">
      <div className="popup-content" ref={routeFormRef}>
        <div className="cnt-of-heading-n-cross">
          <h2>
            <span className="route-icon">
              <FaRoute />
            </span>
            {headingOfForm}
          </h2>
          <p
            className="cross-icon"
            onClick={() => {
              setRouteInfo({
                name: '',
                description: '',
                type: 'Daily',
                scheduleCommonDayList: [
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ],
                coverageArea: {
                  placeNameList: [],
                },
                commonStartTime: '',
                commonEndTime: {
                  time: '',
                  dayInBetweenStartAndEndTime: '',
                },
                createRouteSchedule: {
                  startDateAndTime: '',
                },
                error: {
                  coverageArea: {
                    placeNameList: [],
                  },
                  name: '',
                  description: '',
                  scheduleCommonDayList: '',
                  commonStartTime: '',
                  commonEndTime: {
                    time: '',
                    dayInBetweenStartAndEndTime: '',
                  },
                  createRouteSchedule: {
                    startDateAndTime: '',
                  },
                },
              });
              setShowAddForm(false);
            }}
          >
            <RxCross1 />
          </p>
        </div>

        <form className="addroute-form">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            className="input-text"
            id="name"
            name="name"
            onChange={handleChange}
            value={routeInfo.name}
          />
          <span className="error">{routeInfo.error.name}</span>

          <label htmlFor="description">Description:</label>
          <textarea
            className="textarea"
            id="description"
            onChange={handleChange}
            name="description"
            value={routeInfo.description}
          />
          <span className="error">{routeInfo.error.description}</span>

          <label htmlFor="type">Type:</label>

          <div className="select-box" ref={selectBoxRef}>
            <div
              className="select-box__current"
              onClick={() => {
                setShowType(!showType);
              }}
            >
              <div className="select-box__value">
                <input
                  className="select-box__input"
                  type="radio"
                  id="0"
                  value="1"
                  name="Ben"
                  style={{ display: 'none' }}
                  checked
                />
                <p className="select-box__input-text">
                  {addSpacesToCamelCase(routeInfo.type)}
                </p>
              </div>
              <BsChevronDown className="select-box__icon" />
            </div>
            {showType ? (
              <ul className="select-box__list">
                {options.map((option: any) => {
                  return (
                    <li
                      key={option.label}
                      className="select-box__option"
                      onClick={() => handleClickOnType(option.value)}
                      value={option.value}
                    >
                      <label htmlFor="0" style={{ margin: '0px' }} aria-hidden>
                        {option.label}
                      </label>
                    </li>
                  );
                })}
              </ul>
            ) : (
              ''
            )}
          </div>

          <div style={{ margin: '16px 0' }}>
            <label htmlFor="scheduleDay">
              Select the day on which you would be going to distribute:
            </label>
            <div className="checkbox-list">
              {weekDay.map((day: string) => {
                return (
                  <label key={day}>
                    <input
                      className="input-checkbox"
                      type="checkbox"
                      name="scheduleCommonDayList"
                      onChange={handleChange}
                      checked={
                        routeInfo.scheduleCommonDayList.includes(day)
                          ? true
                          : false
                      }
                      value={day}
                    />
                    <span className="checkbox-custom"></span>
                    <span className="week-day">{day}</span>
                  </label>
                );
              })}
            </div>
            <span className="error">
              {routeInfo.error.scheduleCommonDayList}
            </span>
          </div>

          <label htmlFor="placeNameList">
            {'Place Names:' + ' (use comma , to add one more)'}
          </label>
          <div className="highlighted-text">
            {routeInfo.coverageArea.placeNameList.map((area: string) => {
              return (
                <span className="selected-place">
                  {area}
                  <span
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      marginLeft: '3px',
                      marginTop: '3px',
                    }}
                    onClick={() => handleRemovePlaceNameList(area)}
                  >
                    <RxCross1 />
                  </span>
                </span>
              );
            })}
          </div>
          <input
            type="text"
            id="placeNameList"
            name="placeNameList"
            onKeyDown={handlePlaceNameListChange}
          />
          <span className="error">
            {routeInfo.error.coverageArea.placeNameList}
          </span>

          <label htmlFor="commonStartTime">Common Start Time:</label>

          <input
            type="time"
            id="commonStartTime"
            value={convertTimeFormatIntoLocal(routeInfo.commonStartTime)}
            onChange={handleChange}
            name="commonStartTime"
          />
          <span className="error">{routeInfo.error.commonStartTime}</span>

          <label htmlFor="commonEndTime">Common End Time:</label>
          <div className="checkbox-group">
            <label htmlFor="sameRoute" style={{ fontWeight: '400' }}>
              Does this route end before 24 hrs?
            </label>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '7px 0',
                }}
              >
                <input
                  type="radio"
                  name="isRouteFinishingOnSameDay"
                  id="yes"
                  onChange={handleChange}
                  value={'true'}
                  checked={isRouteFinishingOnSameDay === true}
                />

                <label
                  htmlFor="yes"
                  style={{
                    margin: '0px',
                  }}
                >
                  Yes
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '16px',
                  alignItems: 'center',
                }}
              >
                <input
                  type="radio"
                  value={'false'}
                  id="no"
                  checked={isRouteFinishingOnSameDay === false}
                  onChange={handleChange}
                  name="isRouteFinishingOnSameDay"
                />

                <label
                  htmlFor="no"
                  style={{
                    margin: '0px',
                  }}
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {isRouteFinishingOnSameDay === false && (
            <div className="conditional-input">
              <label htmlFor="distributionDay">
                How many day it will take for you to end distribution?
              </label>
              <input
                type="number"
                className="input-number"
                onChange={handleChange}
                value={routeInfo.commonEndTime.dayInBetweenStartAndEndTime}
                name="dayInBetweenStartAndEndTime"
                id="distributionDay"
              />
              <span className="error">
                {routeInfo.error.commonEndTime.dayInBetweenStartAndEndTime}
              </span>
            </div>
          )}
          <label htmlFor="commonEndTime"> End Time: </label>
          <input
            value={convertTimeFormatIntoLocal(routeInfo.commonEndTime.time)}
            type="time"
            className="input-time"
            id="commonEndTime"
            name="commonEndTime"
            onChange={handleChange}
          />
          <span className="error">{routeInfo.error.commonEndTime.time}</span>

          {!headingOfForm.includes('Update') && (
            <>
              <label htmlFor="firstRouteSchedule">
                First Route Schedule Date And Time:
              </label>
              <input
                type="datetime-local"
                className="input-datetime-local"
                min={getCurrentDatetimeLocalFormat()}
                onChange={handleChange}
                value={convertToDatetimeLocalFormat(
                  routeInfo.createRouteSchedule.startDateAndTime
                )}
                name="startDateAndTime"
                id="firstRouteSchedule"
              />
              <span className="error">
                {routeInfo.error.createRouteSchedule.startDateAndTime}
              </span>
            </>
          )}

          <div className="button-group">
            <button
              type="submit"
              onClick={!isLoading ? handleSubmit : () => {}}
            >
              {isLoading
                ? 'Loading....'
                : headingOfForm.includes('Update')
                ? 'Update'
                : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RouteForm;
