import React, { useState, forwardRef } from 'react';
import './inputText.scss';

interface Props {
  placeholder: string;
  label: string;
  value: string | undefined;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  initialValue?: string;
  inputValidationFC?: (value: string) => string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  InputType?: React.HTMLInputTypeAttribute;
  labelOnTop?:boolean;
}
const InputText = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(props?.labelOnTop??!!props.initialValue);
  const [error, setError] = useState<string | undefined | null>(null);

  return (
    <div className={`input-text-cnt ${error ? ' error ' : ''}`}>
      <label
        className={
          labelOnTop || props?.value
            ? `label label-should-move-upward ${error ? 'error_text' : ''}`
            : `label ${error ? 'error_text' : ''}`
        }
        htmlFor={props.label}
      >
        {props.label}
      </label>
      <input
        ref={ref}
        onFocus={() => setLabelOnTop(true)}
        onBlur={() => {
          if (props.value == '') {
            setLabelOnTop(false);
          } else {
            setLabelOnTop(true);
          }
        }}
        defaultValue={props?.initialValue}
        type={props.InputType ?? 'text'}
        id={props.label}
        name={props.name}
        value={props?.value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (props?.inputValidationFC) {
            setError((pre: any) => {
              let { inputValidationFC }: any = props;

              return inputValidationFC(event.target.value);
            });
          }
          return props.handleChange(event);
        }}
        placeholder={labelOnTop && props.placeholder}
        className="input-text"
        {...props.inputProps}
      />
      {error && (
        <p className="error_text" style={{ paddingLeft: '4%' }}>
          {error}
        </p>
      )}
    </div>
  );
});

export default InputText;
