import { AiFillInfoCircle } from 'react-icons/ai';
import '../style/productList.scss';
import InputNumber from '../../../../common/InputNumber/InputNumber';

export default function PiecePricePerUnit(props: any) {
  let {
    handleAddMoreRange,
    productState,
    handleChange,
    setProductState,
    createLastQuantityWisePricePerObj,
  } = props;

  const hasError = (obj: any): boolean => {
    for (const value of Object.values(obj)) {
      if (typeof value === 'object') {
        if (hasError(value)) {
          return true;
        }
      } else {
        if (value === true) {
          return true;
        }
      }
    }

    return false;
  };

  const handleDeleteRange = (index: number) => {
    let newProductState = { ...productState };

    // Remove the object at the specified index
    const deletedObject =
      newProductState.pricePerUnit.piece.quantityWisePriceList.splice(
        index,
        1
      )[0];

    // Update the 'lessThanOrEqual' property of the subsequent objects
    newProductState.pricePerUnit.piece.quantityWisePriceList.forEach(
      (obj: any) => {
        if (obj.moreThanOrEqual > deletedObject.moreThanOrEqual) {
          obj.moreThanOrEqual = deletedObject.moreThanOrEqual;
          obj.price = deletedObject.price;
        }
      }
    );

    // Remove the corresponding error object (if applicable)
    newProductState.error.pricePerUnit.piece.quantityWisePriceList.splice(
      index,
      1
    );

    setProductState(newProductState);
  };

  return (
    <div
      className="sell-modal-form"
      style={{
        border: 'none',
        marginTop: '0.8rem',
      }}
    >
      <div className="checkbox-group">
        <label className="form-control-text">
          <input
            type="radio"
            checked={productState.pricePerUnit.piece.unique === true}
            name="pieceIsUnique"
            value={'true'}
            onChange={handleChange}
          />
          Fixed Price for one piece
        </label>

        {productState.pricePerUnit.piece.unique === true ? (
          <div className="input-n-error">
            <InputNumber
              htmlFor={'piecePerUnitPrice'}
              name={'piecePerUnitPrice'}
              value={productState.pricePerUnit.piece.price}
              label={'Price for one piece'}
              handleChange={handleChange}
              placeholder={'Enter price'}
            />

            <div className="error">
              {productState.error.pricePerUnit.piece.price}
            </div>
          </div>
        ) : (
          ''
        )}

        <label className="form-control-text">
          <input
            checked={productState.pricePerUnit.piece.unique === false}
            value={'false'}
            type="radio"
            name="pieceIsUnique"
            onChange={handleChange}
          />
          Customize prices based on quantity: set one price for 1-6 pieces and
          another for 7-12 pieces.
        </label>
        <div className="error">
          {productState.error.pricePerUnit.piece.unique}
        </div>
      </div>
      {productState.pricePerUnit.piece.unique === false ? (
        <header style={{ marginTop: '0.5rem', paddingLeft: '1rem' }}>
          <h2>Add Range Of Quantity</h2>
        </header>
      ) : (
        ''
      )}

      {productState.pricePerUnit.piece.unique === false
        ? productState.pricePerUnit.piece.quantityWisePriceList.map(
            (a: any, index: number) => {
              if (index === 0 || a.lessThanOrEqual !== Number.MAX_VALUE) {
                return (
                  <div className="input-container range-cnt">
                    {index > 0 ? (
                      <span
                        className="delete"
                        onClick={() => handleDeleteRange(index)}
                      >
                        &times;
                      </span>
                    ) : (
                      ''
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        marginTop: index > 0 ? '0' : '1.5rem',
                      }}
                    >
                      <div className="from">
                        <InputNumber
                          htmlFor={'pieceMoreThanOrEqual' + index}
                          name={'pieceMoreThanOrEqual'}
                          value={
                            productState.pricePerUnit.piece
                              .quantityWisePriceList[index].moreThanOrEqual
                          }
                          label={'From'}
                          placeholder={'Min quantity'}
                        />

                        <div className="error">
                          {
                            productState.error.pricePerUnit.piece
                              .quantityWisePriceList[index].moreThanOrEqual
                          }
                        </div>
                      </div>
                      <div className="to">
                        <InputNumber
                          onBlur={() =>
                            createLastQuantityWisePricePerObj(
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].moreThanOrEqual,
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].lessThanOrEqual,
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].price,
                              index,
                              'piece'
                            )
                          }
                          htmlFor={'pieceLessThanOrEqual' + index}
                          name={'pieceLessThanOrEqual'}
                          value={
                            productState.pricePerUnit.piece
                              .quantityWisePriceList[index].lessThanOrEqual
                          }
                          label={'To'}
                          handleChange={(event: any) =>
                            handleChange(event, index)
                          }
                          placeholder={'Max quantity'}
                        />

                        <div className="error">
                          {
                            productState.error.pricePerUnit.piece
                              .quantityWisePriceList[index].lessThanOrEqual
                          }
                        </div>
                      </div>

                      <div className="price">
                        <InputNumber
                          htmlFor={'quantityWisePriceListPerPiece' + index}
                          onBlur={async () =>
                            await createLastQuantityWisePricePerObj(
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].moreThanOrEqual,
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].lessThanOrEqual,
                              productState.pricePerUnit.piece
                                .quantityWisePriceList[index].price,
                              index,
                              'piece'
                            )
                          }
                          name={'quantityWisePriceListPerPiece'}
                          value={
                            productState.pricePerUnit.piece
                              .quantityWisePriceList[index].price
                          }
                          label={`Price Range`}
                          handleChange={(event: any) =>
                            handleChange(event, index)
                          }
                          placeholder={'Enter Price'}
                        />

                        <div className="error">
                          {
                            productState.error.pricePerUnit.piece
                              .quantityWisePriceList[index].price
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="last-range-cnt">
                    {productState.pricePerUnit.piece.quantityWisePriceList[
                      productState.pricePerUnit.piece.quantityWisePriceList
                        .length - 1
                    ].lessThanOrEqual === Number.MAX_VALUE ? (
                      <div className="last-range-input">
                        <InputNumber
                          htmlFor={'quantityWisePriceListPerPiece' + index}
                          name={'quantityWisePriceListPerPiece'}
                          value={
                            productState.pricePerUnit.piece
                              .quantityWisePriceList.length == 1
                              ? ''
                              : productState.pricePerUnit.piece
                                  .quantityWisePriceList[
                                  productState.pricePerUnit.piece
                                    .quantityWisePriceList.length - 1
                                ].price
                          }
                          label={
                            'Price after ' +
                            productState.pricePerUnit.piece
                              .quantityWisePriceList[
                              productState.pricePerUnit.piece
                                .quantityWisePriceList.length - 1
                            ].moreThanOrEqual +
                            ' piecees'
                          }
                          handleChange={(event: any) =>
                            handleChange(
                              event,
                              productState.pricePerUnit.piece
                                .quantityWisePriceList.length - 1
                            )
                          }
                          placeholder={'Enter Price'}
                        />

                        <div className="error">
                          {
                            productState.error.pricePerUnit.piece
                              .quantityWisePriceList[
                              productState.pricePerUnit.piece
                                .quantityWisePriceList.length - 1
                            ].price
                          }
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {productState.pricePerUnit.piece.quantityWisePriceList[
                      productState.pricePerUnit.piece.quantityWisePriceList
                        .length - 1
                    ].lessThanOrEqual === Number.MAX_VALUE ? (
                      <button
                        type="button"
                        className={`btn-primary add-range-btn`}
                        onClick={() => handleAddMoreRange('piece')}
                      >
                        Add range
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
            }
          )
        : ''}
    </div>
  );
}
