import React, { useEffect, useRef, useState } from 'react';
import { GetRequest } from '../../../component/Request';
import BuyerStoreListPopup from './BuyerStoreListPopup';
const LazyStepOne = React.lazy(() => import('./component/StepOne'));
const LazyStepTwo = React.lazy(() => import('./component/StepTwo'));
const LazyStepThree = React.lazy(() => import('./component/StepThree'));

export default function AddShop() {
  const ShopNameRef = useRef(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  // step 1 details
  const [shopInfo, setShopInfo] = useState({
    seller: true,
    shopName: '',
    shopType: [],
    shopCategory: [],
    phoneNumberList: [],
    panCard: { number: '', document: '' },
    tradeLicense: { number: '', document: '' },
    profileImage: '',
    shopImages: [],
    GST: { number: '', document: '' },
    email: { id: '', isVerified: false },
    errors: {
      shopName: '',
      shopType: '',
      shopCategory: '',
      phoneNumberList: '',
      tradeLicense: { number: '', document: '' },
      panCard: { number: '', document: '' },
      GST: { number: '', document: '' },
      email: '',
      profileImage: '',
    },
  });

  // step 2 details
  const [shopLocation, setShopLocation] = useState({
    shopAddress: '',
    landmark: '',
    district: '',
    state: '',
    country: '',
    zipCode: '',
    tehsil: '',
    errors: {
      shopAddress: '',
      landmark: '',
      district: '',
      state: '',
      country: '',
      zipCode: '',
      tehsil: '',
    },
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [buyerStoreList, setBuyerStoreList] = useState<any>([]);
  const [userHasEditedAddress, setUserHasEditedAddress] =
    useState<Boolean>(false);

  useEffect(() => {
    GetRequest('store/my-store?buyer=false&seller=true').then((res) => {
      if (res.status === 200) {
        setBuyerStoreList(res.data.data);
      }
    });
  }, []);

  return (
    <div
      style={{
        paddingTop: '120px',
      }}
    >
      {buyerStoreList.length > 0 && (
        <BuyerStoreListPopup
          buyerStoreList={buyerStoreList}
          setBuyerStoreList={setBuyerStoreList}
        />
      )}
      <div className="flex justify-center">
        {currentStep === 1 ? (
          <React.Suspense fallback="loading.....">
            <LazyStepOne
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              shopInfo={shopInfo}
              setShopInfo={setShopInfo}
            />
          </React.Suspense>
        ) : (
          ''
        )}
        {currentStep === 2 ? (
          <React.Suspense fallback="loading.....">
            <LazyStepTwo
              userHasEditedAddress={userHasEditedAddress}
              setUserHasEditedAddress={setUserHasEditedAddress}
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              shopName={shopInfo.shopName}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              shopLocation={shopLocation}
              setShopLocation={setShopLocation}
            />
          </React.Suspense>
        ) : (
          ''
        )}
        {currentStep === 3 ? (
          <React.Suspense fallback="loading....">
            <LazyStepThree
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              shopLocation={shopLocation}
              shopInfo={shopInfo}
            />
          </React.Suspense>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
