import './style/productList.scss';
import { useState, useEffect, useRef } from 'react';
import { DeleteRequest, GetRequest } from '../../../component/Request';
import { useParams } from 'react-router';
import ProductListInPopUpMode from './ProductListInPopUpMode';
import ProductTable from './ProductTable';
import DistributionProductForm from './DistributionProductForm';
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import MobileProductList from './MobileProductList';

export default function ProductList(props: any) {
  let { brandId } = useParams();
  const [products, setProducts] = useState<any>([]);
  const [page, setPage] = useState(1);
  // change this given below one into false;
  const [showForm, setShowForm] = useState<any>(false);
  const [showProductListPopUpMode, setShowProductListPopUpMode] =
    useState(false);
  const [configuredProducts, setConfiguredProducts] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>({});
  const [notEvenOneSelectedByUserErr, setNotEvenOneSelectedByUserErr] =
    useState('');
  const [option, setOption] = useState<any>([]);
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const [height, setHeight] = useState('auto');
  const [loading, setLoading] = useState(true);
  const [showDeleteDialogueBox, setShowDeleteDialogueBox] = useState(false);
  const [userWantToDeleteOrNot, setUserWantToDeleteOrNot] = useState(false);
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState('');
  const [isLoadingInProductListPopUpMode, setIsLoadingInProductListPopUpMode] =
    useState(false);
  const [showEditForm, setShowEditForm] = useState({
    show: false,
    option: '',
    productId: '',
  });
  const configuredProductListRefForInfiniteScrolling = useRef<any>(null);
  const [
    hasMoreProductInConfiguredListOrNot,
    setHasMoreProductInConfiguredListOrNot,
  ] = useState(true);
  const [
    pageNumberForConfiguredProductList,
    setPageNumberForConfiguredProductList,
  ] = useState(1);
  const deleteProductRef = useRef<any>(null);
  // search bar and infintie scrolling in it
  const [searchedValueForProduct, setSearchedValueForProduct] =
    useState<any>('');

  const productListAndSearchBarRef = useRef<any>(null);
  const [allProductInDropDown, setAllProductInDropDown] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMoreProductInDropDownOrNot, setHasMoreProductInDropDownOrNot] =
    useState(true);
  const productListRefForInfiniteScrolling = useRef<any>(null);

  const handleClickOutsideForDeleteProduct = (event: any) => {
    if (
      deleteProductRef.current &&
      !deleteProductRef.current.contains(event.target)
    ) {
      setShowDeleteDialogueBox(false);
      setUserWantToDeleteOrNot(false);
      setProductIdToBeDeleted('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForDeleteProduct);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForDeleteProduct
      );
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (
      productListAndSearchBarRef.current &&
      !productListAndSearchBarRef.current.contains(event.target)
    ) {
      setAllProductInDropDown([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //infinite scrolling in product dropdown
  const handleChangeOnSearchedValue = async (event: any) => {
    let { value } = event.target;
    setSearchedValueForProduct(value);
    setPageNumber(1);

    if (value === '') {
      setAllProductInDropDown([]);
    }
  };

  useEffect(() => {
    if (searchedValueForProduct) {
      getProductBasedOnSearchValue();
    }
  }, [searchedValueForProduct]);

  useEffect(() => {
    getProductForDropDownOnScroll(pageNumber);
  }, [pageNumber]);

  // infinite scrolling in configured product  starts
  const onIntersectionForConfiguredProduct = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreProductInConfiguredListOrNot) {
      if (hasMoreProductInConfiguredListOrNot) {
        setPageNumberForConfiguredProductList(
          (prevPageNumber) => prevPageNumber + 1
        );
      }
    }
  };

  useEffect(() => {
    if (configuredProductListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(
        onIntersectionForConfiguredProduct
      );
      observer.observe(configuredProductListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [configuredProducts.length > 0]);

  useEffect(() => {
    getProductForConfiguredProductListOnScroll(
      pageNumberForConfiguredProductList
    );
  }, [pageNumberForConfiguredProductList]);

  const getProductForConfiguredProductListOnScroll = async (
    currentPageNumber: any
  ) => {
    if (hasMoreProductInConfiguredListOrNot) {
      await GetRequest(
        `product?brand=${brandId}&page=${currentPageNumber}&seller-product-detail=true&seller-product-only=true&limit=8`
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 8) {
              setHasMoreProductInConfiguredListOrNot(false);
            } else {
              setHasMoreProductInConfiguredListOrNot(true);
            }
            setConfiguredProducts((prevState: any) => [
              ...prevState,
              ...res.data.data,
            ]);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, 'this is err in fetching all product ');
        });
    }
  };
  // infinite scrolling in configured product end

  const onIntersection = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreProductInDropDownOrNot) {
      if (hasMoreProductInDropDownOrNot) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  useEffect(() => {
    if (productListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersection);
      observer.observe(productListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allProductInDropDown.length]);

  const getProductForDropDownOnScroll = async (currentPageNumber: any) => {
    if (hasMoreProductInDropDownOrNot && searchedValueForProduct) {
      await GetRequest(
        `product?brand=${brandId}&name=${searchedValueForProduct}&page=${currentPageNumber}&seller-product-detail=true&seller-product-only=true`
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 8) {
              // If response data is less than the limit, set hasMoreProductInDropDownOrNot to false
              setHasMoreProductInDropDownOrNot(false);
              setAllProductInDropDown((prevState: any) => [
                ...prevState,
                ...res.data.data,
              ]);
            } else {
              setHasMoreProductInDropDownOrNot(true);
              setAllProductInDropDown((prevState: any) => [
                ...prevState,
                ...res.data.data,
              ]);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all product ');
        });
    }
  };

  const getProductBasedOnSearchValue = async () => {
    if (searchedValueForProduct) {
      await GetRequest(
        `product?brand=${brandId}&name=${searchedValueForProduct}&page=${pageNumber}&seller-product-detail=true&seller-product-only=true&limit=8`
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 8) {
              // If response data is less than the limit, set hasMoreProductInDropDownOrNot to false
              setHasMoreProductInDropDownOrNot(false);
              setAllProductInDropDown(res.data.data);
            } else {
              setHasMoreProductInDropDownOrNot(true);
              setAllProductInDropDown(res.data.data);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all product ');
        });
    }
  };

  const handleClickOnProductInDropDown = (data: any) => {
    let newArray = [...allProductInDropDown];
    newArray.unshift(data);
    let uniqueSet: any = new Set(newArray);
    let uniqueArray = [...uniqueSet];
    setConfiguredProducts(uniqueArray);
    setAllProductInDropDown([]);
    setSearchedValueForProduct('');
  };

  useEffect(() => {
    if (option.length == 0) {
      return setHeight('95vh');
    } else {
      return setHeight('auto');
    }
  }, [option]);

  const showConfiguredDetails = (index: number) => {
    let updateProduct = [...configuredProducts];

    updateProduct[index].showDropdown = !updateProduct[index].showDropdown;
    setConfiguredProducts(updateProduct);
  };

  const handleChange = (event: any, index: number) => {
    let { name, value } = event.target;
    console.log('wrong');
    let configuredProductToBeUpdate = configuredProducts.filter(
      (p: any) => p.productId._id == showEditForm.productId
    )[0];

    switch (name) {
      case 'pieceMinimumQuantity':
        configuredProductToBeUpdate.minimumQuantity.piece.quantity =
          Number(value);
        break;
      case 'pieceIsMultiple':
        configuredProductToBeUpdate.minimumQuantity.piece.isMultiple.enable =
          event.target.checked;
        break;
      case 'pieceIncDecQuantity':
        configuredProductToBeUpdate.minimumQuantity.piece.isMultiple.number =
          Number(value);
        break;
      case 'pieceIsUnique':
        configuredProductToBeUpdate.pricePerUnit.piece.unique =
          !configuredProductToBeUpdate.pricePerUnit.piece.unique;
        break;
      case 'piecePerUnitPrice':
        configuredProductToBeUpdate.pricePerUnit.piece.price = value;
        break;
      case 'pieceMoreThanOrEqual':
        if (
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ]
        ) {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...configuredProductToBeUpdate.pricePerUnit.piece
              .quantityWisePriceList[index],
            moreThanOrEqual: Number(value),
          };
        } else {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList.push(
            {
              moreThanOrEqual: Number(value),
            }
          );
        }
        break;
      case 'pieceLessThanOrEqual':
        if (
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ]
        ) {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...configuredProductToBeUpdate.pricePerUnit.piece
              .quantityWisePriceList[index],
            lessThanOrEqual: Number(value),
          };
        } else {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList.push(
            {
              lessThanOrEqual: Number(value),
            }
          );
        }
        break;
      case 'quantityWisePriceListPerPiece':
        if (
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ]
        ) {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...configuredProductToBeUpdate.pricePerUnit.piece
              .quantityWisePriceList[index],
            price: Number(value),
          };
        } else {
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList.push(
            {
              price: Number(value),
            }
          );
        }
        break;
      case 'offerOnWhatQuantityOfPiece':
        configuredProductToBeUpdate.offer.pieceOfferList[index] = {
          ...configuredProductToBeUpdate.offer.pieceOfferList[index],
          quantity: value,
        };
        break;
      case 'freeQuantityPiece':
        configuredProductToBeUpdate.offer.pieceOfferList[index] = {
          ...configuredProductToBeUpdate.offer.pieceOfferList[index],
          free: {
            ...configuredProductToBeUpdate.offer.pieceOfferList[index].free,
            quantity: value,
          },
        };
        break;
      case 'typeOfOptionForPieceOffer':
        configuredProductToBeUpdate.offer.pieceOfferList[index] = {
          ...configuredProductToBeUpdate.offer.pieceOfferList[index],
          free: {
            ...configuredProductToBeUpdate.offer.pieceOfferList[index].free,
            type: value,
          },
        };
        break;
      case 'boxMinimumQuantity':
        configuredProductToBeUpdate.minimumQuantity.bundle.quantity = value;
        break;
      case 'bundleIsMultiple':
        configuredProductToBeUpdate.minimumQuantity.bundle.isMultiple.enable =
          value == 'true' ? true : false;
        break;
      case 'bundleIncDecQuantity':
        configuredProductToBeUpdate.minimumQuantity.bundle.isMultiple.number =
          value;
        break;
      case 'boxMinimumQuantity':
        configuredProductToBeUpdate.minimumQuantity.box.quantity = value;
        break;
      case 'boxIsMultiple':
        configuredProductToBeUpdate.minimumQuantity.box.isMultiple.enable =
          value == 'true' ? true : false;
        break;
      case 'boxIncDecQuantity':
        configuredProductToBeUpdate.minimumQuantity.box.isMultiple.number =
          value;
        break;
      default:
        console.log('default');
        break;
    }

    setConfiguredProducts((prevState: any) => {
      return prevState.map((p: any) => {
        if (p.productId._id == showEditForm.productId) {
          return configuredProductToBeUpdate;
        } else {
          return p;
        }
      });
    });
  };

  const handleAddMoreRange = (option: any) => {
    let configuredProductToBeUpdate = configuredProducts.filter(
      (p: any) => p.productId._id == showEditForm.productId
    )[0];
    if (option == 'piece') {
      // first need to get the last elm's from
      let lastFromNumber =
        configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList[
          configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList
            .length - 1
        ].lessThanOrEqual;
      configuredProductToBeUpdate.pricePerUnit.piece.quantityWisePriceList.push(
        {
          price: '',
          moreThanOrEqual: Number(lastFromNumber) + 1,
          lessThanOrEqual: '',
        }
      );
    }

    setConfiguredProducts((prevState: any) => {
      return prevState.map((p: any) => {
        if (p.productId._id == showEditForm.productId) {
          return configuredProductToBeUpdate;
        } else {
          return p;
        }
      });
    });
  };

  const deleteProduct = async () => {
    setUserWantToDeleteOrNot(true);
    await DeleteRequest(
      `seller/store/brand/${brandId}/product/${productIdToBeDeleted}`
    ).then((res) => {
      if (res.data.statusCode === 200) {
        setConfiguredProducts((prevConfiguredProducts: any) => {
          let updatedResponse = prevConfiguredProducts.filter((obj: any) => {
            if (obj._id !== productIdToBeDeleted) {
              return obj;
            }
          });
          return updatedResponse;
        });
        setProductIdToBeDeleted(() => '');
        setShowDeleteDialogueBox(() => false);
        setUserWantToDeleteOrNot(() => false);
      }
    });
  };

  const handleClickOnEnter = async (event: any) => {
    if (event.key === 'Enter') {
      if (allProductInDropDown.length > 0) {
        setConfiguredProducts(allProductInDropDown);
        setAllProductInDropDown([]);
        setSearchedValueForProduct('');
      }
    }
  };

  const handleClickOnAddProduct = async () => {
    setShowProductListPopUpMode(true);
  };

  return (
    <div style={{ position: 'relative' }}>
      {showForm ? (
        <div
          style={{
            position: 'fixed',
            zIndex: '999',
            width: '100vw',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <DistributionProductForm
            setPageNumberForConfiguredProductList={
              setPageNumberForConfiguredProductList
            }
            configuredProducts={configuredProducts}
            setHasMoreProductInConfiguredListOrNot={
              setHasMoreProductInConfiguredListOrNot
            }
            setProductForProductListPopup={setProducts}
            setPageForNewProduct={setPage}
            height={height}
            setSelectedProducts={setSelectedProducts}
            setShowForm={setShowForm}
            brandId={brandId}
            option={option}
            selectedProducts={selectedProducts}
            setOption={setOption}
            setConfiguredProducts={setConfiguredProducts}
          />
        </div>
      ) : (
        ''
      )}

      {showProductListPopUpMode ? (
        <ProductListInPopUpMode
          setProducts={setProducts}
          isLoadingInProductListPopUpMode={isLoadingInProductListPopUpMode}
          setIsLoadingInProductListPopUpMode={
            setIsLoadingInProductListPopUpMode
          }
          brandId={brandId}
          page={page}
          setShowForm={setShowForm}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          setShowProductListPopUpMode={setShowProductListPopUpMode}
          products={products}
          setPage={setPage}
          notEvenOneSelectedByUserErr={notEvenOneSelectedByUserErr}
          setNotEvenOneSelectedByUserErr={setNotEvenOneSelectedByUserErr}
        />
      ) : (
        ''
      )}

      {showDeleteDialogueBox ? (
        <section className="confirmation-cnt">
          <div className="confirmation-box" ref={deleteProductRef}>
            <p style={{ fontSize: '1rem' }}>
              Do you want to delete the product?
            </p>
            <div
              style={{
                textAlign: 'center',
                marginTop: '1rem',
              }}
            >
              <button
                type="button"
                onClick={() => {
                  setShowDeleteDialogueBox(false);
                  setUserWantToDeleteOrNot(false);
                  setProductIdToBeDeleted('');
                }}
                className="cancel-btn"
              >
                No
              </button>
              <button
                type="button"
                style={{
                  padding: '2px',
                  width: '6rem',
                  height: 'auto',
                  marginLeft: '2rem',
                }}
                className="button-primary"
                onClick={deleteProduct}
              >
                Yes
              </button>
            </div>
          </div>
        </section>
      ) : (
        ''
      )}

      <div className="product-list-cnt">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {configuredProducts.length === 0 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh',
                }}
              >
                <div className="info">
                  <h1>
                    Please add some products by clicking on the plus or add
                    button and then fill up the form
                  </h1>
                </div>
              </div>
            ) : (
              <div>
                <div className="search-bar-component">
                  <div className="search-bar" ref={productListAndSearchBarRef}>
                    <input
                      type="text"
                      name="product-name"
                      placeholder="Search Product..."
                      onKeyDown={handleClickOnEnter}
                      value={searchedValueForProduct}
                      onChange={handleChangeOnSearchedValue}
                    />
                    <button
                      className="search-icon"
                      onClick={getProductBasedOnSearchValue}
                    >
                      <FiSearch />
                    </button>
                    {allProductInDropDown.length > 0 ? (
                      <ul
                        id={'product-list-n-search-bar'}
                        className="product-list"
                        style={{ overflowY: 'scroll' }}
                      >
                        {allProductInDropDown.map(
                          (data: any, index: number) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleClickOnProductInDropDown(data)
                              }
                              className="each-product"
                            >
                              <img
                                src={
                                  data.imageList.length > 0
                                    ? data.imageList[0]
                                    : '/no-image-available-icon.jpeg'
                                }
                                alt={data._id}
                                className="product-img"
                              />
                              <div>
                                <p className="name"> {data.name}</p>
                                <p className="mrp">MRP - ₹{data.mrp}</p>
                              </div>
                            </li>
                          )
                        )}
                        {allProductInDropDown.length > 0 && (
                          <div
                            ref={productListRefForInfiniteScrolling}
                            style={{ padding: '10px' }}
                          ></div>
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {!isMobileScreen ? (
                  <div className="table">
                    <div className="table-header">
                      <div className="table-cell"> Image</div>
                      <div className="table-cell"> Name</div>
                      <div className="table-cell"> MRP</div>
                      <div className="table-cell"> Weight</div>
                      {/* <div className="table-cell"> Size</div> */}
                      {/* <div className="table-cell"> Color</div> */}
                      <div className="table-cell"> Flavor</div>

                      <div className="table-cell"> Piece In 1 Bundle </div>

                      {/* <div className="table-cell"> Piece In 1 Box</div> */}
                    </div>

                    <div className="table-body-container">
                      <div className="table-body" style={{ overflow: 'auto' }}>
                        {configuredProducts.length > 0 &&
                          configuredProducts.map((data: any, index: any) => {
                            return (
                              <div
                                key={index}
                                className="table-row tooltip"
                                style={{
                                  bottom: data.showDropdown ? '75%' : '-10%',
                                  borderBottom: '1px solid #ddd',
                                }}
                              >
                                <div style={{ display: 'flex' }}>
                                  <div className="table-cell">
                                    <img
                                      style={{
                                        borderRadius: '8px',
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'contain',
                                      }}
                                      src={
                                        data.imageList.length > 0 &&
                                        data.imageList[0]
                                      }
                                      alt={data.name}
                                    />
                                  </div>
                                  <p className="table-cell product-name">
                                    {data.name}
                                    <div
                                      className="tooltiptext"
                                      style={{
                                        bottom: data.showDropdown
                                          ? '68%'
                                          : '-10%',
                                      }}
                                    >
                                      {data.name}
                                    </div>
                                  </p>

                                  <p
                                    className="table-cell"
                                    style={{ display: 'inline-flex' }}
                                  >
                                    {'₹' + data.mrp}
                                  </p>
                                  {
                                    <p className="table-cell">
                                      {data.weight
                                        ? data.weight?.quantity +
                                          ' ' +
                                          data.weight?.unit
                                        : '-.-'}
                                    </p>
                                  }
                                  {/* {
                                  <p className="table-cell">
                                    {data.size
                                      ? data.size?.quantity +
                                        ' ' +
                                        data.size?.unit
                                      : '-.-'}
                                  </p>
                                } */}

                                  {/* {
                                  <p className="table-cell">
                                    {data?.color?.name
                                      ? data?.color?.name
                                      : '-.-'}
                                  </p>
                                } */}

                                  {
                                    <p className="table-cell">
                                      {data?.flavor?.name
                                        ? data?.flavor?.name
                                        : '-.-'}
                                    </p>
                                  }

                                  {
                                    <p className="table-cell">
                                      {data?.info?.pieceInEachBundle
                                        ? data?.info?.pieceInEachBundle
                                        : '-.-'}
                                    </p>
                                  }

                                  {/* {
                                  <p className="table-cell">
                                    {data?.info?.pieceInEachBox
                                      ? data?.info?.pieceInEachBox
                                      : '-.-'}
                                  </p>
                                } */}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <p
                                    className={
                                      !data.showDropdown
                                        ? 'see-product-detail'
                                        : 'hide-product-detail'
                                    }
                                    onClick={() => showConfiguredDetails(index)}
                                  >
                                    {!data.showDropdown
                                      ? `See Product Details `
                                      : `Hide Product Details  `}

                                    {data.showDropdown ? (
                                      <FiChevronUp />
                                    ) : (
                                      <FiChevronDown />
                                    )}
                                  </p>
                                </div>

                                {data.showDropdown && (
                                  <ProductTable
                                    setPageNumberForConfiguredProductList={
                                      setPageNumberForConfiguredProductList
                                    }
                                    setHasMoreProductInConfiguredListOrNot={
                                      setHasMoreProductInConfiguredListOrNot
                                    }
                                    setConfiguredProducts={
                                      setConfiguredProducts
                                    }
                                    brandId={brandId}
                                    handleAddMoreRange={handleAddMoreRange}
                                    handleChange={handleChange}
                                    setShowForm={setShowForm}
                                    setSelectedProducts={setSelectedProducts}
                                    setShowDeleteDialogueBox={
                                      setShowDeleteDialogueBox
                                    }
                                    setProductIdToBeDeleted={
                                      setProductIdToBeDeleted
                                    }
                                    deleteProduct={deleteProduct}
                                    setShowEditForm={setShowEditForm}
                                    configuredProduct={
                                      configuredProducts[index]
                                    }
                                  />
                                )}
                              </div>
                            );
                          })}
                        {configuredProducts.length > 0 && (
                          <div
                            ref={configuredProductListRefForInfiniteScrolling}
                            style={{ padding: '20px' }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <MobileProductList
                    configuredProducts={configuredProducts}
                    configuredProductListRefForInfiniteScrolling={
                      configuredProductListRefForInfiniteScrolling
                    }
                    setPageNumberForConfiguredProductList={
                      setPageNumberForConfiguredProductList
                    }
                    setHasMoreProductInConfiguredListOrNot={
                      setHasMoreProductInConfiguredListOrNot
                    }
                    setConfiguredProducts={setConfiguredProducts}
                    brandId={brandId}
                    handleAddMoreRange={handleAddMoreRange}
                    handleChange={handleChange}
                    setShowForm={setShowForm}
                    setShowDeleteDialogueBox={setShowDeleteDialogueBox}
                    setProductIdToBeDeleted={setProductIdToBeDeleted}
                    deleteProduct={deleteProduct}
                    setShowEditForm={setShowEditForm}
                    setSelectedProducts={setSelectedProducts}
                    showConfiguredDetails={showConfiguredDetails}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      <button
        className="btn-primary add-product-btn"
        onClick={handleClickOnAddProduct}
      >
        <span className="plus">+</span> Add Product
      </button>
    </div>
  );
}
