import { useEffect, useRef, useState } from 'react';
import './select.scss';
import { FiChevronDown } from 'react-icons/fi';

export default function InputText({
  label,
  value,
  handleClickOnOption,
  option,
  index,
}: any) {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(false);
  const [showOption, setShowOption] = useState<any>(false);
  const dropDownRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className=" custom-select-cnt">
      <label
        className={
          labelOnTop || value ? 'label label-should-move-upward' : 'label'
        }
        htmlFor={label}
        onClick={() => {
          setShowOption(true);
          setLabelOnTop(true);
        }}
      >
        {label}
        <span style={{ display: labelOnTop ? 'none' : 'inline-block' }}>
          <FiChevronDown className="arrow" />
        </span>
      </label>

      <div
        className="custom-dropdown"
        onClick={() => {
          setShowOption(true);
          setLabelOnTop(true);
        }}
      >
        <div className="selected-option">
          {value ? value : 'Choose one'}
          {value ? <FiChevronDown className="arrow" /> : ''}
        </div>
        {showOption ? (
          <ul className="options" ref={dropDownRef}>
            {option.map((data: any) => {
              return (
                <li
                  key={data}
                  className="option"
                  onClick={async () => {
                    await handleClickOnOption(data, index);
                    await setShowOption(false);
                  }}
                >
                  {data}
                </li>
              );
            })}
          </ul>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
