import { AiTwotonePhone, AiTwotoneShop } from 'react-icons/ai';
import { CiCalendarDate } from 'react-icons/ci';
import {
  MdEmail,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles/notAssignedOrder.scss';
import { RxCross1 } from 'react-icons/rx';
import { FaLocationArrow } from 'react-icons/fa';
import RouteDropdown from './RouteDropdown';
import StoreOnMap from './StoreOnMap';
import { useEffect, useRef, useState } from 'react';
import { GetRequest, PatchRequest } from '../../../component/Request';
import DeclineOrderPopUp from './DeclineOrderPopUp';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { currentUserSelector, showError } from '../../../features/auth/Slice';
import { IoMdInformationCircle } from 'react-icons/io';
import AssignAndStartPreparingConfiramtionBox from './AssignAndStartPreparingConfirmationBox';
import EditQuantityPopup from './EditQuantityPopup';
import DeleteOrderPopup from './DeleteOrderPopup';
import { useNavigate } from 'react-router-dom';
import { IOrderedOrder } from '../../../interface/axios.types';

export default function NotAssignedOrder({
  newOrder,
  fetchNotAssignedOrder,
  setShowNewOrder,
  fetchScheduledOrder,
  newOrderListRefForInfiniteScrolling,
  fetchNewOrderCount,
  setNewOrder,
  orderStatus,
  selectedFromDate,
  selectedToDate,
  activeRoute,
  activeBuyer,
  activeSchedule,
}: any) {
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const [showMap, setShowMap] = useState(false);
  const [buyerStoreCoordinates, setBuyerStoreCoordinates] = useState<any>({});
  const [showRoute, setShowRoute] = useState(false);
  const [sellerRoute, setSellerRoute] = useState<any>([]);
  const [buyerStore, setBuyerStore] = useState<any>({});
  const [showDeclineOrderForm, setShowDeclineOrderForm] = useState(false);
  const [orderIdToBeDeclined, setOrderIdToBeDeclined] = useState('');
  const currentStore = useSelector(currentUserSelector);
  const [orderIdToConvertInPreparing, setOrderIdToConvertInPreparing] =
    useState<string>('');
  const [orderIdToConvertInPreparingFullOrderObject,setOrderIdToConvertInPreparingFullOrderObject] = useState<IOrderedOrder|null>(null)
  const [isUrgent,setIsUrgent] = useState<{urgent:boolean,message:string,error:string}>({
    urgent : false , message:"", error:""
  })
  let navigate = useNavigate();
  const [
    currentIndexForEditAndDeleteOption,
    setCurrentIndexForEditAndDeleteOption,
  ] = useState<number>();
  const [
    productIdAndOrderIdForEditingQuantityOrDeletingItem,
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem,
  ] = useState<any>({});
  const editAndDeleteRef = useRef<any>(null);

  const handleClickOutside = async (event: any) => {
    if (
      editAndDeleteRef.current &&
      !editAndDeleteRef.current.contains(event.target)
    ) {
      let updateOrder = [...newOrder];
      updateOrder = await updateOrder.map((order: any) => {
        return {
          ...order,
          itemList: order.itemList.map((data: any) => {
            if (data.showEditAndDeleteOption === true) {
              return {
                ...data,
                showEditAndDeleteOption: false,
              };
            } else {
              return data;
            }
          }),
        };
      });

      setNewOrder((prevState: any) => {
        return updateOrder;
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function fetchSellerRoute(order: any) {
    return await GetRequest(`route?seller-store=${currentStore?._id}`).then(
      (res) => {
        if (res.data.statusCode === 200) {
          setBuyerStore(order.buyerStore);
          setSellerRoute(res.data.data);
          setShowRoute(true);
          setOrderIdToConvertInPreparingFullOrderObject(order)
        }
      }
    );
  }

  function formatDateToDDMMMYY(inputDate: any) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthAbbreviation = months[date.getUTCMonth()];
    const year = String(date.getUTCFullYear()).slice(-4);

    const formattedDate = `${day}-${monthAbbreviation}-${year}`;

    return formattedDate;
  }

  const handleDeclineOrder = (order: any) => {
    setOrderIdToBeDeclined(order._id);
    setBuyerStore(order.buyerStore);
    setShowDeclineOrderForm(true);
  };

  const acceptAndStartPreparing = (order: any) => {
    setOrderIdToConvertInPreparing(() => order._id);
    setOrderIdToConvertInPreparingFullOrderObject(()=>order)
  };

  const editQuantityOrDeleteItem = (
    pieceWiseQuantity: any,
    orderId: string,
    productId: string,
    editOrDelete: string
  ) => {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem(() => {
      return {
        productId,
        orderId,
        editOrDelete: editOrDelete,
        quantity: pieceWiseQuantity,
      };
    });
  };

  const handleUpdateTheOrderToPreparing = async () => {
    if(isUrgent.error ){
      if(isUrgent.error.includes("Something")) return 
      setIsUrgent(pre=>({...pre,error:pre.error+" Something went wrong"}))
      return
    }
    await PatchRequest(`order/${orderIdToConvertInPreparing}`, {
      status: 'Preparing',
      ...(orderIdToConvertInPreparingFullOrderObject?.urgentOrder && {
        urgentOrder: true,
        message:isUrgent.message
      })
    }).then(async (res) => {
      if (res.data.statusCode === 200) {
        setOrderIdToConvertInPreparing('');
        await fetchNewOrderCount();
        await fetchNotAssignedOrder();
        setOrderIdToConvertInPreparingFullOrderObject(null)
      }
    });
  };

  const handleClickOnAction = (
    indexOfNewOrder: number,
    indexOfItem: number
  ) => {
    let updateOrder = [...newOrder];
    updateOrder[indexOfNewOrder].itemList[indexOfItem].showEditAndDeleteOption =
      true;
    setNewOrder((prevState: any) => {
      return updateOrder;
    });
  };

  return (
    <section className="new-order-cnt">
      <div className="new-order-box">
        {orderIdToConvertInPreparing && orderIdToConvertInPreparingFullOrderObject && (
          <AssignAndStartPreparingConfiramtionBox
            handleUpdateTheOrderToPreparing={handleUpdateTheOrderToPreparing}
            setOrderIdToConvertInPreparing={setOrderIdToConvertInPreparing}
            order={orderIdToConvertInPreparingFullOrderObject}
            isUrgent={isUrgent}
            setIsUrgent={setIsUrgent}
            setOrderIdToConvertInPreparingFullOrderObject={setOrderIdToConvertInPreparingFullOrderObject}
          />
        )}

        {productIdAndOrderIdForEditingQuantityOrDeletingItem.orderId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.productId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.editOrDelete ===
            'delete' && (
            <DeleteOrderPopup
              fetchNotAssignedOrder={fetchNotAssignedOrder}
              setProductIdAndOrderIdForEditingQuantityOrDeletingItem={
                setProductIdAndOrderIdForEditingQuantityOrDeletingItem
              }
              productIdAndOrderIdForEditingQuantityOrDeletingItem={
                productIdAndOrderIdForEditingQuantityOrDeletingItem
              }
            />
          )}
        {productIdAndOrderIdForEditingQuantityOrDeletingItem.orderId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.productId &&
          productIdAndOrderIdForEditingQuantityOrDeletingItem.editOrDelete ===
            'edit' && (
            <EditQuantityPopup
              fetchNotAssignedOrder={fetchNotAssignedOrder}
              setProductIdAndOrderIdForEditingQuantityOrDeletingItem={
                setProductIdAndOrderIdForEditingQuantityOrDeletingItem
              }
              productIdAndOrderIdForEditingQuantityOrDeletingItem={
                productIdAndOrderIdForEditingQuantityOrDeletingItem
              }
            />
          )}
        {showMap && (
          <StoreOnMap
            setBuyerStoreCoordinates={setBuyerStoreCoordinates}
            setShowMap={setShowMap}
            latitude={buyerStoreCoordinates.latitude}
            longitude={buyerStoreCoordinates.longitude}
          />
        )}
        {showRoute && (
          <RouteDropdown
            fetchNewOrderCount={fetchNewOrderCount}
            fetchNotAssignedOrder={fetchNotAssignedOrder}
            setShowRoute={setShowRoute}
            sellerRoute={sellerRoute}
            setSellerRoute={setSellerRoute}
            buyerStore={buyerStore}
            fetchScheduledOrder={fetchScheduledOrder}
            order={orderIdToConvertInPreparingFullOrderObject}
            setOrderIdToConvertInPreparingFullOrderObject={setOrderIdToConvertInPreparingFullOrderObject}
          />
        )}
        {showDeclineOrderForm && (
          <DeclineOrderPopUp
            fetchNewOrderCount={fetchNewOrderCount}
            fetchNotAssignedOrder={fetchNotAssignedOrder}
            orderIdToBeDeclined={orderIdToBeDeclined}
            setOrderIdToBeDeclined={setOrderIdToBeDeclined}
            buyerStore={buyerStore}
            setShowDeclineOrderForm={setShowDeclineOrderForm}
            fetchNewOrder={true}
          />
        )}

        <ul className="order-list">
          <div className="cross-icon-cnt">
            <button className="cross-icon">
              <RxCross1
                onClick={() => {
                  setShowNewOrder(false);

                  let navigationUrl = `/order?status=${orderStatus.toLocaleLowerCase()}`;

                  if (selectedFromDate) {
                    navigationUrl =
                      navigationUrl + `&from-date=${selectedFromDate}`;
                  }
                  if (selectedToDate) {
                    navigationUrl =
                      navigationUrl + `&to-date=${selectedToDate}`;
                  }

                  if (activeRoute.id) {
                    navigationUrl =
                      navigationUrl +
                      `&route=${activeRoute.id}&route-name=${activeRoute.name}`;
                  }

                  if (activeBuyer.id) {
                    navigationUrl =
                      navigationUrl +
                      `&buyer-store=${activeBuyer.id}&buyer-name=${activeBuyer.name}`;
                  }

                  if (activeSchedule.id) {
                    navigationUrl =
                      navigationUrl +
                      `&route-schedule=${activeSchedule.id}&schedule-start-date-and-time=${activeSchedule.startDateAndTime}`;
                  }

                  navigate(navigationUrl + '&show_new_order=false');
                }}
              />
            </button>
          </div>

          {newOrder.length > 0 && (
            <div className="note-of-accepting-order">
              <IoMdInformationCircle className="info-icon" />
              <div>
                <h2 className="note-topic">Accept & Assign Delivery Route :</h2>
                <p className="note-answer">
                  By choosing this option, you not only accept the order but
                  also schedule it for a specific delivery route. If the same
                  buyer places another order in the future, it will
                  automatically be scheduled on your upcoming route.
                </p>

                <h2 className="note-topic">Accept & Start Preparing :</h2>
                <p className="note-answer">
                  Opting for this option means you accept the order and initiate
                  the preparation process directly. The order will be visible in
                  the 'Preparing' section on the order page. For subsequent
                  orders from the same buyer, they will follow the same
                  preparation workflow.
                </p>

                <h2 className="note-topic">Delete Order Item :</h2>
                <p className="note-answer">
                  If you want to remove an item from your buyer's order, just
                  click the Delete button next to that item. The buyer will be
                  notified about the removal, and you can add a brief reason for
                  clarity
                </p>

                <h2 className="note-topic">Edit Order Item :</h2>
                <p className="note-answer">
                  Clicking on the Edit button allows you to modify the details
                  of a particular order item's quantity. If you have a limited
                  quantity in stock or need to make adjustments, use the edit
                  option to modify the order item accordingly.
                </p>
              </div>
            </div>
          )}

          {newOrder.length > 0 ? (
            newOrder.map((order: any, index: number) => {
              return (
                <div className="new-order" key={order._id}>
                   {order?.urgentOrder && <div className='urgent-order'>
                    <p>Urgent</p>
                  </div>}
                  <div className="flex justify-between flex-wrap">
                    <div className="cnt-of-seller-img-n-seller-info">
                      {!order.buyerStore.profileImage ? (
                        <AiTwotoneShop className="seller-img" />
                      ) : (
                        <img
                          className="seller-img"
                          src={order.buyerStore.profileImage}
                          alt="buyer-store"
                        />
                      )}
                      {isMobileScreen && (
                        <h2 className="buyer-name">{order.buyerStore.name}</h2>
                      )}
                      {isMobileScreen ? (
                        <div
                          className="buyer-date-n-email"
                          style={{
                            display: 'block',
                            flexBasis: 'content',
                            textAlign: 'center',
                          }}
                        >
                          <p className="buyer-order-date">
                            <span className="buyer-date">
                              <CiCalendarDate />
                            </span>
                            {formatDateToDDMMMYY(order.createdAt)}
                          </p>
                          {order.buyerStore.phoneNumberList?.length > 0 ? (
                            <p className="buyer-number">
                              <span className="buyer-phone-icon">
                                <AiTwotonePhone />
                              </span>

                              {` ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                0,
                                3
                              )}  ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                                3
                              )}`}
                            </p>
                          ) : (
                            ''
                          )}
                          {order.buyerStore.email ? (
                            <p className="buyer-email">
                              <span className="buyer-email-icon">
                                <MdEmail />
                              </span>
                              <span>{order.buyerStore.email}</span>
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="buyer-name-n-location">
                        {!isMobileScreen && (
                          <h2 className="buyer-name">
                            {order.buyerStore.name}
                          </h2>
                        )}

                        <p className="buyer-location">
                          <span className="buyer-location-icon">
                            <FaLocationArrow />
                          </span>
                          <span>
                            {order.buyerStore.address.district +
                              ' , ' +
                              order.buyerStore.address.state +
                              ' , '}
                            {!isMobileScreen ? <br /> : ''}
                            {order.buyerStore.address.country +
                              ' , ' +
                              order.buyerStore.address.postalCode}
                          </span>
                        </p>
                        <a
                          style={{
                            fontSize: '13px',
                            color: '#14539a',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setBuyerStoreCoordinates({
                              latitude:
                                order.buyerStore.location.coordinates[1],
                              longitude:
                                order.buyerStore.location.coordinates[0],
                            });
                            setShowMap(true);
                          }}
                          target="_blank"
                        >
                          See Store Location on map
                        </a>
                      </div>
                    </div>

                    {isMobileScreen ? (
                      ''
                    ) : (
                      <div className="buyer-date-n-email">
                        <p
                          className="buyer-order-date"
                          style={{ justifyContent: 'center' }}
                        >
                          <span className="buyer-date">
                            <CiCalendarDate />
                          </span>
                          {formatDateToDDMMMYY(order.createdAt)}
                        </p>
                        {order.buyerStore.phoneNumberList?.length > 0 ? (
                          <p className="buyer-number">
                            <span className="buyer-phone-icon">
                              <AiTwotonePhone />
                            </span>

                            {`( ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                              0,
                              3
                            )} ) ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                              3
                            )}`}
                          </p>
                        ) : (
                          ''
                        )}
                        {order.buyerStore.email ? (
                          <p className="buyer-email">
                            <span className="buyer-email-icon">
                              <MdEmail />
                            </span>
                            <span>{order.buyerStore.email}</span>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    )}

                    <div className="accept-decline-cnt">
                      <button
                        className="decline-btn"
                        onClick={() => handleDeclineOrder(order)}
                      >
                        Decline
                      </button>
                      <div className="two-accept-cnt">
                        <button
                          className="accept-btn"
                          onClick={() => acceptAndStartPreparing(order)}
                        >
                          Accept & Start Preparing
                        </button>
                        <button
                          style={{
                            marginLeft: '12px',
                          }}
                          className="accept-btn"
                          onClick={() => fetchSellerRoute(order)}
                        >
                          Accept & Assign Delivery Route
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="see-order-items-cnt">
                    <p
                      className="see-order-items"
                      onClick={() => {
                        let updatedNewOrder = [...newOrder];
                        updatedNewOrder[index].showItem = !order.showItem;
                        setShowNewOrder(updatedNewOrder);
                      }}
                    >
                      {order.showItem ? 'Hide Order Items' : 'See Order Items'}
                      {!order.showItem ? (
                        <MdKeyboardArrowDown />
                      ) : (
                        <MdKeyboardArrowUp />
                      )}
                    </p>
                  </div>

                  {isMobileScreen && order.showItem ? (
                    <NewOrderItemsForMobile order={order} />
                  ) : (
                    ''
                  )}

                  {order.showItem && !isMobileScreen ? (
                    <table className="bill-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>MRP</th>
                          <th>Price (Excl. tax)</th>
                          <th>Price (Incl. tax)</th>
                          <th>Quantity (In Pieces)</th>
                          {order.totalTaxAmountList.map((tax: any) => (
                            <th className="order_tax">{tax.type}</th>
                          ))}
                          <th>Total Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.itemList.map(
                          (data: any, indexOfItem: number) => {
                            return (
                              <tr key={indexOfItem}>
                                <td className="order-name-n-img-table-data">
                                  <div>
                                    <LazyLoadImage
                                      alt={data.product.name}
                                      className="order-img"
                                      src={
                                        !data.product.image
                                          ? '/images/no-image-available-icon.jpeg'
                                          : data.product.image
                                      }
                                    />
                                  </div>
                                  <span className="order-item-name">
                                    {data.product.name}
                                  </span>
                                </td>
                                <td> {'₹ ' + data.product.mrp}</td>
                                <td>
                                  {'₹ ' +
                                    data.pricePerUnit.netAmount.toFixed(2)}
                                </td>
                                <td>
                                  {'₹ ' +
                                    data.pricePerUnit.totalAmount.toFixed(2)}
                                </td>
                                <td>
                                  {data.quantity.pieceWise}
                                  {/* {data.product.selectedOption !== 'piece'
                                 ? ` (  ${data.product.quantity}
${data.product.selectedOption} )`
                                 : ''} */}
                                </td>

                                {data?.totalTaxPercentagePrice.map(
                                  (tax: any) => (
                                    <td>
                                      {'₹ ' +
                                        `${tax.price.toFixed(2)} (` +
                                        tax.percentage +
                                        '%)'}
                                    </td>
                                  )
                                )}
                                <td>
                                  {'₹ ' + Number(data.totalPrice).toFixed(2)}
                                </td>
                                <td
                                  style={{
                                    position: 'relative',
                                  }}
                                >
                                  <div
                                    className="menu-container"
                                    onClick={() =>
                                      handleClickOnAction(index, indexOfItem)
                                    }
                                  >
                                    <span className="circle">.</span>
                                    <span className="circle">.</span>
                                    <span className="circle">.</span>
                                  </div>
                                  {data?.showEditAndDeleteOption && (
                                    <ul
                                      className="menu-options"
                                      ref={editAndDeleteRef}
                                    >
                                      <li
                                        onClick={() => {
                                          let updateOrder = [...newOrder];
                                          updateOrder[index].itemList[
                                            indexOfItem
                                          ].showEditAndDeleteOption = false;
                                          setNewOrder((prevState: any) => {
                                            return updateOrder;
                                          });
                                          editQuantityOrDeleteItem(
                                            data.quantity.pieceWise,
                                            order._id,
                                            data.product._id,
                                            'edit'
                                          );
                                        }}
                                      >
                                        Edit Quantity
                                      </li>
                                      <li
                                        onClick={() => {
                                          let updateOrder = [...newOrder];
                                          updateOrder[index].itemList[
                                            indexOfItem
                                          ].showEditAndDeleteOption = false;
                                          setNewOrder((prevState: any) => {
                                            return updateOrder;
                                          });
                                          editQuantityOrDeleteItem(
                                            data.quantity.pieceWise,
                                            order._id,
                                            data.product._id,
                                            'delete'
                                          );
                                        }}
                                      >
                                        Remove
                                      </li>
                                    </ul>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}

                        <tr className="total-price-details">
                          <td colSpan={5} className="grand-total">
                            Grand Total
                          </td>

                          {order.totalTaxAmountList.map((tax: any) => (
                            <td>{'₹ ' + `${tax.amount.toFixed(2)}`}</td>
                          ))}
                          <td>{'₹ ' + Number(order.totalAmount).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ''
                  )}
                </div>
              );
            })
          ) : (
            <p className="no-order-found"> No new orders found</p>
          )}
          {newOrder.length > 0 && (
            <div
              ref={newOrderListRefForInfiniteScrolling}
              style={{ padding: '20px' }}
            ></div>
          )}
        </ul>
      </div>
    </section>
  );
}

function NewOrderItemsForMobile({ order }: any) {
  return (
    <div className="order-items-mb">
      {order.itemList.map((data: any, indexOfItem: number) => {
        return (
          <div
            className="order-item-detail"
            style={{
              background: 'rgb(232 233 240 / 51%)',
            }}
          >
            <div className="img-n-name">
              <LazyLoadImage
                alt={data.product.name}
                className="order-item-img"
                src={
                  !data.product.image
                    ? '/images/no-image-available-icon.jpeg'
                    : data.product.image
                }
              />
              <h1
                className="order-item-name"
                style={{
                  fontSize: '13px',
                  fontWeight: '450',
                }}
              >
                {data.product.name}
              </h1>
            </div>
            <div className="flex padding-6 justify-between">
              <p>MRP</p> <p className="heading-mb">{'₹ ' + data.product.mrp}</p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Exclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' + data.pricePerUnit.netAmount.toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Price (Inclu. tax)</p>
              <p className="heading-mb">
                {'₹ ' + data.pricePerUnit.totalAmount}
              </p>
            </div>
            <div className="flex justify-between padding-6">
              <p>Quantity</p>
              <p className="heading-mb">
                {data.quantity.pieceWise}
                {/* {data.product.selectedOption !== 'piece'
                  ? ` (  ${data.product.quantity}
                      ${data.product.selectedOption} )`
                  : ''} */}
              </p>
            </div>

            {data.totalTaxPercentagePrice.map((tax: any) => (
              <div className="flex justify-between padding-6">
                <p>{tax.type}</p>
                <p className="heading-mb">
                  {'₹ ' + `${tax.price.toFixed(2)} (` + tax.percentage + '%)'}
                </p>
              </div>
            ))}

            <div className="flex justify-between padding-6">
              <p>Total Price</p>
              <p className="heading-mb"> {'₹ ' + data.totalPrice.toFixed(2)}</p>
            </div>
          </div>
        );
      })}

      <div>
        {order.totalTaxAmountList.map((tax: any) => (
          <div
            className="flex  justify-between"
            style={{
              padding: '6px',
            }}
          >
            <p className="order_tax ">{'Total ' + tax.type}</p>
            <p className="heading-mb">{'₹ ' + `${tax.amount.toFixed(2)}`}</p>
          </div>
        ))}
        <div
          className="flex  justify-between"
          style={{
            padding: '6px',
          }}
        >
          <p>Total Amount</p>
          <p className="heading-mb">
            {'₹ ' + Number(order.totalAmount).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
}
