import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLoginSelector } from '../features/auth/Slice';
import { useEffect } from 'react';

const ProtectedRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const isLogedIn = useSelector(isLoginSelector);
  const location = useLocation();

  if (!isLogedIn) {
    return navigate('/login');
  }

  return children;
};

export default ProtectedRoute;
