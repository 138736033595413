import { useSelector } from 'react-redux';
import './styles/orderBill.scss';
import { currentUserSelector } from '../../../features/auth/Slice';

export default function OrderBill({
  billRef,
  orderForGeneratingBill,
  formatDateToDDMMMYY,
}: any) {
  let order = orderForGeneratingBill;
  const currentUser: any = useSelector(currentUserSelector);

  return (
    <div
      ref={billRef}
      className="order bill-of-order"
      key={order._id}
      style={{
        padding: '20px',
      }}
    >
      <div className="seller-store-detail">
        <h1 className="seller-name">{currentUser.name}</h1>
        <p className="seller-address">
          {currentUser?.address.district +
            ' , ' +
            currentUser?.address.state +
            ' , '}
          {currentUser?.address.country +
            ' , ' +
            currentUser?.address.postalCode}
        </p>

        {/* <p>
          {currentUser.phoneNumberList?.length > 0 ? (
            <p>
              {` ${currentUser.phoneNumberList[0].phoneNumber.substring(
                0,
                3
              )}  ${currentUser.phoneNumberList[0].phoneNumber.substring(3)}`}
            </p>
          ) : (
            ''
          )}
        </p> */}

        <p className="seller-email">{currentUser.email}</p>
      </div>
      <div className="flex justify-between flex-wrap">
        <div className="cnt-of-seller-img-n-seller-info">
          <div className="buyer-name-n-location">
            {<h2> {order.buyerStore.name}</h2>}
            <p
              style={{
                justifyContent: 'start',
              }}
            >
              {order.buyerStore.address.district +
                ' , ' +
                order.buyerStore.address.state +
                ' , '}
              {order.buyerStore.address.country +
                ' , ' +
                order.buyerStore.address.postalCode}
            </p>
          </div>
        </div>

        <div className="buyer-date-n-email">
          <p style={{ justifyContent: 'center' }}>
            Invoice Date - {formatDateToDDMMMYY(order)}
          </p>
          {order.buyerStore.phoneNumberList?.length > 0 ? (
            <p>
              {` ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                0,
                3
              )}  ${order.buyerStore.phoneNumberList[0].phoneNumber.substring(
                3
              )}`}
            </p>
          ) : (
            ''
          )}
          {order.buyerStore.email ? (
            <p>
              <span>{order.buyerStore.email}</span>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      <table
        className="bill-table"
        style={{
          border: '0.4px solid black',
        }}
      >
        <thead
          style={{
            backgroundColor: 'black',
          }}
        >
          <tr
            style={{
              color: 'black',
            }}
          >
            <th
              style={{
                color: 'black',
              }}
            >
              Name
            </th>
            <th
              style={{
                color: 'black',
              }}
            >
              MRP
            </th>
            <th
              style={{
                color: 'black',
              }}
            >
              Price (Excl. tax)
            </th>
            <th
              style={{
                color: 'black',
              }}
            >
              Price (Incl. tax)
            </th>
            <th
              style={{
                color: 'black',
              }}
            >
              Quantity (In Pieces)
            </th>
            {order.totalTaxAmountList.map((tax: any) => (
              <th
                style={{
                  color: 'black',
                }}
                className="order_tax"
              >
                {tax.type}
              </th>
            ))}
            <th
              style={{
                color: 'black',
              }}
            >
              Total Price
            </th>
          </tr>
        </thead>
        <tbody>
          {order.itemList.map((data: any, indexOfItem: number) => {
            return (
              <tr key={indexOfItem}>
                <td className="order-name-n-img-table-data">
                  {/* <img
                    alt={data.product.name}
                    className="order-img"
                    src={
                      !data.product.image
                        ? '/images/no-image-available-icon.jpeg'
                        : data.product.image
                    }
                  /> */}
                  <p>{data.product.name}</p>
                </td>
                <td> {'₹ ' + data.product.mrp}</td>
                <td>{'₹ ' + data.pricePerUnit.netAmount.toFixed(2)}</td>
                <td>{'₹ ' + data.pricePerUnit.totalAmount.toFixed(2)}</td>
                <td>
                  {data.quantity.pieceWise}
                  {/* {data.product.selectedOption !== 'piece'
                                 ? ` (  ${data.product.quantity}
${data.product.selectedOption} )`
                                 : ''} */}
                </td>

                {data.totalTaxPercentagePrice.map((tax: any) => (
                  <td>
                    {'₹ ' + `${tax.price.toFixed(2)} (` + tax.percentage + '%)'}
                  </td>
                ))}
                <td>{'₹ ' + Number(data.totalPrice).toFixed(2)}</td>
              </tr>
            );
          })}

          <tr className="total-price-details">
            <td
              colSpan={5}
              className="grand-total"
              style={{
                color: 'black',
              }}
            >
              Grand Total
            </td>

            {order.totalTaxAmountList.map((tax: any) => (
              <td>{'₹ ' + `${tax.amount.toFixed(2)}`}</td>
            ))}
            <td>{'₹ ' + Number(order.totalAmount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',

          width: '100%',
        }}
      >
        Generated By Modulus Sell.
      </p>
    </div>
  );
}
