import { useContext, useEffect, useRef, useState } from 'react';
import ProductContext from '../../../context/ProductContext';
import Main from './Main';

export default function DistributionProductForm(props: any) {
  let {
    setConfiguredProducts,
    setPageNumberForConfiguredProductList,
    setShowForm,
    brandId,
    option,
    setOption,
    selectedProducts,
    setSelectedProducts,
    setPageForNewProduct,
    setProductForProductListPopup,
    configuredProducts,
    setHasMoreProductInConfiguredListOrNot,
  } = props;
  const [optionAccToInfoObj, setOptionAccToInfoObj] = useState<any>([]);
  const [activeOption, setActiveOption] = useState('');
  const [commonError, setCommonError] = useState('');
  let { productState, setProductState, initialProductState } =
    useContext(ProductContext);
  const distributionFormRef = useRef<any>(null);

  useEffect(() => {
    return setActiveOption(option[option.length - 1]);
  }, [option]);

  const setProductStateToInitialState = (value: string) => {
    const newProductState = { ...productState };
    const optionTypes = ['piece', 'box', 'bundle'];

    if (optionTypes.includes(value)) {
      for (const option of optionTypes) {
        newProductState.minimumQuantity[option] = {
          quantity: '',
          isMultiple: { enable: false, number: '' },
        };

        newProductState.error.minimumQuantity[option] = {
          quantity: '',
          isMultiple: { enable: '', number: '' },
        };

        newProductState.pricePerUnit[option] = {
          unique: true,
          price: '',
          quantityWisePriceList: [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ],
        };

        newProductState.error.pricePerUnit[option] = {
          unique: '',
          price: '',
          quantityWisePriceList: [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ],
        };

        // Create an empty array and error object for the offer list of the current option
        newProductState.offer[option + 'OfferList'] = [];
        newProductState.error.offer[option + 'OfferList'] = [
          { quantity: '', free: { quantity: '', type: '' } },
        ];
      }
    }

    setProductState(newProductState);
  };

  const handleOptionChange = (event: any) => {
    const value = event.target.value;

    if (option.includes(value)) {
      setOption((prevOptions: any) => {
        return prevOptions.filter((type: any) => type !== value);
      });
      setProductStateToInitialState(value);
    } else {
      if (value === 'piece') {
        setOption((prevOptions: any) => {
          prevOptions.splice(0, 0, value);
          return [...prevOptions];
        });
      } else if (value === 'bundle') {
        setOption((prevOptions: any) => {
          prevOptions.splice(1, 0, value);
          return [...prevOptions];
        });
      } else if (value === 'box') {
        setOption((prevOptions: any) => {
          prevOptions.splice(2, 0, value);
          return [...prevOptions];
        });
      }

      setActiveOption(value);
    }
  };

  const createLastQuantityWisePricePerObj = (
    moreThanOrEqual: any,
    lessThanOrEqual: any,
    price: any,
    index: number,
    type: string
  ) => {
    if (activeOption === type) {
      if (moreThanOrEqual && lessThanOrEqual && price) {
        const newProductState = { ...productState };
        if (
          index ===
          newProductState.pricePerUnit[type].quantityWisePriceList.length - 1
        ) {
          const obj = {
            moreThanOrEqual: lessThanOrEqual + 1,
            lessThanOrEqual: Number.MAX_VALUE,
            price: '',
          };
          const error = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
          newProductState.pricePerUnit[type].quantityWisePriceList.push(obj);
          newProductState.error.pricePerUnit[type].quantityWisePriceList.push(
            error
          );
          setProductState(newProductState);
        }
      }
    }
  };

  function hasUpToThreeDecimals(number: any) {
    const decimalPart = (number.toString().split('.')[1] || '').length;
    return decimalPart <= 3;
  }

  const handleChange = (event: any, index: number) => {
    let { name, value } = event.target;
    let newProductState = { ...productState };

    switch (name) {
      case 'pieceMinimumQuantity':
        newProductState.minimumQuantity.piece.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.piece.quantity = '';
        }
        break;
      case 'pieceIsMultiple':
        newProductState.minimumQuantity.piece.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.piece.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.piece.isMultiple.number = '';
          newProductState.minimumQuantity.piece.isMultiple.number = '';
        }

        break;
      case 'pieceIncDecQuantity':
        newProductState.minimumQuantity.piece.isMultiple.number = parseInt(
          value
        )
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.piece.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.piece.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'pieceIsUnique':
        newProductState.pricePerUnit.piece.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.piece.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.piece.price = '';
          newProductState.pricePerUnit.piece.price = '';
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
        } else {
          newProductState.pricePerUnit.piece.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.piece.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'piecePerUnitPrice':
        newProductState.pricePerUnit.piece.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.piece.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a value';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a value upto three decimal only';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a positive integer';
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a price less than or equal to the MRP';
        } else {
          newProductState.error.pricePerUnit.piece.price = '';
        }
        break;

      case 'pieceLessThanOrEqual':
        newProductState.pricePerUnit.piece.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.piece.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.piece.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        } else {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: '',
          };
        }

        break;
      case 'quantityWisePriceListPerPiece':
        newProductState.pricePerUnit.piece.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.piece.quantityWisePriceList[index],
          price: value || value == '0' ? value : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: 'Price required',
          };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: 'Please provide a value upto three decimal only',
          };
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: 'Please provide a price less than or equal to the MRP',
          };
        } else {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: '',
          };
        }

        break;

      case 'offerOnWhatQuantityOfPiece':
        newProductState.offer.pieceOfferList[index] = {
          ...newProductState.offer.pieceOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.pieceOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.pieceOfferList[index].quantity =
            'This field is required.';
        }

        break;

      case 'freeQuantityPiece':
        newProductState.offer.pieceOfferList[index] = {
          ...newProductState.offer.pieceOfferList[index],
          free: {
            ...newProductState.offer.pieceOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.pieceOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.pieceOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductState(newProductState);
        break;

      // box

      case 'boxMinimumQuantity':
        newProductState.minimumQuantity.box.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.box.quantity = '';
        }
        break;
      case 'boxIsMultiple':
        newProductState.minimumQuantity.box.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.box.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.box.isMultiple.number = '';
          newProductState.minimumQuantity.box.isMultiple.number = '';
        }

        break;
      case 'boxIncDecQuantity':
        newProductState.minimumQuantity.box.isMultiple.number = parseInt(value)
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.box.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.box.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'boxIsUnique':
        newProductState.pricePerUnit.box.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.box.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.box.price = '';
          newProductState.pricePerUnit.box.price = '';
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              moreThanOrEqual: '',
              lessThanOrEqual: '',
              price: '',
            };
        } else {
          newProductState.pricePerUnit.box.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.box.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'boxPerUnitPrice':
        newProductState.pricePerUnit.box.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.box.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a value';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a value upto three decimal only';
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a price less than or equal to the MRP';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a positive integer';
        } else {
          newProductState.error.pricePerUnit.box.price = '';
        }

        break;

      case 'boxLessThanOrEqual':
        newProductState.pricePerUnit.box.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.box.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: 'Please provide more than from value',
            };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.box.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: 'Please provide more than from value',
            };
        } else {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: '',
            };
        }

        break;
      case 'quantityWisePriceListPerBox':
        newProductState.pricePerUnit.box.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.box.quantityWisePriceList[index],
          price: value || value == '0' ? value : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Price required',
            };
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Please provide a price less than or equal to the MRP',
            };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Please provide a value upto three decimal only',
            };
        } else {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: '',
            };
        }

        break;
      case 'offerOnWhatQuantityOfBox':
        newProductState.offer.boxOfferList[index] = {
          ...newProductState.offer.boxOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.boxOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.boxOfferList[index].quantity =
            'This field is required.';
        }

        break;

      case 'freeQuantityBox':
        newProductState.offer.boxOfferList[index] = {
          ...newProductState.offer.boxOfferList[index],
          free: {
            ...newProductState.offer.boxOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.boxOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.boxOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductState(newProductState);
        break;

      // bundle

      case 'bundleMinimumQuantity':
        newProductState.minimumQuantity.bundle.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.bundle.quantity = '';
        }
        break;
      case 'bundleIsMultiple':
        newProductState.minimumQuantity.bundle.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.bundle.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.bundle.isMultiple.number = '';
          newProductState.minimumQuantity.bundle.isMultiple.number = '';
        }

        break;
      case 'bundleIncDecQuantity':
        newProductState.minimumQuantity.bundle.isMultiple.number = parseInt(
          value
        )
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.bundle.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.bundle.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'bundleIsUnique':
        newProductState.pricePerUnit.bundle.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.bundle.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.bundle.price = '';
          newProductState.pricePerUnit.bundle.price = '';
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
        } else {
          newProductState.pricePerUnit.bundle.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'bundlePerUnitPrice':
        newProductState.pricePerUnit.bundle.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.bundle.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a value';
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a price less than or equal to the MRP';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a value upto three decimal only';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a positive integer';
        } else {
          newProductState.error.pricePerUnit.bundle.price = '';
        }

        break;

      case 'bundleLessThanOrEqual':
        newProductState.pricePerUnit.bundle.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.bundle.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.bundle.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        } else {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: '',
          };
        }

        break;
      case 'quantityWisePriceListPerBundle':
        newProductState.pricePerUnit.bundle.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.bundle.quantityWisePriceList[index],
          price: parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: 'Price required',
          };
        } else if (value > selectedProducts.mrp) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Please provide a price less than or equal to the MRP',
            };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: 'Please provide a value upto three decimal only',
          };
        } else {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: '',
          };
        }

        break;
      case 'offerOnWhatQuantityOfBundle':
        newProductState.offer.bundleOfferList[index] = {
          ...newProductState.offer.bundleOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.bundleOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.bundleOfferList[index].quantity =
            'This field is required.';
        }

        break;
      case 'freeQuantityBundle':
        newProductState.offer.bundleOfferList[index] = {
          ...newProductState.offer.bundleOfferList[index],
          free: {
            ...newProductState.offer.bundleOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.bundleOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.bundleOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductState(newProductState);
        break;
    }
    if (commonError) {
      setCommonError('');
    }
    setProductState(newProductState);
  };

  const handleAddMoreRange = (option: any) => {
    let newProductState = { ...productState };
    if (option == 'piece') {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.piece.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1,
          'piece'
        );

        setProductState(newProductState);
      }
    } else if (option == 'box') {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.box.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1,
          'box'
        );

        setProductState(newProductState);
      }
    } else {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.bundle.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1,
          'bundle'
        );
      }
    }

    setProductState(newProductState);
  };

  const closeThePopUp = () => {
    setActiveOption('');
    setOption([]);
    setSelectedProducts([]);
    setShowForm(false);
    setProductState(initialProductState);
    setPageForNewProduct(() => 1);
    setProductForProductListPopup(() => []);
  };

  const handleClickOutsideForDistributionForm = (event: any) => {
    if (
      distributionFormRef.current &&
      !distributionFormRef.current.contains(event.target)
    ) {
      setActiveOption('');
      setOption([]);
      setSelectedProducts([]);
      setShowForm(false);
      setProductState(initialProductState);
      setPageForNewProduct(() => 1);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideForDistributionForm
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForDistributionForm
      );
    };
  }, []);

  useEffect(() => {
    if (selectedProducts?.info) {
      let { pieceInEachBox, pieceInEachBundle } = selectedProducts?.info;

      if (pieceInEachBox) {
        setOptionAccToInfoObj(['piece', 'box']);
      }
      if (pieceInEachBundle) {
        setOptionAccToInfoObj(['piece', 'bundle']);
      }
      if (pieceInEachBox && pieceInEachBundle) {
        setOptionAccToInfoObj(['piece', 'bundle', 'box']);
      }
    } else {
      setOptionAccToInfoObj(['piece']);
    }
  }, []);

  return (
    <div
      className="sell-modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
      }}
      ref={distributionFormRef}
    >
      <div
        className="sell-modal-content"
        style={{ height: activeOption ? '80vh' : 'auto' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '1rem',
          }}
        >
          <h1
            style={{
              textAlign: 'center',
              color: '#14539a',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {selectedProducts.name}
          </h1>
          <p
            style={{
              color: '#737272',
            }}
          >
            MRP - {' ₹' + selectedProducts.mrp}
          </p>

          {selectedProducts.weight && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Weight -
              {'  ' + selectedProducts.weight
                ? selectedProducts.weight?.quantity +
                  ' ' +
                  selectedProducts.weight?.unit
                : '-.-'}
            </p>
          )}

          {selectedProducts.size && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Size -
              {' ' + selectedProducts.size
                ? selectedProducts.size?.quantity +
                  ' ' +
                  selectedProducts.size?.unit
                : '-.-'}
            </p>
          )}
          {selectedProducts.color && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Color -
              {selectedProducts?.color?.name
                ? selectedProducts?.color?.name
                : '-.-'}
            </p>
          )}
          {selectedProducts.flavor && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Flavor -
              {selectedProducts?.flavor?.name
                ? selectedProducts?.flavor?.name
                : '-.-'}
            </p>
          )}
          {selectedProducts?.info?.pieceInEachBundle && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Pieces in each bundle -{selectedProducts?.info?.pieceInEachBundle}
            </p>
          )}
          {selectedProducts?.info?.pieceInEachBox && (
            <p
              style={{
                color: '#737272',
              }}
            >
              Pieces in each box - {selectedProducts?.info?.pieceInEachBox}
            </p>
          )}
        </div>

        <div className="back-n-h1-cnt">
          <h2>Choose the selling option from given below:</h2>
          <button
            className="btn-primary"
            style={{
              background: 'white',
              width: 'auto',
              height: 'auto',
              margin: '0px',
              fontSize: '30px',
              position: 'absolute',
              top: '0',
              right: '10px',
            }}
            onClick={closeThePopUp}
          >
            <span style={{ color: 'red' }}>&times;</span>
          </button>
        </div>

        <div className="sell-modal-form">
          <div className="checkbox-group">
            {optionAccToInfoObj.includes('piece') && (
              <label>
                <input
                  type="checkbox"
                  value="piece"
                  checked={option.includes('piece')}
                  onChange={handleOptionChange}
                />
                Piece
              </label>
            )}

            {optionAccToInfoObj.includes('bundle') && (
              <label>
                <input
                  type="checkbox"
                  value="bundle"
                  checked={option.includes('bundle')}
                  onChange={handleOptionChange}
                />
                Bundle
              </label>
            )}

            {optionAccToInfoObj.includes('box') && (
              <label>
                <input
                  type="checkbox"
                  value="box"
                  checked={option.includes('box')}
                  onChange={handleOptionChange}
                />
                Box
              </label>
            )}
          </div>
        </div>

        {/* In future add this one */}

        {/* <div className="mark-it-new-checkbox-cnt">
          <input
            type="checkbox"
            value="box"
            name="markItNew"
            id={'markItNew'}
          />
          <label htmlFor="markItNew" className="mark-it-all-new">
            Mark All The Selected Products As{' '}
            <MdFiberNew className="new-icon" />
          </label>
        </div> */}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {option.map((option: any) => {
            return (
              <button
                key={option}
                onClick={() => setActiveOption(option)}
                className={
                  activeOption == option ? 'option-active' : 'option-disabled'
                }
                type="button"
              >
                {option}
              </button>
            );
          })}
        </div>

        {option.length > 0 ? (
          <div className="slide-down">
            <Main
              setPageNumberForConfiguredProductList={
                setPageNumberForConfiguredProductList
              }
              setHasMoreProductInConfiguredListOrNot={
                setHasMoreProductInConfiguredListOrNot
              }
              configuredProducts={configuredProducts}
              closeThePopUp={closeThePopUp}
              commonError={commonError}
              setCommonError={setCommonError}
              setConfiguredProducts={setConfiguredProducts}
              setShowForm={setShowForm}
              brandId={brandId}
              setProductState={setProductState}
              activeOption={activeOption}
              option={option}
              handleAddMoreRange={handleAddMoreRange}
              handleChange={handleChange}
              productState={productState}
              selectedProducts={selectedProducts}
              createLastQuantityWisePricePerObj={
                createLastQuantityWisePricePerObj
              }
            />
          </div>
        ) : (
          ''
        )}

        <hr />
      </div>
    </div>
  );
}
