import './style/productList.scss';
import './style/productInfo.scss';
import { useState } from 'react';
import EditSellerProductDetailForm from './editSellerProductDetailForm/EditSellerProductDetailForm';

export default function ProductTable(props: any) {
  let {
    setShowDeleteDialogueBox,
    setProductIdToBeDeleted,
    configuredProduct,
    setConfiguredProducts,
    brandId,
    setPageNumberForConfiguredProductList,
    setHasMoreProductInConfiguredListOrNot,
  } = props;

  const [showEditForm, setShowEditForm] = useState<Boolean>(false);

  const handleClickOnEdit = (type: any) => {
    setShowEditForm(true);
  };

  const setWidthPercentage = () => {
    let { minimumQuantity } = configuredProduct.sellerProductDetail;
    if (
      (minimumQuantity?.piece?.quantity &&
        minimumQuantity?.bundle?.quantity &&
        minimumQuantity?.box?.quantity) ||
      (minimumQuantity?.piece?.quantity && minimumQuantity?.bundle?.quantity) ||
      (minimumQuantity?.bundle?.quantity && minimumQuantity?.box?.quantity)
    ) {
      return '33%';
    } else if (
      minimumQuantity?.bundle?.quantity &&
      !minimumQuantity?.piece?.quantity &&
      !minimumQuantity?.box?.quantity
    ) {
      return '50%';
    } else if (
      minimumQuantity?.piece?.quantity &&
      !minimumQuantity?.bundle?.quantity &&
      !minimumQuantity?.box?.quantity
    ) {
      return '50%';
    } else if (
      minimumQuantity?.box?.quantity &&
      !minimumQuantity?.piece?.quantity &&
      !minimumQuantity?.bundle?.quantity
    ) {
      return '50%';
    }
  };

  return (
    <div
      className="box-cnt product-info-cnt"
      style={{
        justifyContent: 'center',
        position: showEditForm ? 'static' : 'relative',
      }}
    >
      {showEditForm && (
        <div className="edit-form">
          <EditSellerProductDetailForm
            setPageNumberForConfiguredProductList={
              setPageNumberForConfiguredProductList
            }
            setHasMoreProductInConfiguredListOrNot={
              setHasMoreProductInConfiguredListOrNot
            }
            setConfiguredProducts={setConfiguredProducts}
            brandId={brandId}
            setShowEditForm={setShowEditForm}
            configuredProduct={configuredProduct}
          />
        </div>
      )}

      <div className="edit-delete-cnt">
        <p className="edit" onClick={() => handleClickOnEdit('piece')}>
          Edit Product
        </p>

        <p
          className="delete"
          onClick={async () => {
            setProductIdToBeDeleted(configuredProduct._id);
            setShowDeleteDialogueBox(true);
          }}
        >
          Delete Product
        </p>
      </div>
      {configuredProduct.sellerProductDetail?.minimumQuantity?.piece
        ?.quantity ? (
        <div className="box" style={{ flexBasis: setWidthPercentage() }}>
          <div className="header">
            <h1>
              <span>#</span>
              Piece
            </h1>
          </div>
          <div className="subtopic">
            {configuredProduct.sellerProductDetail.pricePerUnit.piece
              ?.unique ? (
              <h2>
                <span className="subheading">Price Per Unit -</span>
                <p>
                  Price:
                  {'  ' +
                    configuredProduct.sellerProductDetail.pricePerUnit.piece
                      ?.price}
                  (each piece)
                </p>
              </h2>
            ) : (
              <div className="price-range">
                <h3>Price According to Range of Quantity</h3>
                <table>
                  <tr>
                    <th>Quantity Range</th>
                    <th>Price</th>
                  </tr>
                  {configuredProduct.sellerProductDetail.pricePerUnit.piece
                    .quantityWisePriceList.length > 0 &&
                    configuredProduct.sellerProductDetail.pricePerUnit.piece.quantityWisePriceList.map(
                      (data: any) => {
                        return (
                          <tr>
                            <td className="range">
                              {data.lessThanOrEqual === Number.MAX_VALUE &&
                                `After ${data.moreThanOrEqual} pieces`}

                              {data.lessThanOrEqual !== Number.MAX_VALUE &&
                                data.moreThanOrEqual +
                                  ' - ' +
                                  data.lessThanOrEqual +
                                  ' pieces'}
                            </td>
                            <td className="price">{'₹' + data.price}</td>
                          </tr>
                        );
                      }
                    )}
                </table>
              </div>
            )}
          </div>
          <div className="subtopic">
            <span className="subheading"> Minimum Quantity -</span>

            <p>
              <span className="quantity">
                {
                  configuredProduct.sellerProductDetail.minimumQuantity.piece
                    ?.quantity
                }{' '}
                piece is the min. quantity and
              </span>
            </p>
            <p>
              {!configuredProduct.sellerProductDetail.minimumQuantity.piece
                ?.isMultiple.enable
                ? 'Customer will be able to increase/decrease quantity only one by one'
                : `Customer will be able to increase/decrease quantity by ${configuredProduct.sellerProductDetail.minimumQuantity.piece?.isMultiple.number}`}
            </p>
          </div>
          {configuredProduct.sellerProductDetail.offer?.pieceOfferList.length >
            0 && (
            <div className="subtopic">
              <span className="subheading"> Offer - </span>
              {configuredProduct.sellerProductDetail.offer?.pieceOfferList
                .length > 0 &&
                configuredProduct.sellerProductDetail.offer?.pieceOfferList.map(
                  (obj: any) => {
                    return (
                      <p>
                        Buy {obj.quantity} piece, Get {obj.free.quantity} Free{' '}
                        {obj.free.type}
                      </p>
                    );
                  }
                )}
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      {configuredProduct.sellerProductDetail?.minimumQuantity?.bundle
        ?.quantity && (
        <div className="box" style={{ flexBasis: setWidthPercentage() }}>
          <div className="header">
            <h1>
              <span>#</span>
              Bundle
            </h1>
          </div>
          <div className="subtopic">
            {configuredProduct.sellerProductDetail.pricePerUnit.bundle
              ?.unique ? (
              <h2>
                <span className="subheading">Price Per Unit -</span>
                <p>
                  Price:
                  {'  ' +
                    configuredProduct.sellerProductDetail.pricePerUnit.bundle
                      ?.price}
                  (each bundle)
                </p>
              </h2>
            ) : (
              <div className="price-range">
                <h3>Price According to Range of Quantity</h3>
                <table>
                  <tr>
                    <th>Quantity Range</th>
                    <th>Price</th>
                  </tr>
                  {configuredProduct.sellerProductDetail.pricePerUnit.bundle
                    .quantityWisePriceList.length > 0 &&
                    configuredProduct.sellerProductDetail.pricePerUnit.bundle.quantityWisePriceList.map(
                      (data: any) => {
                        return (
                          <tr>
                            <td className="range">
                              {data.lessThanOrEqual === Number.MAX_VALUE &&
                                `After ${data.moreThanOrEqual} bundles`}

                              {data.lessThanOrEqual !== Number.MAX_VALUE &&
                                data.moreThanOrEqual +
                                  ' - ' +
                                  data.lessThanOrEqual +
                                  ' bundles'}
                            </td>
                            <td className="range">{'₹' + data.price}</td>
                          </tr>
                        );
                      }
                    )}
                </table>
              </div>
            )}
          </div>
          <div className="subtopic">
            <div style={{ width: '100%' }}>
              <span className="subheading"> Minimum Quantity -</span>

              <p>
                <span className="quantity">
                  {
                    configuredProduct.sellerProductDetail.minimumQuantity.bundle
                      ?.quantity
                  }{' '}
                  bundle is the min. quantity and
                </span>
              </p>
              <p>
                {!configuredProduct.sellerProductDetail.minimumQuantity.bundle
                  ?.isMultiple.enable
                  ? 'Customer will be able to increase/decrease quantity only one by one'
                  : `Customer will be able to increase/decrease quantity by ${configuredProduct.sellerProductDetail.minimumQuantity.bundle?.isMultiple.number}`}
              </p>
            </div>
          </div>
          {configuredProduct.sellerProductDetail.offer?.bundleOfferList.length >
            0 && (
            <div className="subtopic">
              {<span className="subheading"> Offer -</span>}
              {configuredProduct.sellerProductDetail.offer?.bundleOfferList
                .length > 0 &&
                configuredProduct.sellerProductDetail.offer?.bundleOfferList.map(
                  (obj: any) => {
                    return (
                      <p>
                        Buy {obj.quantity} bundle, Get {obj.free.quantity} Free{' '}
                        {obj.free.type}
                      </p>
                    );
                  }
                )}
            </div>
          )}
        </div>
      )}

      {configuredProduct.sellerProductDetail?.minimumQuantity?.box
        ?.quantity && (
        <div className="box" style={{ flexBasis: setWidthPercentage() }}>
          <div className="header">
            <h1>
              <span>#</span>
              Box
            </h1>
          </div>
          <div className="subtopic">
            {configuredProduct.sellerProductDetail.pricePerUnit.box?.unique ? (
              <h2>
                <span className="subheading">Price Per Unit -</span>
                <p>
                  Price:
                  {'  ' +
                    configuredProduct.sellerProductDetail.pricePerUnit.box
                      ?.price}
                  (each box)
                </p>
              </h2>
            ) : (
              <div className="price-range">
                <h3>Price According to Range of Quantity</h3>
                <table>
                  <tr>
                    <th>Quantity Range</th>
                    <th>Price</th>
                  </tr>
                  {configuredProduct.sellerProductDetail.pricePerUnit.box
                    .quantityWisePriceList.length > 0 &&
                    configuredProduct.sellerProductDetail.pricePerUnit.box.quantityWisePriceList.map(
                      (data: any) => {
                        return (
                          <tr>
                            <td className="range">
                              {data.lessThanOrEqual === Number.MAX_VALUE &&
                                `After ${data.moreThanOrEqual} boxes`}

                              {data.lessThanOrEqual !== Number.MAX_VALUE &&
                                data.moreThanOrEqual +
                                  ' - ' +
                                  data.lessThanOrEqual +
                                  ' boxes'}
                            </td>
                            <td className="range">{'₹' + data.price}</td>
                          </tr>
                        );
                      }
                    )}
                </table>
              </div>
            )}
          </div>
          <div className="subtopic">
            <span className="subheading"> Minimum Quantity -</span>

            <p>
              <span className="quantity">
                {
                  configuredProduct.sellerProductDetail.minimumQuantity.box
                    ?.quantity
                }{' '}
                box is the min. quantity and
              </span>
            </p>
            <p>
              {!configuredProduct.sellerProductDetail.minimumQuantity.box
                ?.isMultiple.enable
                ? 'Customer will be able to increase/decrease quantity only one by one'
                : `Customer will be able to increase/decrease quantity by ${configuredProduct.sellerProductDetail.minimumQuantity.box?.isMultiple.number}`}
            </p>
          </div>

          {/* {configuredProduct.sellerProductDetail.offer?.boxOfferList.length >
            0 && (
            <div className="subtopic">
              <span className="subheading"> Offer -</span>
              {configuredProduct.sellerProductDetail.offer?.boxOfferList
                .length > 0 &&
                configuredProduct.sellerProductDetail.offer?.boxOfferList.map(
                  (obj: any) => {
                    return (
                      <p>
                        Buy {obj.quantity} box, Get {obj.free.quantity} Free{' '}
                        {obj.free.type}
                      </p>
                    );
                  }
                )}
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}
