import { useState } from 'react';
import ProductContext from './ProductContext';

function ProductState(props: any) {
  const initialProductState = {
    productId: '',
    minimumQuantity: {
      piece: {
        quantity: '',
        isMultiple: {
          enable: false,
          number: '',
        },
      },
      bundle: {
        quantity: '',
        isMultiple: {
          enable: false,
          number: '',
        },
      },
      box: {
        quantity: '',
        isMultiple: {
          enable: false,
          number: '',
        },
      },
    },
    offer: {
      pieceOfferList: [],
      bundleOfferList: [],
      boxOfferList: [],
    },
    pricePerUnit: {
      piece: {
        unique: true,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      },
      bundle: {
        unique: true,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      },
      box: {
        unique: true,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      },
    },
    error: {
      pricePerUnit: {
        piece: {
          unique: '',
          price: '',
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: '',
              lessThanOrEqual: '',
            },
          ],
        },
        bundle: {
          unique: '',
          price: '',
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: '',
              lessThanOrEqual: '',
            },
          ],
        },
        box: {
          price: '',
          unique: '',
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: '',
              lessThanOrEqual: '',
            },
          ],
        },
      },
      minimumQuantity: {
        piece: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
        bundle: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
        box: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
      },
      offer: {
        pieceOfferList: [
          {
            quantity: '',
            free: {
              quantity: '',
              type: '',
            },
          },
        ],
        bundleOfferList: [
          {
            quantity: '',

            free: {
              quantity: '',
              type: '',
            },
          },
        ],
        boxOfferList: [
          {
            quantity: '',
            free: {
              quantity: '',
              type: '',
            },
          },
        ],
      },
    },
  };
  const [productState, updateProductState] = useState<any>(initialProductState);

  const setProductState = (newProductState: any) => {
    updateProductState(newProductState);
  };

  return (
    <ProductContext.Provider
      value={{ productState, setProductState, initialProductState }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}

export default ProductState;
