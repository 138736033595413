import { useState } from 'react';
import { AiTwotoneShop } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { PatchRequest } from '../../../component/Request';

export default function DeclineOrderPopUp({
  buyerStore,
  setShowDeclineOrderForm,
  setOrderIdToBeDeclined,
  orderIdToBeDeclined,
  setOrderToBeDeclined,
  orderToBeDeclined,
  fetchNewOrderCount,
  fetchNotAssignedOrder,
  fetchNewOrder,
  setAllOrder,
  allOrder,
}: any) {
  let [declinedMessage, setDeclinedMessage] = useState('');
  let [showTextBox, setShowTextBox] = useState(false);
  let [textAreaMessage, setTextAreaMessage] = useState('');
  let [err, setErr] = useState('');

  const handlChange = (event: any) => {
    if (event.target.value) {
      setErr('');
      setTextAreaMessage(event.target.value);
    } else {
      setErr('Required');
      setTextAreaMessage(event.target.value);
    }
  };

  const handleSubmit = async (orderId: string) => {
    if (declinedMessage === 'others') {
      if (textAreaMessage) {
        await PatchRequest(`order/${orderId}`, {
          message:
            declinedMessage == 'others' ? textAreaMessage : declinedMessage,
          status: 'Declined',
        })
          .then(async (res) => {
            if (res.data.statusCode == 200) {
              if (fetchNewOrder) {
                await fetchNewOrderCount();
                await fetchNotAssignedOrder();
                await setOrderIdToBeDeclined(() => '');
              } else {
                let updatedOrder = [...allOrder];
                updatedOrder = updatedOrder.filter((data: any) => {
                  if (data._id !== orderId) {
                    return data;
                  }
                });
                setAllOrder((prevState: any) => {
                  return updatedOrder;
                });
                await setOrderToBeDeclined(() => '');
              }
              setShowDeclineOrderForm(false);
            }
          })
          .catch((err) => {
            console.log(err, ' err in accepting the order');
          });
      } else {
        setErr('Required');
      }
    } else if (declinedMessage && declinedMessage !== 'others') {
      await PatchRequest(`order/${orderId}`, {
        message:
          declinedMessage == 'others' ? textAreaMessage : declinedMessage,
        status: 'Declined',
      })
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (fetchNewOrder) {
              await fetchNotAssignedOrder();
              await setOrderIdToBeDeclined(() => '');
            } else {
              let updatedOrder = [...allOrder];
              updatedOrder = updatedOrder.filter((data: any) => {
                if (data._id !== orderId) {
                  return data;
                }
              });
              setAllOrder((prevState: any) => {
                return updatedOrder;
              });
              await setOrderToBeDeclined(() => '');
            }
            setShowDeclineOrderForm(false);
          }
        })
        .catch((err) => {
          console.log(err, ' err in accepting the order');
        });
    } else {
      setErr('Required');
    }
  };

  return (
    <section className="new-order-cnt " style={{ overflow: 'hidden' }}>
      <div
        className="new-order-box decline-order-box"
        // ref={routeContainerRef}
      >
        <div
          className="cross-icon-cnt"
          style={{ justifyContent: 'space-between' }}
        >
          <h1 className="heading-of-seller-route">
            Please inform your customer that why are you declining the order :-
          </h1>
          <button className="cross-icon">
            <RxCross1
              onClick={() => {
                setDeclinedMessage('');
                setShowTextBox(false);
                setErr('');
                setTextAreaMessage('');
                setShowDeclineOrderForm(false);
              }}
            />
          </button>
        </div>

        <div className="route-dropdown" style={{ overflow: 'hidden' }}>
          <div className="buyer-info">
            {!buyerStore.profileImage ? (
              <AiTwotoneShop className="buyer-img" />
            ) : (
              <img
                className="buyer-img"
                src={buyerStore.profileImage}
                alt="buyer-store"
              />
            )}
            <h2 className="buyer-name">{buyerStore.name}</h2>
          </div>

          <form action="#" style={{ width: '100%' }}>
            <label htmlFor="#" className="label">
              Select a reason
            </label>
            <div className="custom-dropdown">
              <div className="selected-option">
                {declinedMessage ? declinedMessage : 'Choose one'}
              </div>
              <div className="options">
                <div
                  className="option"
                  onClick={() => {
                    setErr('');
                    setDeclinedMessage("You don't distribute in his area");
                  }}
                >
                  You don't distribute in his area
                </div>
                <div
                  className="option"
                  onClick={() => {
                    setDeclinedMessage('others');
                    setShowTextBox(true);
                  }}
                >
                  Others
                </div>
              </div>
            </div>

            {declinedMessage == 'others' && (
              <>
                <textarea
                  onChange={handlChange}
                  name="Message"
                  className="text-area"
                  placeholder="send message to buyer"
                ></textarea>
              </>
            )}
            <span className="error">{err}</span>
          </form>
        </div>

        {/* <span className="error">{errorForRoute && errorForRoute}</span> */}

        <div className="seller-route-button-container">
          <button
            type="submit"
            onClick={() =>
              handleSubmit(
                fetchNewOrder ? orderIdToBeDeclined : orderToBeDeclined._id
              )
            }
            className="submit-button"
          >
            Submit
          </button>
        </div>
      </div>
    </section>
  );
}
