import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IProduct } from './types';
import { AsyncThunkConfig } from '../../interface';
import { GetRequest } from '../../component/Request';

type ICart = {};
type IProductSlice = {
  productList: IProduct[];
  cartList: IProduct[];
  currentStoreId: string;
};

const initialState: IProductSlice = {
  productList: [],
  cartList: [],
  currentStoreId: '',
};

export const fetchProductWhenUpdateedAsyncThunk = createAsyncThunk<
  IProduct,
  Record<string, any>,
  AsyncThunkConfig
>(
  'product/updateProductList',
  async (value: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await GetRequest(
        `product/${value.productId}?seller-product-detail=true&pending-order-detail=true&seller-store=${value.storeId}&'brand-detail=true`
      );
      return response.data.data as IProduct;
    } catch (error: any) {
      return rejectWithValue(error.data.data);
    }
  }
);
export const fetchProductListAsyncThunk = createAsyncThunk<
  IProduct[],
  Record<string, any>,
  AsyncThunkConfig
>('product/fetch', async (value: Record<string, any>, { rejectWithValue }) => {
  try {
    const searchparms = new URLSearchParams(value);
    const response = await GetRequest('product?' + searchparms);
    return response.data.data as IProduct[];
  } catch (error: any) {
    return rejectWithValue(error.data.data);
  }
});
export const fetchCartListAsyncThunk = createAsyncThunk<
  IProduct[],
  string,
  AsyncThunkConfig
>('product/cart', async (value: string, { rejectWithValue }) => {
  try {
    const response = await GetRequest(
      `order?seller-store=${value}&status=Pending&order-detail=true&seller-product-detail=true`
    );

    return response.data.data[0]?.item;
  } catch (error: any) {
    return rejectWithValue(error.data.data);
  }
});
export const cartSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addCurrentStoreId: (state, { payload }: PayloadAction<string>) => {
      state.currentStoreId = payload;
    },
    updateCartList: (state, { payload }: any) => {
      // todo complite this before 3:30 1/11/2023
      const indexOfProduct = state.productList.findIndex(
        (value) => value._id === payload.productID
      );
      state.productList[indexOfProduct] = payload.product;
    },
    removeItemFromCart: (state, { payload }) => {
      state.cartList = state.cartList.filter(
        (item: any) => item?.product?._id !== payload
      );
    },
    addProductToIndexZero: (state, { payload }) => {
      const indexOfProduct = state.productList.findIndex(
        (value) => value._id === payload._id
      );
      state.productList[indexOfProduct] = state.productList[0];
      state.productList[0] = payload;
    },
    addProductsToList: (state, { payload }) => {
      state.productList = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductListAsyncThunk.pending, (state) => {
        // console.log("loading");
      })
      .addCase(
        fetchProductListAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IProduct[]>) => {
          state.productList = payload;
        }
      )
      .addCase(fetchCartListAsyncThunk.pending, (state) => {
        // console.log("loading");
      })
      .addCase(
        fetchCartListAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.cartList = payload;
        }
      )
      .addCase(fetchProductWhenUpdateedAsyncThunk.pending, (state) => {
        // console.log("loading");
      })
      .addCase(
        fetchProductWhenUpdateedAsyncThunk.fulfilled,
        (state, { payload }: PayloadAction<IProduct>) => {
          const indexOfProduct = state.productList.findIndex(
            (value) => value._id === payload._id
          );
          console.log(indexOfProduct);
          state.productList[indexOfProduct] = payload;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  addCurrentStoreId,
  updateCartList,
  removeItemFromCart,
  addProductToIndexZero,
  addProductsToList,
} = cartSlice.actions;
export default cartSlice.reducer;

// export const currentStoreIdSelecter = (state: RootState) =>
//   state.product.currentStoreId;
// export const productListSelecter = (state: RootState) =>
//   state.product.productList;
// export const cartListSelecter = (state: RootState) => state.product.cartList;
