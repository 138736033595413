import React, { useEffect, useRef, useState, useMemo } from 'react';
import 'react-phone-number-input/style.css';
import { Link, useNavigate } from 'react-router-dom';
import './login.scss';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { PostRequest } from '../../../component/Request';
import { AppDispatch, store } from '../../../store';
import {
  fetchUserStoreListAsyncThunk,
  isLoginSelector,
  loginAsyncThunk,
  storeListFetchedSelector,
  storeListSelector,
  switchProfileAsyncThunk,
} from '../../../features/auth/Slice';
import { currentUserSelector } from '../../../features/auth/Slice';
import { getToken } from 'firebase/messaging';
import { messaging } from '../../../firebase';
import { ProfileTypeEnum } from './enum/enum';

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function Login() {
  // require('dotenv').config();
  const storeListInfo = useSelector(storeListSelector);
  const navigate = useNavigate();
  const [phoneNumberOTP, setPhoneNumberOTP] = useState<string[]>(
    new Array(6).fill('')
  );
  const isLoggedIn = useSelector(isLoginSelector);
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] = useState(30);
  const [phoneNumberObj, setPhoneNumberObj] = useState({
    phoneNumber: '',
    countryCode: '+91',
    error: '',
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const [otpId, setOtpId] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [errorOfOTP, setErrorOfOTP] = useState('');
  const [sentOrResendOTPMsg, setSentOrResendOTPMsg] = useState(
    'OTP has been sent to your phone number'
  );
  const storeListFetched = useSelector(storeListFetchedSelector);
  const currentUser = useSelector(currentUserSelector);
  // const [deviceToken, setDeviceToken] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();

  const phoneNumberOTPHandleOnKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const newPhoneNumberOTP: string[] = [...phoneNumberOTP];

    setErrorOfOTP('');
    if (key === 'Backspace') {
      if (newPhoneNumberOTP[index] === '') {
        setActiveOTPIndex(index - 1);
        newPhoneNumberOTP[index - 1] = '';
      } else {
        newPhoneNumberOTP[index] = '';
        setPhoneNumberOTP(newPhoneNumberOTP);
        setActiveOTPIndex(index);
      }
    } else if (key.match('[0-9]')) {
      newPhoneNumberOTP[index] = key;
      setActiveOTPIndex(index + 1);
      setPhoneNumberOTP(newPhoneNumberOTP);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    return;
  }, [activeOTPIndex]);

  const sendOTP = async (secondStep: any) => {
    if (!phoneNumberObj.error) {
      await PostRequest('auth/send-otp', {
        countryCode: phoneNumberObj.countryCode,
        nationalNumber: phoneNumberObj.phoneNumber,
      }).then((res) => {
        if (res.data.statusCode === 200) {
          setOtpId(res.data.data.otpId);
          if (secondStep) {
            setCurrentStep(currentStep + 1);
          }
        }
      });
    }
  };

  const handleSubmitOTP = async (event: any) => {
    if (phoneNumberOTP.length === 6) {
      // console.log(process.env.FIREBASE_VAPID_KEY, '####');
      // const firebaseDeviceToken = await getToken(messaging, {
      //   vapidKey: process.env.FIREBASE_VAPID_KEY,
      // });

      await dispatch(
        loginAsyncThunk({
          countryCode: phoneNumberObj.countryCode,
          nationalNumber: phoneNumberObj.phoneNumber,
          otp: phoneNumberOTP.join(''),
          otpId,
          // firebaseDeviceToken,
        })
      );

      if (isLoggedIn) {
        await dispatch(fetchUserStoreListAsyncThunk());

        if (storeListFetched && storeListInfo.length === 0) {
          navigate('/store/create');
        }

        if (
          storeListInfo.length === 1 &&
          currentUser?.profileType === ProfileTypeEnum.User
        ) {
          dispatch(
            switchProfileAsyncThunk({
              storeId: storeListInfo[0]._id,
              self: false,
            })
          );
          navigate('/dashboard');
        }

        if (
          storeListInfo.length > 1 &&
          currentUser?.profileType === ProfileTypeEnum.User
        ) {
          navigate('/dashboard');
        }
      }

      navigate('/dashboard');
    } else {
      await setErrorOfOTP('Incorrect OTP');
    }
  };

  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(false);
      return 0;
    }
    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (startPhoneOTPTimer) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer(
          (resendPhoneOTPTimer) => resendPhoneOTPTimer - 1
        );
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startPhoneOTPTimer]);

  const handleStartPhoneOTPTimer = () => {
    setStartPhoneOTPTimer(true);
  };

  return (
    <div className="parent_cnt">
      <div className="cnt">
        {currentStep == 1 ? (
          <div className="cnt">
            <div className="user_icon_cnt">
              <img
                src="/distributor-icon.svg"
                alt="distributor-icon"
                className="user_icon"
              />
            </div>
            <h1 className="heading">Welcome!</h1>

            <p className="greeting">Make Your Business Smart</p>

            <form>
              <div
                className="phone_number"
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <CssTextField
                  sx={{
                    width: '95px',
                    margin: '1.2rem 0',
                    '& .': {
                      color: '#013FB9',
                    },
                  }}
                  value="🇮🇳 +91"
                  type="text"
                  id="outlined-basic"
                  aria-readonly={true}
                />
                <CssTextField
                  sx={{
                    width: '340px',
                    marginLeft: '1.25rem',
                    margin: '1.2rem 0',
                    '& .': {
                      color: '#013FB9',
                    },
                  }}
                  value={phoneNumberObj.phoneNumber}
                  placeholder="Enter your Phone Number"
                  onChange={(event) => {
                    let { value } = event.target;
                    setPhoneNumberObj({
                      ...phoneNumberObj,
                      phoneNumber: value,
                      error:
                        value.length > 10 ? 'Please enter a valid number' : '',
                    });
                  }}
                  type="number"
                  id="outlined-basic"
                  name="email"
                  label="Phone Number"
                  error={phoneNumberObj.error ? true : false}
                  helperText={phoneNumberObj.error ? phoneNumberObj.error : ''}
                />
              </div>
              <p
                className="greeting"
                style={{ marginBottom: '1rem', textAlign: 'center' }}
              >
                <Link to="/register">
                  Click here to register, if you are not an existing user.
                </Link>
              </p>
              <button
                type="button"
                onClick={() => sendOTP(true)}
                className="send_otp_btn"
              >
                Send OTP
              </button>
            </form>
          </div>
        ) : (
          ''
        )}
        {currentStep == 2 ? (
          <Otp
            setSentOrResendOTPMsg={setSentOrResendOTPMsg}
            sentOrResendOTPMsg={sentOrResendOTPMsg}
            startPhoneOTPTimer={startPhoneOTPTimer}
            handleStartPhoneOTPTimer={handleStartPhoneOTPTimer}
            setResendPhoneOTPTimer={setResendPhoneOTPTimer}
            resendPhoneOTPTimer={resendPhoneOTPTimer}
            calculateResendPhoneOTPTimer={calculateResendPhoneOTPTimer}
            currentStep={currentStep}
            activeOTPIndex={activeOTPIndex}
            inputRef={inputRef}
            phoneNumberOTP={phoneNumberOTP}
            handleSubmitOTP={handleSubmitOTP}
            errorOfOTP={errorOfOTP}
            sendOTP={sendOTP}
            setPhoneNumberOTP={setPhoneNumberOTP}
            setStartPhoneOTPTimer={setStartPhoneOTPTimer}
            setErrorOfOTP={setErrorOfOTP}
            setCurrentStep={setCurrentStep}
            phoneNumberOTPHandleOnKeyDown={phoneNumberOTPHandleOnKeyDown}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

function Otp(props: any) {
  let {
    sendOTP,
    handleStartPhoneOTPTimer,
    setResendPhoneOTPTimer,
    resendPhoneOTPTimer,
    calculateResendPhoneOTPTimer,
    currentStep,
    inputRef,
    phoneNumberOTP,
    activeOTPIndex,
    phoneNumberOTPHandleOnKeyDown,
    handleSubmitOTP,
    errorOfOTP,
    startPhoneOTPTimer,
    sentOrResendOTPMsg,
    setSentOrResendOTPMsg,
    setPhoneNumberOTP,
    setStartPhoneOTPTimer,
    setErrorOfOTP,
    setCurrentStep,
  } = props;

  useEffect(() => {
    handleStartPhoneOTPTimer();
  }, []);

  const handleClickOnGoback = () => {
    setPhoneNumberOTP(new Array(6).fill(''));
    setStartPhoneOTPTimer(false);
    setResendPhoneOTPTimer(30);
    setErrorOfOTP('');
    setCurrentStep(1);
  };

  return (
    <div className="cnt">
      <div className="user_icon_cnt">
        <img
          src="/distributor-icon.svg"
          alt="distributor-icon"
          className="user_icon"
        />
      </div>
      <h1 className="heading">Welcome!</h1>
      <p className="greeting">
        Let's me make some ease for shopkeepers. <br />
        Make Your Business Smart
      </p>
      <p
        className="greeting"
        style={{
          color: 'green',
          marginTop: '0.75rem',
        }}
      >
        {sentOrResendOTPMsg}
      </p>
      <div className="otp">
        <div className="cnt_otp">
          {phoneNumberOTP.map((a: any, index: number) => {
            return (
              <input
                key={index}
                value={phoneNumberOTP[index]}
                onChange={(event) => {
                  return;
                }}
                type="number"
                maxLength={1}
                onFocus={(event) => {
                  event.target.classList.add('border-solid');
                  event.target.classList.add('border-[1.5px]');
                  event.target.classList.add('border-black');
                }}
                onBlur={(event) => {
                  event.target.classList.remove('border-solid');
                  event.target.classList.remove('border-[1.5px]');
                  event.target.classList.remove('border-black');
                }}
                ref={index === activeOTPIndex ? inputRef : null}
                onKeyDown={(event) =>
                  phoneNumberOTPHandleOnKeyDown(event, index)
                }
                className="otp_field input"
              />
            );
          })}
        </div>
        <div
          className="resend_otp"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'red' }}>{errorOfOTP}</span>
          <Link
            to="#"
            onClick={async () => {
              if (startPhoneOTPTimer != true) {
                await sendOTP(false);
                handleStartPhoneOTPTimer();
                setResendPhoneOTPTimer(30);
                setSentOrResendOTPMsg('OTP has been resend to your number');
              }
            }}
            style={{
              color: resendPhoneOTPTimer == 0 ? 'blue' : 'gray',
            }}
          >
            Resend OTP
            {resendPhoneOTPTimer == 0
              ? ''
              : ' in ' + calculateResendPhoneOTPTimer}
          </Link>
        </div>

        <button
          type="submit"
          onClick={handleSubmitOTP}
          className="send_otp_btn"
        >
          Submit OTP
        </button>
      </div>
      <p
        style={{
          color: '#14539a',
          cursor: 'pointer',
          marginTop: '1rem',
        }}
        onClick={handleClickOnGoback}
      >
        Go back
      </p>
    </div>
  );
}
