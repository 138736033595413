import { MdFiberNew } from 'react-icons/md';
import '../style/productList.scss';
import EditMinimumQuantity from './EditMinimumQuantity';
import EditOffer from './EditOffer';
import EditPricePerUnit from './EditPricePerUnit';
import { useEffect, useRef, useState } from 'react';
import './editSellerProductDetailForm.scss';
import { GetRequest, PatchRequest } from '../../../../component/Request';

export default function EditSellerProductDetailForm({
  configuredProduct,
  setShowEditForm,
  setConfiguredProducts,
  brandId,
  setPageNumberForConfiguredProductList,
  setHasMoreProductInConfiguredListOrNot,
}: any) {
  const [productStateToUpdate, setProductStateToUpdate] = useState<any>({});
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [optionAccToInfoObj, setOptionAccToInfoObj] = useState<any>([]);
  const [activeOption, setActiveOption] = useState<string>('');
  const [nowShowEditForm, setNowShowEditForm] = useState<Boolean>(false);
  const [commonError, setCommonError] = useState('');
  const editProductFormRef = useRef<any>(null);

  //
  const [payload, setPayload] = useState<any>({
    // productId: [configuredProduct._id],
    minimumQuantity: {},
    offer: {},
    pricePerUnit: {},
  });

  useEffect(() => {
    if (activeOption) {
      updateTheProductAccToActiveOption();
    }
  }, [activeOption]);

  useEffect(() => {
    if (configuredProduct && configuredProduct.sellerProductDetail) {
      console.log('444444');
      const minimumQuantity =
        configuredProduct.sellerProductDetail.minimumQuantity;

      const optionOrder = ['piece', 'bundle', 'box'];

      const selectedOptions = [];

      for (const option of optionOrder) {
        if (minimumQuantity[option] && minimumQuantity[option].quantity) {
          selectedOptions.push(option);
        }
      }

      setSelectedOption(selectedOptions);

      if (selectedOptions.length > 0) {
        setActiveOption(selectedOptions[0]);
      } else {
        setActiveOption('');
      }
    }
  }, [configuredProduct]);

  useEffect(() => {
    if (configuredProduct?.info) {
      let { pieceInEachBox, pieceInEachBundle } = configuredProduct?.info;

      if (pieceInEachBox) {
        setOptionAccToInfoObj(['piece', 'box']);
      }
      if (pieceInEachBundle) {
        setOptionAccToInfoObj(['piece', 'bundle']);
      }
      if (pieceInEachBox && pieceInEachBundle) {
        setOptionAccToInfoObj(['piece', 'bundle', 'box']);
      }
    } else {
      setOptionAccToInfoObj(['piece']);
    }
  }, []);

  const updateTheProductAccToActiveOption = async () => {
    let errorObj: any = {
      pricePerUnit: {},
      offer: {
        bundleOfferList: [],
        pieceOfferList: [],
        boxOfferList: [],
      },
      minimumQuantity: {
        piece: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
        bundle: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
        box: {
          quantity: '',
          isMultiple: {
            enable: '',
            number: '',
          },
        },
      },
    };
    let newProductState = {
      // productId: [configuredProduct._id],
      minimumQuantity: configuredProduct.sellerProductDetail.minimumQuantity,
      pricePerUnit: configuredProduct.sellerProductDetail.pricePerUnit,
      // offer: configuredProduct.sellerProductDetail?.offer
      //   ? configuredProduct.sellerProductDetail?.offer
      //   : { pieceOfferList: [], bundleOfferList: [], boxOfferList: [] },
      error: errorObj,
    };

    newProductState.minimumQuantity.piece = newProductState.minimumQuantity
      .piece
      ? newProductState.minimumQuantity.piece
      : {
          quantity: '',
          isMultiple: {
            enable: false,
            number: '',
          },
        };

    newProductState.minimumQuantity.bundle = newProductState.minimumQuantity
      .bundle
      ? newProductState.minimumQuantity.bundle
      : {
          quantity: '',
          isMultiple: {
            enable: false,
            number: '',
          },
        };

    newProductState.minimumQuantity.box = newProductState.minimumQuantity.box
      ? newProductState.minimumQuantity.box
      : {
          quantity: '',
          isMultiple: {
            enable: false,
            number: '',
          },
        };

    // price per unit start

    if (newProductState.pricePerUnit.piece) {
      if (newProductState.pricePerUnit.piece?.unique) {
        newProductState.pricePerUnit.piece = {
          unique: true,
          price: newProductState.pricePerUnit.piece.price,
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: 1,
              lessThanOrEqual: '',
            },
          ],
        };
      } else {
        newProductState.pricePerUnit.piece = {
          unique: false,
          price: '',
          quantityWisePriceList:
            newProductState.pricePerUnit.piece.quantityWisePriceList,
        };
      }
    } else {
      newProductState.pricePerUnit.piece = {
        unique: false,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      };
    }

    if (newProductState.pricePerUnit?.bundle) {
      if (newProductState.pricePerUnit.bundle?.unique) {
        newProductState.pricePerUnit.bundle = {
          unique: true,
          price: newProductState.pricePerUnit.bundle.price,
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: 1,
              lessThanOrEqual: '',
            },
          ],
        };
      }
      if (!newProductState.pricePerUnit.bundle?.unique) {
        newProductState.pricePerUnit.bundle = {
          unique: false,
          price: '',
          quantityWisePriceList:
            newProductState.pricePerUnit.bundle.quantityWisePriceList,
        };
      }
    } else {
      newProductState.pricePerUnit.bundle = {
        unique: false,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      };
    }

    if (newProductState.pricePerUnit?.box) {
      if (newProductState.pricePerUnit.box?.unique) {
        newProductState.pricePerUnit.box = {
          unique: true,
          price: newProductState.pricePerUnit.box.price,
          quantityWisePriceList: [
            {
              price: '',
              moreThanOrEqual: 1,
              lessThanOrEqual: '',
            },
          ],
        };
      }
      if (!newProductState.pricePerUnit.box?.unique) {
        newProductState.pricePerUnit.box = {
          unique: false,
          price: '',
          quantityWisePriceList:
            newProductState.pricePerUnit.box.quantityWisePriceList,
        };
      }
    } else {
      newProductState.pricePerUnit.box = {
        unique: false,
        price: '',
        quantityWisePriceList: [
          {
            price: '',
            moreThanOrEqual: 1,
            lessThanOrEqual: '',
          },
        ],
      };
    }

    // price per unit ends

    errorObj.pricePerUnit.piece = {
      price: '',
      unique: '',
      quantityWisePriceList:
        configuredProduct.sellerProductDetail.pricePerUnit?.piece
          ?.quantityWisePriceList.length > 0
          ? configuredProduct.sellerProductDetail.pricePerUnit?.piece?.quantityWisePriceList.map(
              (data: any) => {
                return {
                  unique: '',
                  price: '',
                  quantityWisePriceList: [
                    {
                      price: '',
                      moreThanOrEqual: '',
                      lessThanOrEqual: '',
                    },
                  ],
                };
              }
            )
          : {
              unique: '',
              price: '',
              quantityWisePriceList: [
                {
                  price: '',
                  moreThanOrEqual: '',
                  lessThanOrEqual: '',
                },
              ],
            },
    };

    errorObj.pricePerUnit.bundle = {
      price: '',
      unique: '',
      quantityWisePriceList:
        configuredProduct.sellerProductDetail.pricePerUnit?.bundle
          ?.quantityWisePriceList.length > 0
          ? configuredProduct.sellerProductDetail.pricePerUnit?.bundle?.quantityWisePriceList.map(
              (data: any) => {
                return {
                  unique: '',
                  price: '',
                  quantityWisePriceList: [
                    {
                      price: '',
                      moreThanOrEqual: '',
                      lessThanOrEqual: '',
                    },
                  ],
                };
              }
            )
          : {
              unique: '',
              price: '',
              quantityWisePriceList: [
                {
                  price: '',
                  moreThanOrEqual: '',
                  lessThanOrEqual: '',
                },
              ],
            },
    };

    errorObj.pricePerUnit.box = {
      price: '',
      unique: '',
      quantityWisePriceList:
        configuredProduct.sellerProductDetail.pricePerUnit?.box
          ?.quantityWisePriceList.length > 0
          ? configuredProduct.sellerProductDetail.pricePerUnit?.box?.quantityWisePriceList.map(
              (data: any) => {
                return {
                  unique: '',
                  price: '',
                  quantityWisePriceList: [
                    {
                      price: '',
                      moreThanOrEqual: '',
                      lessThanOrEqual: '',
                    },
                  ],
                };
              }
            )
          : {
              unique: '',
              price: '',
              quantityWisePriceList: [
                {
                  price: '',
                  moreThanOrEqual: '',
                  lessThanOrEqual: '',
                },
              ],
            },
    };

    if (
      configuredProduct.sellerProductDetail.offer?.pieceOfferList.length > 0
    ) {
      errorObj.offer.pieceOfferList =
        configuredProduct.sellerProductDetail.offer.pieceOfferList.map(
          (data: any) => {
            return { quantity: '', free: { quantity: '', type: '' } };
          }
        );
    }

    if (
      configuredProduct.sellerProductDetail.offer?.bundleOfferList.length > 0
    ) {
      errorObj.offer.bundleOfferList =
        configuredProduct.sellerProductDetail.offer.bundleOfferList.map(
          (data: any) => {
            return { quantity: '', free: { quantity: '', type: '' } };
          }
        );
    }

    if (configuredProduct.sellerProductDetail.offer?.boxOfferList.length > 0) {
      errorObj.offer.boxOfferList =
        configuredProduct.sellerProductDetail.offer.boxOfferList.map(
          (data: any) => {
            return { quantity: '', free: { quantity: '', type: '' } };
          }
        );
    }

    newProductState.error = errorObj;

    setNowShowEditForm(true);

    return await setProductStateToUpdate(newProductState);
  };

  const createLastQuantityWisePricePerObj = (
    moreThanOrEqual: any,
    lessThanOrEqual: any,
    price: any,
    index: number,
    type: string
  ) => {
    if (activeOption === type) {
      if (moreThanOrEqual && lessThanOrEqual && price) {
        const newProductState = { ...productStateToUpdate };
        if (
          index ===
          newProductState.pricePerUnit[type].quantityWisePriceList.length - 1
        ) {
          const obj = {
            moreThanOrEqual: lessThanOrEqual + 1,
            lessThanOrEqual: Number.MAX_VALUE,
            price: '',
          };
          const error = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
          newProductState.pricePerUnit[type].quantityWisePriceList.push(obj);
          newProductState.error.pricePerUnit[type].quantityWisePriceList.push(
            error
          );
          setProductStateToUpdate(newProductState);
        }
      }
    }
  };

  function hasUpToThreeDecimals(number: any) {
    const decimalPart = (number.toString().split('.')[1] || '').length;
    return decimalPart <= 3;
  }

  const handleChange = (event: any, index: number) => {
    let { name, value } = event.target;
    let newProductState = { ...productStateToUpdate };
    console.log('this is the produftgregergordgfnrougdnsogidhrgodifghnfsdoi');
    switch (name) {
      case 'pieceMinimumQuantity':
        newProductState.minimumQuantity.piece.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.piece.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.piece.quantity = '';
        }
        break;
      case 'pieceIsMultiple':
        newProductState.minimumQuantity.piece.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.piece.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.piece.isMultiple.number = '';
          newProductState.minimumQuantity.piece.isMultiple.number = '';
        }

        break;
      case 'pieceIncDecQuantity':
        newProductState.minimumQuantity.piece.isMultiple.number = parseInt(
          value
        )
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.piece.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.piece.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'pieceIsUnique':
        newProductState.pricePerUnit.piece.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.piece.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.piece.price = '';
          newProductState.pricePerUnit.piece.price = '';
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
        } else {
          newProductState.pricePerUnit.piece.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.piece.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'piecePerUnitPrice':
        newProductState.pricePerUnit.piece.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.piece.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a value';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a value upto three decimal only';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.piece.price =
            'Please provide a positive integer';
        } else {
          newProductState.error.pricePerUnit.piece.price = '';
        }
        break;

      case 'pieceLessThanOrEqual':
        newProductState.pricePerUnit.piece.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.piece.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.piece.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        } else {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: '',
          };
        }

        break;
      case 'quantityWisePriceListPerPiece':
        newProductState.pricePerUnit.piece.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.piece.quantityWisePriceList[index],
          price: Number(value) || value == '0' ? Number(value) : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: 'Price required',
          };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: 'Please provide a value upto three decimal only',
          };
        } else {
          newProductState.error.pricePerUnit.piece.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.piece.quantityWisePriceList[
              index
            ],
            price: '',
          };
        }

        break;

      case 'offerOnWhatQuantityOfPiece':
        newProductState.offer.pieceOfferList[index] = {
          ...newProductState.offer.pieceOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.pieceOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.pieceOfferList[index].quantity =
            'This field is required.';
        }

        break;

      case 'freeQuantityPiece':
        newProductState.offer.pieceOfferList[index] = {
          ...newProductState.offer.pieceOfferList[index],
          free: {
            ...newProductState.offer.pieceOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.pieceOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.pieceOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductStateToUpdate(newProductState);
        break;

      // box

      case 'boxMinimumQuantity':
        newProductState.minimumQuantity.box.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.box.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.box.quantity = '';
        }
        break;
      case 'boxIsMultiple':
        newProductState.minimumQuantity.box.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.box.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.box.isMultiple.number = '';
          newProductState.minimumQuantity.box.isMultiple.number = '';
        }

        break;
      case 'boxIncDecQuantity':
        newProductState.minimumQuantity.box.isMultiple.number = parseInt(value)
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.box.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.box.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'boxIsUnique':
        newProductState.pricePerUnit.box.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.box.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.box.price = '';
          newProductState.pricePerUnit.box.price = '';
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              moreThanOrEqual: '',
              lessThanOrEqual: '',
              price: '',
            };
        } else {
          newProductState.pricePerUnit.box.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.box.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'boxPerUnitPrice':
        newProductState.pricePerUnit.box.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.box.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a value';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a value upto three decimal only';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.box.price =
            'Please provide a positive integer';
        } else {
          newProductState.error.pricePerUnit.box.price = '';
        }

        break;

      case 'boxLessThanOrEqual':
        newProductState.pricePerUnit.box.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.box.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: 'Please provide more than from value',
            };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.box.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: 'Please provide more than from value',
            };
        } else {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              lessThanOrEqual: '',
            };
        }

        break;
      case 'quantityWisePriceListPerBox':
        newProductState.pricePerUnit.box.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.box.quantityWisePriceList[index],
          price: value || value == '0' ? value : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Price required',
            };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: 'Please provide a value upto three decimal only',
            };
        } else {
          newProductState.error.pricePerUnit.box.quantityWisePriceList[index] =
            {
              ...newProductState.error.pricePerUnit.box.quantityWisePriceList[
                index
              ],
              price: '',
            };
        }

        break;
      case 'offerOnWhatQuantityOfBox':
        newProductState.offer.boxOfferList[index] = {
          ...newProductState.offer.boxOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.boxOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.boxOfferList[index].quantity =
            'This field is required.';
        }

        break;

      case 'freeQuantityBox':
        newProductState.offer.boxOfferList[index] = {
          ...newProductState.offer.boxOfferList[index],
          free: {
            ...newProductState.offer.boxOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.boxOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.boxOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductStateToUpdate(newProductState);
        break;

      // bundle

      case 'bundleMinimumQuantity':
        newProductState.minimumQuantity.bundle.quantity =
          parseInt(value) || value == '0' ? parseInt(value) : '';

        if (value === '') {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a value';
        } else if (parseInt(value) < 0 || isNaN(parseInt(value))) {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a positive integer';
        } else if (parseInt(value) === 0) {
          newProductState.error.minimumQuantity.bundle.quantity =
            'Please provide a value greater than zero';
        } else {
          newProductState.error.minimumQuantity.bundle.quantity = '';
        }
        break;
      case 'bundleIsMultiple':
        newProductState.minimumQuantity.bundle.isMultiple.enable =
          event.target.checked;

        newProductState.error.minimumQuantity.bundle.isMultiple.enable = '';
        if (event.target.checked === false) {
          newProductState.error.minimumQuantity.bundle.isMultiple.number = '';
          newProductState.minimumQuantity.bundle.isMultiple.number = '';
        }

        break;
      case 'bundleIncDecQuantity':
        newProductState.minimumQuantity.bundle.isMultiple.number = parseInt(
          value
        )
          ? parseInt(value)
          : '';
        if (value && value != '0') {
          newProductState.error.minimumQuantity.bundle.isMultiple.number = '';
        } else {
          newProductState.error.minimumQuantity.bundle.isMultiple.number =
            'Please provide more than one';
        }

        break;
      case 'bundleIsUnique':
        newProductState.pricePerUnit.bundle.unique =
          value === 'true' ? true : false;
        newProductState.error.pricePerUnit.bundle.unique = '';

        if (value === 'false') {
          newProductState.error.pricePerUnit.bundle.price = '';
          newProductState.pricePerUnit.bundle.price = '';
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            moreThanOrEqual: '',
            lessThanOrEqual: '',
            price: '',
          };
        } else {
          newProductState.pricePerUnit.bundle.quantityWisePriceList = [
            { price: '', moreThanOrEqual: 1, lessThanOrEqual: '' },
          ];
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList = [
            { price: '', moreThanOrEqual: '', lessThanOrEqual: '' },
          ];
        }

        break;
      case 'bundlePerUnitPrice':
        newProductState.pricePerUnit.bundle.price =
          value || value == '0' ? value : '';
        newProductState.error.pricePerUnit.bundle.price = '';

        if (value === '') {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a value';
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a value upto three decimal only';
        } else if (value < 0 || isNaN(value)) {
          newProductState.error.pricePerUnit.bundle.price =
            'Please provide a positive integer';
        } else {
          newProductState.error.pricePerUnit.bundle.price = '';
        }

        break;

      case 'bundleLessThanOrEqual':
        newProductState.pricePerUnit.bundle.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.bundle.quantityWisePriceList[index],
          lessThanOrEqual:
            parseInt(value) || value == '0' ? parseInt(value) : '',
        };

        if (isNaN(parseInt(value))) {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        }

        if (
          isNaN(parseInt(value)) ||
          parseInt(value) == 0 ||
          parseInt(value) <=
            newProductState.pricePerUnit.bundle.quantityWisePriceList[index]
              .moreThanOrEqual
        ) {
          // error
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: 'Please provide more than from value',
          };
        } else {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            lessThanOrEqual: '',
          };
        }

        break;
      case 'quantityWisePriceListPerBundle':
        newProductState.pricePerUnit.bundle.quantityWisePriceList[index] = {
          ...newProductState.pricePerUnit.bundle.quantityWisePriceList[index],
          price: value || value == '0' ? value : '',
        };

        if (value == '') {
          // error
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: 'Price required',
          };
        } else if (!hasUpToThreeDecimals(value)) {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: 'Please provide a value upto three decimal only',
          };
        } else {
          newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
            index
          ] = {
            ...newProductState.error.pricePerUnit.bundle.quantityWisePriceList[
              index
            ],
            price: '',
          };
        }

        break;
      case 'offerOnWhatQuantityOfBundle':
        newProductState.offer.bundleOfferList[index] = {
          ...newProductState.offer.bundleOfferList[index],
          quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
        };
        // Clear the error for this field
        newProductState.error.offer.bundleOfferList[index].quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.bundleOfferList[index].quantity =
            'This field is required.';
        }

        break;
      case 'freeQuantityBundle':
        newProductState.offer.bundleOfferList[index] = {
          ...newProductState.offer.bundleOfferList[index],
          free: {
            ...newProductState.offer.bundleOfferList[index].free,
            quantity: parseInt(value) || value == '0' ? parseInt(value) : '',
          },
        };
        // Clear the error for this field
        newProductState.error.offer.bundleOfferList[index].free.quantity = '';

        // Check if the value is empty
        if (value.trim() === '') {
          // Set the error message for this field
          newProductState.error.offer.bundleOfferList[index].free.quantity =
            'This field is required.';
        }
        setProductStateToUpdate(newProductState);
        break;
    }
    setProductStateToUpdate(newProductState);
  };

  const handleAddMoreRange = (option: any) => {
    let newProductState = { ...productStateToUpdate };
    if (option == 'piece') {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.piece.quantityWisePriceList[
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.piece.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.piece.quantityWisePriceList[
            newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.piece.quantityWisePriceList.length - 1,
          'piece'
        );

        setProductStateToUpdate(newProductState);
      }
    } else if (option == 'box') {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.box.quantityWisePriceList[
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.box.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.box.quantityWisePriceList[
            newProductState.pricePerUnit.box.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.box.quantityWisePriceList.length - 1,
          'box'
        );

        setProductStateToUpdate(newProductState);
      }
    } else {
      // first need to get the last elm's from
      let lastToNumber = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual
      );

      let lastFromNumber = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].moreThanOrEqual
      );

      let lastPrice = parseInt(
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].price
      );

      let maxToValueNumber =
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual;

      if (
        maxToValueNumber == Number.MAX_VALUE &&
        !isNaN(lastFromNumber) &&
        lastToNumber != 0 &&
        lastFromNumber != 0
      ) {
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].lessThanOrEqual = '';
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].moreThanOrEqual = lastFromNumber;
        newProductState.pricePerUnit.bundle.quantityWisePriceList[
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
        ].price = isNaN(lastPrice) ? '' : lastPrice;

        newProductState.error.pricePerUnit.bundle.quantityWisePriceList.push({
          price: '',
          moreThanOrEqual: '',
          lessThanOrEqual: '',
        });

        createLastQuantityWisePricePerObj(
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].moreThanOrEqual,
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].lessThanOrEqual,
          newProductState.pricePerUnit.bundle.quantityWisePriceList[
            newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1
          ].price,
          newProductState.pricePerUnit.bundle.quantityWisePriceList.length - 1,
          'bundle'
        );
      }
    }

    setProductStateToUpdate(newProductState);
  };

  const handleClickOutsideForEditProduct = async (event: any) => {
    if (
      editProductFormRef.current &&
      !editProductFormRef.current.contains(event.target)
    ) {
      await closeTheEditPopUp();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForEditProduct);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForEditProduct
      );
    };
  }, []);

  const closeTheEditPopUp = async () => {
    await setActiveOption('');
    await setSelectedOption([]);
    await setProductStateToUpdate({});
    await setShowEditForm(false);
    await setNowShowEditForm(false);
    await getConfiguredProduct();
  };

  const getConfiguredProduct = async () => {
    await GetRequest(
      `product?brand=${brandId}&page=1&limit=8&seller-product-detail=true&seller-product-only=true`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPageNumberForConfiguredProductList(() => 1);
          if (res.data.data.length < 8) {
            setHasMoreProductInConfiguredListOrNot(false);
          } else {
            setHasMoreProductInConfiguredListOrNot(true);
          }
          if (res.data.data.length != 0) {
            setConfiguredProducts((prevConfiguredProducts: any) => {
              let updatedResponse = res.data.data.map((obj: any) => {
                return { ...obj, showDropdown: false };
              });
              return updatedResponse;
            });
          } else {
            setConfiguredProducts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'err in updating the product detail form');
      });
  };

  const handleOptionChange = (event: any) => {
    const value = event.target.value;

    if (selectedOption.includes(value)) {
      setSelectedOption((prevOptions: any) => {
        return prevOptions.filter((type: any) => type !== value);
      });
    } else {
      if (value === 'piece') {
        setSelectedOption((prevOptions: any) => {
          prevOptions.splice(0, 0, value);
          return [...prevOptions];
        });
      } else if (value === 'bundle') {
        setSelectedOption((prevOptions: any) => {
          prevOptions.splice(1, 0, value);
          return [...prevOptions];
        });
      } else if (value === 'box') {
        setSelectedOption((prevOptions: any) => {
          prevOptions.splice(2, 0, value);
          return [...prevOptions];
        });
      }

      setActiveOption(value);
    }
  };

  useEffect(() => {
    if (activeOption == '') {
      setNowShowEditForm(false);
    }
  }, [activeOption]);

  const hasError = (obj: any): boolean => {
    if (obj === undefined || obj === null) {
      return false;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (
          value === true ||
          (typeof value === 'string' && value.trim() !== '')
        ) {
          return true;
        } else if (typeof value === 'object' && hasError(value)) {
          return true;
        }
      }
    }
    return false;
  };

  const updateProductDetailForAllOption = async () => {
    const updatedPayload = { ...payload };

    for (let i = 0; i < selectedOption.length; i++) {
      if (selectedOption[i] === activeOption) {
        let optionInLoop = selectedOption[i];
        await checkErrorInAllOptionAndCreatePayload(optionInLoop);
      } else {
        let optionInLoop = selectedOption[i];

        if (
          configuredProduct.sellerProductDetail?.minimumQuantity[optionInLoop]
            ?.quantity ||
          configuredProduct.sellerProductDetail?.minimumQuantity[optionInLoop]
            ?.isMultiple.number ||
          configuredProduct.sellerProductDetail?.offer[
            optionInLoop + 'OfferList'
          ]?.length > 0
        ) {
          await checkErrorInAllOptionAndCreatePayload(optionInLoop);
        }
        if (
          configuredProduct.sellerProductDetail?.pricePerUnit[optionInLoop]
            ?.price ||
          !configuredProduct.sellerProductDetail?.pricePerUnit[optionInLoop]
            ?.unique
        ) {
          await checkErrorInAllOptionAndCreatePayload(optionInLoop);
        }
      }
    }

    // if (Object.keys(updatedPayload?.offer)?.length === 0) {
    //   delete updatedPayload.offer;
    // }

    setPayload(updatedPayload);

    if (!hasError(productStateToUpdate.error)) {
      setCommonError('');
      await handlePatchRequest(updatedPayload);
    } else {
      setCommonError(
        `Check the ${selectedOption.join(
          ' , '
        )} for errors and fix any you find. Thank you!`
      );
    }
  };

  const checkErrorInAllOptionAndCreatePayload = async (type: any) => {
    let newProductState = { ...productStateToUpdate };
    const typeLowerCase = type.toLowerCase();
    let obj: any = { ...payload };

    // logic update newProductState and error messages

    // checking for minimmum quantity
    if (
      newProductState.minimumQuantity[typeLowerCase].quantity &&
      newProductState.minimumQuantity[typeLowerCase].quantity !== '0'
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].quantity = '';
    } else {
      newProductState.error.minimumQuantity[typeLowerCase].quantity =
        'Please provide more than one';
    }

    if (
      newProductState.minimumQuantity[typeLowerCase].isMultiple.enable === ''
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].isMultiple.enable =
        'Please select one from above ones';
    } else if (
      newProductState.minimumQuantity[typeLowerCase].isMultiple.enable == true
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].isMultiple.enable =
        '';
      if (!newProductState.minimumQuantity[typeLowerCase].isMultiple.number) {
        newProductState.error.minimumQuantity[typeLowerCase].isMultiple.number =
          'Please provide common price';
      } else if (
        newProductState.minimumQuantity[typeLowerCase].isMultiple.number
      ) {
        newProductState.error.minimumQuantity[typeLowerCase].isMultiple.number =
          '';
      }
    }

    // error for [typeLowerCase] price per unit
    if (newProductState.pricePerUnit[typeLowerCase].unique === '') {
      newProductState.error.pricePerUnit[typeLowerCase].unique =
        'Please select one from above ones';
    } else if (newProductState.pricePerUnit[typeLowerCase].unique == true) {
      if (newProductState.pricePerUnit[typeLowerCase].price == '') {
        newProductState.error.pricePerUnit[typeLowerCase].price =
          'Please provide price';
      }
    } else {
      newProductState.error.pricePerUnit[typeLowerCase].unique = '';
      newProductState.pricePerUnit[typeLowerCase].quantityWisePriceList.forEach(
        (obj: any, index: any) => {
          if (obj.price === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].price = 'Please provide price';
          }

          if (obj.moreThanOrEqual === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].moreThanOrEqual =
              'Please provide min. value';
          }

          if (obj.moreThanOrEqual == '0') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].moreThanOrEqual =
              'Please provide at least 1';
          }

          if (obj.lessThanOrEqual === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].lessThanOrEqual =
              'Please provide max. value';
          }

          if (obj.lessThanOrEqual < obj.moreThanOrEqual) {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].lessThanOrEqual =
              'Please provide more than from value';
          }
        }
      );
    }

    // write logic for offer error later in future use it

    // if (newProductState?.offer[typeLowerCase + 'OfferList'].length > 0) {
    //   let offerErrorArray =
    //     newProductState.error.offer[typeLowerCase + 'OfferList'];
    //   let offerArray = newProductState.offer[typeLowerCase + 'OfferList'];

    //   offerArray.forEach((offer: any, index: number) => {
    //     if (!offer.quantity) {
    //       offerErrorArray[index].quantity = 'Required';
    //     }
    //     if (!offer.free.quantity) {
    //       offerErrorArray[index].free.quantity = 'Required';
    //     }
    //     if (!offer.free.type) {
    //       offerErrorArray[index].free.type = 'Required';
    //     }
    //   });
    // }

    setProductStateToUpdate(newProductState);

    // After handling errors, update the payload properties based on your conditions
    if (newProductState.minimumQuantity[typeLowerCase].isMultiple.enable) {
      obj.minimumQuantity[typeLowerCase] = {
        isMultiple: newProductState.minimumQuantity[typeLowerCase].isMultiple,
        quantity: newProductState.minimumQuantity[typeLowerCase].quantity,
      };
    } else {
      obj.minimumQuantity[typeLowerCase] = {
        quantity: newProductState.minimumQuantity[typeLowerCase].quantity,
        isMultiple: { enable: false, number: 1 },
      };
    }

    if (newProductState.pricePerUnit[typeLowerCase].unique) {
      obj.pricePerUnit[typeLowerCase] = {
        unique: newProductState.pricePerUnit[typeLowerCase].unique,
        price: newProductState.pricePerUnit[typeLowerCase].price,
      };
    } else {
      obj.pricePerUnit[typeLowerCase] = {
        unique: false,
        quantityWisePriceList:
          newProductState.pricePerUnit[typeLowerCase].quantityWisePriceList,
      };
    }

    // Handle payload for offer later on in future

    // if (newProductState?.offer[typeLowerCase + 'OfferList']?.length > 0) {
    //   let key = typeLowerCase + 'OfferList';
    //   obj.offer[key] = newProductState?.offer[key];
    // }

    // if (newProductState?.offer[typeLowerCase + 'OfferList']?.length === 0) {
    //   let key = typeLowerCase + 'OfferList';
    //   delete obj.offer[key];
    // }

    setProductStateToUpdate(newProductState);

    if (!hasError(newProductState.error)) {
      return obj; // Returning the updated payload
    }

    return null; // Handle the case where there are errors
  };

  const handlePatchRequest = async (payload: any) => {
    await PatchRequest(
      `seller/store/brand/${brandId}/product/${configuredProduct._id}`,
      payload
    )
      .then(async (res: any) => {
        if (res.data.statusCode === 200) {
          setConfiguredProducts((prevState: any) => {
            let newProduct = prevState.filter((data: any) => {
              if (data._id === configuredProduct._id) {
                return {
                  ...data,
                  sellerProductDetail: {
                    minimumQuantity: res.data.data.minimumQuantity,
                    pricePerUnit: res.data.data.pricePerUnit,
                  },
                };
              } else {
                return data;
              }
            });
            return prevState.concat(newProduct);
          });

          await closeTheEditPopUp();
          GetRequest(
            `product?brand=${brandId}&limit=8&page=1&seller-product-detail=true&seller-product-only=true`
          )
            .then(async (res) => {
              if (res.data.statusCode === 200) {
                if (res.data.data.length !== 0) {
                  setConfiguredProducts((prevConfiguredProducts: any) => {
                    let updatedResponse = res.data.data.map((obj: any) => {
                      return { ...obj, showDropdown: false };
                    });
                    return updatedResponse;
                  });
                } else {
                  setConfiguredProducts([]);
                }
              }
            })
            .catch((err) => {
              console.log(err, 'err in submitting the product detail form');
            });
          setShowEditForm(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="edit-seller-product-detail-form"
      ref={editProductFormRef}
      style={{ height: nowShowEditForm && activeOption ? '100%' : 'auto' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '1rem',
        }}
      >
        <h1
          style={{
            textAlign: 'center',
            color: '#14539a',
            fontSize: '18px',
            fontWeight: '500',
          }}
        >
          {configuredProduct.name}
        </h1>
        <p
          style={{
            color: '#737272',
          }}
        >
          MRP - {' ₹' + configuredProduct.mrp}
        </p>

        {configuredProduct.weight && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Weight -
            {'  ' + configuredProduct.weight
              ? configuredProduct.weight?.quantity +
                ' ' +
                configuredProduct.weight?.unit
              : '-.-'}
          </p>
        )}

        {configuredProduct.size && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Size -
            {' ' + configuredProduct.size
              ? configuredProduct.size?.quantity +
                ' ' +
                configuredProduct.size?.unit
              : '-.-'}
          </p>
        )}
        {configuredProduct.color && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Color -
            {configuredProduct?.color?.name
              ? configuredProduct?.color?.name
              : '-.-'}
          </p>
        )}
        {configuredProduct.flavor && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Flavor -
            {configuredProduct?.flavor?.name
              ? configuredProduct?.flavor?.name
              : '-.-'}
          </p>
        )}
        {configuredProduct?.info?.pieceInEachBundle && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Pieces in each bundle - {configuredProduct?.info?.pieceInEachBundle}
          </p>
        )}
        {configuredProduct?.info?.pieceInEachBox && (
          <p
            style={{
              color: '#737272',
            }}
          >
            Pieces in each box - {configuredProduct?.info?.pieceInEachBox}
          </p>
        )}
      </div>

      <div className="back-n-h1-cnt">
        <h2 className="heading">
          Choose the selling option to update from given below:
        </h2>
        <button
          className="btn-primary"
          style={{
            background: 'white',
            width: 'auto',
            height: 'auto',
            margin: '0px',
            fontSize: '30px',
            position: 'absolute',
            right: '10px',
            top: '5px',
          }}
          onClick={closeTheEditPopUp}
        >
          <span style={{ color: 'red' }}>&times;</span>
        </button>
      </div>
      <div className="option-cnt">
        <div className="checkbox-group">
          {optionAccToInfoObj.includes('piece') && (
            <label className="option-n-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                value="piece"
                checked={selectedOption.includes('piece')}
                onChange={handleOptionChange}
              />
              Piece
            </label>
          )}

          {optionAccToInfoObj.includes('bundle') && (
            <label className="option-n-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                value="bundle"
                checked={selectedOption.includes('bundle')}
                onChange={handleOptionChange}
              />
              Bundle
            </label>
          )}

          {optionAccToInfoObj.includes('box') && (
            <label className="option-n-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                value="box"
                checked={selectedOption.includes('box')}
                onChange={handleOptionChange}
              />
              Box
            </label>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {selectedOption.map((option: any) => {
            return (
              <button
                key={option}
                onClick={() => setActiveOption(option)}
                className={
                  activeOption == option ? 'option-active' : 'option-disabled'
                }
                type="button"
              >
                {option}
              </button>
            );
          })}
        </div>
      </div>
      {/* later on need to add */}
      {/* <div className="mark-it-new-checkbox-cnt">
        <input type="checkbox" value="box" name="markItNew" id={'markItNew'} />
        <label htmlFor="markItNew" className="mark-it-all-new">
          Mark All The Selected Products As <MdFiberNew className="new-icon" />
        </label>
      </div> */}
      {nowShowEditForm && activeOption ? (
        <>
          <EditMinimumQuantity
            activeOption={activeOption}
            handleChange={handleChange}
            productStateTobeUpdated={productStateToUpdate}
          />
          <EditPricePerUnit
            activeOption={activeOption}
            createLastQuantityWisePricePerObj={
              createLastQuantityWisePricePerObj
            }
            handleAddMoreRange={handleAddMoreRange}
            handleChange={handleChange}
            productStateTobeUpdated={productStateToUpdate}
            updateProductState={setProductStateToUpdate}
          />

          {/* <EditOffer
            activeOption={activeOption}
            updateProductState={setProductStateToUpdate}
            handleChange={handleChange}
            productStateTobeUpdated={productStateToUpdate}
          /> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              className="update-btn"
              onClick={updateProductDetailForAllOption}
            >
              Update
            </button>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
