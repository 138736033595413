import React, { createContext, useState } from 'react';
import ActiveStoreContext from './ActiveStoreContext';

const ActiveStoreState = (props: any) => {
  const [activeStoreState, updateActiveStoreState] = useState({});
  const setActiveStoreState = async (newActiveStoreState: any) => {
    return await updateActiveStoreState(newActiveStoreState);
  };

  return (
    <ActiveStoreContext.Provider
      value={{ activeStoreState, setActiveStoreState }}
    >
      {props.children}
    </ActiveStoreContext.Provider>
  );
};

export default ActiveStoreState;
