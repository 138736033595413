import './App.css';
import { Route as ReactRoute, Routes } from 'react-router-dom';
import Dashboard from './pages/user/dashboard/Dashboard';
import Route from './pages/user/route/Route';
import Login from './pages/auth/login/Login';
import PolygonMap from './pages/user/polygon/PolygonMap';
import AddStore from './pages/user/addStore/AddStore';
import Brand from './pages/user/brand/Brand';
import Profile from './pages/user/profileSetting/Profile';
import SellerProfile from './pages/user/sellerProfile/SellerProfile';
import ProductsLists from './pages/user/productList/ProductList';
import ProductState from './context/ProductState';
import Schedule from './pages/user/schedule/Schedule';
import ActiveStoreState from './context/ActiveStore/ActiveStoreState';
import Order from './pages/user/order/Order';
import NotificationPage from './pages/notificationPage/NotificationPage';
import BuyerAccToRoute from './pages/user/buyer/BuyerAccToRoute';
import BrandList from './pages/user/brand/BrandList';
import Registration from './pages/register/Registeration';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './store';
import {
  fetchUserInfoAsyncThunk,
  fetchUserStoreListAsyncThunk,
  isLoginSelector,
  fetchStoreSelfAsyncThunk,
  errorSelector,
  loadingSelector,
  storeListFetchedSelector,
} from './features/auth/Slice';
import { useEffect } from 'react';
import ProtectedRoute from './component/ProtectedRoute';
import PrivateHeader from './layout/sidebar/PrivateHeader';
import Indicator from './common/Indicator/Indicator';

import Home from './pages/Home/Home';
import Support from './pages/helpAndSupport/Support';
import PrivacyPolicy from './pages/user/privacy/Privacy';
import StorePublicProfile from './pages/user/storePublicProfile/StorePublicProfile';
import PublicHeader from './layout/sidebar/PublicHeader';

function App() {
  const isLogedIn = useSelector(isLoginSelector);
  const isLoading = useSelector(loadingSelector);
  const dispatch = useDispatch<AppDispatch>();
  const storeListFetched = useSelector(storeListFetchedSelector);

  useEffect(() => {
    dispatch(
      fetchUserInfoAsyncThunk({
        showIndicatorForErrorRequest: false,
      })
    );
  }, []);

  useEffect(() => {
    if (isLoading === 'Fulfilled' && isLogedIn) {
      // need to check

      dispatch(fetchUserStoreListAsyncThunk());
      // if (storeListFetched) {
      dispatch(fetchStoreSelfAsyncThunk());
      // }

      // %%%%%5%%%%%%
      // if (location.pathname === '/register' || location.pathname === '/login') {
      //   navigate('/dashboard');
      // } else {
      //   navigate(location.pathname);
      // }
    } else {
      // console.log(location.pathname === '/stores', 'this is');
      // if (location.pathname === '/register') {
      //   return navigate('/register');
      // } else {
      //   navigate('/login');
      // }
    }
  }, [isLogedIn]);

  return isLogedIn ? (
    <AuthenticatedApp isLogedIn={isLogedIn} />
  ) : (
    <NonAuthenticatedApp />
  );
}

function NonAuthenticatedApp() {
  const error = useSelector(errorSelector);

  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ''
      )}
      <PublicHeader />
      <Routes>
        <ReactRoute path="/" element={<Home />} />
        <ReactRoute path="/login" element={<Login />} />
        <ReactRoute path="/register" element={<Registration />} />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        <ReactRoute path="/support" element={<Support />} />
        <ReactRoute path="/store" element={<StorePublicProfile />} />
        <ReactRoute path="/store/:slug" element={<SellerProfile />} />
      </Routes>
    </>
  );
}

function AuthenticatedApp({ isLogedIn }: any) {
  const error = useSelector(errorSelector);

  return (
    <>
      {error ? (
        <Indicator
          message={error.message}
          type={error.type}
          errorId={error.errorId}
        />
      ) : (
        ''
      )}
      <ActiveStoreState>
        <PrivateHeader />
      </ActiveStoreState>

      <Routes>
        <ReactRoute path="/" element={<Home />} />
        <ReactRoute path="/privacy" element={<PrivacyPolicy />} />
        {/* <ReactRoute path="/notification" element={<NotificationPage />} /> */}

        <ReactRoute path="/support" element={<Support />} />
        <ReactRoute
          path="/dashboard"
          element={
            <ActiveStoreState>
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            </ActiveStoreState>
          }
        />
        <ReactRoute path="/store" element={<StorePublicProfile />} />
        <ReactRoute
          path="/order"
          element={
            <ProtectedRoute>
              <Order />
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="/route"
          element={
            <ActiveStoreState>
              <ProtectedRoute>
                <Route />
              </ProtectedRoute>
            </ActiveStoreState>
          }
        />

        <ReactRoute
          path="/store/create"
          element={
            <ProtectedRoute>
              <AddStore />
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="/seller/brand/:brandId/area"
          element={
            <ProtectedRoute>
              <PolygonMap />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/seller/brand"
          element={
            <ProtectedRoute>
              <Brand />
            </ProtectedRoute>
          }
        />

        <ReactRoute
          path="/seller/brand/:brandId/product"
          element={
            <ProtectedRoute>
              <ProductState>
                <ProductsLists />
              </ProductState>
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="route/:routeId/schedule"
          element={
            <ProtectedRoute>
              <Schedule />
            </ProtectedRoute>
          }
        ></ReactRoute>
        <ReactRoute
          path="/brand"
          element={
            <ProtectedRoute>
              <BrandList />
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="/store/:slug"
          element={
            <ProtectedRoute>
              <SellerProfile />
            </ProtectedRoute>
          }
        />
        <ReactRoute
          path="/buyer"
          element={
            <ProtectedRoute>
              <BuyerAccToRoute />
            </ProtectedRoute>
          }
        />
        <ReactRoute />
      </Routes>
    </>
  );
}

export default App;
