import React, { useEffect, useMemo, useState } from 'react';

type Props = {
  delayTime: number;
  sendOTP: () => void;
};
const TimerComponent = ({
  delayTime: delayTime = 30,
  sendOTP,
}: Props): JSX.Element => {
  const [startPhoneOTPTimer, setStartPhoneOTPTimer] = useState<boolean>(false);
  const [resendPhoneOTPTimer, setResendPhoneOTPTimer] =
    useState<number>(delayTime);
  const calculateResendPhoneOTPTimer = useMemo(() => {
    if (resendPhoneOTPTimer <= 0) {
      setStartPhoneOTPTimer(true);
      return 0;
    }
    return resendPhoneOTPTimer;
  }, [resendPhoneOTPTimer]);

  useEffect(() => {
    let intervalId: any;
    if (calculateResendPhoneOTPTimer > 0) {
      intervalId = setInterval(() => {
        setResendPhoneOTPTimer((prevTimer: number) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [calculateResendPhoneOTPTimer]);
  const innerResendOTP = () => {
    sendOTP();
    setResendPhoneOTPTimer(30);
    setStartPhoneOTPTimer(false);
  };
  return (
    <div style={{ textAlign: 'right', margin: '1rem 0', cursor: 'pointer' }}>
      {startPhoneOTPTimer ? (
        <p
          onClick={innerResendOTP}
          style={{ color: '#14539a', fontWeight: '700', fontSize: '15px' }}
        >
          Resend OTP
        </p>
      ) : (
        <p style={{ color: '#a8a497' }}>
          Resend Timer: {calculateResendPhoneOTPTimer}
        </p>
      )}
    </div>
  );
};

export default TimerComponent;
