import axios from 'axios';

//  const BASE_URL = 'http://localhost:5500/api/v1/';
const BASE_URL = 'https://api.modulusseventeen.com/api/v1/';

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosRequest = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
