import { useEffect, useRef, useState } from 'react';
import { RxHamburgerMenu, RxCross1 } from 'react-icons/rx';
import './styles/header.scss';
import { HiUserCircle } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import { CiLogout } from 'react-icons/ci';
import { IoIosNotifications, IoIosArrowRoundBack } from 'react-icons/io';
import { FaStore } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  currentUserSelector,
  logOutAsyncThunk,
  profileTypeSelector,
  storeListSelector,
  switchProfileAsyncThunk,
} from '../../features/auth/Slice';
import { useMediaQuery } from 'react-responsive';
import { GetRequest, PatchRequest } from '../../component/Request';
import { ReadStatusEnum } from './enum/enum';

export default function PrivateHeader() {
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch<AppDispatch>();
  const [sidebar, setsidebar] = useState(false);
  const storeList = useSelector(storeListSelector);
  const currentUser = useSelector(currentUserSelector);
  const [showStores, setShowStores] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
      className="common-container"
    >
      <header
        className="fixed_header"
        style={{
          width: '95%',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          borderRadius: '15px',
          marginTop: '1rem',
        }}
      >
        <div
          style={{
            transition: 'all 400ms ease-in-out',
          }}
        >
          {sidebar == false ? (
            <div onClick={() => setsidebar(true)} className="hamburger">
              <RxHamburgerMenu onClick={() => setsidebar(true)} />
            </div>
          ) : (
            <div onClick={() => setsidebar(false)} className="cross_icon">
              <RxCross1 onClick={() => setsidebar(false)} />
            </div>
          )}
        </div>
        <Link to="/">
          <img
            src="/sell-logo.png"
            className="md:w-[100px] w-[140px]"
            style={{
              width: '80px',
            }}
            alt="logo"
          />
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {!isMobileScreen && storeList.length === 0 && (
            <Link
              to="/store/create"
              style={{
                display: 'flex',
                marginRight: '1rem',
                alignItems: 'center',
                fontWeight: '600',
                color: '#14539a',
              }}
            >
              Add Store
            </Link>
          )}
          {!isMobileScreen && (
            <Link
              to="/dashboard"
              style={{
                display: 'flex',
                marginRight: '1rem',
                alignItems: 'center',
                fontWeight: '600',
                color: '#14539a',
              }}
            >
              Dashboard
            </Link>
          )}

          {!isMobileScreen && (
            <Link
              to="/route"
              style={{
                display: 'flex',
                marginRight: '1rem',
                alignItems: 'center',
                fontWeight: '600',
                color: '#14539a',
              }}
            >
              Routes
            </Link>
          )}
          {!isMobileScreen && (
            <Link
              style={{
                marginRight: '1rem',
                color: '#14539a',
                fontWeight: '600',
              }}
              to="/order?status=ordered&show_new_order=false"
            >
              Orders
            </Link>
          )}

          <Notification />

          <>
            <button
              style={{
                position: 'relative',
              }}
              onClick={toggleMenu}
            >
              {currentUser?.profileImage ? (
                <img
                  className="user_profile"
                  src={currentUser.profileImage}
                  alt={currentUser.name}
                />
              ) : (
                <HiUserCircle className="user_profile" />
              )}
              {showStores ? (
                <Store
                  setsidebar={setsidebar}
                  info={currentUser}
                  showStores={showStores}
                  setShowStores={setShowStores}
                  currentUser={currentUser}
                  storeList={storeList}
                />
              ) : (
                <UserAvatar
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                  showStores={showStores}
                  storeList={storeList}
                  setShowStores={setShowStores}
                  currentUser={currentUser}
                />
              )}
            </button>
          </>
        </div>
      </header>
      <Sidebar
        isMobileScreen={isMobileScreen}
        sidebar={sidebar}
        currentUser={currentUser}
        setsidebar={setsidebar}
      />
    </div>
  );
}

function Sidebar(props: any) {
  let { currentUser, isMobileScreen, sidebar, setsidebar } = props;
  const sidebarRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setsidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const hideSideBar = () => {
    return setsidebar(false);
  };

  return (
    <nav
      className="sidebar"
      style={{
        display: !sidebar ? 'none' : 'block',
      }}
      ref={sidebarRef}
    >
      <ul>
        {/* <li className="nav_item" onClick={hideSideBar}>
          <Link to="/profile#user-profile">
            <FaIcons.FaUserAlt />
            <span>Profile</span>
          </Link>
        </li> */}

        {isMobileScreen && (
          <li className="nav_item" onClick={hideSideBar}>
            <Link
              to="/store/create"
              style={{
                display: 'flex',

                alignItems: 'center',
                fontWeight: '600',
                color: '#14539a',
              }}
            >
              Add Store
            </Link>
          </li>
        )}

        {isMobileScreen && (
          <li className="nav_item" onClick={hideSideBar}>
            <Link to="/order?status=ordered&show_new_order=false">
              {/* <IoIcons.IoIosPaper /> */}
              <span>Order</span>
            </Link>
          </li>
        )}
        {isMobileScreen && (
          <li className="nav_item" onClick={hideSideBar}>
            <Link to="/route">
              {/* <IoIcons.IoIosPaper /> */}
              <span>Routes</span>
            </Link>
          </li>
        )}

        {isMobileScreen && (
          <li className="nav_item" onClick={hideSideBar}>
            <Link
              style={{
                marginRight: '1rem',
                color: '#14539a',
                fontWeight: '600',
              }}
              to="/dashboard"
            >
              <span>Dashboard</span>
            </Link>
          </li>
        )}

        <li className="nav_item" onClick={hideSideBar}>
          <Link to="/support">
            {/* <IoIcons.IoMdHelpCircle /> */}
            <span>Support</span>
          </Link>
        </li>

        <li className="nav_item" onClick={hideSideBar}>
          <Link to="/buyer">
            <span>Buyer List</span>
          </Link>
        </li>

        <li className="nav_item" onClick={hideSideBar}>
          <Link to="/brand">
            <span>Brand List</span>
          </Link>
        </li>

        {/* <li className="nav_item" onClick={hideSideBar}>
          <Link to="/seller/brand">
            <span> Order</span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

function UserAvatar(props: any) {
  const dispatch = useDispatch<AppDispatch>();
  let {
    showMenu,
    setShowMenu,
    showStores,
    setShowStores,
    currentUser,
    storeList,
  }: any = props;
  const navigate = useNavigate();
  const dropdownRef = useRef<any>();
  const profileType = useSelector(profileTypeSelector);

  useEffect(() => {
    let handler = (event: any) => {
      if (!dropdownRef?.current?.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <div
      className="user-avatar-container"
      style={{
        position: 'absolute',
        right: '29%',
        top: '100%',
      }}
    >
      {showMenu && (
        <div ref={dropdownRef} className="dropdown-menu">
          <ul
            style={{
              padding: '0.2rem 0.8rem',
              color: '#040504',
            }}
          >
            <div
              style={{
                margin: '0.4rem 0',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                borderRadius: '0.4rem',
                padding: '4px 8px',
              }}
            >
              {profileType === 'Store' ? (
                <Link
                  to={'/store/' + `${currentUser.slug}`}
                  style={{
                    flexBasis: '100%',
                  }}
                >
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexBasis: '100%',
                    }}
                  >
                    <div className="icon-cnt">
                      <FaStore className="react-icon" />
                    </div>

                    <span
                      style={{
                        marginLeft: '0.5rem',
                        fontWeight: '610',
                      }}
                    >
                      {currentUser.name}
                    </span>
                  </li>
                </Link>
              ) : (
                <p
                  style={{
                    flexBasis: '100%',
                  }}
                >
                  <li
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexBasis: '100%',
                    }}
                  >
                    <div className="icon-cnt">
                      <FaStore className="react-icon" />
                    </div>

                    <span
                      style={{
                        marginLeft: '0.5rem',
                        fontWeight: '610',
                      }}
                    >
                      {currentUser.name}
                    </span>
                  </li>
                </p>
              )}

              <hr />
              <li
                onClick={
                  storeList.length > 0
                    ? () => setShowStores(true)
                    : () => navigate('/store/create')
                }
              >
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {storeList.length > 0 ? (
                    <>
                      <span
                        style={{
                          fontSize: '0.86rem',
                          fontWeight: '500',
                          color: '#14539A',
                        }}
                      >
                        See All Shops
                      </span>
                      <span
                        style={{
                          background: '#14539A',
                          width: '18px',
                          height: '18px',
                          borderRadius: '50%',
                          color: 'white',
                          display: 'flex',
                          fontSize: '0.7rem',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {storeList.length}
                      </span>
                    </>
                  ) : (
                    <span
                      style={{
                        fontSize: '0.86rem',
                        fontWeight: '500',
                        color: '#14539A',
                      }}
                    >
                      You don't have any store.
                    </span>
                  )}
                </p>
              </li>
            </div>

            <li>
              <Link to="profile#user-profile">
                <div className="icon-cnt">
                  <FiSettings className="react-icon" />
                </div>
                <span className="navigations">Settings</span>
              </Link>
            </li>

            <li
              onClick={async () => {
                await dispatch(logOutAsyncThunk(''));
                await navigate('/login');
              }}
            >
              <p
                style={{
                  display: 'flex',
                  alignItems: ' center',
                }}
              >
                <div className="icon-cnt">
                  {' '}
                  <CiLogout className="react-icon" />
                </div>

                <span className="navigations">Logout</span>
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

function Store(props: any) {
  const {
    info,
    showStores,
    storeList,
    setShowStores,
    currentUser,
    setsidebar,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const storeListRef = useRef<any>(null);

  const makeStoreActive = async (storeId: any) => {
    await dispatch(
      switchProfileAsyncThunk({
        self: false,
        storeId,
      })
    );
    await window.location.reload();
  };

  useEffect(() => {
    let handler = (event: any) => {
      if (!storeListRef?.current?.contains(event.target)) {
        setShowStores(false);
        setsidebar(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <div
      className="user-avatar-container"
      style={{
        position: 'static',
      }}
      ref={storeListRef}
    >
      {showStores && (
        <div className="dropdown-menu" id="store-list-in-header">
          <ul
            style={{
              padding: '0.8rem',
            }}
          >
            <li
              onClick={() => {
                setShowStores(false);
              }}
            >
              <Link to="#">
                <IoIosArrowRoundBack />
                <span
                  style={{
                    marginLeft: '0.5rem',
                  }}
                >
                  Select Store
                </span>
              </Link>
            </li>
            {storeList.length > 0
              ? storeList.map((store: any) => {
                  return (
                    <li
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      onClick={() => makeStoreActive(store._id)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {!store.profileImage ? (
                          <FaStore
                            style={{
                              width: '28px',
                              height: '28px',
                            }}
                          />
                        ) : (
                          <img
                            className="user_profile"
                            style={{
                              margin: '0px',
                            }}
                            src={store.profileImage}
                            alt={store._id}
                          />
                        )}

                        <span
                          style={{
                            marginLeft: '0.5rem',
                          }}
                        >
                          {store.name}
                        </span>
                      </div>

                      <>
                        <input
                          type="radio"
                          checked={
                            currentUser?._id === store?._id ? true : false
                          }
                        />
                      </>
                    </li>
                  );
                })
              : ''}
          </ul>
        </div>
      )}
    </div>
  );
}

function Notification() {
  const [notification, setNotification] = useState<any>([]);
  const [notificationCount, setNotificationCount] = useState<any>();

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  const fetchNotification = async () => {
    await GetRequest('notification').then((res) => {
      if (res.data.statusCode === 200) {
        setNotification(() => res.data.data);
      }
    });
  };

  const fetchNotificationCount = async () => {
    await GetRequest('notification/count').then((res) => {
      if (res.data.statusCode === 200) {
        setNotificationCount(() => res.data.data);
      }
    });
  };

  const markItAsRead = async (data: any, index: number) => {
    await PatchRequest(`notification/${data._id}`, {
      readStatus: ReadStatusEnum.Read,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        let updatedNotification = [...notification];
        updatedNotification[index].readStatus = ReadStatusEnum.Read;
        setNotification(() => updatedNotification);
      }
    });
  };

  const markAllAsRead = async () => {
    await PatchRequest('notification/mark-as-read-all', {
      readStatus: ReadStatusEnum.Read,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        let updatedNotification = [...notification];
        updatedNotification.map((data: any) => {
          data.readStatus = ReadStatusEnum.Read;
          return data;
        });
        setNotification(() => updatedNotification);
      }
    });
  };

  return (
    <div className="notification-icon">
      <div onMouseEnter={fetchNotification} onClick={fetchNotification}>
        <p>
          <IoIosNotifications
            style={{
              fontSize: '1.8rem',
              lineHeight: '1.8rem',
            }}
          />
        </p>
        {notificationCount > 0 && (
          <span className="badge">{notificationCount}</span>
        )}
      </div>

      <div className="notification-menu">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0.5rem',
            flexBasis: 'wrap',
          }}
        >
          <h1 className="title">Notifications</h1>
          <p className="mark-all-read" onClick={markAllAsRead}>
            Mark all as read
          </p>
        </div>

        <ul>
          {notification.length > 0
            ? notification.map((data: any, index: number) => {
                return (
                  <li key={data._id} onClick={() => markItAsRead(data, index)}>
                    {data.readStatus === ReadStatusEnum.Unread && (
                      <span className="circle"></span>
                    )}

                    <p>{data.message}</p>
                  </li>
                );
              })
            : 'No notifications found'}
        </ul>
      </div>
    </div>
  );
}
