
import { PostRequest } from "../component/Request";
import axios from "axios";

export function generateDynamicLink(storeSlug: string): string {
  let searchPrams = new URLSearchParams({
    link: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    apn: 'com.modulusbuy.android',
    amv: '27',
    afl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    ofl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    ibi: `com.modulusbuy.ios`,
    ifl: `https://buy.modulusseventeen.com/store/${storeSlug}`,
    imv: '27',
    utm_campaign: 'banner',
  });
  return 'https://modulusbuymobile.page.link/?' + searchPrams;
}

export async function firebaseDynamicShortLink(storeSlug: string): Promise<{ shortLink: string }> {
  try {
    const response = await axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBBlNszDeAJkUlvOtVSoIj-PGlbgUuVpHY',
      {
        "dynamicLinkInfo": {
          domainUriPrefix: 'https://modulusbuymobile.page.link',
          link: `https://buy.modulusseventeen.com/store/${storeSlug}`,
          "androidInfo": {
            "androidPackageName": 'com.modulusbuy.android',
            "androidFallbackLink": `https://buy.modulusseventeen.com/store/${storeSlug}`,
            "androidMinPackageVersionCode": '55'
          },
          "iosInfo": {
            "iosBundleId": `com.modulusbuy.ios`,
            "iosFallbackLink": `https://buy.modulusseventeen.com/store/${storeSlug}`,
            // "iosCustomScheme": string,
            "iosIpadFallbackLink": `https://buy.modulusseventeen.com/store/${storeSlug}`,
          },
          "navigationInfo": {
            "enableForcedRedirect": true,
          },
        },
        "suffix": {
          "option": "SHORT" // "UNGUESSABLE"
        }
      }
      , {
        headers: {
          "Content-Type": "application/json",
        }
      })
    return response.data;
  } catch (error) {
    return { shortLink: "" }
  }
}

let timeoutId: any;
export const debounce = function (callback: Function, delay: number) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    callback();
    timeoutId = null;
  }, delay);
};
