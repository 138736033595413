import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../features/auth/Slice';
import { useEffect } from 'react';
import { GetRequest } from '../../../component/Request';

export const SchedulePdf = (props: any) => {
  let { schedulePdfRef, schedulePdfDetail } = props;
  const currentUser: any = useSelector(currentUserSelector);

  return (
    <div
      ref={schedulePdfRef}
      className="order bill-of-order"
      key={schedulePdfDetail._id}
      style={{
        padding: '20px',
      }}
    >
      <div className="seller-store-detail">
        <h1 className="seller-name">{currentUser.name}</h1>
        <p className="seller-address">
          {currentUser?.address?.district +
            ' , ' +
            currentUser?.address.state +
            ' , '}
          {currentUser?.address.country +
            ' , ' +
            currentUser?.address.postalCode}
        </p>

        <p className="seller-email">{currentUser.email}</p>
      </div>

      {schedulePdfDetail?.totalItemList?.length > 0 && (
        <>
          <p>Note - Products are given below which needs to get delivered.</p>
          <table
            className="bill-table"
            style={{
              border: '0.4px solid black',
            }}
          >
            <thead
              style={{
                backgroundColor: 'white',
              }}
            >
              <tr
                style={{
                  color: 'black',
                  background: 'white',
                }}
              >
                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                >
                  MRP
                </th>

                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                  colSpan={5}
                >
                  Quantity (In Pieces)
                </th>
              </tr>
            </thead>
            <tbody>
              {schedulePdfDetail?.totalItemList?.map(
                (data: any, indexOfItem: number) => {
                  return (
                    <tr key={indexOfItem}>
                      <td className="order-name-n-img-table-data">
                        <p>{data.product.name}</p>
                      </td>
                      <td> {'₹ ' + data.product.mrp}</td>
                      <td colSpan={5}>{data.quantity.pieceWise}</td>
                    </tr>
                  );
                }
              )}

              <tr className="total-price-details">
                <th
                  className="grand-total"
                  style={{
                    textAlign: 'center',
                    background: 'white',
                    color: 'black',
                  }}
                  colSpan={5}
                >
                  Grand Total
                </th>
                <td
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {'₹ ' + Number(schedulePdfDetail?.totalAmount).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      {schedulePdfDetail?.buyerList?.length > 0 && (
        <>
          <p>Note - All the buyer details whose has ordered the above.</p>
          <table
            className="bill-table"
            style={{
              border: '0.4px solid black',
              background: 'white',
            }}
          >
            <thead
              style={{
                backgroundColor: 'black',
              }}
            >
              <tr
                style={{
                  color: 'black',
                  background: 'white',
                }}
              >
                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                >
                  Address
                </th>
                <th
                  style={{
                    color: 'black',
                    background: 'white',
                  }}
                  colSpan={5}
                >
                  Email
                </th>
              </tr>
            </thead>
            {
              // schedulePdfDetail?.buyerList?.length > 0 &&
              schedulePdfDetail?.buyerList?.map((data: any) => {
                return (
                  <tbody>
                    <tr>
                      <td className="order-name-n-img-table-data">
                        <p>{data?.buyerStore?.name}</p>
                      </td>
                      <td>
                        {' '}
                        {data?.buyerStore?.address?.district +
                          ' , ' +
                          data?.buyerStore?.address?.state +
                          ' , '}
                        {data?.buyerStore?.address?.country +
                          ' , ' +
                          data?.buyerStore?.address?.postalCode}
                      </td>
                      <td colSpan={5}>{data?.buyerStore?.email}</td>
                    </tr>

                    <tr className="total-price-details">
                      <th
                        colSpan={5}
                        style={{
                          textAlign: 'center',
                          background: 'white',
                          color: 'black',
                        }}
                      >
                        Grand Total
                      </th>
                      <td
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        {'₹ ' + Number(data.totalAmount).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                );
              })
            }
          </table>
        </>
      )}

      <p
        style={{
          textAlign: 'center',
          marginTop: '2rem',
          width: '100%',
        }}
      >
        Generated By Modulus Sell.
      </p>
    </div>
  );
};
