import '../style/productList.scss';
import PieceMinimumQuantity from './PieceMinimumQuantity';
import PieceOffer from './PieceOffer';
import PricePerPieceUnit from './PiecePricePerUnit';

export default function Piece(props: any) {
  let {
    setProductState,
    hasError,
    handleAddMoreRange,
    handleChange,
    productState,
    createLastQuantityWisePricePerObj,
  } = props;
  return (
    <div>
      <PieceMinimumQuantity
        handleChange={handleChange}
        productState={productState}
      />
      <PricePerPieceUnit
        createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
        handleAddMoreRange={handleAddMoreRange}
        handleChange={handleChange}
        productState={productState}
        setProductState={setProductState}
      />

      {/* <PieceOffer
        setProductState={setProductState}
        handleChange={handleChange}
        productState={productState}
      /> */}
    </div>
  );
}
