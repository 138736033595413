import { useEffect, useState } from 'react';

import * as FaIcons from 'react-icons/fa';

import './styles/header.scss';
import { Link } from 'react-router-dom';

export default function PublicHeader() {
  const [sidebar, setsidebar] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        margin: '0 auto',
        maxWidth: '1240px',
      }}
    >
      <header
        className="fixed_header"
        style={{
          width: '100%',
          position: 'absolute',
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }}
      >
        <Link to="/">
          <img
            src="/sell-logo.png"
            className="md:w-[100px] w-[140px]"
            style={{
              width: '80px',
            }}
            alt="logo"
            height="40"
          />
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link className="login" to="/store">
            Find Nearby Stores
          </Link>
          <Link className="login" to="/login">
            Login
          </Link>
          <Link className="login" to="/register">
            <button className="start-btn" type="button">
              Get Started
              <FaIcons.FaLongArrowAltRight style={{ marginLeft: '0.4rem' }} />
            </button>
          </Link>
        </div>
      </header>
    </div>
  );
}
