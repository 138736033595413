import '../style/productList.scss';
import BoxMinimumQuantity from './BoxMinimumQuantity';
import BoxOffer from './BoxOffer';
import BoxPricePerUnit from './BoxPricePerUnit';

export default function Box(props: any) {
  let {
    setProductState,
    hasError,
    handleAddMoreRange,
    handleChange,
    productState,
    createLastQuantityWisePricePerObj,
  } = props;
  return (
    <div>
      <BoxMinimumQuantity
        handleChange={handleChange}
        productState={productState}
      />
      <BoxPricePerUnit
        createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
        handleAddMoreRange={handleAddMoreRange}
        handleChange={handleChange}
        productState={productState}
        setProductState={setProductState}
      />

      {/* <BoxOffer
        setProductState={setProductState}
        handleChange={handleChange}
        productState={productState}
      /> */}
    </div>
  );
}
