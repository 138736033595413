import { useEffect, useRef, useState } from 'react';
import './style/schedule.scss';
import { GetRequest } from '../../../component/Request';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { MdOutlineKeyboardBackspace, MdPictureAsPdf } from 'react-icons/md';
import { RiFilter2Fill } from 'react-icons/ri';
import { FiChevronDown, FiChevronUp, FiEdit } from 'react-icons/fi';
import EditScheduleForm from './EditScheduleForm';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../features/auth/Slice';
import { SchedulePdf } from './SchedulePdf';

export const Schedule = (props: any) => {
  let { routeId }: any = useParams();
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [allSchedule, setAllSchedule] = useState([]);
  const [routeName, setRouteName] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const currentUser: any = useSelector(currentUserSelector);
  const [editScheduleInfo, setEditScheduleInfo] = useState({
    status: '',
    startDateAndTime: '',
    endDateAndTime: '',
    createRouteSchedule: {
      startDateAndTime: '',
    },
    error: {
      status: '',
      startDateAndTime: '',
      endDateAndTime: '',
      createRouteSchedule: {
        startDateAndTime: '',
      },
    },
  });
  const [schedulePdfDetail, setSchedulePdfDetail] = useState<any>({ _id: '' });
  const schedulePdfRef = useRef<any>(null);
  const scheduleListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreScheduleListOrNot, setHasMoreScheduleListOrNot] =
    useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getAllSchedule();
  }, [pageNumber]);

  const getAllSchedule = async () => {
    let url: string = `route/${routeId}/schedule?page=${pageNumber}&limit=8`;

    if (selectedFromDate) {
      url = `route/${routeId}/schedule?page=${pageNumber}&limit=8&from-date=${selectedFromDate}`;
    }

    if (selectedToDate) {
      url = `route/${routeId}/schedule?page=${pageNumber}&limit=8&from-date=${selectedToDate}`;
    }

    if (selectedFromDate && selectedToDate) {
      url = `route/${routeId}/schedule?page=${pageNumber}&limit=8&from-date=${selectedFromDate}&to-date=${selectedToDate}`;
    }

    await GetRequest(url).then(async (res) => {
      getRouteDetail();

      let allNewSchedule = res.data.data.map((data: any) => {
        return {
          ...data,
          showDropDown: false,
          showEditStatusForm: false,
        };
      });

      setAllSchedule((prevState) =>
        prevState.length > 0 ? prevState.concat(allNewSchedule) : res.data.data
      );
      if (res.data.data.length < 8) {
        await setHasMoreScheduleListOrNot(false);
      } else {
        await setHasMoreScheduleListOrNot(true);
      }
    });
  };

  const getRouteDetail = async () => {
    await GetRequest(`route/${routeId}`).then((res) => {
      if (res.data.statusCode == 200) {
        setRouteName(res.data.data.name);
      }
    });
  };

  function formatDateToDDMMMYY(inputDate: any) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(inputDate);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const monthAbbreviation = months[date.getUTCMonth()];
    const year = String(date.getUTCFullYear()).slice(-4);

    const formattedDate = `${day}-${monthAbbreviation}-${year}`;

    return formattedDate;
  }

  const seeProduct = async (schedule: any, index: number) => {
    if (!schedule.showDropDown) {
      await GetRequest(`route/${routeId}/schedule/${schedule._id}`).then(
        (res) => {
          if (res.data.statusCode === 200) {
            let updatedAllSchedule: any = [...allSchedule];
            updatedAllSchedule = updatedAllSchedule.map((data: any) => {
              if (schedule._id === data._id) {
                return {
                  ...data,
                  totalItemList: res.data.data.totalItemList,
                  showDropDown: true,
                };
              } else {
                return data;
              }
            });

            setAllSchedule(updatedAllSchedule);
          }
        }
      );
    } else {
      let updatedAllSchedule: any = [...allSchedule];
      updatedAllSchedule = updatedAllSchedule.map((data: any) => {
        if (schedule._id === data._id) {
          return {
            ...data,
            showDropDown: false,
          };
        } else {
          return data;
        }
      });

      setAllSchedule(updatedAllSchedule);
    }
  };

  const giveQuantity = (obj: any) => {
    if (obj.pieceWise) {
      return obj.pieceWise + ' Pieces';
    } else if (obj.bundleWise) {
      return obj.bundleWise + ' Bundles';
    } else {
      return obj.boxWise + ' Boxes';
    }
  };

  const handleFromDateChange = (date: any) => {
    const formattedDate: any = dayjs(date.$d).format(
      'YYYY-MM-DDTHH:mm:ss.SSS+00:00'
    );

    setSelectedFromDate(formattedDate);
  };

  const handleToDateChange = (date: any) => {
    const formattedDate: any = dayjs(date.$d).format(
      'YYYY-MM-DDTHH:mm:ss.SSS+00:00'
    );

    setSelectedToDate(formattedDate);
  };

  const applyFilter = async () => {
    let url = `route/${routeId}/schedule?limit=8&page=1&`;

    if (selectedFromDate) {
      url = url + `from-date=${selectedFromDate}&`;
    }
    if (selectedToDate) {
      url = url + `to-date=${selectedToDate}&`;
    }

    // if (!selectedFromDate && !selectedToDate) {}
    GetRequest(url).then((res) => {
      if (res.data.data.length < 8) {
        setHasMoreScheduleListOrNot(false);
      } else {
        setHasMoreScheduleListOrNot(true);
      }
      let allNewSchedule = res.data.data.map((data: any) => {
        return {
          ...data,
          showDropDown: false,
          showEditStatusForm: false,
        };
      });
      setAllSchedule(allNewSchedule);
    });
  };

  // infinite  scrolling starts here
  const onIntersectionForScheduleList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreScheduleListOrNot) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (scheduleListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForScheduleList);
      observer.observe(scheduleListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allSchedule.length > 0, hasMoreScheduleListOrNot]);

  const generateBill = useReactToPrint({
    content: () => schedulePdfRef?.current,
    documentTitle: schedulePdfDetail?._id + ' - invoice',
  });

  const getScheduleDetailForPdf = async (scheduleId: string) => {
    let newSchedulePdfDetail = { ...schedulePdfDetail };
    await GetRequest(`route/${routeId}/schedule/${scheduleId}`).then(
      async (res) => {
        if (res.data.statusCode === 200) {
          newSchedulePdfDetail = { ...res.data.data };
          await GetRequest(
            `order?status=Ordered&order-detail=true&buyer-store-detail=true&seller-buyer-route-mapped=true&page=1&limit=8&route=${routeId}&route-schedule=${scheduleId}`
          ).then(async (res) => {
            if (res.data.statusCode === 200) {
              newSchedulePdfDetail.buyerList = [...res.data.data];
              console.log(newSchedulePdfDetail, '$$$$');
              await setSchedulePdfDetail(() => newSchedulePdfDetail);
              generateBill();
            }
          });
        }
      }
    );
  };

  return (
    <div className="big-schedule-cnt">
      {allSchedule.length > 0 &&
        allSchedule.map((schedule: any, index: any) => {
          return schedule?.showEditStatusForm ? (
            <EditScheduleForm
              setAllSchedule={setAllSchedule}
              allSchedule={allSchedule}
              index={index}
              setHasMoreScheduleListOrNot={setHasMoreScheduleListOrNot}
              editScheduleInfo={editScheduleInfo}
              setEditScheduleInfo={setEditScheduleInfo}
              activeSchedule={schedule}
              getRouteDetail={getRouteDetail}
            />
          ) : (
            ''
          );
        })}
      {schedulePdfDetail?.totalItem > 0 &&
        schedulePdfDetail?.totalBuyer > 0 && (
          <div
            style={{
              display: 'none',
            }}
          >
            <SchedulePdf
              schedulePdfRef={schedulePdfRef}
              schedulePdfDetail={schedulePdfDetail}
            />
          </div>
        )}
      <div
        className="breadcrumb"
        style={{
          marginLeft: '3rem',
          marginBottom: '1rem',
          textAlign: 'center',
        }}
      >
        <span className="hash">#</span>
        <p className="breadcrumb-text">Schedules</p>
      </div>
      <div className="map-icon-cnt">
        <img
          src="/images/google-maps.jpg"
          alt="Map Icon"
          className="path-icon"
        />
        <h3 className="route-name">{routeName}</h3>
      </div>

      <div className="schedule-cnt">
        <div>
          <input
            type="checkbox"
            id="toggle"
            style={{
              display: 'none',
            }}
          />
          <label
            htmlFor="toggle"
            onClick={() => setShowFilter(true)}
            className="filter-icon"
          >
            <span>
              <RiFilter2Fill />
            </span>
          </label>
        </div>

        <div
          className={!showFilter ? 'filters-section' : 'filters-section-mobile'}
        >
          <button
            className="go-back-button"
            onClick={() => setShowFilter(false)}
          >
            <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
          </button>
          <h2 className="header-of-filter">
            Filters <RiFilter2Fill />
          </h2>

          <hr />
          <FormControl
            fullWidth
            sx={{
              marginTop: '1.5rem',
              '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                width: '160px',
              },
              '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                lineHeight: '1rem',
                fontSize: '0.90rem',
                color: '#9FA6B3',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '0.35rem',
                  borderColor: '#E5E7EB',
                },
              },
            }}
          >
            <div style={{ marginBottom: '1.2rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="From" onChange={handleFromDateChange} />
              </LocalizationProvider>
            </div>
            <div style={{ marginBottom: '1.2rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="To" onChange={handleToDateChange} />
              </LocalizationProvider>
            </div>
            <div className="apply-filter-btn-cnt">
              <button className="apply-filter-btn" onClick={applyFilter}>
                Apply filters
              </button>
            </div>
          </FormControl>
        </div>
        <hr />
        <div className="schedule-based-on-order">
          {allSchedule.length > 0 ? (
            allSchedule.map((schedule: any, index: number) => {
              return (
                <div className="each-schedule" key={index}>
                  <div className="middle-schedule-info">
                    <p className="schedule-date">
                      # {formatDateToDDMMMYY(schedule.startDateAndTime)}
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignContent: 'center',
                      }}
                    >
                      <p className="status">{schedule.status}</p>
                      {schedule.status !== 'Completed' && (
                        <div className="edit" style={{ marginLeft: '0.5rem' }}>
                          <FiEdit
                            onClick={() => {
                              let newEditScheduleInfo = { ...editScheduleInfo };
                              let allScheduleCopy: any = [...allSchedule];
                              allScheduleCopy[index].showEditStatusForm = true;
                              newEditScheduleInfo.status = schedule.status;
                              newEditScheduleInfo.startDateAndTime =
                                schedule.startDateAndTime;

                              // newEditScheduleInfo.endDateAndTime =
                              //   schedule.endDateAndTime;
                              // newEditScheduleInfo.createRouteSchedule.endDateAndTime = schedule.endDateAndTime;
                              setEditScheduleInfo(newEditScheduleInfo);
                              setAllSchedule(allScheduleCopy);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="left-schedule-info">
                    <p>
                      Total Buyers : <span>{schedule.totalBuyer}</span>
                    </p>
                    <p className="total-products">
                      Total Products: <span>{schedule.totalItem}</span>
                      <Link
                        to={`/order?status=${
                          schedule.status === 'Scheduled'
                            ? 'ordered'
                            : schedule.status.toLowerCase()
                        }&route=${routeId}&route-name=${routeName}&route-schedule=${
                          schedule._id
                        }&schedule-start-date-and-time=${
                          schedule.startDateAndTime
                        }`}
                      >
                        (View Orders)
                      </Link>
                    </p>
                  </div>

                  <div className="right-schedule-info">
                    {schedule.totalTaxAmountList.length > 0
                      ? schedule.totalTaxAmountList.map((tax: any) => (
                          <p>
                            {tax.type} : <span>₹ {tax.amount.toFixed(2)}</span>
                          </p>
                        ))
                      : ''}

                    <p>
                      Total <span> ₹ {schedule.totalAmount.toFixed(2)}</span>
                    </p>

                    <p
                      className="pdf-btn"
                      onClick={() => getScheduleDetailForPdf(schedule._id)}
                    >
                      <MdPictureAsPdf
                        style={{
                          marginRight: '0.5rem',
                        }}
                      />
                      PDF
                    </p>
                  </div>
                  <div className="see-products">
                    {!schedule.showDropDown ? (
                      <p onClick={() => seeProduct(schedule, index)}>
                        See Products <FiChevronDown className="arrow-down" />
                      </p>
                    ) : (
                      <p onClick={() => seeProduct(schedule, index)}>
                        Hide Products <FiChevronUp className="arrow-down" />
                      </p>
                    )}
                  </div>
                  {/* product card it is  */}
                  {schedule.showDropDown ? (
                    schedule.totalItemList.length > 0 ? (
                      schedule.totalItemList.map((item: any) => {
                        return (
                          <div className="product-card" key={item.product._id}>
                            <img
                              src={item.product.image}
                              alt="Product Image"
                              className="product-image"
                            />
                            <div className="product-details">
                              <h3 className="product-name">
                                {item.product.name}
                              </h3>
                              <p className="product-quantity">
                                Quantity: {giveQuantity(item.quantity)}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p
                        style={{
                          fontWeight: '550',
                          color: 'red',
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        No Orders Found
                      </p>
                    )
                  ) : (
                    ''
                  )}
                </div>
              );
            })
          ) : (
            <p style={{ fontWeight: '550', color: 'red', textAlign: 'center' }}>
              No Schedules Found
            </p>
          )}

          {allSchedule.length > 0 && (
            <div
              ref={scheduleListRefForInfiniteScrolling}
              style={{ padding: '20px' }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Schedule;
