export enum OrderStatusEnum {
  Recommended = 'Recommended',
  Pending = 'Pending',
  Ordered = 'Ordered',
  Reserved = 'Reserved',
  Preparing = 'Preparing',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  Returned = 'Returned',
  Refunded = 'Refunded',
  Exchanged = 'Exchanged',
  Backordered = 'Backordered',
}
