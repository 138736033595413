import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store';
import {
  IUserStore,
  UpdateSellerStoreAsyncThunk,
  storeProfileSelector,
} from '../../../../features/auth/Slice';
import DocumentPicker from '../../../../common/upload';
import { Document } from '../../../../features/auth/Slice';
import { RxCross2 } from 'react-icons/rx';
import { PostRequest } from '../../../../component/Request';
import { panCardValidation, validateGST } from '../../../../utils';
const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: '0.75rem 1.25rem',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    lineHeight: '1rem',
    fontSize: '0.90rem',
    color: '#9FA6B3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0.35rem',
      borderColor: '#E5E7EB',
    },
  },
});

export default function StoreLegal(props: any) {
  let {
    shopInfo,
    setShopInfo,
    setShowIndicator,
    setMessage,
    setType,
    setSelectedNavigation,
  } = props;
  const navigate = useNavigate();

  //! new code for updated one

  const dispatch = useDispatch<AppDispatch>();
  const [storeData, setStoreData] = useState<any>({});
  const store = useSelector(storeProfileSelector);


  useEffect(() => {
    setShopInfo((prevState: any) => {
      return {
        ...shopInfo,
        pancard: store?.pan,
        gst: store?.gst,
        tradeLicense: store?.tradeLicense,
      };
    });
  }, [store]);

  function handleInputChange(name: string, value: Document): void {
    setStoreData((pre: any) => ({
      ...pre,
      [name]: value,
    }));
  }
  function traverseObject(obj: any) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
        if (value && Object.keys(value).length) {
          traverseObject(value);
        } else {
          delete obj[key];
        }
      } else {
        // Access the property value
        console.log(key, value);
      }
    }
    return obj;
  }

  async function handleUpdateProfile() {
    let { pancard, gst, tradeLicense } = shopInfo;

    const hasValidGST =
      validateGST(shopInfo.gst.number) && shopInfo.errors.gst.number === '';

    const hasValidTradeLicense =
      (shopInfo.tradeLicense.number === '' &&
        shopInfo.tradeLicense.document === '') ||
      (shopInfo.tradeLicense.number !== '' &&
        shopInfo.tradeLicense.document !== '' &&
        shopInfo.errors.tradeLicense.document === '' &&
        shopInfo.errors.tradeLicense.number === '');

    const hasValidPanCard =
      (shopInfo.pancard.number === '' && shopInfo.pancard.document === '') ||
      (shopInfo.pancard.number !== '' &&
        shopInfo.pancard.document !== '' &&
        shopInfo.errors.pancard.document === '' &&
        shopInfo.errors.pancard.number === '');

    let updatedShopInfo = { ...shopInfo };

    if (!hasValidGST) {
      updatedShopInfo.errors.gst.number = 'gst is invalid';
    }

    if (!hasValidTradeLicense) {
      updatedShopInfo.errors.tradeLicense.number =
        'Please write a valid trade license and also upload the trade license';
    }

    if (!hasValidPanCard) {
      updatedShopInfo.errors.pancard.number =
        'Please write a valid pan card and also upload the pan card';
    }

    setShopInfo(() => updatedShopInfo);
    if (
      hasValidGST === true &&
      hasValidTradeLicense === true &&
      hasValidPanCard === true
    ) {
      let response = await dispatch(
        UpdateSellerStoreAsyncThunk(
          traverseObject({
            pan: pancard,
            gst,
            tradeLicense,
          })
        )
      );

      if (response.meta.requestStatus === 'fulfilled') {
        setShowIndicator(true);
        setMessage('Updated Successfully');
        setType('success');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    }
  }

  const handleChange = async (event: any) => {
    let { value, name } = event.target;
    let { errors } = shopInfo;
    switch (name) {
      case 'pancard':
        value = value.toUpperCase();
        if (value.length >= 10 && !panCardValidation(value)) {
          errors.pancard.number = 'PAN Card is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.pancard.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState[name], number: value },
              errors: { ...errors },
            };
          });
        }
        break;
      case 'gst':
        if (!validateGST(value) && value !== '') {
          errors.gst.number = 'gst is invalid';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.gst, number: value },
              errors: { ...errors },
            };
          });
        } else {
          errors.gst.number = '';
          setShopInfo((prevState: any) => {
            return {
              ...prevState,
              [name]: { ...prevState.gst, number: value },
              errors: { ...errors, gst: { number: '', document: '' } },
            };
          });
        }
        break;

      case 'tradeLicense':
        setShopInfo((prevState: any) => {
          return {
            ...prevState,
            [name]: { ...prevState.tradeLicense, number: value },
            errors: { ...errors, tradeLicense: { number: '', document: '' } },
          };
        });
    }
  };

  const handleClickToUploadLegalDocuments = async (event: any, type: any) => {
    let img = event.target.files[0];
    const formData = new FormData();
    formData.append('file', img);
    await PostRequest(`file?type=store-legal-document`, formData)
      .then((fileResponse: any) => {
        if (fileResponse.data.statusCode == 201) {
          if (type == 'pancard') {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.pancard.document = fileResponse.data.data.url;
            updatedShopInfo.errors.pancard.document = '';
            updatedShopInfo.errors.pancard.number = '';
            setShopInfo(updatedShopInfo);
          } else if (type == 'gst') {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.gst.document = fileResponse.data.data.url;
            updatedShopInfo.errors.gst.document = '';
            updatedShopInfo.errors.gst.number = '';
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = fileResponse.data.data.url;
            updatedShopInfo.errors.tradeLicense.document = '';
            updatedShopInfo.errors.tradeLicense.number = '';
            setShopInfo(updatedShopInfo);
          }
        }
      })
      .catch((error) => {
        if (type === 'pancard') {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.pancard.document = '';
            updatedShopInfo.errors.pancard.document =
              error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.pancard.document = '';
            updatedShopInfo.errors.pancard.document = 'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        } else if (type === 'tradeLicense') {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = '';
            updatedShopInfo.errors.tradeLicense.document =
              error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.tradeLicense.document = '';
            updatedShopInfo.errors.tradeLicense.document =
              'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        } else {
          if (
            error.response.data.message === 'File larger than 1 MB not accepted'
          ) {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.gst.document = '';
            updatedShopInfo.errors.gst.document = error.response.data.message;
            setShopInfo(updatedShopInfo);
          } else {
            let updatedShopInfo = { ...shopInfo };
            updatedShopInfo.gst.document = '';
            updatedShopInfo.errors.gst.document = 'Something went wrong';
            setShopInfo(updatedShopInfo);
          }
        }
      });
  };

  return (
    <div className="cnt_of_stepone store-profile-form store-legal-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <form action="#">
        {/* <div className="flex my-10  sm:flex-col  items-center sm:items-stretch">
          <DocumentPicker
            name="pan"
            placeholder="Pan Card"
            label="Pan Card *"
            value={store?.pan}
            handleChange={handleInputChange}
            uploadLocation="store-image"
            inputValidationFC={panCardValidation}
          />
        </div>

        <div className="flex my-10  sm:flex-col items-center sm:items-stretch">
          <DocumentPicker
            name="tradeLicense"
            placeholder=""
            label="Trade License"
            value={store?.tradeLicense}
            handleChange={handleInputChange}
            uploadLocation="store-image"
          />
        </div>

        <div className="flex my-10  sm:flex-col items-center sm:items-stretch">
          <DocumentPicker
            name="gst"
            placeholder="Enter your gst number"
            label="gst*"
            value={store?.gst}
            handleChange={handleInputChange}
            uploadLocation="store-image"
            documentRequired={false}
            inputValidationFC={validateGST}
          />
        </div> */}
        <div className="flex my-10 justify-between sm:flex-col  items-center sm:items-stretch">
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
                color: 'red',
              }}
              onChange={handleChange}
              id="outlined-basic"
              name="pancard"
              label="PAN Card"
              value={shopInfo?.pancard?.number??""}
              variant="outlined"
              placeholder=""
              error={shopInfo.errors.pancard.number ? true : false}
              helperText={
                shopInfo.errors.pancard.number
                  ? shopInfo.errors.pancard.number
                  : ''
              }
            />
          </div>
          <div className="basis-[47.5%] sm:justify-center sm:self-center  justify-between flex sm:mt-2 sm:flex-wrap">
            <div className="uploaded-cnt">
              {shopInfo?.pancard?.document ? (
                <figure
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopInfo = { ...shopInfo };
                      newShopInfo.pancard.document = '';
                      setShopInfo(newShopInfo);
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <iframe
                    allow-transparency="true"
                    src={shopInfo?.pancard?.document}
                    className="rounded-md w-full h-[110px] object-cover"
                  />
                </figure>
              ) : (
                <div>
                  <button
                    type="button"
                    style={{ position: 'relative' }}
                    className={'button-primary shadow-inner'}
                  >
                    <input
                      onChange={(event: any) => {
                        if (panCardValidation(shopInfo.pancard.number)) {
                          return handleClickToUploadLegalDocuments(
                            event,
                            'pancard'
                          );
                        } else {
                          let updatedShopInfo = { ...shopInfo };
                          updatedShopInfo.errors.pancard.number =
                            'Provide a valid PAN Card';

                          setShopInfo(updatedShopInfo);
                        }
                      }}
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      name="pancard"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                      }}
                    />
                    Upload PAN
                  </button>
                </div>
              )}
            </div>

            {shopInfo.errors.pancard.document && (
              <div
                className="error sm:basis-[100%]"
                style={{
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {shopInfo.errors.pancard.document}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            shopInfo?.tradeLicense?.document
              ? 'flex my-10 justify-between sm:flex-col  items-center sm:items-stretch'
              : 'flex my-10 justify-between sm:flex-col  items-start sm:items-stretch'
          }
        >
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
              }}
              onChange={handleChange}
              id="outlined-basic"
              value={shopInfo?.tradeLicense?.number}
              name="tradeLicense"
              label="Trade License"
              variant="outlined"
              error={shopInfo.errors.tradeLicense.number ? true : false}
              helperText={shopInfo.errors.tradeLicense.number}
            />
          </div>
          <div className="basis-[47.5%] sm:justify-center sm:self-center  justify-between flex sm:mt-2 sm:flex-wrap">
            <div className="uploaded-cnt">
              {shopInfo?.tradeLicense?.document ? (
                <figure
                  className="relative m-[0.4rem] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[black]"
                  style={{
                    width: '110px',
                    height: '110px',
                  }}
                >
                  <span
                    onClick={() => {
                      let newShopInfo = { ...shopInfo };
                      newShopInfo.tradeLicense.document = '';
                      setShopInfo(newShopInfo);
                    }}
                    style={{
                      position: 'absolute',
                      right: '4px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: 'white',
                      top: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <RxCross2 />
                  </span>
                  <iframe
                    src={shopInfo?.tradeLicense?.document}
                    className="rounded-md w-full h-[110px] object-cover"
                  />
                </figure>
              ) : (
                <div>
                  <button
                    type="button"
                    style={{ position: 'relative' }}
                    className={'button-primary shadow-inner'}
                  >
                    <input
                      onChange={(event: any) => {
                        if (shopInfo?.tradeLicense?.number) {
                          return handleClickToUploadLegalDocuments(
                            event,
                            'tradeLicense'
                          );
                        } else {
                          let updatedShopInfo = { ...shopInfo };
                          updatedShopInfo.errors.tradeLicense.number =
                            'Provide a valid tradeLicense';

                          setShopInfo(updatedShopInfo);
                        }
                      }}
                      type="file"
                      accept=".jpg, .jpeg, .pdf, .png"
                      name="tradeLicense"
                      style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        opacity: 0,
                      }}
                    />
                    Upload License
                  </button>
                </div>
              )}
            </div>

            {shopInfo.errors.tradeLicense.document && (
              <div
                className="error sm:basis-[100%]"
                style={{
                  margin: '0',
                  textAlign: 'center',
                  marginTop: '0.5rem',
                }}
              >
                {shopInfo.errors.tradeLicense.document}
              </div>
            )}
          </div>
        </div>
        <div className="flex my-10 justify-between sm:flex-col items-center sm:items-stretch">
          <div className="basis-[47.5%]">
            <CssTextField
              sx={{
                width: '100%',
                borderRadius: '1.5rem',
              }}
              onChange={handleChange}
              id="outlined-basic"
              name="gst"
              label="gst"
              value={shopInfo?.gst?.number}
              variant="outlined"
              placeholder=""
              error={shopInfo.errors.gst.number ? true : false}
              helperText={
                shopInfo.errors.gst.number ? shopInfo.errors.gst.number : ''
              }
              required
            />
          </div>
        </div>
        <hr />
        <div className="text-right my-10 mx-2 store-profile-update-cnt">
          <button
            type="button"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleUpdateProfile}
            className={'button-primary md:w-[5rem] md:text-[12px]'}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
