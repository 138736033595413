import './style/brandList.scss';
import { useEffect, useRef, useState } from 'react';
import { GetRequest } from '../../../component/Request';
import { BiLinkExternal } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Brand from './Brand';

export default function BrandList() {
  const [brandList, setBrandList] = useState<any>([]);
  const navigate = useNavigate();
  const [showBrandForm, setShowBrandForm] = useState<Boolean>(false);
  const brandListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreBrandListOrNot, setHasMoreBrandListOrNot] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const addBrand = () => {
    setShowBrandForm(true);
  };

  // infinite  scrolling starts here

  const onIntersectionForBrandList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreBrandListOrNot) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (brandListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForBrandList);
      observer.observe(brandListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [brandList.length > 0]);

  useEffect(() => {
    getRegisteredBrandOnScroll(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    getRegistreredBrand();
  }, []);

  const getRegistreredBrand = async () => {
    await GetRequest(
      `seller/store/brand?seller-brand-product-total-count=true&page=1&limit=8`
    )
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await setBrandList((prevState: any) => [...res.data.data]);

          if (res.data.data.length < 8) {
            await setHasMoreBrandListOrNot(false);
          } else {
            await setHasMoreBrandListOrNot(true);
          }
          setShowBrandForm(false);
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching all brand');
      });
  };

  const getRegisteredBrandOnScroll = async (currentPageNumber: any) => {
    if (hasMoreBrandListOrNot) {
      await GetRequest(
        `seller/store/brand?seller-brand-product-total-count=true&page=${currentPageNumber}&limit=8`
      ).then(async (res) => {
        if (res.data.statusCode === 200) {
          if (res.data.data.length < 8) {
            setHasMoreBrandListOrNot(false);
          } else {
            setHasMoreBrandListOrNot(true);
          }
          setBrandList((prevState: any) => [...prevState, ...res.data.data]);
        }
      });
    }
  };

  // ends here

  return (
    <div className="brand-list-cnt">
      <div className="brand-form-cnt">
        {showBrandForm && (
          <Brand
            getRegisteredBrand={getRegistreredBrand}
            showBrandForm={showBrandForm}
            setShowBrandForm={setShowBrandForm}
          />
        )}
      </div>
      <h1 className="heading"> Explore Your Selling Brands....</h1>
      <ul className="brand-list">
        {brandList.length > 0 &&
          brandList.map((data: any) => {
            return (
              <li className="each-brand" key={data._id}>
                <div className="brand-logo-cnt">
                  <img
                    src={
                      data.brand?.logo
                        ? data.brand?.logo
                        : '/images/no-image-available-icon.jpeg'
                    }
                    style={{
                      border: data.brand?.image ? '1px solid #ddd' : 'none ',
                    }}
                    className="brand-logo-img"
                    alt="brand-logo"
                  />
                </div>
                <h2
                  className="brand-name"
                  style={{
                    marginTop: !data.brand?.image ? '0px' : '0.5rem',
                  }}
                >
                  {data.brand.name}
                </h2>
                <p className="total-product-count">
                  {data.sellerBrandProductTotalCount} Total Products
                </p>
                <p
                  className="links"
                  onClick={() =>
                    navigate(`/seller/brand/${data.brand._id}/product`)
                  }
                >
                  <BiLinkExternal className="icon" /> See Product
                </p>
                <p
                  className="links"
                  onClick={() =>
                    navigate(`/seller/brand/${data.brand._id}/area`)
                  }
                >
                  <BiLinkExternal className="icon" /> See Selling Areas{' '}
                </p>
              </li>
            );
          })}
        {brandList.length > 0 && (
          <div
            ref={brandListRefForInfiniteScrolling}
            style={{ padding: '20px' }}
          ></div>
        )}
      </ul>
      <button
        className="btn-primary add-product-btn"
        style={{ zIndex: showBrandForm ? '-1' : '1' }}
        onClick={() => addBrand()}
      >
        <span className="plus">+</span> Add Brand
      </button>
    </div>
  );
}
