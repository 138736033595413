import { FiEdit } from 'react-icons/fi';
import './styles/routelist.scss';
import { FiInfo, FiUser, FiCalendar } from 'react-icons/fi';
import RouteForm from './RouteForm';
import { useState, useEffect, useContext } from 'react';
import { GetRequest } from '../../../component/Request';
import ActiveStoreContext from '../../../context/ActiveStore/ActiveStoreContext';
import { AiOutlinePlus } from 'react-icons/ai';
import RouteList from './RouteList';

export default function Route(props: any) {
  let [route, setRoute] = useState<any>([]);
  let [showAddForm, setShowAddForm] = useState(false);
  let [headingOfForm, setHeadingOfForm] = useState<string>('');
  const [showType, setShowType] = useState<any>(false);
  const [routeInfo, setRouteInfo] = useState<any>({
    name: '',
    description: '',
    type: 'Daily',
    scheduleCommonDayList: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    coverageArea: {
      placeNameList: [],
    },
    commonStartTime: '',
    commonEndTime: {
      time: '',
      dayInBetweenStartAndEndTime: '',
    },
    createRouteSchedule: {
      startDateAndTime: '',
    },
    error: {
      coverageArea: {
        placeNameList: [],
      },
      name: '',
      description: '',
      scheduleCommonDayList: '',
      commonStartTime: '',
      commonEndTime: {
        time: '',
        dayInBetweenStartAndEndTime: '',
      },
      createRouteSchedule: {
        startDateAndTime: '',
      },
    },
  });
  const [isRouteFinishingOnSameDay, setIsRouteFinishingOnSameDay] =
    useState<any>(true);
  const [weekDay, setWeekDay] = useState<any>([
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]);

  // useEffect(() => {
  //   GetRequest(`route/my-route`)
  //     .then((res) => {
  //       setRoute(res.data.data);
  //       console.log(
  //         res.data.data,
  //         'This is the err i am getting in fetching all my routes'
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(
  //         err,
  //         'This is the err i am getting in fetching all my routes'
  //       );
  //     });
  // }, []);

  return (
    <div style={{ position: 'relative' }}>
      {showAddForm ? (
        <RouteForm
          weekDay={weekDay}
          setWeekDay={setWeekDay}
          isRouteFinishingOnSameDay={isRouteFinishingOnSameDay}
          setIsRouteFinishingOnSameDay={setIsRouteFinishingOnSameDay}
          showType={showType}
          setShowType={setShowType}
          routeInfo={routeInfo}
          setRouteInfo={setRouteInfo}
          setRoute={setRoute}
          headingOfForm={headingOfForm}
          setShowAddForm={setShowAddForm}
        />
      ) : (
        ''
      )}
      <RouteList
        route={route}
        routeInfo={routeInfo}
        setRoute={setRoute}
        setRouteInfo={setRouteInfo}
        setShowAddForm={setShowAddForm}
        setHeadingOfForm={setHeadingOfForm}
      />
    </div>
  );
}
