import './storePublicProfile.scss';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GetRequest } from '../../../component/Request';
import { IoIosArrowRoundForward } from 'react-icons/io';
import Loader from '../../../layout/Loader/Loader';
import { FiSearch } from 'react-icons/fi';
import { debounce } from 'lodash';

export default function StorePublicProfile() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [sellerSlug, setSellerSlug] = useState<any>(slug);
  const [searchedValue, setSearchedValue] = useState<any>('');
  const storeListRef = useRef<any>(null);
  const storeListAndSearchBarRef = useRef<any>(null);
  const [allStore, setAllStore] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const storeListRefForInfiniteScrolling = useRef(null);
  const [sellerList, setSellerList] = useState<any>([]);
  const sellerListRefForInfiniteScrolling = useRef<any>(null);
  // const [hasMoreBuyer]
  const [hasMoreSellerListOrNot, setHasMoreSellerListOrNot] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberForMainSellerList, setPageNumberForMainSellerList] =
    useState(1);

  const onIntersection = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMore) {
      await setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (storeListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersection);
      observer.observe(storeListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allStore.length > 0, hasMore]);

  const handleClickOutside = (event: any) => {
    if (
      storeListAndSearchBarRef.current &&
      !storeListAndSearchBarRef.current.contains(event.target)
    ) {
      setAllStore(() => []);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchedValue) {
      getBuyerAndSeller();
    }
  }, [searchedValue, pageNumber]);

  const handleChangeOnSearchedValue = (event: any) => {
    if (event.target.value.length === 0) {
      setAllStore([]);
    }
    setSearchedValue(event.target.value);
    setPageNumber(() => 1);
  };

  const getBuyerAndSeller = async () => {
    if (searchedValue) {
      await GetRequest(
        `store?name=${searchedValue}&limit=10&page=${pageNumber}`
      ).then(async (res) => {
        if (res.data.statusCode === 200) {
          if (res.data.data.length < 10) {
            setHasMore(() => false);
          } else {
            setHasMore(() => true);
          }
          setAllStore((prevState: any) => [...prevState, ...res.data.data]);
        }
      });
    }
  };

  const handleClickOnStore = (data: any) => {
    setSellerSlug(data.slug);
    navigate(`/store/${data.slug}`);
    setAllStore([]);
    setSearchedValue('');
  };

  // infinite  scrolling starts here

  const onIntersectionForSellerList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreSellerListOrNot) {
      setPageNumberForMainSellerList((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (sellerListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForSellerList);
      observer.observe(sellerListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [sellerList.length > 0, hasMoreSellerListOrNot]);

  useEffect(() => {
    getRegisteredSellerOnScroll(pageNumberForMainSellerList);
  }, [pageNumberForMainSellerList]);

  useEffect(() => {
    getSeller();
  }, []);

  const getSeller = async () => {
    await GetRequest(`store?page=${pageNumberForMainSellerList}&limit=10`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await setSellerList((prevState: any) => [
            ...prevState,
            ...res.data.data,
          ]);

          if (res.data.data.length < 10) {
            await setHasMoreSellerListOrNot(false);
          } else {
            await setHasMoreSellerListOrNot(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching all sellers');
      });
  };

  const getRegisteredSellerOnScroll = async (currentPageNumber: any) => {
    if (hasMoreSellerListOrNot) {
      await GetRequest(`store?page=${currentPageNumber}&limit=10`).then(
        async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 10) {
              setHasMoreSellerListOrNot(false);
            } else {
              setHasMoreSellerListOrNot(true);
            }
            setSellerList((prevState: any) => [...prevState, ...res.data.data]);
          }
        }
      );
    }
  };

  return (
    <div className="all-store-cnt">
      <div className="search-bar-component">
        <div className="search-bar" ref={storeListAndSearchBarRef}>
          <input
            type="text"
            placeholder="Search Store..."
            value={searchedValue}
            onChange={handleChangeOnSearchedValue}
            onKeyUp={() => debounce(getBuyerAndSeller, 500)}
          />
          <button className="search-icon">
            <FiSearch />
          </button>
          {allStore.length > 0 ? (
            <ul
              className="store-list"
              style={{ overflowY: 'scroll' }}
              ref={storeListRef}
            >
              {allStore.map((data: any, index: number) => (
                <li
                  key={data._id}
                  onClick={() => handleClickOnStore(data)}
                  className="each-store"
                >
                  <img
                    src={
                      data.profileImage
                        ? data.profileImage
                        : '/distributor-icon.svg'
                    }
                    alt={data._id}
                    className="store-img"
                  />
                  <div>
                    <p className="name"> {data.name}</p>
                    <p className="location">
                      {data.address.district},{data.address.state},
                      {data.address.country} ,{data.address.postalCode}
                    </p>
                  </div>
                </li>
              ))}
              {hasMore && allStore.length > 0 && (
                <div
                  style={{
                    display: 'block',
                    height: '50px',
                  }}
                  ref={storeListRefForInfiniteScrolling}
                ></div>
              )}
            </ul>
          ) : (
            ''
          )}
        </div>
      </div>
      <section className="seller-list">
        <div className="seller-list-container">
          {sellerList.length > 0 ? (
            sellerList.map((data: any) => {
              return (
                <article className="flex-49">
                  <figure
                    className="figure-1 font-0"
                    style={{
                      background: data.profileImage ? 'none' : '#FFEBCC',
                      borderRadius: '11px',
                    }}
                  >
                    <img
                      className="width-full"
                      src={
                        data.profileImage
                          ? data.profileImage
                          : 'images/seller.png'
                      }
                      style={{
                        objectFit: data.profileImage ? 'contain' : 'scale-down',
                      }}
                      alt="values-1"
                    />
                  </figure>
                  <div className="small-article">
                    <h4 className="text-center">{data.name}</h4>
                    <p>We deal in {data.typeList?.join(' , ')}.</p>
                    <Link to={`/store/${data.slug}`}>
                      See Store
                      <span>
                        <IoIosArrowRoundForward className="arrow-right" />
                      </span>
                    </Link>
                  </div>
                </article>
              );
            })
          ) : (
            <Loader />
          )}

          {sellerList.length > 0 && (
            <div
              ref={sellerListRefForInfiniteScrolling}
              style={{ padding: '20px' }}
            ></div>
          )}
        </div>
      </section>
    </div>
  );
}
