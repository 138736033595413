import { useEffect, useRef, useState } from 'react';
import InputNumber from '../../../common/InputNumber/InputNumber';
import { PatchRequest } from '../../../component/Request';

export default function EditQuantityPopup(props: any) {
  let {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem,
    productIdAndOrderIdForEditingQuantityOrDeletingItem,
    fetchNotAssignedOrder,
    fetchOrderAccToStatus,
  } = props;
  const [quantityInPiece, setQuantityInPiece] = useState<any>('');
  const [error, setError] = useState<string>('');

  const confirmationBoxRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (
      confirmationBoxRef.current &&
      !confirmationBoxRef.current.contains(event.target)
    ) {
      removeOrderIdAndProductId();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const removeOrderIdAndProductId = () => {
    setProductIdAndOrderIdForEditingQuantityOrDeletingItem(() => {
      return { orderId: '', productId: '', editOrDelete: '' };
    });
    setError(() => '');
    setQuantityInPiece(() => '');
  };
  const handleUpdateTheOrderQuantity = async () => {
    let { orderId, productId, quantity } =
      productIdAndOrderIdForEditingQuantityOrDeletingItem;

    if (quantityInPiece && !error && quantityInPiece < quantity) {
      await PatchRequest(`order/${orderId}/product/${productId}`, {
        quantityInPiece: Number(quantityInPiece),
        message: 'Out of stock',
      }).then(async (res: any) => {
        if (res.data.statusCode == 200) {
          removeOrderIdAndProductId();
          if (!fetchOrderAccToStatus) {
            await fetchNotAssignedOrder();
          } else {
            await fetchOrderAccToStatus();
          }
        }
      });
    }
  };

  const handleChange = (event: any) => {
    let { value } = event.target;
    value = value !== '' ? Number(value) : value;

    if (value > productIdAndOrderIdForEditingQuantityOrDeletingItem.quantity) {
      setError(
        () =>
          "Please enter a quantity less than the original buyer's quantity, as you can only reduce the quantity due to limited stock availability."
      );
    }

    if (value === 0) {
      setError(() => 'Please enter a correct quantity');
    }

    if (
      value < productIdAndOrderIdForEditingQuantityOrDeletingItem.quantity &&
      value != 0
    ) {
      setError(() => '');
    }

    setQuantityInPiece(() => value);
  };

  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box" ref={confirmationBoxRef}>
        <p>
          If due to stock constraints or any other reason, you need to adjust
          the quantity of an item in the order, how many pieces would you like
          to provide to the buyer?
        </p>

        <div
          style={{
            margin: '1rem 0',
          }}
        >
          {' '}
          <InputNumber
            name={'quantity'}
            value={quantityInPiece}
            label={'Quantity In Pieces'}
            handleChange={handleChange}
            placeholder={'Such as 10 or 20'}
          />
        </div>

        <p
          className="error"
          style={{
            fontSize: '0.8rem',
          }}
        >
          {error}
        </p>

        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={removeOrderIdAndProductId}
            type="button"
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={handleUpdateTheOrderQuantity}
          >
            Update
          </button>
        </div>
      </div>
    </section>
  );
}
