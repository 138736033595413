import { forwardRef, useState } from 'react';
import './inputDate.scss';
interface Props {
  placeholder: string;
  label: string;
  value: string;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  initialValue?:string;
  InputType?:React.HTMLInputTypeAttribute
}
const InputDate = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [labelOnTop, setLabelOnTop] = useState<Boolean>(!!props.initialValue);

  return (
    <div className="input-date-cnt">
      <label
        className={
          labelOnTop || props.value ? 'label label-should-move-upward' : 'label'
        }
        htmlFor={props.label}
      >
        {props.label}
      </label>
      <input
        ref={ref}
        onFocus={() => setLabelOnTop(true)}
        onBlur={() => {
          if (props.value == '') {
            setLabelOnTop(false);
          } else {
            setLabelOnTop(true);
          }
        }}
        type={props.InputType??"date"}
        id={props.label}
        name={props.name}
        value={props.value}
        defaultValue={props.initialValue}
        onChange={props.handleChange}
        placeholder={labelOnTop && props.placeholder}
        className="input-date"
      />
    </div>
  );
})

export default InputDate
