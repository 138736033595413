import { useEffect, useRef, useState } from 'react';
import { GetRequest } from '../../../component/Request';
import './style/productListInPopUpMode.scss';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export default function ProductListInPopUpMode(props: any) {
  let {
    isLoadingInProductListPopUpMode,
    setIsLoadingInProductListPopUpMode,
    products,
    setProducts,
    setPage,
    setShowProductListPopUpMode,
    selectedProducts,
    setSelectedProducts,
    notEvenOneSelectedByUserErr,
    setNotEvenOneSelectedByUserErr,
    setShowForm,
    brandId,
    page,
  } = props;
  const endOfListRef = useRef<any>(null);
  const [searchedValueForProduct, setSearchedValueForProduct] =
    useState<any>('');
  const [pageNumber, setPageNumber] = useState(1);
  const productListAndSearchBarRef = useRef<any>(null);
  const [allProductInDropDown, setAllProductInDropDown] = useState<any>([]);
  const [hasMoreProductInDropDownOrNot, setHasMoreProductInDropDownOrNot] =
    useState(true);
  const [hasMoreProduct, setHasMoreProduct] = useState(true);
  const productListRefForInfiniteScrolling = useRef<any>(null);
  const productListRef = useRef<any>(null);

  const handleClickOutsideForMainProductList = (event: any) => {
    if (
      productListRef.current &&
      !productListRef.current.contains(event.target)
    ) {
      setSelectedProducts(() => []);
      setShowProductListPopUpMode(() => false);
      setNotEvenOneSelectedByUserErr(() => '');
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideForMainProductList
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForMainProductList
      );
    };
  }, []);

  // dropdown will get closed while outside
  const handleClickOutside = (event: any) => {
    if (
      productListAndSearchBarRef.current &&
      !productListAndSearchBarRef.current.contains(event.target)
    ) {
      setAllProductInDropDown([]);
      // setPageNumber(1);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /****/

  const handleChangeOnSearchedValue = async (event: any) => {
    let { value } = event.target;
    setSearchedValueForProduct(value);
    setPageNumber(1);

    if (value === '') {
      setAllProductInDropDown([]);
    }
  };

  useEffect(() => {
    if (searchedValueForProduct) {
      getProductBasedOnSearchValue();
    }
  }, [searchedValueForProduct]);

  useEffect(() => {
    getProductForDropDownOnScroll(pageNumber);
  }, [pageNumber]);

  const getProductForDropDownOnScroll = async (currentPageNumber: any) => {
    if (searchedValueForProduct) {
      await GetRequest(
        `product?name=${searchedValueForProduct}&limit=8&page=${currentPageNumber}&brand=${brandId}&create-seller-product-detail=true`
      ).then(async (res) => {
        if (res.data.statusCode === 200) {
          if (res.data.data.length < 8) {
            // If response data is less than the limit, set hasMoreProductInDropDownOrNot to false
            setHasMoreProductInDropDownOrNot(false);
            setAllProductInDropDown((prevState: any) => [
              ...prevState,
              ...res.data.data,
            ]);
          } else {
            setHasMoreProductInDropDownOrNot(true);
            setAllProductInDropDown((prevState: any) => [
              ...prevState,
              ...res.data.data,
            ]);
          }
        }
      });
    }
  };

  const onIntersection = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreProductInDropDownOrNot) {
      if (hasMoreProductInDropDownOrNot) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  useEffect(() => {
    if (productListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersection);
      observer.observe(productListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allProductInDropDown.length > 0, hasMoreProductInDropDownOrNot]);

  const handleClickOnProductInDropDown = (data: any) => {
    const productExist = products.some(
      (product: any) => product._id === data._id
    );

    let updatedProducts = [...products];
    updatedProducts.unshift(data);
    // Update state variables

    updatedProducts = updatedProducts.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => o._id === obj._id && o.name === obj.name)
    );

    setSearchedValueForProduct('');
    setProducts(updatedProducts);
    setNotEvenOneSelectedByUserErr('');
    setAllProductInDropDown([]);
  };

  const getProductBasedOnSearchValue = async () => {
    if (searchedValueForProduct) {
      await GetRequest(
        `product?name=${searchedValueForProduct}&limit=8&page=${pageNumber}&brand=${brandId}&create-seller-product-detail=true`
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 8) {
              // If response data is less than the limit, set hasMoreProductInDropDownOrNot to false
              setHasMoreProductInDropDownOrNot(false);
              setAllProductInDropDown(res.data.data);
            } else {
              setHasMoreProductInDropDownOrNot(true);
              setAllProductInDropDown(res.data.data);
            }
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all product ');
        });
    }
  };

  useEffect(() => {
    updateProductsAndFilterTheCheckedOnes();
  }, [page]);

  const updateProductsAndFilterTheCheckedOnes = async () => {
    // if (hasMoreProduct) {
    await GetRequest(
      `product?limit=8&page=${page}&brand=${brandId}&create-seller-product-detail=true`
    ).then(async (res) => {
      if (res.data.statusCode === 200) {
        if (res.data.data.length < 8) {
          // If response data is less than the limit, set hasMoreProduct to false
          setHasMoreProduct(false);
        } else {
          setHasMoreProduct(true);
        }

        // Check for unique products based on _id before updating the state
        setProducts((prevState: any) => {
          const updatedProducts = [...prevState];
          res.data.data.forEach((newProduct: any) => {
            // Check if the new product is not already in the state based on _id
            const isProductExist = updatedProducts.some(
              (existingProduct) => existingProduct._id === newProduct._id
            );
            if (!isProductExist) {
              updatedProducts.push(newProduct);
            }
          });
          return updatedProducts;
        });
      }
    });

    // }
  };

  const onIntersectionForEndOfListRef = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreProduct) {
      setPage((prevPageNumber: any) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (endOfListRef.current) {
      const observer = new IntersectionObserver(onIntersectionForEndOfListRef);
      observer.observe(endOfListRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [products.length > 0, hasMoreProduct]);

  const chooseProduct = (event: any, product: any) => {
    let { checked } = event.target;
    if (checked == true) {
      setSelectedProducts((prevSelectedProducts: any) => {
        return product;
      });
      setNotEvenOneSelectedByUserErr('');
    } else {
      setSelectedProducts([]);
    }
  };

  return (
    <div className="product-list-in-pop-up-mode">
      <div
        className="product-list-cnt"
        ref={productListRef}
        style={{
          padding: '10px',
          background: 'white',
        }}
      >
        <button
          type="button"
          className="go-back-btn"
          style={{
            color: 'red',
            fontSize: '1.5rem',
            background: 'white',
            padding: '0px',
            marginTop: ' -0.5rem',
            alignItems: 'end',
            width: '100%',
            justifyContent: 'end',
          }}
          onClick={() => {
            setSelectedProducts([]);
            setShowProductListPopUpMode(false);
            setNotEvenOneSelectedByUserErr('');
            setPage(1);
          }}
        >
          <span>&times;</span>
        </button>
        <div className="back-n-h1-cnt">
          <h1>
            Customize your product details, set prices, minimum quantities, and
            special offers.
          </h1>
          <Link
            style={{
              flexBasis: '100%',
              color: '#2962ff',
              marginTop: '0.5rem',
              fontSize: '0.9rem',
            }}
            target="_blank"
            to="https://docs.google.com/forms/d/e/1FAIpQLSch4ZrkS_rR8mKtxgNn_1flqOjX_3xJoxZKov5J3TPfNyligg/viewform"
          >
            Don't able to find the product you were looking for? Help us add
          </Link>
        </div>

        <div className="search-bar-component">
          <div className="search-bar" ref={productListAndSearchBarRef}>
            <input
              type="text"
              name="product-name"
              placeholder="Search Product..."
              value={searchedValueForProduct}
              onChange={handleChangeOnSearchedValue}
            />
            <button
              className="search-icon"
              onClick={getProductBasedOnSearchValue}
            >
              <FiSearch />
            </button>
            {allProductInDropDown.length > 0 ? (
              <ul
                id={'product-list-n-search-bar'}
                className="product-list"
                style={{ overflowY: 'scroll' }}
              >
                {allProductInDropDown.map((data: any, index: number) => (
                  <li
                    key={index}
                    onClick={() => handleClickOnProductInDropDown(data)}
                    className="each-product"
                  >
                    <img
                      src={
                        data.imageList.length > 0
                          ? data.imageList[0]
                          : '/no-image-available-icon.jpeg'
                      }
                      alt={data._id}
                      className="product-img"
                    />
                    <div>
                      <p className="name"> {data.name}</p>
                      <p className="mrp">MRP - ₹{data.mrp}</p>
                    </div>
                  </li>
                ))}
                {allProductInDropDown.length > 0 && (
                  <div
                    ref={productListRefForInfiniteScrolling}
                    style={{ padding: '10px' }}
                  ></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>

        {isLoadingInProductListPopUpMode ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p>Loading...</p>
          </div>
        ) : (
          <div className="table">
            <div className="table-header">
              <div className="table-cell"> Name</div>
              <div className="table-cell"> Image</div>
              <div
                className="table-cell"
                style={{
                  flex: '0.5',
                }}
              >
                MRP
              </div>
              <div
                className="table-cell"
                style={{
                  flex: '0.5',
                }}
              >
                Weight
              </div>
              <div
                className="table-cell"
                style={{
                  flex: '0.3',
                }}
              >
                Size
              </div>
              <div
                className="table-cell"
                style={{
                  flex: '0.5',
                }}
              >
                Color
              </div>
              <div
                className="table-cell"
                style={{
                  flex: '0.5',
                }}
              >
                Flavor
              </div>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
              <div
                className="table-body"
                style={{
                  overflowY: 'scroll',
                }}
              >
                {products.length > 0
                  ? products.map((product: any, index: number) => {
                      return (
                        <div key={product._id} className="tooltip">
                          <div
                            className="table-row"
                            style={{
                              borderTop: product.isActive
                                ? '1px solid #ddd'
                                : 'none',
                            }}
                          >
                            <div className="table-cell product-name-n-checkbox">
                              <div className="checkbox">
                                <input
                                  className="input-type-checkbox"
                                  type="checkbox"
                                  checked={
                                    selectedProducts?._id == product._id
                                      ? true
                                      : false
                                  }
                                  onChange={(event: any) =>
                                    chooseProduct(event, product)
                                  }
                                />
                              </div>

                              <div className="name">
                                {product.name}
                                <div className="tooltiptext">
                                  {product.name}
                                </div>
                              </div>
                            </div>
                            <div className="table-cell">
                              <img
                                src={product.imageList[0]}
                                alt={product.name}
                                className="product-img"
                              />
                            </div>
                            <div
                              className="table-cell"
                              style={{
                                flex: '0.5',
                              }}
                            >
                              <p
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {'₹' + product.mrp}
                              </p>
                            </div>
                            {
                              <p
                                className="table-cell"
                                style={{
                                  flex: '0.5',
                                }}
                              >
                                {product.weight
                                  ? product.weight?.quantity +
                                    ' ' +
                                    product.weight?.unit
                                  : '-.-'}
                              </p>
                            }
                            {
                              <p
                                style={{
                                  flex: '0.3',
                                }}
                                className="table-cell"
                              >
                                {product.size
                                  ? product.size?.quantity +
                                    ' ' +
                                    product.size?.unit
                                  : '-.-'}
                              </p>
                            }
                            {
                              <p
                                style={{
                                  flex: '0.5',
                                }}
                                className="table-cell"
                              >
                                {product?.color?.name
                                  ? product?.color?.name
                                  : '-.-'}
                              </p>
                            }

                            {
                              <p
                                style={{
                                  flex: '0.5',
                                }}
                                className="table-cell"
                              >
                                {product?.flavor?.name
                                  ? product?.flavor?.name
                                  : '-.-'}
                              </p>
                            }
                          </div>
                        </div>
                      );
                    })
                  : ''}
                {products.length > 0 && (
                  <div ref={endOfListRef} style={{ padding: '20px' }}></div>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {notEvenOneSelectedByUserErr && (
            <div className="error" style={{ margin: '0', textAlign: 'center' }}>
              {notEvenOneSelectedByUserErr}
            </div>
          )}
          <button
            className="submit-btn"
            onClick={() => {
              if (selectedProducts.length === 0) {
                setNotEvenOneSelectedByUserErr(
                  'Select one product to continue'
                );
              } else {
                setShowProductListPopUpMode(false);
                setShowForm(true);
              }
            }}
          >
            Continue to Product Details
          </button>
        </div>
      </div>
    </div>
  );
}
