import { AiFillInfoCircle } from 'react-icons/ai';
import '../style/productList.scss';
import InputNumber from '../../../../common/InputNumber/InputNumber';
import '../piece/style/pieceMinimumQuantity.scss';

//for minimum quantity

export default function EditMinimumQuantity(props: any) {
  let { handleChange, productStateTobeUpdated, activeOption } = props;
  // Construct field names based on activeOption
  const fieldPrefix = activeOption ? activeOption.toLowerCase() : ''; // Convert activeOption to lowercase
  const fieldName = `${fieldPrefix}MinimumQuantity`;
  const isMultipleFieldName = `${fieldPrefix}IsMultiple`;
  const incDecQuantityFieldName = `${fieldPrefix}IncDecQuantity`;

  return (
    <div>
      <div className="input-n-error">
        <InputNumber
          htmlFor={fieldName}
          name={fieldName}
          value={productStateTobeUpdated.minimumQuantity[fieldPrefix]?.quantity}
          label={`Minimum Quantity`}
          handleChange={handleChange}
          placeholder={'Enter quantity'}
        />

        <div className="error">
          {productStateTobeUpdated.error.minimumQuantity[fieldPrefix]?.quantity}
        </div>
      </div>

      <div
        className="sell-modal-form"
        style={{
          border: 'none',
        }}
      >
        <div
          className="checkbox-group"
          style={{
            display: 'block',
          }}
        >
          <input
            type="checkbox"
            className="edit-checkbox"
            name={isMultipleFieldName}
            checked={
              productStateTobeUpdated.minimumQuantity[fieldPrefix]?.isMultiple
                .enable
            }
            onChange={handleChange}
          />
          <label htmlFor={isMultipleFieldName}>
            Enable custom quantity increment/decrement: Set the value to be
            added when customers increase or decrease the quantity above the
            minimum
          </label>
        </div>
        <div className="error">
          {
            productStateTobeUpdated.error.minimumQuantity[fieldPrefix]
              ?.isMultiple.enable
          }
        </div>
      </div>

      {productStateTobeUpdated.minimumQuantity[fieldPrefix].isMultiple
        .enable ? (
        <div className="input-n-error">
          <InputNumber
            name={incDecQuantityFieldName}
            htmlFor={incDecQuantityFieldName}
            value={
              productStateTobeUpdated.minimumQuantity[fieldPrefix].isMultiple
                .number
            }
            label={'Quantity Inc/Dec'}
            handleChange={handleChange}
            placeholder={'Enter quantity'}
          />

          <div className="error">
            {
              productStateTobeUpdated.error.minimumQuantity[fieldPrefix]
                .isMultiple.number
            }
          </div>
        </div>
      ) : (
        ''
      )}
      <hr />
    </div>
  );
}
