import { useContext, useState } from 'react';
import { GetRequest, PostRequest } from '../../../component/Request';
import Box from './box/Box';
import Bundle from './bundle/Bundle';
import Piece from './piece/Piece';
import ProductContext from '../../../context/ProductContext';

// on the basis what user has selected in terms of options
export default function Main(props: any) {
  let {
    setPageNumberForConfiguredProductList,
    brandId,
    activeOption,
    handleAddMoreRange,
    option,
    closeThePopUp,
    handleChange,
    productState,
    setProductState,
    selectedProducts,
    setShowForm,
    createLastQuantityWisePricePerObj,
    setConfiguredProducts,
    commonError,
    setCommonError,
    configuredProducts,
    setHasMoreProductInConfiguredListOrNot,
  } = props;
  const [payload, setPayload] = useState<any>({
    productIds: [selectedProducts._id],
    minimumQuantity: {},
    offer: {},
    pricePerUnit: {},
  });
  let { initialProductState } = useContext(ProductContext);

  const hasError = (obj: any): boolean => {
    if (obj === undefined || obj === null) {
      return false;
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (
          value === true ||
          (typeof value === 'string' && value.trim() !== '')
        ) {
          return true;
        } else if (typeof value === 'object' && hasError(value)) {
          return true;
        }
      }
    }
    return false;
  };

  const createProductDetailForAllOption = async () => {
    const updatedPayload = { ...payload };
    for (let i = 0; i < option.length; i++) {
      if (option[i] === activeOption) {
        let optionInLoop = option[i];
        await checkErrorInAllOptionAndCreatePayload(optionInLoop);
      } else {
        let optionInLoop = option[i];
        if (
          productState.minimumQuantity[optionInLoop].quantity ||
          productState.minimumQuantity[optionInLoop].isMultiple.number ||
          productState.offer[optionInLoop + 'OfferList'].length > 0
        ) {
          await checkErrorInAllOptionAndCreatePayload(optionInLoop);
        }
        if (
          productState.pricePerUnit[optionInLoop].price ||
          !productState.pricePerUnit[optionInLoop].unique
        ) {
          await checkErrorInAllOptionAndCreatePayload(optionInLoop);
        }
      }
    }

    // if (Object.keys(updatedPayload?.offer)?.length === 0) {
    //   delete updatedPayload.offer;
    // }

    setPayload(updatedPayload);

    if (!hasError(productState.error)) {
      setCommonError('');
      await handlePostRequest(updatedPayload);
    } else {
      setCommonError(
        `Check the ${option.join(
          ' , '
        )} for errors and fix any you find. Thank you!`
      );
    }
  };

  const checkErrorInAllOptionAndCreatePayload = async (type: any) => {
    let newProductState = { ...productState };
    const typeLowerCase = type.toLowerCase();
    let obj: any = { ...payload };

    // logic update newProductState and error messages

    // checking for minimmum quantity
    if (
      newProductState.minimumQuantity[typeLowerCase].quantity &&
      newProductState.minimumQuantity[typeLowerCase].quantity !== '0'
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].quantity = '';
    } else {
      newProductState.error.minimumQuantity[typeLowerCase].quantity =
        'Please provide more than one';
    }

    if (
      newProductState.minimumQuantity[typeLowerCase].isMultiple.enable === ''
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].isMultiple.enable =
        'Please select one from above ones';
    } else if (
      newProductState.minimumQuantity[typeLowerCase].isMultiple.enable == true
    ) {
      newProductState.error.minimumQuantity[typeLowerCase].isMultiple.enable =
        '';
      if (!newProductState.minimumQuantity[typeLowerCase].isMultiple.number) {
        newProductState.error.minimumQuantity[typeLowerCase].isMultiple.number =
          'Please provide common price';
      } else if (
        newProductState.minimumQuantity[typeLowerCase].isMultiple.number
      ) {
        newProductState.error.minimumQuantity[typeLowerCase].isMultiple.number =
          '';
      }
    }

    // error for [typeLowerCase] price per unit
    if (newProductState.pricePerUnit[typeLowerCase].unique === '') {
      newProductState.error.pricePerUnit[typeLowerCase].unique =
        'Please select one from above ones';
    } else if (newProductState.pricePerUnit[typeLowerCase].unique == true) {
      if (newProductState.pricePerUnit[typeLowerCase].price == '') {
        newProductState.error.pricePerUnit[typeLowerCase].price =
          'Please provide price';
      }
    } else {
      newProductState.error.pricePerUnit[typeLowerCase].unique = '';
      newProductState.pricePerUnit[typeLowerCase].quantityWisePriceList.forEach(
        (obj: any, index: any) => {
          if (obj.price === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].price = 'Please provide price';
          }

          if (obj.moreThanOrEqual === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].moreThanOrEqual =
              'Please provide min. value';
          }

          if (obj.moreThanOrEqual == '0') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].moreThanOrEqual =
              'Please provide at least 1';
          }

          if (obj.lessThanOrEqual === '') {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].lessThanOrEqual =
              'Please provide max. value';
          }

          if (obj.lessThanOrEqual < obj.moreThanOrEqual) {
            newProductState.error.pricePerUnit[
              typeLowerCase
            ].quantityWisePriceList[index].lessThanOrEqual =
              'Please provide more than from value';
          }
        }
      );
    }

    // write logic for offer error later on

    // if (newProductState.offer[typeLowerCase + 'OfferList'].length > 0) {
    //   let offerErrorArray =
    //     newProductState.error.offer[typeLowerCase + 'OfferList'];
    //   let offerArray = newProductState.offer[typeLowerCase + 'OfferList'];

    //   offerArray.forEach((offer: any, index: number) => {
    //     if (!offer.quantity) {
    //       offerErrorArray[index].quantity = 'Required';
    //     }
    //     if (!offer.free.quantity) {
    //       offerErrorArray[index].free.quantity = 'Required';
    //     }
    //     if (!offer.free.type) {
    //       offerErrorArray[index].free.type = 'Required';
    //     }
    //   });
    // }

    setProductState(newProductState);

    // After handling errors, update the payload properties based on your conditions
    if (newProductState.minimumQuantity[typeLowerCase].isMultiple.enable) {
      obj.minimumQuantity[typeLowerCase] = {
        isMultiple: newProductState.minimumQuantity[typeLowerCase].isMultiple,
        quantity: newProductState.minimumQuantity[typeLowerCase].quantity,
      };
    } else {
      obj.minimumQuantity[typeLowerCase] = {
        quantity: newProductState.minimumQuantity[typeLowerCase].quantity,
        isMultiple: { enable: false, number: 1 },
      };
    }

    if (newProductState.pricePerUnit[typeLowerCase].unique) {
      obj.pricePerUnit[typeLowerCase] = {
        unique: newProductState.pricePerUnit[typeLowerCase].unique,
        price: newProductState.pricePerUnit[typeLowerCase].price,
      };
    } else {
      obj.pricePerUnit[typeLowerCase] = {
        unique: false,
        quantityWisePriceList:
          newProductState.pricePerUnit[typeLowerCase].quantityWisePriceList,
      };
    }

    // Handle payload for offer later on

    // if (newProductState?.offer[typeLowerCase + 'OfferList']?.length > 0) {
    //   let key = typeLowerCase + 'OfferList';
    //   obj.offer[key] = newProductState?.offer[key];
    // }

    // if (newProductState?.offer[typeLowerCase + 'OfferList']?.length === 0) {
    //   let key = typeLowerCase + 'OfferList';
    //   delete obj.offer[key];
    // }

    setProductState(newProductState);

    if (!hasError(newProductState.error)) {
      return obj; // Returning the updated payload
    }

    return null; // Handle the case where there are errors
  };

  const handlePostRequest = async (payload: any) => {
    await PostRequest(`seller/store/brand/${brandId}/product`, payload).then(
      (res: any) => {
        if (res.data.statusCode === 201) {
          GetRequest(
            `product?brand=${brandId}&page=1&seller-product-detail=true&seller-product-only=true&limit=8`
          )
            .then(async (res) => {
              if (res.data.statusCode === 200) {
                if (res.data.data.length < 8) {
                  setHasMoreProductInConfiguredListOrNot(false);
                } else {
                  setHasMoreProductInConfiguredListOrNot(true);
                }
                setPageNumberForConfiguredProductList(() => 1);
                setConfiguredProducts((prevState: any) => res.data.data);
                await closeThePopUp();
              }
            })
            .catch((err) => {
              console.log(err, 'err in submitting the product detail form');
              // setLoading(false);
            });
          setShowForm(false);
        }
      }
    );
  };

  if (activeOption === 'box') {
    return (
      <>
        <Box
          createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
          setProductState={setProductState}
          handleChange={handleChange}
          productState={productState}
          handleAddMoreRange={handleAddMoreRange}
        />
        <span className="error">{commonError}</span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            style={{
              color: 'white',
              background: '#14539a',
            }}
            onClick={() => createProductDetailForAllOption()}
            className="submit-btn"
          >
            Submit
          </button>
        </div>
      </>
    );
  } else if (activeOption === 'bundle') {
    return (
      <>
        <Bundle
          createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
          setProductState={setProductState}
          handleChange={handleChange}
          productState={productState}
          handleAddMoreRange={handleAddMoreRange}
        />
        <span className="error">{commonError}</span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            style={{
              color: 'white',
              background: '#14539a',
            }}
            onClick={() => createProductDetailForAllOption()}
            className="submit-btn"
          >
            Submit
          </button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Piece
          createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
          hasError={hasError}
          setProductState={setProductState}
          handleChange={handleChange}
          productState={productState}
          handleAddMoreRange={handleAddMoreRange}
        />
        <span className="error">{commonError}</span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            type="button"
            style={{
              color: 'white',
              background: '#14539a',
            }}
            onClick={() => createProductDetailForAllOption()}
            className="submit-btn"
          >
            Submit
          </button>
        </div>
      </>
    );
  }
}
