import '../storePublicProfile.scss';
import { FaRegImage } from 'react-icons/fa';
import { RiDeleteBinFill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { PatchRequest, PostRequest } from '../../../../component/Request';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export default function StorePublicProfile({
  handleChange,
  storeProfileInfo,
  setStoreProfileInfo,
  fetchStoreProfileDetail,
  updateStoreProfileInfo,
  setUpdateStoreProfileInfo,
  setSelectedNavigation,
  storeId,
  setShowIndicator,
  setType,
  setMessage,
}: any) {
  let navigate = useNavigate();

  const removeCoverImage = () => {
    let newStoreProfileInfo = { ...storeProfileInfo };
    newStoreProfileInfo.coverImage.image = '';
    newStoreProfileInfo.errors.coverImage = '';
    setStoreProfileInfo(newStoreProfileInfo);
    setUpdateStoreProfileInfo(true);
  };

  function handleDragStartBanner(event: any) {
    event.dataTransfer.setData('text/plain', event.currentTarget.dataset.index);
  }

  function handleDragOverBanner(event: any) {
    event.preventDefault();
  }

  function handleDropBanner(event: React.DragEvent<HTMLElement>) {
    event.preventDefault();
    const oldIndex = Number(event.dataTransfer.getData('text/plain'));
    const newIndex = Number(event.currentTarget.dataset.index);
    const maxIndex = storeProfileInfo.banner.imageList.length - 1;

    if (
      oldIndex < 0 ||
      oldIndex > maxIndex ||
      newIndex < 0 ||
      newIndex > maxIndex
    ) {
      return; // Invalid index, do not perform the replacement
    }

    if (oldIndex === newIndex) {
      return; // No need to perform the replacement if oldIndex and newIndex are the same
    }

    setStoreProfileInfo((prevShopInfo: any) => {
      const newBanner = [...prevShopInfo.banner.imageList];
      const draggedImage = newBanner[oldIndex];
      newBanner.splice(oldIndex, 1); // Remove the dragged element
      newBanner.splice(newIndex, 0, draggedImage); // Insert the dragged element at the drop position
      setUpdateStoreProfileInfo(true);
      return {
        ...prevShopInfo,
        banner: newBanner,
      };
    });
  }

  // useEffect(() => {
  //   fetchStoreProfileDetail();
  // }, []);

  const handleSubmit = async () => {
    let { banner, aboutUs, coverImage } = storeProfileInfo;
    let obj: any = {};

    if (banner.imageList.length > 0) {
      obj.banner = banner;
    }

    if (aboutUs.text) {
      obj.aboutUs = aboutUs;
    }

    if (coverImage.image) {
      obj.coverImage = coverImage;
    }

    if (Object.keys(obj).length > 1) {
      await PostRequest('store/profile', obj).then(async (res) => {
        if (res.data.statusCode == 201) {
          await fetchStoreProfileDetail();
          setShowIndicator(true);
          setMessage('Successfully Created');
          setType('success');
          setTimeout(() => {
            setShowIndicator(false);
          }, 3000);
        }
      });
    }
  };

  const handleUpdate = async () => {
    let { banner, aboutUs, coverImage } = storeProfileInfo;
    let obj: any = {};

    if (banner.imageList.length > 0) {
      obj.banner = banner;
    }

    if (aboutUs.text) {
      obj.aboutUs = aboutUs;
    }

    if (coverImage.image) {
      obj.coverImage = coverImage;
    }

    await PatchRequest('store/profile', obj).then((res) => {
      if (res.data.statusCode === 200) {
        setShowIndicator(true);
        setMessage('Successfully Updated');
        setType('success');
        setTimeout(() => {
          setShowIndicator(false);
        }, 3000);
      }
    });
  };

  return (
    <div className="public-store-profile-form">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <button
          className="go-back-button"
          onClick={() => {
            setSelectedNavigation('');
            navigate('/profile');
          }}
        >
          <MdOutlineKeyboardBackspace style={{ display: 'inline' }} />
        </button>
      </div>
      <div
        className="cover-picture-cnt"
        style={{
          padding: !storeProfileInfo.coverImage.image ? '1rem' : '0px',
        }}
      >
        {storeProfileInfo.coverImage.image ? (
          <>
            <div className="cross-icon-cnt" onClick={removeCoverImage}>
              <RiDeleteBinFill className="cross-icon" />
            </div>
            <img
              src={storeProfileInfo.coverImage.image}
              alt="Cover Picture"
              className="cover-image"
            />
          </>
        ) : (
          <>
            <FaRegImage className="img-icon" />
            <p className="upload-text">Upload profile cover here</p>
            <button className="btn-primary">
              Upload
              <input
                type="file"
                onChange={handleChange}
                className="file-input"
                name="coverImage"
              />
            </button>
          </>
        )}
        {storeProfileInfo.errors.coverImage ? (
          <span
            className="error"
            style={{
              marginTop: '0.9rem',
              marginBottom: '',
            }}
          >
            {storeProfileInfo.errors.coverImage}
          </span>
        ) : (
          ''
        )}
      </div>

      <div className="about-field">
        <textarea
          placeholder="About"
          name="aboutUs"
          value={storeProfileInfo.aboutUs.text}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="mb-4 mt-2">
        <div className="mb-[-0.7rem] ml-[0.5rem]">
          <label
            htmlFor="shopImage"
            className="text-sm bg-white px-[0.2rem] lg:text-xs font-semibold"
          >
            Banner For Your Seller Profile
          </label>
        </div>

        {/* card for showing uploaded banner by the user */}
        <div
          className="basis-[90%] sm:p-[0.5rem] flex flex-wrap rounded border-solid border-[1px] p-4 border-gray-220"
          style={{
            borderColor: '#ddd',
          }}
        >
          {storeProfileInfo.banner.imageList.map((data: any, index: number) => (
            <figure
              key={index}
              draggable
              onDragStart={handleDragStartBanner}
              onDragOver={handleDragOverBanner}
              onDrop={handleDropBanner}
              data-index={index}
              className="relative m-[0.4rem] basis-[100%] sm:m-[0.2rem] inline-block rounded border-[1px] border-solid border-[#ddd]"
            >
              <span
                onClick={() => {
                  let newBannerList = storeProfileInfo.banner.imageList.filter(
                    (data: any, i: number) => {
                      return i !== index;
                    }
                  );
                  setStoreProfileInfo({
                    ...storeProfileInfo,
                    banner: { imageList: newBannerList },
                  });
                }}
                style={{
                  position: 'absolute',
                  right: '4px',
                  borderRadius: '50%',
                  display: 'flex',
                  background: 'white',
                  top: '5px',
                  color: 'red',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '20px',
                  height: '20px',
                }}
              >
                <RxCross2 />
              </span>
              <img
                src={data.image}
                className="rounded-md w-[100%] h-[200px] object-contain"
                alt={'banner-image' + index}
              />
            </figure>
          ))}
          <button
            className="w-[110px] m-[0.4rem] sm:m-[0.2rem] p-[24px] h-[110px] border-box bg-[#EAECEC] h-auto flex-col flex items-center justify-center border-solid border-[1px] border-gray-220 rounded-md"
            style={{
              width: '110px',
              height: '110px',
            }}
          >
            <i
              style={{
                backgroundImage: 'url("/add_photos_icon.png")',
                backgroundPosition: '0px -58px',
                backgroundSize: 'auto',
                color: 'white',
                width: '20px',
                height: '20px',
                backgroundRepeat: 'no-repeat',
                display: 'inline-block',
              }}
            ></i>
            <span className="text-xs mt-2">Add Banner</span>
            <input
              type="file"
              name="banner"
              style={{
                position: 'absolute',
                opacity: '0',
                cursor: 'pointer',
                width: '110px',
                top: '100',
                height: '110px',
              }}
              onChange={handleChange}
            />
          </button>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <span className="error"> {storeProfileInfo.errors.banner}</span>
        </div>
      </div>

      <button
        type="submit"
        onClick={updateStoreProfileInfo ? handleUpdate : handleSubmit}
        className="btn-primary"
      >
        {!updateStoreProfileInfo ? 'Submit' : 'Update'}
      </button>
    </div>
  );
}
