import './slidesOfPolygon.scss';
import { useState } from 'react';

export default function PolygonSlides(props: any) {
  const { slideStep, setSlideStep } = props;

  if (slideStep == 1) {
    return <SlideOne slideStep={slideStep} setSlideStep={setSlideStep} />;
  } else if (slideStep == 2) {
    return <SlideTwo slideStep={slideStep} setSlideStep={setSlideStep} />;
  } else if (slideStep == 3) {
    return <SlideThree slideStep={slideStep} setSlideStep={setSlideStep} />;
  } else {
    return <div style={{ display: 'hidden' }}></div>;
  }
}

function SlideOne(props: any) {
  let { slideStep, setSlideStep } = props;
  return (
    <section className="slides-cnt">
      <div className="slide-box">
        <p className="slide-info">
          It shows how to resize your polygon on the map based on your
          distribution area.
        </p>
        <div>
          <img width="100%" src="/images/resize-drag.gif" alt="resize-drag" />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={() => setSlideStep(null)}
            type="button"
            className="cancel-btn"
          >
            Skip All
          </button>
          <button
            type="button"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={() => setSlideStep(slideStep + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function SlideTwo(props: any) {
  let { slideStep, setSlideStep } = props;
  return (
    <section className="slides-cnt">
      <div className="slide-box">
        <p className="slide-info">
          It shows how to search your area of distribution.
        </p>
        <div>
          <img width="100%" src="/images/search-area.gif" alt="search-area" />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            type="button"
            onClick={() => setSlideStep(null)}
            className="cancel-btn"
          >
            Skip All
          </button>
          <button
            onClick={() => setSlideStep(slideStep + 1)}
            type="button"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function SlideThree(props: any) {
  let { slideStep, setSlideStep } = props;
  return (
    <section className="slides-cnt">
      <div className="slide-box">
        <p className="slide-info">
          It shows how to undo and redo your steps in polygon creation on the
          map.
        </p>
        <div>
          <img width="100%" src="/images/undo-redo.gif" alt="undo-redo" />
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            type="button"
            onClick={() => setSlideStep(null)}
            className="cancel-btn"
          >
            Skip
          </button>
        </div>
      </div>
    </section>
  );
}
