import { AiFillInfoCircle } from 'react-icons/ai';
import '../style/productList.scss';
import InputNumber from '../../../../common/InputNumber/InputNumber';
import '../piece/style/pieceMinimumQuantity.scss';

//for minimum quantity
export default function BundleMinimumQuantity(props: any) {
  let { handleChange, productState } = props;

  return (
    <div>
      <div className="input-n-error" style={{ marginTop: '1rem !important' }}>
        <InputNumber
          htmlFor={'bundleMinimumQuantity'}
          name={'bundleMinimumQuantity'}
          value={productState.minimumQuantity.bundle.quantity}
          label={'Minimum Quantity'}
          handleChange={handleChange}
          placeholder={'Enter quantity'}
        />

        <div className="error">
          {productState.error.minimumQuantity.bundle.quantity}
        </div>
      </div>

      <div
        className="sell-modal-form"
        style={{
          border: 'none',
        }}
      >
        <div className="checkbox-group">
          <label className="form-control-text">
            <input
              type="checkbox"
              name="bundleIsMultiple"
              checked={productState.minimumQuantity.bundle.isMultiple.enable}
              onChange={handleChange}
            />
            Enable custom quantity increment/decrement: Set the value to be
            added when customers increase or decrease the quantity above the
            minimum
          </label>
        </div>
        <div className="error">
          {productState.error.minimumQuantity.bundle.isMultiple.enable}
        </div>
      </div>

      {productState.minimumQuantity.bundle.isMultiple.enable ? (
        <div className="input-n-error">
          <InputNumber
            htmlFor={'bundleIncDecQuantity'}
            name={'bundleIncDecQuantity'}
            value={productState.minimumQuantity.bundle.isMultiple.number}
            label={'Quantity Inc/Dec'}
            handleChange={handleChange}
            placeholder={'Enter quantity'}
          />

          <div className="error">
            {productState.error.minimumQuantity.bundle.isMultiple.number}
          </div>
        </div>
      ) : (
        ''
      )}
      <hr />
    </div>
  );
}
