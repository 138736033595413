import { useEffect, useRef, useState } from 'react';
import { PatchRequest } from '../../../component/Request';
import InputText from '../../../common/Select/Select';
import { useLocation } from 'react-router-dom';

export default function ChangeTheOrderStatus(props: any) {
  let {
    setOrderIdToConvertInShippedOrDelivered,
    fetchOrderAccToStatus,
    orderIdToConvertInShippedOrDelivered,
  } = props;
  const [orderStatus, setOrderStatus] = useState<string>('');
  const confirmationBoxRef = useRef<any>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status');
  const [option, setOption] = useState<any>([]);
  const [error, setError] = useState('');
  const handleClickOutside = (event: any) => {
    if (
      confirmationBoxRef.current &&
      !confirmationBoxRef.current.contains(event.target)
    ) {
      removeOrderIdForShippedOrDelivered();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  function capitalizeWords(inputString: any) {
    return inputString?.replace(/\b\w/g, (match: any) => match.toUpperCase());
  }

  const handleUpdateTheOrderToShippedOrDelivered = async () => {
    if (!error && orderStatus) {
      let payload: any = { status: orderStatus };
      if (orderIdToConvertInShippedOrDelivered.status === 'Ordered') {
        payload.routeScheduleId = '';
      }

      await PatchRequest(
        `order/${orderIdToConvertInShippedOrDelivered._id}`,
        payload
      )
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            setOrderIdToConvertInShippedOrDelivered(() => {
              return { _id: '', status: '' };
            });
            await fetchOrderAccToStatus(capitalizeWords(status));
          }
        })
        .catch((err) => {
          console.log(err, 'err while updating the status');
        });
    }
  };

  const handleChange = (event: any) => {
    let { value } = event.target;
    setOrderStatus(() => (value === 'Choose one' ? '' : value));
    if (value === 'Choose one') {
      setError('required');
    } else {
      setError('');
    }
  };

  const removeOrderIdForShippedOrDelivered = () => {
    setOrderIdToConvertInShippedOrDelivered(() => {
      return { _id: '', status: '' };
    });
  };

  useEffect(() => {
    giveOptionAccToOrderStatus();
  }, []);

  const giveOptionAccToOrderStatus = () => {
    if (orderIdToConvertInShippedOrDelivered.status === 'Ordered') {
      setOption(() => ['Choose one', 'Preparing', 'Shipped', 'Delivered']);
    } else if (orderIdToConvertInShippedOrDelivered.status === 'Preparing') {
      setOption(() => ['Choose one', 'Shipped', 'Delivered']);
    } else {
      setOption(() => ['Choose one', 'Delivered']);
    }
  };

  return (
    <section className="confirmation-cnt">
      <div className="confirmation-box" ref={confirmationBoxRef}>
        <p>
          {orderIdToConvertInShippedOrDelivered.status === 'Ordered'
            ? 'Are you sure you want to change the status ? Please note that this buyer will be removed from their current schedule.'
            : 'Are you thinking about updating your order status to better track packages for users?'}
        </p>
        <div
          style={{
            margin: '1.5rem 0',
          }}
        >
          <select
            style={{
              border: '1px solid #dddd',
              padding: '8px 10px',
              borderRadius: '5px',

              width: '100%',
            }}
            name="reason"
            value={orderStatus}
            id="reason"
            onChange={handleChange}
          >
            {option.map((data: any) => (
              <option value={data}>{data}</option>
            ))}
          </select>
          {/* <InputText
            name={'status'}
            value={orderStatus}
            label={'Choose one'}
            handleClickOnOption={handleClickOnOption}
            placeholder={'Choose One'}
            option={option}
          /> */}
        </div>
        <p className="error">{error}</p>
        <div
          style={{
            textAlign: 'center',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={removeOrderIdForShippedOrDelivered}
            type="button"
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            type="button"
            id="button-primary"
            style={{
              padding: '4px',
              width: '6rem',
              height: 'auto',
              marginLeft: '2rem',
            }}
            className="button-primary"
            onClick={handleUpdateTheOrderToShippedOrDelivered}
          >
            Update
          </button>
        </div>
      </div>
    </section>
  );
}
