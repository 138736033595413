import { axiosRequest } from '../api/Axios';
import { showError } from '../features/auth/Slice';
import { store } from '../store';

// axiosRequest.interceptors.response.use(
//   (response) => response,
//   async (error) => {
// const location = useLocation();
// if (
//   error.response.request.responseURL !==
//     'http://localhost:5500/api/v1/user/self' &&
//   location.pathname !== '/login' &&
//   error.response.request.responseURL !==
//     'http://localhost:5500/api/v1/auth/refresh'
// ) {
// }
// store.dispatch(
//   showError({
//     type: 'error',
//     message: error.response.data.message,
//     errorId: String(Math.random()),
//   })
// );

// return error;
//   }
// );

const axiosPrivate = async (
  config: any,
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  // const dispatch = useDispatch<AppDispatch>();
  try {
    const response = await axiosRequest(config);

    // if (showIndicatorForSuccessRequest) {
    //   store.dispatch(
    //     showError({
    //       type: 'success',
    //       message: response.data.data.message,
    //       errorId: String(Math.random()),
    //     })
    //   );
    // }

    // if (showIndicatorForErrorRequest && optionalMsg) {
    //   store.dispatch(
    //     showError({
    //       type: 'success',
    //       message: optionalMsg,
    //       errorId: String(Math.random()),
    //     })
    //   );
    // }

    // if (showIndicatorForSuccessRequest && optionalMsg) {
    //   store.dispatch(
    //     showError({
    //       type: 'success',
    //       message: optionalMsg,
    //       errorId: String(Math.random()),
    //     })
    //   );
    // }

    return response;
  } catch (error: any) {
    if (showIndicatorForErrorRequest) {
      store.dispatch &&
        store.dispatch(
          showError({
            type: 'error',
            message: error.response.data.message,
            errorId: String(Math.random()),
          })
        );
    }

    if (error.response && error.response.status === 401) {
      try {
        let refreshTokenResponse = await axiosRequest.get(`auth/refresh-token`);
      } catch (err: any) {
        if (err.response && err.response.status === 401) {
          //todo: remove loggedIn redux and redirect to login page
          // dispatch(logOutAsyncThunk(''));
        } else {
          // todo: add logout redux chunk
          // dispatch(logOutAsyncThunk(''));
        }
      }
    }
    // Handle other errors or throw the error
    throw error;
  }
};

export const GetRequest = async (
  URL: string,
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  return axiosPrivate(
    {
      method: 'get',
      url: URL,
    },
    showIndicatorForErrorRequest,
    showIndicatorForSuccessRequest,
    optionalMsg
  );
};

export const PostRequest = async (
  URL: string,
  data = {},
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  return axiosPrivate(
    {
      method: 'post',
      url: URL,
      data: data,
    },
    showIndicatorForErrorRequest,
    showIndicatorForSuccessRequest,
    optionalMsg
  );
};

export const PutRequest = async (
  URL: string,
  data = {},
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  return axiosPrivate(
    {
      method: 'put',
      url: URL,
      data: data,
    },
    showIndicatorForErrorRequest,
    showIndicatorForSuccessRequest,
    optionalMsg
  );
};

export const PatchRequest = async (
  URL: string,
  data = {},
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  return axiosPrivate(
    {
      method: 'patch',
      url: URL,
      data: data,
    },
    showIndicatorForErrorRequest,
    showIndicatorForSuccessRequest,
    optionalMsg
  );
};

export const DeleteRequest = async (
  URL: string,
  showIndicatorForErrorRequest = true,
  showIndicatorForSuccessRequest = false,
  optionalMsg = ''
) => {
  return axiosPrivate(
    {
      method: 'delete',
      url: URL,
    },
    showIndicatorForErrorRequest,
    showIndicatorForSuccessRequest,
    optionalMsg
  );
};
