import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './style/mobileProductList.scss';
import ProductTable from './ProductTable';
export default function MobileProductList({
  configuredProducts,
  configuredProductListRefForInfiniteScrolling,
  setPageNumberForConfiguredProductList,
  setHasMoreProductInConfiguredListOrNot,
  setConfiguredProducts,
  brandId,
  handleAddMoreRange,
  handleChange,
  setShowForm,
  setShowDeleteDialogueBox,
  setProductIdToBeDeleted,
  deleteProduct,
  setShowEditForm,
  setSelectedProducts,
  showConfiguredDetails,
}: any) {
  return (
    <div className="productListCntMb">
      {configuredProducts.length > 0 &&
        configuredProducts.map((data: any, index: any) => {
          return (
            <div key={index} className="productMb">
              <div className="imgAndName">
                <img
                  className="productImg"
                  src={data.imageList.length > 0 && data.imageList[0]}
                  alt={data.name}
                />
                <p className="productName">{data.name}</p>
              </div>

              <div className="productDetailInRow">
                <p>MRP</p>
                <p className="detail">{'₹' + data.mrp}</p>
              </div>

              <div className="productDetailInRow">
                <p>Weight</p>
                <p className="detail">
                  {data.weight
                    ? data.weight?.quantity + ' ' + data.weight?.unit
                    : '-.-'}
                </p>
              </div>

              <div className="productDetailInRow">
                <p> Flavor</p>
                <p className="detail">
                  {data?.flavor?.name ? data?.flavor?.name : '-.-'}
                </p>
              </div>

              <div className="productDetailInRow">
                <p>Piece in 1 bundle</p>
                <p className="detail">
                  {data?.info?.pieceInEachBundle
                    ? data?.info?.pieceInEachBundle
                    : '-.-'}
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <p
                  className={
                    !data.showDropdown
                      ? 'seeProductDetail'
                      : 'hideProductDetail'
                  }
                  onClick={() => showConfiguredDetails(index)}
                >
                  {!data.showDropdown
                    ? `See Product Details `
                    : `Hide Product Details  `}

                  {data.showDropdown ? <FiChevronUp /> : <FiChevronDown />}
                </p>
              </div>

              {data.showDropdown && (
                <ProductTable
                  setPageNumberForConfiguredProductList={
                    setPageNumberForConfiguredProductList
                  }
                  setHasMoreProductInConfiguredListOrNot={
                    setHasMoreProductInConfiguredListOrNot
                  }
                  setConfiguredProducts={setConfiguredProducts}
                  brandId={brandId}
                  handleAddMoreRange={handleAddMoreRange}
                  handleChange={handleChange}
                  setShowForm={setShowForm}
                  setSelectedProducts={setSelectedProducts}
                  setShowDeleteDialogueBox={setShowDeleteDialogueBox}
                  setProductIdToBeDeleted={setProductIdToBeDeleted}
                  deleteProduct={deleteProduct}
                  setShowEditForm={setShowEditForm}
                  configuredProduct={configuredProducts[index]}
                />
              )}
            </div>
          );
        })}
      {configuredProducts.length > 0 && (
        <div
          ref={configuredProductListRefForInfiniteScrolling}
          style={{ padding: '20px' }}
        ></div>
      )}
    </div>
  );
}
