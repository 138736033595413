import { FiEdit } from 'react-icons/fi';
import './styles/routelist.scss';
import { FiInfo, FiUser, FiCalendar } from 'react-icons/fi';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaRoute } from 'react-icons/fa';
import { Link, Navigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GetRequest, PostRequest } from '../../../component/Request';
import { Route, RouteSchedule } from '../../../interface/axios.types';
import InputText from '../../../common/InputText/InputText';
import { RxCross1 } from 'react-icons/rx';
import InputTextArea from '../../../common/InputText/InputTextArea';

export default function RouteList({
  setHeadingOfForm,
  route: routeList,
  setRouteInfo,
  setShowAddForm,
  setRoute,
}: any) {
  let navigate = useNavigate();
  const routeListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreRouteListOrNot, setHasMoreRouteListOrNot] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showNotifyModal, setShowNotifyModal] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);
  const [notificationForm, setNotificationForm] = useState<Record<string, any>>(
    {
      notificationTitle: 'New Order Preparing',
      notificationMessage:
        'New order is coming soon so if you want to order something then make order',
    }
  );
  function convertToDatetimeLocalFormat(inputFormat: any) {
    try {
      const inputDate = new Date(inputFormat);

      if (isNaN(inputDate.getTime())) {
        throw new Error('Invalid input format');
      }

      const day = inputDate.getDate();
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = monthNames[inputDate.getMonth()];
      const year = inputDate.getFullYear().toString().slice(-2);
      const hours = inputDate.getHours();
      const minutes = inputDate.getMinutes();
      const amOrPm = hours >= 12 ? 'pm' : 'am';

      // Convert hours to 12-hour format
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes
        .toString()
        .padStart(2, '0')}${amOrPm}`;
      return formattedDate;
    } catch (error) {
      return 'Invalid input format';
    }
  }

  function convertTimeFormat(inputTime: string): string {
    try {
      const [timePart, _] = inputTime.split('+');
      const [hours, minutes] = timePart.split(':').map(Number);

      let period = 'AM';
      let convertedHours = hours;

      if (convertedHours >= 12) {
        period = 'PM';
        if (convertedHours > 12) {
          convertedHours -= 12;
        }
      }

      const convertedTime = `${convertedHours}:${minutes
        .toString()
        .padStart(2, '0')} ${period}`;
      return convertedTime;
    } catch (error) {
      return 'Invalid input time format';
    }
  }

  const handleClickOnEdit = (id: any) => {
    let routeNeedToBeUpdate = routeList.filter((route: any) => route._id == id)[0];
    console.log(routeNeedToBeUpdate);
    let {
      _id,
      name,
      description,
      type,
      scheduleCommonDayList,
      coverageArea,
      commonStartTime,
      commonEndTime,
      upcomingRouteSchedule,
    } = routeNeedToBeUpdate;
    setRouteInfo({
      _id,
      name,
      description,
      type,
      scheduleCommonDayList,
      coverageArea,
      commonStartTime,
      commonEndTime,
      createRouteSchedule: {
        startDateAndTime:
          routeNeedToBeUpdate.upcomingRouteSchedule.startDateAndTime,
      },
      error: {
        coverageArea: {
          placeName: [],
        },
        name: '',
        description: '',
        scheduleCommonDayList: '',
        commonStartTime: '',
        commonEndTime: {
          time: '',
          dayInBetweenStartAndEndTime: '',
        },
        createRouteSchedule: {
          startDateAndTime: '',
        },
      },
    });

    setHeadingOfForm('Update your route');
    setShowAddForm(true);
  };

  // infinite  scrolling starts here

  const onIntersectionForRouteList = async (entries: any) => {
    const firstEntry = entries[0];

    if (firstEntry.isIntersecting && hasMoreRouteListOrNot) {
      if (hasMoreRouteListOrNot) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    }
  };

  useEffect(() => {
    if (routeListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForRouteList);
      observer.observe(routeListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [routeList.length > 0]);

  useEffect(() => {
    getRegisteredRouteOnScroll(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    getRegistreredRoute();
  }, []);

  const getRegistreredRoute = async () => {
    await GetRequest(`route/my-route?page=${pageNumber}&limit=8`)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await setRoute((prevState: any) => [...prevState, ...res.data.data]);

          if (res.data.data.length < 8) {
            await setHasMoreRouteListOrNot(false);
          } else {
            await setHasMoreRouteListOrNot(true);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching all route');
      });
  };

  const getRegisteredRouteOnScroll = async (currentPageNumber: any) => {
    if (hasMoreRouteListOrNot) {
      await GetRequest(`route/my-route?page=${currentPageNumber}&limit=8`)
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.length < 8) {
              setHasMoreRouteListOrNot(false);
            } else {
              setHasMoreRouteListOrNot(true);
            }
            setRoute((prevState: any) => [...prevState, ...res.data.data]);
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching all route');
        });
    }
  };
  async function handleNotifyBuyers(route: Route) {
    setShowNotifyModal(true);
    setSelectedRoute(route);
  }
  function handleCloseNotifyModal() {
    setShowNotifyModal(() => false);
    setSelectedRoute(null);
  }
  async function handleNotifySubmit(submitEvent: FormEvent<HTMLFormElement>) {
    submitEvent.preventDefault();
    if (notificationForm.notificationMessage.length < 5) {
      setNotificationForm((pre) => ({
        ...pre,
        error: {
          ...pre?.error,
          notificationMessage: 'Please enter at least 5 characters',
        },
      }));
      return;
    }
    if (notificationForm.notificationTitle.length < 5) {
      setNotificationForm((pre) => ({
        ...pre,
        error: {
          ...pre?.error,
          notificationTitle: 'Please enter at least 5 characters',
        },
      }));
      return;
    }
    try {
      const response = await PostRequest(`route/${selectedRoute?._id}/send-notification`, {
        notificationTitle: notificationForm.notificationTitle,
        notificationMessage: notificationForm.notificationMessage,
      });
    } catch (error: any) {
      setNotificationForm((pre) => ({
        ...pre,
        error: {
          ...pre?.error,
          server: error?.message,
        },
      }));
    }
  }
  // ends here

  return (
    <>
    <div className="route-boxes-cnt">
      <h1 className="breadcrumb">
        <span className="hash">#</span>
        <div className="breadcrumb-text">Routes</div>
      </h1>
      <header className="heading-of-route-page">
        Easily plan your daily visits to different areas with organized routes.
      </header>
      {routeList.length > 0
        ? routeList.map((data: any) => {
            return (
              <div className="middle-cnt" key={data._id}>
                <div
                  className={`route-box ${
                    data.status == 'Active' ? '' : 'inactive'
                  }`}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src="/images/google-maps.jpg"
                      alt="Map Icon"
                      className="map-icon"
                    />
                  </div>

                  <div className="route-details">
                    <h2 className="route-name">{data.name}</h2>
                    <p className="route-description">{data.description}</p>

                    <p className="route-schedule">
                      <span className="label">Schedule : </span>

                      {data.scheduleCommonDayList.join(', ')}
                    </p>
                    <p className="route-coverage">
                      <span className="label"> Area Covering : </span>

                      {data.coverageArea.placeNameList.length > 0 &&
                        data.coverageArea.placeNameList.join(', ')}
                    </p>
                    <p className="route-start-time">
                      <span className="label"> Start Time : </span>

                      {convertTimeFormat(data.commonStartTime)}
                    </p>
                    <p className="route-end-time">
                      <span className="label">End Time : </span>

                      {convertTimeFormat(data.commonEndTime.time)}
                    </p>
                    <p className="route-start-date">
                      <span className="label"> Route Start Date : </span>
                      {convertToDatetimeLocalFormat(
                        data.upcomingRouteSchedule.startDateAndTime
                      )}
                    </p>
                  </div>
                  {
                    <FiEdit
                      className="edit-icon"
                      onClick={() => handleClickOnEdit(data._id)}
                    />
                  }
                  <hr />
                  <div className="links">
                    {/* <Link to="#">
                      <FiInfo className="icon" /> More Info
                    </Link> */}
                    <p
                      className="view-schedule-and-view-buyer-link"
                      onClick={() => {
                        navigate(
                          `/buyer?routeId=${data._id}&routeName=${data.name}&seller-buyer-route-mapped-only=true`
                        );
                      }}
                    >
                      <FiUser className="icon" /> View Buyers
                    </p>
                    <Link
                      className="view-schedule-and-view-buyer-link"
                      to={`/route/${data._id}/schedule`}
                    >
                      <FiCalendar className="icon" /> View Schedule
                    </Link>
                    <p
                      className='view-schedule-and-view-buyer-link'
                      onClick={() => handleNotifyBuyers(data)}
                    >
                      Notify Buyers
                    </p>
                  </div>
                </div>
                {data.status !== 'Active' ? (
                  <button className="make-active-btn">
                    Click me to make it active
                  </button>
                ) : (
                  ''
                )}
              </div>
            );
          })
        : ''}
      {routeList.length > 0 && (
        <div
          ref={routeListRefForInfiniteScrolling}
          style={{ padding: '1px' }}
        ></div>
      )}
      <div
        className="circle"
        onClick={() => {
          setHeadingOfForm('Add new route');
          return setShowAddForm(true);
        }}
      >
        <FaRoute style={{ color: 'white', marginLeft: '-0.5rem' }} />{' '}
        <span>
          <AiOutlinePlus className="plus" />
        </span>
      </div>
    </div>
      {showNotifyModal && (
        <div className='route-notify-modal'>
          <div className='box'>
            <form onSubmit={handleNotifySubmit}>
              <div>
                <div className='heading'>
                  <h1 className='text'>
                    Notify buyer in {selectedRoute?.name} route{' '}
                  </h1>
                  <RxCross1
                    className='text text-red'
                    onClick={handleCloseNotifyModal}
                  />
                </div>
                <InputText
                  placeholder={'Enter title of navigation'}
                  label={'Title'}
                  value={notificationForm?.notificationTitle}
                  handleChange={function (
                    event: ChangeEvent<HTMLInputElement>
                  ): void {
                    setNotificationForm((pre) => ({
                      ...pre,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                  name={'notificationTitle'}
                  inputProps={{
                    min: 5,
                    inputMode: 'text',
                    maxLength: 50,
                  }}
                />

                {!!notificationForm?.error?.notificationTitle && (
                  <p>{notificationForm?.error?.notificationTitle}</p>
                )}

                <InputTextArea
                  placeholder={'Enter message of the Notification'}
                  value={notificationForm?.notificationMessage}
                  handleChange={function (
                    event: ChangeEvent<HTMLTextAreaElement>
                  ): void {
                    setNotificationForm((pre) => ({
                      ...pre,
                      [event.target.name]: event.target.value,
                    }));
                  }}
                  name={'notificationMessage'}
                  label='Message'
                  inputProps={{
                    min: 5,
                    inputMode: 'text',
                    maxLength: 150,
                    style: { paddingTop: '10px', fontSize: '14px' },
                  }}
                />
                {!!notificationForm?.error?.notificationMessage && (
                  <p>{notificationForm?.error?.notificationMessage}</p>
                )}

                <button type='submit' className='button-group'>
                  Notify
                </button>
                {!!notificationForm?.error?.server && (
                  <p>{notificationForm?.error?.server}</p>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
