import { RiDeleteBin5Fill } from 'react-icons/ri';
import { AiTwotonePhone, AiTwotoneShop } from 'react-icons/ai';
import { FaRoute } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  DeleteRequest,
  GetRequest,
  PostRequest,
} from '../../../component/Request';
import './style/buyer.scss';
import BuyerFilter from './BuyerFilter';
import { FiExternalLink, FiSearch } from 'react-icons/fi';
import ConfirmationBox from './ConfirmationBox';
import AssignOrReassignRoute from './AssignOrReassignRoute';
import { useSelector } from 'react-redux';
import { IUserStore, currentUserSelector } from '../../../features/auth/Slice';
import Loader from '../../../layout/Loader/Loader';
import debounce from 'lodash/debounce';
import { RouteMappedBuyer } from './types';

export default function BuyerAccToRoute(props: any) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const routeName = queryParams.get('routeName');
  const routeId = queryParams.get('routeId');
  const sellerBuyerRouteMappedOnly = queryParams.get(
    'seller-buyer-route-mapped-only'
  );
  const [allBuyer, setAllBuyer] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<any>({
    id: queryParams.get('routeId') && '',
    name: queryParams.get('routeName') && '',
  });
  const [showFilter, setShowFilter] = useState(false);
  // route state starts here
  const [listOfRoute, setListOfRoute] = useState([]);
  const [pageNumberForRoute, setPageNumberForRoute] = useState(1);
  const [hasMoreRouteListOrNot, setHasMoreRouteListOrNot] =
    useState<boolean>(false);
  const [routeObserver, setRouteObserver] = useState<any>(null);
  const [isRouteOpen, setIsRouteOpen] = useState(false);
  const routeRef = useRef<any>(null);
  const [errorForRoute, setErrorForRoute] = useState('');
  // route state ends here
  const [userWantToSeeNotAssignedBuyer, setUserWantToSeeNotAssignedBuyer] =
    useState<any>(sellerBuyerRouteMappedOnly === 'false' ? true : false);
  const [showConfirmationBox, setShowConfirmationBox] =
    useState<Boolean>(false);
  const [dataForDelete, setDataForDelete] = useState<any>({});
  const [showAssignRouteForm, setShowAssignRouteForm] =
    useState<Boolean>(false);
  const [sellerRoute, setSellerRoute] = useState<any>([]);
  const [buyerStore, setBuyerStore] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const currentStore = useSelector(currentUserSelector);
  const buyerListRefForInfiniteScrolling = useRef<any>(null);
  const routeListRefForInfiniteScrolling = useRef<any>(null);
  const [hasMoreBuyerListOrNot, setHasMoreBuyerListOrNot] =
    useState<boolean>(false);
  const storeListRef = useRef<any>(null);
  const [allStore, setAllStore] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const storeListRefForInfiniteScrolling = useRef(null);
  const [searchedValue, setSearchedValue] = useState<any>('');
  const [pageNumberForBuyerDropDown, setPageNumberForBuyerDropDown] =
    useState<number>(1);
  const [hasMoreBuyerInDropdownOrNot, setHasMoreBuyerInDropdownOrNot] =
    useState<boolean>(false);
  const buyerDropdownRefForInfiniteScrolling = useRef<any>(null);
  const [isLoadingForMainBuyerList, setIsLoadingForMainBuyerList] =
    useState<boolean>(false);

  const handleAssignOrReassignTheRoute = async (store: IUserStore) => {
    return await GetRequest(`route?seller-store=${currentStore?._id}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setBuyerStore(store);
          setSellerRoute(res.data.data);
          setShowAssignRouteForm(true);
        }
      })
      .catch((err) => console.log(err, 'err in fetching the seller route'));
  };

  const onIntersectionForBuyerList = async (entries: any) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMoreBuyerListOrNot) {
      setPage((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (buyerListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForBuyerList);
      observer.observe(buyerListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allBuyer.length > 0, hasMoreBuyerListOrNot]);

  // useEffect(() => {
  //   fetchBuyerOnScroll();
  // }, [page]);

  useEffect(() => {
    if (routeId && routeName) {
      setSelectedRoute(() => {
        return {
          id: routeId,
          name: routeName,
        };
      });
    }
    fetchBuyer();
  }, [page]);

  // const fetchBuyerOnScroll = async () => {
  //   if (hasMoreBuyerListOrNot) {
  //   let url: any = `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&limit=8&page=${page}`;

  //   if (routeId && routeName) {
  //     url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&route=${routeId}`;
  //   }

  //   if (sellerBuyerRouteMappedOnly === 'false') {
  //     url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=false&route-detail=true`;
  //   }
  //   await GetRequest(url)
  //     .then(async (res) => {
  //       if (res.data.data.length < 8) {
  //         await setHasMoreBuyerListOrNot(() => false);
  //       } else {
  //         await setHasMoreBuyerListOrNot(() => true);
  //       }
  //       setAllBuyer((prevState: any) => {
  //         return prevState.concat(res.data.data);
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err, 'err in fetching');
  //     });
  //   }
  // };

  const fetchBuyer = async () => {
    setIsLoadingForMainBuyerList(true);
    let url: any = `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&limit=8&page=${page}&route-summary=true`;

    if (routeId && routeName) {
      url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&route=${routeId}&route-summary=true`;
    }

    if (sellerBuyerRouteMappedOnly === 'false') {
      url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=false&route-summary=true`;
    }

    await GetRequest(url)
      .then((res) => {
        if (res.data.data.length < 8) {
          setHasMoreBuyerListOrNot(false);
        } else {
          setHasMoreBuyerListOrNot(true);
        }
        setAllBuyer((prevState: any) =>
          prevState.length > 0 ? prevState.concat(res.data.data) : res.data.data
        );
      })
      .catch((err) => {
        console.log(err, 'err in fetching');
      });
    setIsLoadingForMainBuyerList(false);
  };

  const getBuyerForDropDown = async () => {
    const debouncedGetBuyer = debounce(async () => {
      if (searchedValue) {
        let url: any = `store?name=${searchedValue}&buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&limit=8&page=${pageNumberForBuyerDropDown}`;

        if (routeId && routeName) {
          url = `store?name=${searchedValue}&buyer=true&page=${pageNumberForBuyerDropDown}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&route=${routeId}`;
        }

        if (sellerBuyerRouteMappedOnly === 'false') {
          url = `store?name=${searchedValue}&buyer=true&page=${pageNumberForBuyerDropDown}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=false&route-summary=true`;
        }

        await GetRequest(url)
          .then(async (res) => {
            if (res.data.statusCode === 200) {
              if (res.data.data.length < 8) {
                setHasMoreBuyerInDropdownOrNot(() => false);
              } else {
                setHasMoreBuyerInDropdownOrNot(() => true);
              }
              setAllStore((prevState: any) => {
                return prevState.length > 0
                  ? prevState.concat(res.data.data)
                  : res.data.data;
              });
            }
          })
          .catch((err) => {
            console.log(err, 'this is err in fetching all store in searching');
          });
      }
    }, 500);

    await debouncedGetBuyer();
  };

  const handleClickOutsideForStoreDropDown = (event: any) => {
    if (storeListRef.current && !storeListRef.current.contains(event.target)) {
      setAllStore([]);
      setPageNumberForBuyerDropDown(1);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForStoreDropDown);
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideForStoreDropDown
      );
    };
  }, []);

  const onIntersectionForBuyerDropdown = async (entries: any) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMoreBuyerInDropdownOrNot) {
      setPageNumberForBuyerDropDown((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (buyerDropdownRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForBuyerDropdown);
      observer.observe(buyerDropdownRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [allStore.length > 0, hasMoreBuyerInDropdownOrNot]);

  const handleClickOnStore = async (data: any) => {
    // setPage(pageNumberForBuyerDropDown);
    let newArr = [...allStore];
    newArr.unshift(data);
    new Set(newArr);

    // await setAllBuyer((prevState: any) => {
    //   return [newArr][0];
    // });

    setAllStore([]);
    setPageNumberForBuyerDropDown(1);
    setSearchedValue('');
  };

  // route starts here

  const handleClickOutsideForRoute = (event: any) => {
    if (routeRef.current && !routeRef.current.contains(event.target)) {
      setIsRouteOpen(false);
    }
  };

  const onIntersectionForRouteList = async (entries: any) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMoreRouteListOrNot) {
      setPage((prevPageNumber) => prevPageNumber + 1);
    }
  };

  useEffect(() => {
    if (routeListRefForInfiniteScrolling.current) {
      const observer = new IntersectionObserver(onIntersectionForRouteList);
      observer.observe(routeListRefForInfiniteScrolling.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [listOfRoute.length > 0, hasMoreRouteListOrNot]);

  const fetchRoute = async () => {
    await GetRequest(`route/my-route?page=${pageNumberForRoute}&limit=10`)
      .then(async (res) => {
        if (res.status === 200) {
          if (res.data.data.length < 8) {
            setHasMoreRouteListOrNot(false);
          } else {
            setHasMoreRouteListOrNot(true);
          }

          setListOfRoute((prevState) => {
            return prevState.length > 0
              ? prevState.concat(res.data.data)
              : res.data.data;
          });

          return setIsRouteOpen(true);
        }
      })
      .catch((err) => {
        console.log(err, 'this is err in fetching brand for the first');
      });
  };

  const fetchRouteOnScroll = async () => {
    if (hasMoreRouteListOrNot) {
      await GetRequest(`route/my-route?page=${pageNumberForRoute}&limit=10`)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data.data.length < 8) {
              setHasMoreRouteListOrNot(false);
            } else {
              setHasMoreRouteListOrNot(true);
            }

            setListOfRoute((prevState) => {
              return prevState.length > 0
                ? prevState.concat(res.data.data)
                : res.data.data;
            });

            return setIsRouteOpen(true);
          }
        })
        .catch((err) => {
          console.log(err, 'this is err in fetching brand for the first');
        });
    }
  };

  useEffect(() => {
    if (pageNumberForRoute > 1) {
      fetchRouteOnScroll();
    }
  }, [pageNumberForRoute]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForRoute);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForRoute);
    };
  }, []);

  const handleActiveRoute = async (route: any) => {
    setSelectedRoute({
      id: route._id,
      name: route.name,
    });
    setListOfRoute([]);
    setErrorForRoute('');
    setUserWantToSeeNotAssignedBuyer(false);
    setIsRouteOpen(false);
  };

  useEffect(() => {
    if (searchedValue) {
      getBuyerForDropDown();
    }
  }, [searchedValue, pageNumberForBuyerDropDown]);

  // route end here

  const removeAssignedRoute = async () => {
    await DeleteRequest(
      `route/${dataForDelete.route._id}/buyer/${dataForDelete._id}?buyer-type=Store`
    )
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await setPage(1);
          let url: any = `store?buyer=true&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&limit=8&page=${page}&route-summary=true`;
          if (routeId && routeName) {
            url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=true&route=${routeId}&route-summary=true`;
          }
          if (sellerBuyerRouteMappedOnly === 'false') {
            url = `store?buyer=true&page=${page}&limit=8&seller-buyer-route-mapped=true&seller-buyer-route-mapped-only=false&route-summary=true`;
          }
          await GetRequest(url)
            .then((res) => {
              if (res.data.data.length < 8) {
                setHasMoreBuyerListOrNot(false);
              } else {
                setHasMoreBuyerListOrNot(true);
              }
              setAllBuyer((prevState: any) => res.data.data);
            })
            .catch((err) => {
              console.log(err, 'err in fetching');
            });
        }
      })
      .catch((err) => {
        console.log(err, 'this is the err in removing assigned route');
      });
  };

  const handleChangeOnSearchedValue = (event: any) => {
    let { value } = event.target;

    setPageNumberForBuyerDropDown(() => 1);
    setSearchedValue(() => value);
    if (!value) {
      setAllStore(() => []);
    }
  };
  async function handleNotifyBuyer(data: RouteMappedBuyer) {
    try {
      const response = PostRequest(
        `route/${data.route._id}/buyer/${data._id}/send-notification`,
        {}
      );
    } catch (error) {}
  }

  return (
    <>
      <div className="cnt-of-buyer-page">
        {showAssignRouteForm && (
          <AssignOrReassignRoute
            allBuyer={allBuyer}
            setAllBuyer={setAllBuyer}
            setShowAssignRouteForm={setShowAssignRouteForm}
            sellerRoute={sellerRoute}
            setSellerRoute={setSellerRoute}
            buyerStore={buyerStore}
            setBuyerStore={setBuyerStore}
            // fetchScheduledOrder={fetchScheduledOrder}
          />
        )}
        {showFilter && (
          <BuyerFilter
            setPageNumberForRoute={setPageNumberForRoute}
            setHasMoreBuyerListOrNot={setHasMoreBuyerListOrNot}
            setShowFilter={setShowFilter}
            selectedRoute={selectedRoute}
            setSelectedRoute={setSelectedRoute}
            fetchRoute={fetchRoute}
            setListOfRoute={setListOfRoute}
            setHasMoreRouteListOrNot={setHasMoreRouteListOrNot}
            setIsRouteOpen={setIsRouteOpen}
            isRouteOpen={isRouteOpen}
            listOfRoute={listOfRoute}
            routeRef={routeRef}
            handleActiveRoute={handleActiveRoute}
            routeObserver={routeObserver}
            errorForRoute={errorForRoute}
            setErrorForRoute={setErrorForRoute}
            userWantToSeeNotAssignedBuyer={userWantToSeeNotAssignedBuyer}
            setUserWantToSeeNotAssignedBuyer={setUserWantToSeeNotAssignedBuyer}
            setAllBuyer={setAllBuyer}
          />
        )}

        {showConfirmationBox && (
          <ConfirmationBox
            removeAssignedRoute={removeAssignedRoute}
            setShowConfirmationBox={setShowConfirmationBox}
            setDataForDelete={setDataForDelete}
          />
        )}

        <h1 className="heading-of-buyer-page">Buyer Lists</h1>

        <div className="search-bar-cnt">
          <div
            className="search-bar"
            style={{
              position: 'relative',
            }}
          >
            <input
              type="text"
              placeholder="Search Buyer"
              onChange={handleChangeOnSearchedValue}
              onFocus={getBuyerForDropDown}
              value={searchedValue}
            />
            <button className="search-icon" onClick={getBuyerForDropDown}>
              <FiSearch />
            </button>
            {allStore.length > 0 ? (
              <ul
                className="store-list"
                style={{ overflowY: 'scroll', zIndex: 1 }}
                ref={storeListRef}
              >
                {allStore.map((data: RouteMappedBuyer, index: number) => (
                  <li
                    key={data._id}
                    onClick={() => handleClickOnStore(data)}
                    className="each-store"
                  >
                    <img
                      src={
                        data.profileImage
                          ? data.profileImage
                          : '/distributor-icon.svg'
                      }
                      alt={data._id}
                      className="store-img"
                    />
                    <div>
                      <Link to={`store/${data.name}`} className="name">
                        {' '}
                        {data.name}
                      </Link>
                      <p className="location">
                        {data.address.district},{data.address.state},
                        {data.address.country} ,{data.address.postalCode}
                      </p>
                    </div>
                  </li>
                ))}
                {allStore.length > 0 && hasMoreBuyerInDropdownOrNot && (
                  <div
                    style={{
                      padding: '20px',
                    }}
                    ref={buyerDropdownRefForInfiniteScrolling}
                  ></div>
                )}
              </ul>
            ) : (
              ''
            )}
          </div>
          <button
            className="filter-icon"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <img src="filter.png" alt="filter" />
          </button>
        </div>

        <div
          className="cnt-list-of-buyer"
          style={{
            flexBasis: '100%',
          }}
        >
          <div>
            <ul className="all-buyer-cnt">
              {allBuyer.length > 0
                ? allBuyer.map((data: any, index: number) => {
                    return (
                      <div className="buyer" key={data._id}>
                        <div className="buyer-info">
                          {data.sellerBuyerRouteMapped && (
                            <p
                              className="delete"
                              onClick={() => {
                                setDataForDelete(data);
                                setShowConfirmationBox(true);
                              }}
                            >
                              <RiDeleteBin5Fill />
                            </p>
                          )}

                          <div
                            className="cnt-of-buyer-img-n-buyer-info"
                            style={{ flexDirection: 'column' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '8px',
                              }}
                            >
                              {!data.profileImage ? (
                                <AiTwotoneShop className="buyer-img padding-14" />
                              ) : (
                                <img
                                  className="buyer-img"
                                  src={data.profileImage}
                                  alt="buyer-store"
                                />
                              )}
                            </div>

                            <h2
                              className="buyer-name"
                              style={{ textAlign: 'center' }}
                            >
                              {data.name}
                            </h2>
                            <div className="buyer-date-n-email">
                              {data.phoneNumberList?.length > 0 ? (
                                <p className="buyer-number">
                                  <span className="buyer-phone-icon">
                                    <AiTwotonePhone />
                                  </span>

                                  {` ${data.phoneNumberList[0].phoneNumber.substring(
                                    0,
                                    3
                                  )}  ${data.phoneNumberList[0].phoneNumber.substring(
                                    3
                                  )}`}
                                </p>
                              ) : (
                                ''
                              )}
                              {data.email ? (
                                <p className="buyer-email">
                                  <span className="buyer-email-icon">
                                    <MdEmail />
                                  </span>
                                  <span>{data.email}</span>
                                </p>
                              ) : (
                                ''
                              )}
                            </div>
                            <p className="location">
                              {/* <span className="buyer-location-icon">
                                <FaLocationArrow />
                              </span> */}

                              {data.address?.district +
                                ' ,' +
                                data.address?.state +
                                ' ,' +
                                data.address?.country +
                                ' ,' +
                                data.address?.postalCode}
                            </p>

                            {
                              <p className="route-name">
                                Delivery route -{' '}
                                {data.sellerBuyerRouteMapped
                                  ? data?.route?.name
                                  : 'Not Assigned'}
                              </p>
                            }

                            <p
                              className="reassign-buyer"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleAssignOrReassignTheRoute(data)
                              }
                            >
                              <span className="icon">
                                <FaRoute />
                              </span>
                              {data.sellerBuyerRouteMapped
                                ? 'Re-assign to a new route'
                                : 'Assign to a route'}
                            </p>
                            <Link
                              to={`/order?status=ordered&buyer-name=${data.name}&buyer-store=${data._id}`}
                              className="order-page-link"
                            >
                              <span className="icon">
                                <FiExternalLink />
                              </span>
                              See Orders
                            </Link>
                            <p
                              className="view-schedule-and-view-buyer-link"
                              onClick={() => handleNotifyBuyer(data)}
                            >
                              Notify Buyers
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}

              {!isLoadingForMainBuyerList && allBuyer.length === 0 ? (
                <p className="no-data-found">
                  {' '}
                  No buyer found in {routeName} route
                </p>
              ) : (
                ''
              )}

              {isLoadingForMainBuyerList && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexBasis: '100%',
                    flexWrap: 'wrap',
                    marginTop: '1rem',
                  }}
                >
                  {' '}
                  <Loader />
                </div>
              )}

              {allBuyer.length > 0 && (
                <div
                  ref={buyerListRefForInfiniteScrolling}
                  style={{
                    padding: '20px',
                    flexBasis: '100%',
                  }}
                ></div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
