import { forwardRef } from 'react';
import './select.scss';
type Data = { value: string; label: string };
interface Props {
  placeholder: string;
  label: string;
  value: string;
  handleChange: (value: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  initialValue?: string;
  option: Data[];
  disabled?: boolean;
}
const DropDown = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  return (
    <div className="custom-select-cnt">
      <label
        className={props.value ? 'label label-should-move-upward' : ''}
        htmlFor={props.label}
      >
        {props.label}
      </label>

      <select
        className="custom-dropdown"
        onChange={props.handleChange}
        name={props.name}
        ref={ref}
        disabled={!!props.disabled}
      >
        {/* <option className="" value={props.label} selected={true}>
          {props.label}
        </option> */}
        {props.option.map((data: Data) => (
          <option
            value={data.value}
            selected={props.value === data.value}
            className="option"
            key={data.value}
          >
            {data.label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default DropDown;
