import '../style/productList.scss';
import BundleMinimumQuantity from './BundleMinimumQunatity';
import BundleOffer from './BundleOffer';
import BundlePricePerUnit from './BundlePricePerUnit';

export default function Bundle(props: any) {
  let {
    setProductState,
    hasError,
    handleAddMoreRange,
    handleChange,
    productState,
    createLastQuantityWisePricePerObj,
  } = props;
  return (
    <div>
      <BundleMinimumQuantity
        handleChange={handleChange}
        productState={productState}
      />
      <BundlePricePerUnit
        createLastQuantityWisePricePerObj={createLastQuantityWisePricePerObj}
        handleAddMoreRange={handleAddMoreRange}
        handleChange={handleChange}
        productState={productState}
        setProductState={setProductState}
      />

      {/* <BundleOffer
        setProductState={setProductState}
        handleChange={handleChange}
        productState={productState}
      /> */}
    </div>
  );
}
